/* eslint-disable react/jsx-props-no-spreading */
import DateFnsUtils from '@date-io/date-fns';
import {
  // KeyboardTimePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import deLocale from 'date-fns/locale/pt-BR';
import TextField from '@material-ui/core/TextField';
import React, { useEffect, useState } from 'react';
import moment from 'moment';

const DateComponent = ({
  formatoData = 'dd/MM/yyyy',
  id,
  label,
  dataInicial = Date.now(),
  callback,
  dataMinima,
  width,
  ...rest
}) => {
  const handleDateChange = date => {
    console.log(date.target.value);
    callback(date.target.value);
  };

  return (
    <TextField
      id={id}
      label={label}
      type="date"
      defaultValue={dataInicial}
      InputLabelProps={{
        shrink: true,
      }}
      style={{ width: `${width || '100%'}` }}
      onChange={handleDateChange}
      size="small"
      variant="outlined"
      {...rest}
    />
  );
};

export default DateComponent;
