import { all, takeLatest, select, call, put } from 'redux-saga/effects';
import { api_multipague_conta_paged_favoritos } from '../../../../services/api';
import { hideLoading, showLoading } from '../../loading/actions';
import { listarFavoritosError, listarFavoritosSuccess } from './actions';

import { toastComponentError } from '../../../../components/Toast';

function* listarFavoritos({ payload }) {
  try {
    yield put(showLoading(true));
    const { data } = yield call(
      api_multipague_conta_paged_favoritos.get,
      `/favorecido?idCliente=${payload.id}&idClienteConta=${payload.idClienteConta}&situacao=${payload.situacao}&ativo=${payload.ativo}&page=${payload.page}&size=${payload.size}`,
    );

    yield put(hideLoading(false));

    if (data.totalElements > 0) {
      yield put(
        listarFavoritosSuccess({
          content: data.content,
          totalElements: data.totalElements,
          currentPage: data.currentPage,
          numberOfElements: data.numberOfElements,
          pageSize: data.numberOfElements,
          totalPages: data.totalPages,
        }),
      );
    } else {
      yield put(listarFavoritosError());
    }
  } catch (err) {
    yield put(hideLoading(false));
    yield put(listarFavoritosError({ error: err.response.status}));
    if (err.response.status >= 401 && err.response.status < 404) {
      toastComponentError(
        'Não foi possivel chamar o serviço. Por favor tente novamente mais tarde.',
      );
    } else {
      // toast.error(
      //   ` Codigo: ${err.response.data.codigo} - ${err.response.data.mensagem}`,
      // );
    }
  }
}

export default all([takeLatest('LISTAR_FAVORITOS_REQUEST', listarFavoritos)]);
