import { alpha, makeStyles } from '@material-ui/core';

const drawerWidth = 260;

export const useStyles = makeStyles(theme => ({
  toolbar: {
    paddingRight: 24,
  },
  toolbarIcon: {
    position: 'fixed',
    borderBottom: `1px solid ${alpha(theme.palette.background.paper, 0.2)}`,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '10px',
    height: '100px',
    zIndex: theme.zIndex.drawer + 1,
    width: '300px',
    backgroundColor: theme.palette.appBar[theme.palette.type].bgMenu,
    ...theme.mixins.toolbar,
  },
  toolbarIconShift: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: '62px',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    [theme.breakpoints.down('sm')]: {
      height: '85px',
    },
    [theme.breakpoints.down('xs')]: {
      height: '10px',
    },
  },
  appBar: {
    position: 'fixed',
    padding: '17px 0 0',
    backgroundColor:
      theme.palette.type === 'dark'
        ? theme.palette.background.dark
        : theme.palette.background.default,
    paddingLeft: '20px',
    borderBottom: `1px solid ${alpha(theme.palette.text.disabled, 0.2)}`,
    height: '100px',
    color: theme.palette.appBar[theme.palette.type].text,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0',
      padding: '0',
      height: '100px',
      backgroundColor: theme.palette.appBar[theme.palette.type].bgMenu,
    },
  },
  appBarShift: {
    padding: '0',
    position: 'fixed',
    paddingLeft: '20px',
    marginLeft: drawerWidth,
    marginRight: 0,
    flexGrow: 1,
    backgroundColor:
      theme.palette.type === 'dark'
        ? theme.palette.background.dark
        : theme.palette.background.default,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    zIndex: theme.zIndex.drawer + 5,
    overflowX: 'auto',
    scrollbarColor: theme.palette.primary.main,
    scrollbarWidth: 'thin',
    height: '100px',
    justifyContent: 'center',

    '&::-webkit-scrollbar': {
      width: '8px',
      maxHeight: '8px',
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '3px',
    },

    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0',
      padding: '0',
      height: '100px',
      backgroundColor: theme.palette.appBar[theme.palette.type].bgMenu,
    },
    justifyContent: 'center',
  },
  boxGridBgMenu: {
    [theme.breakpoints.between('sm', 'md')]: {
      display: 'flex',
    },
  },
  gridBgMenu: {
    borderRadius: '20px',
    display: 'flex',
    padding: 10,
    height: '80px',
    backgroundColor: theme.palette.appBar[theme.palette.type].bgMenu,
  },
  gridBgMenuNome: {
    borderRight: `2px solid ${alpha(
      theme.palette.getContrastText(
        theme.palette.appBar[theme.palette.type].bgMenu,
      ),
      0.2,
    )}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  gridBgMenuBanco: {
    textTransform: 'uppercase',
    fontWeight: 100,
  },
  gridBgMenuBancoFont: {
    fontSize: '12px',
  },
  gridBgMenuSaldo: {
    fontSize: '12px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  iconButtonSaldo: {
    padding: 0,
  },
  iconButtonRight: {
    padding: 2,
  },
  iconButtonArrowDown: {
    padding: 0,
    margin: 0,
  },
  iconButtonBrightness5: {
    color: theme.palette.background.default,
  },
  iconButtonBrightness2: {
    color: theme.palette.appBar[theme.palette.type].bgMenu,
  },
  iconButtonBrightness2Mobile: {
    color: theme.palette.background.paper,
  },
  menuButton: {
    marginRight: 36,
    [theme.breakpoints.down('sm')]: {
      marginRight: 5,
      marginLeft: 15,
      marginTop: 20,
    },
    [theme.breakpoints.down('xs')]: {
      marginRight: 8,
      marginLeft: -5,
    },
  },
  menuButtonHidden: {
    display: 'none',
  },
  logo: {
    width: '65%',
    marginRight: '10px',
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    border: 'none',
    width: drawerWidth,
    backgroundColor: theme.palette.appBar[theme.palette.type].bgMenu,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    border: 'none',
    backgroundColor: theme.palette.appBar[theme.palette.type].bgMenu,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 110,
    [theme.breakpoints.down('xs')]: {
      width: 0,
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paperModal: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '20px',
    padding: theme.spacing(4),
  },
  button: {
    width: '100%',
    marginTop: theme.spacing(1),
    fontWeight: '500',
    fontSize: '14px',
    textTransform: 'none',
  },
  bancoEmpresaGrid: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      marginTop: '5px',
      justifyContent: 'center',
    },
  },
  acessoGrid: {
    display: 'flex',
    alignItems: 'center',
    borderRight: `2px solid ${alpha(
      theme.palette.getContrastText(
        theme.palette.appBar[theme.palette.type].bgMenu,
      ),
      0.2,
    )}`,
  },
  IconButtonsGrid: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-end',
      marginTop: '-12px',
      marginLeft: '10px',
      height: '55px',
    },
  },
  chevroletBackground: {
    width: 25,
    height: 25,
    boxShadow: 'none',
    marginLeft: 10,
    textTransform: 'initial',
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: alpha(theme.palette.primary.main, 0.4),
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.getContrastText(theme.palette.primary.main),
    },
  },
  topBarMobile: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '50px',

    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  topBarMobileleft: {
    display: 'flex',
    flex: 1,
    marginLeft: '15px',
    justifyContent: 'flex-start',
    alignItems: 'center',

    [theme.breakpoints.down('xs')]: {
      marginLeft: '5px',
    },
  },
  divTopBarMobileContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 30,
  },
  gridMobileRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginLeft: '20px',
  },
  menuMobileAlert: {
    marginLeft: '10px',
    marginRight: '10px',
  },
  menuMobileDropdown: {
    marginRight: 5,
  },
}));
