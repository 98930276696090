import React from 'react';
import {
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  Tooltip,
  IconButton,
  Checkbox,
} from '@material-ui/core';
import { MdCheck, MdClose } from 'react-icons/md';
import { TextEditor } from '../Inputs/TextEditor';
import ConfirmButton from '../Buttons/ConfirmButton';

export const Termos = ({
  termoAssunto,
  openModal,
  handleCloseModal,
  children,
  onAprove,
  aprove,
  reject,
  loadingButton,
  onReject,
  onConfirm,
}) => {
  return (
    <Dialog
      open={openModal}
      PaperProps={{
        style: {
          borderRadius: '8px',
        },
      }}
      maxWidth={'md'}
      fullWidth={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent dividers style={{ paddingBottom: '30px' }}>
        <Typography
          style={{ textAlign: 'center', marginBottom: 15 }}
          variant="h6"
          component="h6"
        >
          {termoAssunto}
        </Typography>
        <Tooltip title="Fechar">
          <IconButton
            onClick={handleCloseModal}
            style={{
              position: 'absolute',
              top: '16px',
              right: '16px',
            }}
          >
            <MdClose size={18} />
          </IconButton>
        </Tooltip>

        <TextEditor
          formats={[
            'header',
            'font',
            'size',
            'bold',
            'italic',
            'underline',
            'strike',
            'blockquote',
            'list',
            'bullet',
            'indent',
            'link',
            'image',
            'video',
          ]}
          readOnly
          value={children}
          modules={{
            toolbar: false,
            clipboard: {
              matchVisual: false,
            },
          }}
        />
      </DialogContent>

      <DialogActions>
        <Grid container style={{ padding: 10 }}>
          <Grid
            item
            md={12}
            style={{
              marginLeft: 20,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  checked={aprove}
                  onChange={onAprove}
                />
              }
              label={
                <Typography style={{ fontSize: '13px' }}>Aceitar</Typography>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  checked={reject}
                  onChange={onReject}
                />
              }
              label={
                <Typography style={{ fontSize: '13px' }}>Rejeitar</Typography>
              }
            />
          </Grid>

          <Grid
            item
            md={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: 10,
            }}
          >
            <ConfirmButton
              title="Confirmar"
              isLoading={loadingButton}
              disabled={loadingButton}
              onClick={onConfirm}
              startIcon={<MdCheck color="background" />}
            />
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
