import React, { useState, useEffect, useMemo } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import formattedMoney from '../../util/FormatteMoney/index';
import Button from '../../components/Buttons/ConfirmButton';
import { useDispatch, useSelector } from 'react-redux';
import { MdPrint } from 'react-icons/md';

const useStyles = makeStyles(theme => ({
  root: {
    height: 280,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  buttonOff: {
    display: 'none',
  },
  comprovante: {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  grey: {
    color: 'grey',
  },
  icons: {
    marginTop: '3px',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  alinhamento: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  alinhar: {
    float: 'left',
  },
}));

export default function ExtractPdfDiario() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const infoRedux = useSelector(state => state.contas);

  const [dadosConta, setDadosConta] = useState();

  const accountComponent = useMemo(
    () =>
      !!infoRedux.contasCliente.length &&
      infoRedux.contasCliente.map(item => (
        // <ListItem button onClick={() => console.log(item)} key={item}>
        <ListItem button onClick={() => setDefaultAccount(item)} key={item}>
          <ListItemText
            secondary={
              'Banco: ' +
              item.banco.nome +
              ' / ' +
              'Agência ' +
              item.agencia +
              ' / ' +
              'Conta: ' +
              item.conta +
              '-' +
              item.digitoConta
            }
          />
        </ListItem>
      )),
    [infoRedux, dispatch],
  );

  function printDiv() {
    var css = '@page { size: portrait; }',
      head = document.head || document.getElementsByTagName('head')[0],
      style = document.createElement('style');

    style.type = 'text/css';
    style.media = 'print';

    if (style.styleSheet) {
      style.styleSheet.cssText = css;
    } else {
      style.appendChild(document.createTextNode(css));
    }

    head.appendChild(style);

   document.getElementById('printButton').classList.add('buttonOff');
   document.getElementById('printButton').style.display = 'none';
   window.print();
   document.getElementById('printButton').style.display = 'inline';
  }

  const rows = JSON.parse(localStorage.getItem('pdfExtratoDiario'));

  const newRows = rows.map(value => (
    <List>
      <ListItem>
        <ListItemText secondary={value.data} />
      </ListItem>
      <ListItem style={{ display: 'flex', justifyContent: 'space-between' }}>
        <ListItemText primary={'Saldo do Dia'} />
        <ListItemText
          style={{ color: '#0000ff', textAlign: 'end' }}
          primary={value.saldoDia}
        />
      </ListItem>
      {value.transacoes.map(index => (
        <>
          <ListItem
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <ListItemText primary={index.descricaoEvento} />
            {index.tipoEvento != 'D' ? (
              <ListItemText
                style={{ color: '#0000ff', textAlign: 'end' }}
                primary={formattedMoney(parseFloat(index.valor))}
              />
            ) : (
              <ListItemText
                style={{ color: '#ff0000', textAlign: 'end' }}
                primary={'-' + formattedMoney(parseFloat(index.valor))}
              />
            )}
          </ListItem>
          <ListItem style={{ marginTop: '-20px' }}>
            <ListItemText secondary={index.nome} />
          </ListItem>
        </>
      ))}
      <ListItem style={{ display: 'flex', justifyContent: 'space-between' }}>
        <ListItemText primary={'Saldo Anterior'} />
        <ListItemText
          style={{ color: '#0000ff', textAlign: 'end' }}
          primary={value.saldoAnterior}
        />
      </ListItem>
      <Divider />
    </List>
  ));

  useEffect(() => {
    setDadosConta(infoRedux);
  }, [infoRedux]);

  console.log('Teste redux: ', accountComponent);
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="md">
        <div id="printableArea">
          <img alt="" height="80" src={theme.config.loginLogo} />
          <div
            className={classes.comprovante}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Button
              variant="contained"
              title={'Imprimir'}
              color="primary"
              id="printButton"
              startIcon={<MdPrint color="background" />}
              onClick={printDiv}
            >
              Imprimir
            </Button>
            <br />
          </div>
          <div
            className={classes.comprovante}
            style={{ display: 'block', textAlign: 'center' }}
          >
            <Typography variant="body1" component="body1">
              CNPJ: {localStorage.getItem('printCnpj')}
            </Typography>
            <br />
            <Typography variant="body1" component="body1">
              Nome: {localStorage.getItem('printNome')}
            </Typography>{' '}
            <br />
            <Typography variant="body1" component="body1">
              Banco: {localStorage.getItem('printBanco')}
            </Typography>{' '}
            <br />
            <Typography variant="body1" component="body1">
              Agencia: {localStorage.getItem('printAgencia')}
            </Typography>{' '}
            <br />
            <Typography variant="body1" component="body1">
              Conta: {localStorage.getItem('printConta')}-
              {localStorage.getItem('printDigito')}
            </Typography>
          </div>
          {newRows}
        </div>
      </Container>
    </React.Fragment>
  );
}
