import moment from 'moment';
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import Button from '../../components/Buttons/ConfirmButton';
import Boleto from '../../components/Inputs/Boleto';
import CPF from '../../components/Inputs/CPF';
import InputText from '../../components/InputText';
import SmsToken from '../../components/Inputs/SmsToken';
import jwt_decode from 'jwt-decode';
import RadioGroup from '../../components/RadioButton';
import DateComponent from '../../components/InputDate';
import axios, {
  api_multipague_cobranca,
  api_multipague_conta,
} from '../../services/api';
import * as Yup from 'yup';
import getValidationErros from '../../util/FormattedError';
import { listarCentroCustoRequest } from '../../store/modules/centroCusto/listar/actions';
import PopUp from '../../components/PopUp';
import Password from '../../components/Password';
import FormatteMoney from '../../util/FormatteMoney';
import Alert from '@material-ui/lab/Alert';
import { MdMonetizationOn } from 'react-icons/md';
import {
  Dialog,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  useTheme,
} from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import {
  hideLoadingButton,
  showLoadingButton,
} from '../../store/modules/loading/actions';
import {
  AgendamentoPagamento,
  BancoPagador,
  Container,
  ContainerButton,
  CpfCnpjBeneficiario,
  DadosCadastrais,
  DadosSecundariosBoleto,
  DigitoBoleto,
  NomeFantasia,
  Subcontainer,
  Valor,
  Dates,
} from './styles';
import { showPopUp } from '../../store/modules/popUp/actions';
import { useHistory } from 'react-router';

import Box from '../../components/Box';
import Wrapper from '../../components/Wrapper';
import { useAuthContext } from '../../Context/AuthContext';
import {
  toastComponentError,
  toastComponentSuccess,
} from '../../components/Toast';
import { InputMoney } from '../../components/Inputs/Money';

function PayTicket() {
  const [open, setOpen] = React.useState(false);
  const { permissionAdmin, permissionBackEmpresa } = useAuthContext();
  const token = localStorage.getItem('tkn-access');
  const { realm_access, permissaoContas } = jwt_decode(token);
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const [loadingButtonSms, setLoadingButtonSms] = useState(false);
  const listCentroCusto = useSelector(state => state.centroCusto);
  const { loadingButton } = useSelector(state => state.loading);
  const { userAccount } = useSelector(state => state.user);
  const { contaPadrao } = useSelector(state => state.contas);
  const saldo = useSelector(state => state.saldo);
  const [centroCusto, setCentroCusto] = useState([
    {
      centroCustoList: [],
      centroCustoSelected: ' ',
    },
  ]);

  const [infosPagamento, setInfosPagamento] = useState({});
  const [dataAgendamento, setDataAgendamento] = useState(Date.now());
  const [dataVencimento, setDataVencimento] = useState(
    moment(new Date()).format('YYYY-MM-DD'),
  );
  const [dataLimitePagamento, setDataLimitePagamento] = useState(Date.now());
  const [empresa, setEmpresa] = useState(false);
  const [formularioTicket, setFormularioTicket] = useState(false);
  const [linhaDigitavel, setLinhaDigitavel] = useState();
  const [valorSms, setValorSms] = useState('');
  const [paymentDate, setPaymentDate] = useState([
    {
      label: 'Hoje',
      value: uuidv4(),
      selected: false,
      agendado: false,
    },
    {
      label: 'Agendamento',
      value: uuidv4(),
      selected: false,
      agendado: true,
    },
  ]);
  function handleOpen() {
    // Manda o sms pro cliente
    setOpen(true);
    api_multipague_conta
      .post(`/token/send-token/${contaPadrao.id}`)
      .then(response => {
        setOpen(true);
      })
      .catch(err => {
        console.log(err);
      });
  }

  function handleClose() {
    setOpen(false);
  }
  const [agendamentoFormaPagamento, setAgendamentoFormaPagamento] = useState(
    {},
  );
  const [carregarBoleto, setCarregarBoleto] = useState(false);
  const [nomeBeneficiario, setBeneficiario] = useState({
    id: null,
    banco: {
      codigo: '',
      descricao: '',
    },
    nomeBeneficiario: '',
    documentoFederalBeneficiario: '',
    tipoPessoaBeneficiario: '',
    dataLimitePagamento: null,
    dataCadastro: null,
    valorTitulo: 0,
    valorAbatimento: 0,
    valorJuros: 0,
    valorMulta: 0,
    valorPagamento: 0,
    linhaDigitavel: null,
    codigoBarras: '',
    numeroTitulo: null,
    descontos: [],
    erro: null,
  });
  const infoRedux = useSelector(state => state.contas);

  useEffect(() => {
    const verify = realm_access.roles.filter(
      val => val.toUpperCase() === 'BACKOFFICE-EMPRESA',
    );
    setEmpresa(verify);
  }, []);

  const formatCPF = value => {
    const cleanedValue = value.replace(/\D/g, '');

    if (cleanedValue.length === 11) {
      const formattedValue = cleanedValue.replace(
        /^(\d{3})(\d{3})(\d{3})(\d{2})$/,
        '$1.$2.$3-$4',
      );
      return formattedValue;
    } else if (cleanedValue.length === 14) {
      const formattedValue = cleanedValue.replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
        '$1.$2.$3/$4-$5',
      );
      return formattedValue;
    } else {
      return value;
    }
  };

  useEffect(() => {
    if (userAccount?.id) {
      dispatch(listarCentroCustoRequest(userAccount?.id));
    }
  }, [dispatch, userAccount]);

  useEffect(() => {
    if (listCentroCusto.length) {
      const formatted = listCentroCusto.map(data => ({
        ...data,
        label: data.descricao,
        value: data.id,
      }));
      setCentroCusto({ centroCustoList: formatted });
    }
  }, [listCentroCusto]);

  function selecionarDiaPagamento(data) {
    const dataSelecionada = paymentDate.map(val =>
      val.value === data
        ? {
          ...val,
          selected: true,
        }
        : {
          ...val,
          selected: false,
        },
    );
    const filterDalaSelecionada = dataSelecionada.filter(date => date.selected);
    setAgendamentoFormaPagamento(filterDalaSelecionada[0]);
    setPaymentDate(dataSelecionada);
  }

  const mostrarDataAgendamento = () => {
    if (
      agendamentoFormaPagamento.agendado &&
      agendamentoFormaPagamento.selected
    ) {
      return (
        <DateComponent
          id="data-pagamento-agendado"
          label="Agendar Pagamento"
          callback={setDataAgendamento}
          value={dataAgendamento}
        />
      );
    }
  };

  const pesquisarBoleto = useCallback(async linhaDigitavel => {
    setLinhaDigitavel(linhaDigitavel);
    try {
      const token = localStorage.getItem('tkn-access');
      const { data } = await api_multipague_cobranca.get(
        `/boleto/banco/MONEYPLUS?linhaDigitavel=${linhaDigitavel}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            ContentType: 'application/json',
            IdempotencyKey: uuidv4(),
          },
        },
      );

      setLinhaDigitavel(linhaDigitavel);
      setCarregarBoleto(false);

      if (!data.codigoBarras) {
        toastComponentError('Não foi possivel consultar dados do boleto.');
        history.push('/dashboard');
        return;
      }
      if (data.dataVencimento != null) {
        setDataVencimento(
          moment(data.dataVencimento, 'YYYY-MM-DD').format('YYYY-MM-DD'),
        );
      }
      setDataAgendamento(
        moment(data.dataPagamento, 'YYYY-MM-DD').format('YYYY-MM-DD'),
      );

      setDataLimitePagamento(
        moment(data.dataLimitePagamento, 'YYYY-MM-DD').format('YYYY-MM-DD'),
      );

      setBeneficiario(data);

      setInfosPagamento({
        ...data,
      });

      const formInfo = formRef.current.getData();

      formRef.current.setData({
        linhaDigitavel: formInfo.linhaDigitavel,
        nomeBeneficiario: data.nomeBeneficiario ? data.nomeBeneficiario : '',
        documentoFederalBeneficiario:
          formatCPF(data.documentoFederalBeneficiario) || '',
        bancoPagador:
          data.banco.descricao == 'null - null' || ''
            ? ''
            : data.banco.descricao,
        valorTitulo: FormatteMoney(data.valorTitulo),
        valorDesconto:
          data.descontos[0].total === 0
            ? 'R$ 0,00'
            : FormatteMoney(data.descontos[0].total),
        valorPagamento: data.valorPagamento,
      });

      setFormularioTicket(formInfo.linhaDigitavel.length == 48 ? true : false);
    } catch (err) {
      setCarregarBoleto(false);
      if (
        (err?.response?.status >= 401 && err?.response?.status < 404) ||
        err?.response?.status === 500
      ) {
        toastComponentError(
          'Não foi possivel chamar o serviço. Por favor, tente mais tarde.',
        );
      } else if (err?.response?.status === 504) {
        toastComponentError(
          `Não foi possível identificar o boleto. Por favor, tente mais tarde.`,
        );
      } else {
        toastComponentError(
          ` Codigo: ${err.response.data.codigo} - ${err.response.data.mensagem}`,
        );
      }
    }
  }, []);

  const submitForm = useCallback(
    async info => {
      try {
        const schmeCadastro = Yup.object().shape({
          linhaDigitavel: Yup.string().required('Campo obrigatorio'),
        });

        await schmeCadastro.validate(info, {
          abortEarly: false,
        });

        // if (
        //   agendamentoFormaPagamento.agendado &&
        //   agendamentoFormaPagamento.selected
        // ) {
        //   if (moment(dataAgendamento).isBefore(Date.now())) {
        //     toastComponentError(
        //       ` Data de pagamento não pode ser anterior ao dia de hoje`,
        //     );
        //     return;
        //   }
        // }

        const verify = realm_access.roles.filter(
          val => val.toUpperCase() === 'correntista_operador'.toUpperCase(),
        );

        if (!permissionAdmin) {
          submitTed('');
        } else if (
          permissionAdmin &&
          infoRedux.contaPadrao?.tokenPorTransacao == true
        ) {
          handleOpen();
          // setOpen(true)
        } else {
          const jwtToken = jwt_decode(localStorage.getItem('tkn-access'));
          const contaId = localStorage.getItem('idConta');

          if (jwtToken?.permissaoContas) {
            const conta = jwtToken?.permissaoContas?.find(item =>
              item.contas.find(conta => conta.id == contaId),
            );

            if (conta?.role === 'correntista_operador') {
              submitTed('');
            } else {
              dispatch(showPopUp(true));
            }
          }
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const erros = getValidationErros(err);
          formRef.current?.setErrors(erros);
        } else {
          toastComponentError(
            ` Codigo: ${err.response.data.codigo} - ${err.response.data.mensagem}`,
          );
        }
        console.log(err.inner);
      }
    },
    [dataAgendamento, dataVencimento, infosPagamento, centroCusto],
  );

  async function submitTed(password) {
    dispatch(showPopUp(false));
    dispatch(showLoadingButton(true));
    const formInfo = formRef.current.getData();
    try {
      const body = {
        ...infosPagamento,
        ...formInfo,
        linhaDigitavel:
          infosPagamento.linhaDigitavel != null
            ? infosPagamento.linhaDigitavel
            : linhaDigitavel,
        dataPagamento:
          agendamentoFormaPagamento.agendado &&
          agendamentoFormaPagamento.selected
            ? moment(dataAgendamento).format('YYYY-MM-DD')
            : moment(Date.now()).format('YYYY-MM-DD'),
        valorPagamento:
          linhaDigitavel?.slice(-8) == '00000000'
            ? nomeBeneficiario?.valorPagamento
            : infosPagamento.valorPagamento,
        dataVencimento: dataVencimento,
        valorTitulo: infosPagamento.valorTitulo,
        idCentroCusto: centroCusto.centroCustoSelected,
      };
      const idContaCliente = contaPadrao?.id
        ? contaPadrao?.id
        : localStorage.getItem('idConta');
      const { data } = await api_multipague_cobranca.post(
        `/boleto/pagar/${idContaCliente}`,
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            ContentType: 'application/json',
            IdempotencyKey: uuidv4(),
            'x-assinatura-financeira': password,
            token: valorSms,
          },
        },
      );
      dispatch(hideLoadingButton(false));

      if (data.esteira) {
        history.push('/MinhasContas');
        toastComponentSuccess(`Pagamento enviado para aprovação`);
      } else {
        const verify = realm_access.roles.filter(
          val =>
            val.toUpperCase() === 'correntista_administrador'.toUpperCase(),
        );
        if (permissionAdmin) {
          if (
            agendamentoFormaPagamento.agendado &&
            agendamentoFormaPagamento.selected
          ) {
            toastComponentSuccess(
              `A transação será efetivada mediante saldo disponível as 8:00 horas da data agendada `,
            );
          } else {
            toastComponentSuccess(`Boleto pago com sucesso`);
          }
          history.push(`/ComprovanteBoleto/${contaPadrao?.id}/${data.id}`);
        } else {
          toastComponentSuccess('Pagamento enviado para análise');
          history.push(`/dashboard`);
        }
      }
    } catch (err) {
      console.log('aqui erro', err);
      dispatch(hideLoadingButton(false));
      toastComponentError(
        !dataVencimento
          ? 'Preencha o campo Data de Vencimento.'
          : ` Codigo: ${err.response.data.codigo} - ${err.response.data.mensagem}`,
      );
    }
  }

  function callBackLinhaDigitavel(value) {
    setCarregarBoleto(true);
    pesquisarBoleto(value);
  }

  function enviarSms() {
    handleClose();
    dispatch(showPopUp(true));
  }

  return (
    <>
      <Wrapper
        title="Boleto"
        crumb={[{ name: 'Dashboard', link: '/dashboard' }, { name: 'Pagamento' }]}
      >
        <PopUp>
          <Password callback={data => submitTed(data)} />
        </PopUp>
        <Container onSubmit={submitForm} ref={formRef}>
          <Box>
            <Alert severity="warning">
              {' '}
              Horário limite para pagamento de boleto de valor acima de R$
              250.000,00 até às 16:30 horas.
            </Alert>
            <div style={{ height: '0.5rem' }} />
            <Alert severity="warning">
              Valores abaixo de R$ 250.000,00 até às 22:00 horas.
            </Alert>
            <div style={{ height: '1.5rem' }} />

            <Subcontainer>
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle id="form-dialog-title">
                  Insira o token que você recebeu via SMS
                </DialogTitle>
                <DialogContent style={{ margin: 'auto', textAlign: 'center' }}>
                  <SmsToken
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="******"
                    size="small"
                    type="password"
                    name="smsToken"
                    inputProps={{ maxLength: 6 }}
                    onChange={e => setValorSms(e.target.value)}
                  />
                </DialogContent>
                <DialogActions style={{ margin: 'auto' }}>
                  <Button
                    color="primary"
                    variant="contained"
                    width={150}
                    title={'Prosseguir'}
                    style={{ marginTop: '20px', marginBottom: '10px' }}
                    onClick={() => enviarSms()}
                    isLoading={loadingButtonSms}
                    startIcon={
                      <MdMonetizationOn
                        color={useTheme().palette.background.paper}
                      />
                    }
                  ></Button>
                </DialogActions>
              </Dialog>
              <DigitoBoleto>
                <Boleto
                  name="linhaDigitavel"
                  id="linhaDigitavel"
                  variant="outlined"
                  label="Linha Digitável"
                  callback={callBackLinhaDigitavel}
                  isLoading={carregarBoleto}
                />
              </DigitoBoleto>
              <DadosCadastrais>
                <NomeFantasia>
                  <InputText
                    id="nomeBeneficiario"
                    disabled
                    value={nomeBeneficiario.nomeBeneficiario}
                    label="Nome Fantasia/Beneficiário"
                    name="nomeBeneficiario"
                  />
                </NomeFantasia>
                <CpfCnpjBeneficiario>
                  <CPF
                    disabled
                    id="documentoFederalBeneficiario"
                    label="CPF/CNPJ Beneficiário"
                    name="documentoFederalBeneficiario"
                  />
                </CpfCnpjBeneficiario>
                <BancoPagador>
                  <InputText
                    id="bancoPagador"
                    variant="outlined"
                    label="Banco Pagador"
                    name="bancoPagador"
                    disabled
                  />
                </BancoPagador>
                <Dates>
                  <DateComponent
                    id="data-vencimento"
                    label="Data de Vencimento"
                    callback={setDataVencimento}
                    value={dataVencimento}
                    disabled={!formularioTicket}
                  />
                  <DateComponent
                    id="data-limite-pagamento"
                    label="Data Limite Pagamento"
                    callback={setDataLimitePagamento}
                    value={dataLimitePagamento}
                    disabled
                  />
                </Dates>
              </DadosCadastrais>

              <DadosSecundariosBoleto>
                <Valor>
                  <InputText
                    id="valorTitulo"
                    label="Valor Titulo"
                    value={FormatteMoney(nomeBeneficiario.valorTitulo)}
                    name="valorTitulo"
                    disabled
                  />
                  <InputText
                    id="valorDesconto"
                    value={
                      nomeBeneficiario.descontos[0]?.total === 0
                        ? 'R$ 0,00'
                        : FormatteMoney(nomeBeneficiario.descontos[0]?.total)
                    }
                    label="Valor Desconto"
                    name="valorDesconto"
                    disabled
                  />
                  {linhaDigitavel?.slice(-8) == '00000000' ? (
                    <InputMoney
                      prefix={'R$ '}
                      id="valorPagamento"
                      label="Valor Pagamento"
                      value={nomeBeneficiario?.valorPagamento}
                      onChange={val => {
                        const pagamento = val;
                        setBeneficiario({
                          ...nomeBeneficiario,
                          valorPagamento: pagamento,
                        });
                      }}
                      name="valorPagamento"
                    />
                  ) : (
                    <InputText
                      id="valorPagamento"
                      label="Valor Pagamento"
                      disabled
                      value={FormatteMoney(nomeBeneficiario?.valorPagamento)}
                      name="valorPagamento"
                    />
                  )}
                </Valor>
                <FormControl variant="outlined" style={{ flex: 1 }}>
                  <InputLabel
                    shrink={true}
                    style={{
                      padding: '0 10px 0 0',
                      backgroundColor: useTheme().palette.background.paper,
                      height: 20,
                    }}
                    id="demo-simple-select-outlined-label"
                  >
                    Centro de Custo
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label="Tipo Operação"
                    size="small"
                    InputLabelProps={{
                      shrink: false,
                    }}
                    style={{ height: 40 }}
                    value={centroCusto.centroCustoSelected}
                    onChange={event =>
                      setCentroCusto(old => ({
                        ...old,
                        centroCustoSelected: event.target.value,
                      }))
                    }
                  >
                    {centroCusto?.centroCustoList?.map(item => {
                      return <MenuItem value={item.id}>{item.label}</MenuItem>;
                    })}
                  </Select>
                </FormControl>

                <InputText
                  id="historico"
                  label="Histórico"
                  multiline
                  rows={4}
                  variant="outlined"
                  name="descricaoHistorico"
                />
              </DadosSecundariosBoleto>
              <AgendamentoPagamento>
                <RadioGroup
                  titulo="Data de Pagamento"
                  optionDefault={paymentDate[0].value}
                  options={paymentDate}
                  callback={selecionarDiaPagamento}
                />
                {mostrarDataAgendamento()}
              </AgendamentoPagamento>
              <ContainerButton>
                <Button
                  color="primary"
                  variant="contained"
                  width={150}
                  type="submit"
                  title={'Pagar Boleto'}
                  isLoading={loadingButton}
                  disabled={!empresa}
                  startIcon={
                    <MdMonetizationOn
                      color={useTheme().palette.background.paper}
                    />
                  }
                ></Button>
              </ContainerButton>
            </Subcontainer>
          </Box>
        </Container>
      </Wrapper>
    </>
  );
}

export default PayTicket;
