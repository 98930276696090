import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Button from '../../components/Buttons/ConfirmButton';
import moment from 'moment';
import { MdPrint } from 'react-icons/md';

const useStyles = makeStyles(theme => ({
  root: {
    height: 280,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  buttonOff: {
    display: 'none',
  },
  comprovante: {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  grey: {
    color: 'grey',
  },
  icons: {
    marginTop: '3px',
  },
}));

export default function RecipeReviewCard() {
  const classes = useStyles();
  const theme = useTheme();
  function printDiv() {
    var css = '@page { size: portrait; }',
      head = document.head || document.getElementsByTagName('head')[0],
      style = document.createElement('style');

    style.type = 'text/css';
    style.media = 'print';

    if (style.styleSheet) {
      style.styleSheet.cssText = css;
    } else {
      style.appendChild(document.createTextNode(css));
    }

    head.appendChild(style);
    document.getElementById('printButton').classList.add('buttonOff');
    document.getElementById('printButton').style.display = 'none';
    window.print();
    document.getElementById('printButton').style.display = 'inline';
  }

  const boletoCancelado = JSON.parse(localStorage.getItem('boleto-cancelado'));

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="sm">
        <div id="printableArea">
          <img alt="" height="64" src={theme.config.loginLogo} />
          <Typography variant="h5" component="h5" style={{ margin: '1rem 0' }}>
            Comprovante de Cancelamento
          </Typography>

          <div
            className={classes.comprovante}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div>
              <Typography component="body1">Data do Cancelamento</Typography>
            </div>
            <div>
              <Typography component="body1" className={classes.grey}>
                {moment(boletoCancelado[0]?.ultimaAlteracao).format(
                  'DD/MM/YYYY',
                )}
              </Typography>
            </div>
          </div>
          <div
            className={classes.comprovante}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div>
              <Typography component="body1">Código Cancelamento</Typography>
            </div>
            <div>
              <Typography component="body1" className={classes.grey}>
                {boletoCancelado[0]?.codigoRegistroBoleto}
              </Typography>
            </div>
          </div>
          <div
            className={classes.comprovante}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div>
              <Typography component="body1">Linha Digitável</Typography>
            </div>
            <div>
              <Typography component="body1" className={classes.grey}>
                {boletoCancelado[0].codigoBarras}
              </Typography>
            </div>
          </div>
          <div
            className={classes.comprovante}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div>
              <Typography component="body1">Documento Federal</Typography>
            </div>
            <div>
              <Typography component="body1" className={classes.grey}>
                {boletoCancelado[0].documentoFederalExcel}
              </Typography>
            </div>
          </div>
          <div
            className={classes.comprovante}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div>
              <Typography component="body1">Data de Vencimento</Typography>
            </div>
            <div>
              <Typography component="body1" className={classes.grey}>
                {boletoCancelado[0].dataVencimentoFormattedExcel}
              </Typography>
            </div>
          </div>
          <div
            className={classes.comprovante}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div>
              <Typography component="body1">
                Data Limite de Pagamento
              </Typography>
            </div>
            <div>
              <Typography component="body1" className={classes.grey}>
                {moment(boletoCancelado[0].dataLimitePagamento).format(
                  'DD/MM/YYYY',
                )}
              </Typography>
            </div>
          </div>
          <div
            className={classes.comprovante}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div>
              <Typography component="body1">Valor</Typography>
            </div>
            <div>
              <Typography component="body1" className={classes.grey}>
                {`R$ ${boletoCancelado[0].valorTitulo}`}
              </Typography>
            </div>
          </div>

          <Divider />
          <div className={classes.comprovante}>
            <Typography component="subtitle1">
              <LocalAtmIcon className={classes.icons} />
              <ArrowDropDownIcon className={classes.icons} />
              Dados do pagador
            </Typography>
          </div>
          <Divider />
          <div
            className={classes.comprovante}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div>
              <Typography component="body1">Nome</Typography>
            </div>
            {boletoCancelado[0].nomePagador == 'null' ? (
              <div>
                <Typography
                  component="body1"
                  className={classes.grey}
                ></Typography>
              </div>
            ) : (
              <div>
                <Typography component="body1" className={classes.grey}>
                  {boletoCancelado[0].nomePagador}
                </Typography>
              </div>
            )}
          </div>
          {boletoCancelado[0].enderecoPagador != '' && (
            <div
              className={classes.comprovante}
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <div>
                <Typography component="body1">Endereço</Typography>
              </div>
              <div>
                <Typography component="body1" className={classes.grey}>
                  {`Rua ${boletoCancelado[0].enderecoPagador} -  ${boletoCancelado[0].bairroPagador}/
                  ${boletoCancelado[0].cidadePagador}`}
                </Typography>
              </div>
            </div>
          )}
          {boletoCancelado[0].cepPagador != '' && (
            <div
              className={classes.comprovante}
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <div>
                <Typography component="body1">CEP</Typography>
              </div>
              <div>
                <Typography component="body1" className={classes.grey}>
                  {boletoCancelado[0].cepPagador}
                </Typography>
              </div>
            </div>
          )}
        </div>
      </Container>
      <div
        className={classes.comprovante}
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <Button
          variant="contained"
          color="primary"
          id="printButton"
          title={'Imprimir'}
          startIcon={<MdPrint color="background" />}
          onClick={printDiv}
        >
          Imprimir
        </Button>
      </div>
    </React.Fragment>
  );
}
