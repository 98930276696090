import React from 'react';
import { Typography } from '@material-ui/core';
import { MdArrowBack, MdDone } from 'react-icons/md';
import ConfirmButton from '../../../components/Buttons/ConfirmButton';
import DialogModal from '../../../components/DialogModal';

const ModalDelete = ({ open, setOpen, row, deleteKey, loadingDelete }) => {
  return (
    <DialogModal
      onClose={() => setOpen(false)}
      key={row}
      open={open}
      dialogTitle={'Deletar chave PIX'}
      dialogActions={
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-evenly',
            padding: '10px',
          }}
        >
          <ConfirmButton
            title={'Cancelar'}
            variant="contained"
            color="primary"
            startIcon={<MdArrowBack color="background" />}
            onClick={() => setOpen(false)}
          />
          <ConfirmButton
            variant="contained"
            color="primary"
            title={'Deletar'}
            isLoading={loadingDelete}
            onClick={deleteKey}
            startIcon={<MdDone color="background" />}
          />
        </div>
      }
      dialogContent={
        <>
          <Typography variant="body1" style={{ paddingBottom: 10 }}>
            Tem certeza que deseja deletar esta chave PIX?
          </Typography>
        </>
      }
    />
  );
};

export default ModalDelete;
