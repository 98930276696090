import React from "react"
import RetornoPagamentoProvider from "../../Context/RetornoPagamento"
import Content from "./Content"

const RetornoPagamento = () => {
    return(<RetornoPagamentoProvider>
        <Content />
    </RetornoPagamentoProvider>)
}

export default RetornoPagamento