import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Button from '../../components/Buttons/ConfirmButton';
import formattedMoney from '../../util/FormatteMoney/index';
import { MdPrint } from 'react-icons/md';

const useStyles = makeStyles(theme => ({
  root: {
    height: 280,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  buttonOff: {
    display: 'none',
  },
  comprovante: {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  comprovanteAreaPage: {
    marginTop: '20px',
    marginBottom: '20px',
    pageBreakBefore: 'always',
  },
  grey: {
    color: 'grey',
  },
  icons: {
    marginTop: '3px',
  },
}));

export default function RecipeReviewCardLote() {
  const classes = useStyles();
  const theme = useTheme();
  function printDiv() {
    var css = '@page { size: portrait; }',
      head = document.head || document.getElementsByTagName('head')[0],
      style = document.createElement('style');

    style.type = 'text/css';
    style.media = 'print';

    if (style.styleSheet) {
      style.styleSheet.cssText = css;
    } else {
      style.appendChild(document.createTextNode(css));
    }

    head.appendChild(style);
    document.getElementById('changePrintSize').style.maxWidth = '800px';
    document.getElementById('printButton').classList.add('buttonOff');
    document.getElementById('printButton').style.display = 'none';
    window.print();
    document.getElementById('changePrintSize').style.maxWidth = '600px';
    document.getElementById('printButton').style.display = 'inline';
  }

  const itensLote = JSON.parse(localStorage.getItem('printLote'));
  const codigosTransacao = JSON.parse(
    localStorage.getItem('print-codigo-transacao'),
  );

  React.useEffect(() => {
    function handleKeyPress(event) {
      if (event.ctrlKey && event.key === 'p') {
        event.preventDefault();
        printDiv();
      }
    }

    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  return (
    <React.Fragment>
      <CssBaseline />
      <Container id="changePrintSize" maxWidth="sm">
        {itensLote.map((item, index) => {
          return (
            <>
              <div id="printableArea">
                {index != 0 && (
                  <Divider
                    className={classes.comprovanteAreaPage}
                    id="comprovanteAreaPageId"
                  />
                )}
                <img
                  alt=""
                  height="64"
                  src={theme.config.loginLogo}
                  style={{ marginTop: 30 }}
                />
                <Typography variant="h6" component="h6">
                  {item.contaDestino
                    ? 'Comprovante de Transferência'
                    : 'Comprovante de Pagamento'}
                </Typography>
                <div
                  className={classes.comprovante}
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div>
                    <Typography component="body1">Data </Typography>
                  </div>
                  <div>
                    <Typography component="body2" className={classes.grey}>
                      {item.transferencia
                        ? item.transferencia.dataPagamento
                        : item.boleto.dataPagamento}
                    </Typography>
                  </div>
                </div>
                <div
                  className={classes.comprovante}
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div>
                    <Typography component="body1">Valor</Typography>
                  </div>
                  <div>
                    <Typography component="body1" className={classes.grey}>
                      {formattedMoney(
                        parseFloat(
                          item.transferencia
                            ? item.transferencia.valor
                            : item.boleto.valorPagamento,
                        ),
                      )}
                    </Typography>
                  </div>
                </div>
                <div
                  className={classes.comprovante}
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div>
                    <Typography component="body1">Autenticação</Typography>
                  </div>
                  <div>
                    <Typography component="body1" className={classes.grey}>
                      {codigosTransacao[index]}
                    </Typography>
                  </div>
                </div>
                <div
                  className={classes.comprovante}
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div>
                    <Typography component="body1">Evento</Typography>
                  </div>
                  <div>
                    <Typography component="body1" className={classes.grey}>
                      {item.transferencia
                        ? item.transferencia.evento
                        : item.boleto.descricaoCliente}
                    </Typography>
                  </div>
                </div>

                {item.historicoEvento && (
                  <div className={classes.comprovante}>
                    <Typography component="body1">Observação:</Typography>
                    <div>
                      <Typography component="body1" className={classes.grey}>
                        {item.historicoEvento}
                      </Typography>
                    </div>
                  </div>
                )}

                <Divider />
                <div className={classes.comprovante}>
                  <Typography component="subtitle1">
                    <LocalAtmIcon className={classes.icons} />
                    <ArrowDropDownIcon className={classes.icons} />
                    Destino
                  </Typography>
                </div>
                <Divider />
                <div
                  className={classes.comprovante}
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div>
                    <Typography component="body1">Nome</Typography>
                  </div>
                  {item.correntistaDestino.nome == 'null' ? (
                    <div>
                      <Typography
                        component="body1"
                        className={classes.grey}
                      ></Typography>
                    </div>
                  ) : (
                    <div>
                      <Typography component="body1" className={classes.grey}>
                        {item.correntistaDestino.nome}
                      </Typography>
                    </div>
                  )}
                </div>
                {item.contaDestino && (
                  <div
                    className={classes.comprovante}
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <div>
                      <Typography component="body1">Banco</Typography>
                    </div>
                    <div>
                      <Typography component="body1" className={classes.grey}>
                        {item.contaDestino.nomeBanco}
                      </Typography>
                    </div>
                  </div>
                )}

                {item.correntistaDestino.documentoFederal == '' ? (
                  // <div className={classes.comprovante} style={{display:"flex", justifyContent:"space-between"}}>
                  // <div>
                  //     <Typography component="body1">Documento Federal</Typography>
                  // </div>
                  // <div>
                  //     <Typography component="body1" className={classes.grey}></Typography>
                  // </div>
                  // </div>
                  ''
                ) : (
                  <div
                    className={classes.comprovante}
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <div>
                      <Typography component="body1">
                        Documento Federal
                      </Typography>
                    </div>
                    <div>
                      <Typography component="body1" className={classes.grey}>
                        {item.correntistaDestino.documentoFederal}
                      </Typography>
                    </div>
                  </div>
                )}
              </div>
              {item.contaDestino && (
                <div
                  className={classes.comprovante}
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div>
                    <Typography component="body1">Agência</Typography>
                  </div>
                  <div>
                    <Typography component="body1" className={classes.grey}>
                      {item.contaDestino.agencia}
                    </Typography>
                  </div>
                </div>
              )}
              {item.contaDestino && (
                <div
                  className={classes.comprovante}
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div>
                    <Typography component="body1">Conta</Typography>
                  </div>
                  <div>
                    <Typography component="body1" className={classes.grey}>
                      {item.contaDestino.numeroContaDigito}
                    </Typography>
                  </div>
                </div>
              )}

              <Divider />
              <div className={classes.comprovante}>
                <Typography component="subtitle1">
                  <LocalAtmIcon className={classes.icons} />
                  <ArrowDropUpIcon className={classes.icons} />
                  Origem
                </Typography>
              </div>
              <Divider />
              <div
                className={classes.comprovante}
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <div>
                  <Typography component="body1">Nome</Typography>
                </div>
                <div>
                  <Typography component="body1" className={classes.grey}>
                    {item.correntistaOrigem.nome}
                  </Typography>
                </div>
              </div>
              {item.contaOrigem.nomeBanco == 'null' ? (
                <div
                  className={classes.comprovante}
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div>
                    <Typography component="body1">Banco</Typography>
                  </div>
                  <div>
                    <Typography
                      component="body1"
                      className={classes.grey}
                    ></Typography>
                  </div>
                </div>
              ) : (
                <div
                  className={classes.comprovante}
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div>
                    <Typography component="body1">Banco</Typography>
                  </div>
                  <div>
                    <Typography component="body1" className={classes.grey}>
                      {item.contaOrigem.nomeBanco}
                    </Typography>
                  </div>
                </div>
              )}

              {item.correntistaOrigem.documentoFederal == 'null' ? (
                <div
                  className={classes.comprovante}
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div>
                    <Typography component="body1">Documento Federal</Typography>
                  </div>
                  <div>
                    <Typography
                      component="body1"
                      className={classes.grey}
                    ></Typography>
                  </div>
                </div>
              ) : (
                <div
                  className={classes.comprovante}
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div>
                    <Typography component="body1">Documento Federal</Typography>
                  </div>
                  <div>
                    <Typography component="body1" className={classes.grey}>
                      {item.correntistaOrigem.documentoFederal}
                    </Typography>
                  </div>
                </div>
              )}
              <div
                className={classes.comprovante}
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <div>
                  <Typography component="body1">Agência</Typography>
                </div>
                <div>
                  <Typography component="body1" className={classes.grey}>
                    {item.contaOrigem.agencia}
                  </Typography>
                </div>
              </div>
              <div
                className={classes.comprovante}
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <div>
                  <Typography component="body1">Conta</Typography>
                </div>
                <div>
                  <Typography component="body1" className={classes.grey}>
                    {item.contaOrigem.numeroContaDigito}
                  </Typography>
                </div>
              </div>
            </>
          );
        })}
      </Container>
      <div
        className={classes.comprovante}
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <Button
          variant="contained"
          color="primary"
          id="printButton"
          title={'Imprimir'}
          startIcon={<MdPrint color="background" />}
          onClick={printDiv}
        >
          Imprimir
        </Button>
      </div>
    </React.Fragment>
  );
}
