import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import { Alert } from '@material-ui/lab/';
import LockIcon from '@material-ui/icons/Lock';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AccountCircle from '@material-ui/icons/AccountCircle';
import PeopleIcon from '@material-ui/icons/People';
import '../styles.css';
import { useDispatch, useSelector } from 'react-redux';
import formattedMoney from '../../util/FormatteMoney';
import { Tooltip } from '@material-ui/core';
import { saldoRequest } from '../../store/modules/saldo/actions';
import { useStyles } from './styles';
import {
  Brightness5,
  Brightness2,
  VisibilityOff,
  ErrorRounded,
  Visibility,
} from '@material-ui/icons';
import { changeModeDark } from '../../store/modules/app';
import KeycloakService from '../../auth';

export default ({
  localEmpresa,
  saldo,
  handleProfileMenuOpen,
  infoRedux,
  anchorElProfile,
  handleCloseProfile,
  newHoraRefresh,
  horaexpiracao,
  handleClickProfile,
  handleOpenAccount,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { modeTheme } = useSelector(state => state.app);

  const saldoDataShow = useSelector(state => state.saldo);

  return (
    <div className={classes.divTopBarMobileContainer}>
      <Grid item className={classes.topBarMobileleft}>
        <IconButton
          onClick={handleProfileMenuOpen}
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountBalanceIcon />
        </IconButton>
        <Typography
          variant="body2"
          style={{
            fontSize: '11px',
          }}
        >
          <Typography
            style={{
              flexGrow: '2',
              textTransform: 'uppercase',
              fontSize: '11px',
            }}
            color="inherit"
            variant="h5"
            noWrap
            class="font-titulo"
          >
            {
              ('Banco: ',
              infoRedux.contaPadrao.banco?.id +
                ' - ' +
                infoRedux.contaPadrao.banco?.nome)
            }{' '}
            {('Ag: ', infoRedux.contaPadrao?.agencia)}{' '}
            {('Conta: ', infoRedux.contaPadrao?.conta)}
            {'-'}
            {infoRedux.contaPadrao?.digitoConta}
          </Typography>
          Saldo:{' '}
          {saldoDataShow?.showSaldo
            ? saldo?.saldo
              ? ` ${formattedMoney(parseFloat(saldo?.saldo))}${' '}`
              : 'R$ 0,00 '
            : null}
          <Tooltip
            placement="right"
            title={
              saldoDataShow === 'erro'
                ? 'Não foi possível conectar-se ao servidor'
                : !saldoDataShow?.showSaldo
                ? 'Visualizar saldo'
                : 'Ocultar saldo'
            }
          >
            <IconButton
              className={classes.iconButtonSaldo}
              color="inherit"
              onClick={() => {
                if (infoRedux) {
                  dispatch(saldoRequest(!saldoDataShow?.showSaldo));
                }
              }}
            >
              {saldoDataShow === 'erro' ? (
                <ErrorRounded />
              ) : saldoDataShow?.showSaldo ? (
                <VisibilityOff />
              ) : (
                <Visibility />
              )}
            </IconButton>
          </Tooltip>
        </Typography>
      </Grid>
      <Grid item className={classes.gridMobileRight}>
        <Tooltip
          title={
            modeTheme === 'dark'
              ? 'Desativar tema escuro'
              : 'Ativar tema escuro'
          }
        >
          <IconButton
            color="primary"
            onClick={() =>
              dispatch(changeModeDark(modeTheme === 'dark' ? false : true))
            }
          >
            {modeTheme === 'dark' ? (
              <Brightness5 className={classes.iconButtonBrightness5} />
            ) : (
              <Brightness2 className={classes.iconButtonBrightness2Mobile} />
            )}
          </IconButton>
        </Tooltip>
        <IconButton color="inherit" onClick={handleClickProfile}>
          <AccountCircle />
        </IconButton>
      </Grid>

      <Menu
        id="simple-menu"
        anchorEl={anchorElProfile}
        keepMounted
        open={Boolean(anchorElProfile)}
        onClose={handleCloseProfile}
      >
        <Alert severity="info" className={classes.menuMobileAlert}>
          Seu acesso expira às{' '}
          {newHoraRefresh === null ? horaexpiracao : newHoraRefresh}
        </Alert>

        <MenuItem onClick={handleOpenAccount}>
          <AccountBalanceWalletIcon
            color="primary"
            className={classes.menuMobileDropdown}
          />
          <Typography component="body2" color="primary">
            {localEmpresa}
          </Typography>
        </MenuItem>
        <Link to="/meu-perfil">
          <MenuItem onClick={handleCloseProfile}>
            <AccountCircle
              color="primary"
              className={classes.menuMobileDropdown}
            />
            <Typography component="body2" color="primary">
              Minha conta
            </Typography>
          </MenuItem>
        </Link>
        <Link to="/new-financial-password">
          <MenuItem>
            <LockIcon color="primary" className={classes.menuMobileDropdown} />
            <Typography component="body2" color="primary">
              Alterar senhas
            </Typography>
          </MenuItem>
        </Link>
        <Link to="/usuarios">
          <MenuItem>
            <PeopleIcon
              color="primary"
              className={classes.menuMobileDropdown}
            />
            <Typography component="body2" color="primary">
              Usuários
            </Typography>
          </MenuItem>
        </Link>
        <Link onClick={() => KeycloakService.logoutMfe()}>
          <MenuItem>
            <ExitToAppIcon
              color="primary"
              className={classes.menuMobileDropdown}
            />
            <Typography component="body2" color="primary">
              Sair
            </Typography>
          </MenuItem>
        </Link>
      </Menu>
    </div>
  );
};
