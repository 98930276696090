import React, { useState, useRef, useEffect } from 'react';
import * as Yup from 'yup';
import { api_multipague_configuracao } from '../../services/api';
import Button from '../../components/Buttons/ConfirmButton';
import CPF from '../../components/Inputs/CPF';
import DDD from '../../components/Inputs/DDD';
import Telefone from '../../components/Inputs/Telefone';
import InputText from '../../components/InputText';
import validarCpf from '../../util/vlidarCpfCnpj';
import getValidationErros from '../../util/FormattedError';
import {
  Container,
  Main,
  ContainerButton,
  InfoContainer,
} from '../NovoUsuario/styles';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { hideLoading, showLoading } from '../../store/modules/loading/actions';
import { MdSave, MdArrowBack } from 'react-icons/md';
import Box from '../../components/Box';
import Wrapper from '../../components/Wrapper';
import { useTheme } from '@material-ui/core';
import {
  toastComponentError,
  toastComponentSuccess,
} from '../../components/Toast';
import SelectComponent from '../../components/Select';

export const EditUsuarios = () => {
  const history = useHistory();
  const { userAccount } = useSelector(state => state.user);
  const editingInfo = JSON.parse(localStorage.getItem('edicao_usuario'));
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const [listaPerfis, setListaPerfis] = useState([]);
  const [grupos, setGrupos] = useState([{ label: '', value: '' }]);
  const [ddd, setDdd] = useState();
  const [telefone, setTelefone] = useState();
  const [grupoSelected, setGrupoSelected] = useState();
  const verifyMaster = localStorage.getItem('roles');
  const isMaster = verifyMaster?.includes('usuario_master');

  useEffect(() => {
    const fetchGrupo = async () => {
      try {
        const response = await api_multipague_configuracao.get(
          '/keycloak/groups',
        );
        setGrupos(
          response.data?.map(item => {
            return { label: item.name, value: item.id };
          }) || [],
        );
      } catch (error) {
        toastComponentError(
          error.response.data.mensagem
            ? `Código: ${error.response.data.codigo} - ${error.response.data.mensagem}`
            : `Não foi possível buscar grupos`,
          theme,
        );
        console.error('Erro ao buscar dados:', error);
      }
    };

    fetchGrupo();

    api_multipague_configuracao
      .get(`/usuario/${editingInfo?.login.replace(/[^0-9]+/g, '')}`)
      .then(function (response) {
        setDdd(response.data.ddd);
        setTelefone(response.data.telefone);
        setGrupoSelected(response.data.grupos[0]?.id);
        const perfis = response.data.perfis;

        const arrayPerfis = perfis.map(item =>
          item
            .replace(/correntista_operador/g, ' Operador')
            .replace(/analista_credito/g, ' Analista de crédito')
            .replace(/correntista_administrador/g, ' Administrador'),
        );

        setListaPerfis(arrayPerfis);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const onSubmit = async body => {
    try {
      dispatch(showLoading(true));
      const schmeCadastro = Yup.object().shape({
        nome: Yup.string().required('Campo obrigatorio'),
        usuario: Yup.string().required('Campo obrigatorio'),
        email: Yup.string().required('Campo obrigatorio'),
        ddd: Yup.string().required('Campo obrigatorio'),
        telefone: Yup.string().required('Campo obrigatorio'),
        perfil: Yup.string().required('Campo obrigatorio'),
      });

      await schmeCadastro.validate(body, {
        abortEarly: false,
      });

      if (!validarCpf(body.usuario)) {
        formRef.current.setFieldError('usuario', 'CPF inválido');
        return;
      }

      const bodyResquest = {
        ...body,
        atributos: {
          profileBackOffice: editingInfo.atributos.profileBackOffice,
          permissaoContas: editingInfo.atributos.permissaoContas,
          profileBackOffice: editingInfo.atributos.profileBackOffice,
        },
        ativo: editingInfo?.ativo,
        email: editingInfo?.email,
        id: editingInfo?.id,
        login: editingInfo?.login,
        nome: editingInfo?.nome,
        perfil: editingInfo?.perfil,
        perfis: editingInfo?.perfis,
        situacao: editingInfo?.situacao,
        keycloakGroupName: grupoSelected,
      };
      if (!!editingInfo?.id) {
        const updateBody = {
          ddd: ddd,
          telefone: telefone,
          keycloakGroupId: body?.grupos,
        };

        await api_multipague_configuracao.put(
          `/usuario/${editingInfo?.login.replace(/[^0-9]+/g, '')}`,
          updateBody,
        );
      } else {
        const { data } = await api_multipague_configuracao.post(
          `/usuario?idCliente=${userAccount.id}`,
          bodyResquest,
        );
      }

      localStorage.removeItem('edicao_usuario');
      history.push('/Usuarios');
      toastComponentSuccess('Usuário editado com sucesso');
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationErros(err);
        formRef.current?.setErrors(erros);
      } else {
        toastComponentError(
          ` Codigo: ${err.response.data.codigo} - ${err.response.data.mensagem}`,
        );
        console.log(err);
      }
    } finally {
      dispatch(hideLoading(false));
    }
  };

  const handleBackEditUsuario = () => {
    history.goBack();
    localStorage.removeItem('edicao_usuario');
  };

  return (
    <Wrapper
      title="Edição de Usuário"
      crumb={[
        { name: 'Dashboard', link: '/dashboard' },
        { name: 'Usuário', link: '/Usuario' },
      ]}
      crumbActive="Edição"
    >
      <Container ref={formRef} onSubmit={onSubmit}>
        <Box style={{ borderRadius: '20px', boxShadow: 'none' }}>
          <Main>
            <InputText
              variant="outlined"
              label="Nome Completo"
              name="nome"
              id="nome"
              value={editingInfo?.nome}
              disabled
            />
            <DDD
              variant="outlined"
              label="DDD"
              name="ddd"
              id="ddd"
              value={ddd}
              onChange={e => setDdd(e.target.value)}
            />
            <Telefone
              variant="outlined"
              label="Telefone"
              name="telefone"
              id="telefone"
              value={telefone}
              onChange={e => setTelefone(e.target.value)}
            />
            <InputText
              variant="outlined"
              label="E-mail"
              name="email"
              id="email"
              value={editingInfo?.email}
              disabled
            />
            <CPF
              name="usuario"
              id="usuario"
              variant="outlined"
              label="CPF"
              value={editingInfo?.login}
              disabled
            />
            <InputText
              variant="outlined"
              label="Perfil"
              name="perfil"
              id="text"
              value={listaPerfis}
              disabled
            />
            {isMaster && (
              <SelectComponent
                id="grupos"
                name="grupos"
                label="Grupos"
                itens={grupos}
                value={grupoSelected || ''}
                callback={setGrupoSelected}
                valueDefault={grupoSelected}
              />
            )}
          </Main>
          <InfoContainer></InfoContainer>
          <ContainerButton>
            <Button
              variant="contained"
              type="button"
              title="Voltar"
              onClick={() => handleBackEditUsuario()}
              startIcon={<MdArrowBack color="background" />}
            >
              Voltar
            </Button>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              title={'Salvar'}
              startIcon={<MdSave color={useTheme().palette.background.paper} />}
            >
              Salvar
            </Button>
          </ContainerButton>
        </Box>
      </Container>
    </Wrapper>
  );
};
