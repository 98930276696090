import produce from 'immer';

const INITIAL_STATE = [];

const user = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'LISTAR_ESPECIE_SUCCESS': {
      return produce(state, draft => action.payload);
    }

    case 'LISTAR_ESPECIE_ERROR': {
      return state;
    }

    default: {
      return state;
    }
  }
};

export default user;
