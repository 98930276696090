import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import { Form } from '@unform/web';

export const Container = styled(Form)`
  flex-grow: 1;
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;

export const ContainerDocumentoValor = styled.div`
  display: grid;
  row-gap: 20px;
  width: 100%;
  @media only screen and (min-width: 1024px) {
    display: grid;
    grid-template-columns: repeat(2, auto);
    column-gap: 50px;
  }
  @media only screen and (min-width: 1440px) {
  }
`;
export const ContainerDataValue = styled.div`
  display: grid;
  row-gap: 20px;
  width: 100%;
  @media only screen and (min-width: 1024px) {
    display: grid;
    grid-template-columns: repeat(3, auto);
    align-items: center;
    column-gap: 40px;
  }
  @media only screen and (min-width: 1440px) {
  }
`;
export const ContainerButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (min-width: 1024px) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  @media only screen and (min-width: 1440px) {
  }
`;
export const InputText = styled(TextField)`
  width: 100%;
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;
