import React, { useEffect, useState } from 'react';
import {
  List,
  ListItem,
  ListItemIcon,
  makeStyles,
  Typography,
  ListItemText,
  Tooltip,
} from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import { Link } from 'react-router-dom';
import { useTheme, alpha } from '@material-ui/core/styles';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setMenu } from '../../store/modules/app';

const useStyles = makeStyles(theme => ({
  menu: {
    paddingRight: '18%',
    paddingTop: theme.spacing(1.4),
    paddingBottom: theme.spacing(1.4),
    color: theme.palette.appBar[theme.palette.type].text,
  },
  menuActive: {
    borderBottom: 'none',
  },
}));

const ItemMenu = ({ name, Icon, to, children, tooltip, disabled, ...rest }) => {
  const app = useSelector(state => state.app);
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    if (app.activeMenu.toLowerCase() == name.toString().toLowerCase()) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [app.activeMenu]);

  const changeOpenMenu = () => {
    setOpen(!open);
    dispatch(setMenu(name));
  };

  const getListItemStyle = path => {
    if (location.pathname === path) {
      return {
        marginLeft: '8%',
        color: theme.palette.text.primary,
      };
    } else {
      return { marginLeft: '8%' };
    }
  };

  return (
    <>
      <Link to={to} {...rest}>
        <ListItem
          button
          style={disabled ? null : getListItemStyle(to)}
          onClick={changeOpenMenu}
          className={`${classes.menu} ${open && classes.menuActive}`}
        >
          <ListItemIcon
            style={{
              marginLeft: tooltip ? '18px' : '0',
            }}
          >
            {tooltip ? (
              <Tooltip placement="bottom" title={name}>
                <Icon style={{ fill: theme.palette.primary.main }} />
              </Tooltip>
            ) : (
              <Icon style={{ fill: theme.palette.primary.main }} />
            )}
          </ListItemIcon>
          <ListItemText
            style={{
              marginLeft: tooltip ? '30px' : '-15px',
              color:
                location.pathname === to
                  ? theme.palette.primary.main
                  : 'inherit',
            }}
          >
            <Typography
              style={{
                fontWeight: location.pathname === to ? '600' : '400',
                fontSize: location.pathname === to ? '16px' : '15px',
              }}
            >
              {name}
            </Typography>
          </ListItemText>
          {children && (open ? <ExpandLess /> : <ExpandMore />)}
        </ListItem>
      </Link>
      {children && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children}
          </List>
        </Collapse>
      )}
    </>
  );
};

export default ItemMenu;
