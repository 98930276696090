import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import { Form } from '@unform/web';


export const Container = styled(Form)`
  flex-grow: 1;
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;

export const ContainerTipoPessoa = styled.div`
  width: 100%;
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;
export const ContainerNome = styled.div`
  width: 100%;
  display: grid;
  row-gap: 20px;
  @media only screen and (min-width: 1024px) {
    display: grid;
    grid-template-columns: repeat(2, auto);
    column-gap: 50px;
    row-gap: 20px;
  }
  @media only screen and (min-width: 1440px) {
  }
`;
export const ContainerEndereco = styled.div`
  width: 100%;
  display: grid;
  row-gap: 20px;
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;
export const SubContainerEndereco = styled.div`
  width: 100%;
  display: grid;
  row-gap: 20px;
  @media only screen and (min-width: 1024px) {
    display: grid;
    grid-template-columns: repeat(3, auto);
    column-gap: 50px;
  }
  @media only screen and (min-width: 1440px) {
  }
`;
export const SubContainerEnderecoComplemento = styled.div`
  width: 100%;
  display: grid;
  row-gap: 20px;
  @media only screen and (min-width: 1024px) {
    display: grid;
    grid-template-columns: repeat(4, auto);
    column-gap: 50px;
  }
  @media only screen and (min-width: 1440px) {
  }
`;

export const ContainerButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media only screen and (min-width: 1024px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  @media only screen and (min-width: 1440px) {
  }
`;

export const InputText = styled(TextField)`
  width: 100%;
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;
