import React from 'react';
import {
  TextField,
  Tooltip,
  useTheme,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import { columnsTable } from './columsTable';
import { DataGridPage } from '../../components/DataGrid/DataGridPaginationServer';
import { useEffect } from 'react';
import { api_multipague_conta } from '../../services/api';
import { useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { v4 as uuidv4 } from 'uuid';
import RenderActionsCell from '../../components/DataGrid/DataGridPaginationServer/Actions';
import { FaEye } from 'react-icons/fa';
import { Termos } from '../../components/Termos';
import { approveRejectTerm } from './handlers';
import {
  toastComponentError,
  toastComponentSuccess,
} from '../../components/Toast';

const TermosPage = () => {
  const theme = useTheme();
  const idCliente = localStorage.getItem('contaId');
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openMenuActions, setOpenMenuActions] = React.useState(false);
  const [row, setRow] = React.useState({
    id: 0,
    template: '',
    assunto: '',
    idTermoCorrentista: '',
    idTermoClienteConta: '',
  });
  const [approve, setApprove] = useState(true);
  const initialParams = {
    situacao: '',
    vigentes: false,
    idCliente: idCliente,
  };
  const [params, setParams] = useState(initialParams);
  const [stateInput, setStateInput] = useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [loadingConfirmTerm, setLoadingConfirmTerm] = React.useState(false);

  const useCpf = localStorage.getItem('userCpf');

  const getTermsCorrentista = async () => {
    const URL = `/correntista/${useCpf}/termo`;
    const { data } = await api_multipague_conta.get(URL, { params });

    return data;
  };

  const handleTermosCorrentistas = () => {
    setLoading(true);
    getTermsCorrentista()
      .then(data => {
        setLoading(false);
        const rows = data.map(item => ({
          idTermoCorrentista: item.idTermoCorrentista,
          id: uuidv4(),
          idTermoClienteConta: item.idTermoClienteConta,
          assunto: item.assunto,
          observacao: item.observacao,
          template: item.template,
          situacao: item.situacao,
        }));
        setRows(rows);
      })
      .catch(err => {
        setRows([]);
        setLoading(false);
      });
  };

  useEffect(() => {
    handleTermosCorrentistas();
  }, [useCpf]);

  const handleApproveReject = async () => {
    setLoadingConfirmTerm(true);
    await approveRejectTerm(useCpf, {
      idTermoClienteConta: row.idTermoClienteConta,
      idTermoCorrentista: row.idTermoCorrentista,
      situacao: approve ? 'APROVADO' : 'REPROVADO',
    })
      .then(() => {
        setLoadingConfirmTerm(false);
        toastComponentSuccess(
          `Termo ${approve ? 'APROVADO' : 'REJEITADO'} com sucesso`,
        );
      })
      .catch(error => {
        setLoadingConfirmTerm(false);
        const errorMessage =
          error?.response?.data?.mensagem || 'Erro desconhecido';
        toastComponentError(errorMessage, theme);
      });
  };

  const filterInputs = {
    inputsData: [
      {
        html: () => (
          <Autocomplete
            value={params.situacao}
            onChange={(event, option) => {
              if (option) {
                setParams(prev => ({ ...prev, situacao: option }));
              } else {
                setParams(prev => ({ ...prev, situacao: '' }));
              }
            }}
            inputValue={stateInput}
            onInputChange={(event, newValue) => {
              setStateInput(newValue);
            }}
            getOptionLabel={option => option}
            size="small"
            id="combo-box-demo"
            options={['APROVADO', 'PENDENTE', 'REPROVADO']}
            renderInput={params => (
              <TextField
                {...params}
                label="Situação"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
              />
            )}
          />
        ),

        grid: { xs: 12, sm: 5, md: 5 },
      },
      {
        html: () => (
          <FormControlLabel
            control={
              <Switch
                onChange={e => {
                  setParams(prev => ({ ...prev, vigentes: e.target.checked }));
                }}
                checked={params.vigentes}
                color={params.vigentes ? 'primary' : 'secundary'}
              />
            }
            label="Vigente"
          />
        ),
        grid: { xs: 12, sm: 5, md: 5 },
      },
    ],
    searchButton: {
      searchButton: () => handleTermosCorrentistas(),
    },
    cleanButton: {
      cleanButton: () => {
        setParams(initialParams);
        handleTermosCorrentistas();
      },
    },
  };

  return (
    <>
      <Termos
        openModal={openModal}
        children={row.template}
        onAprove={() => {
          setApprove(true);
        }}
        loadingButton={loadingConfirmTerm}
        aprove={approve}
        reject={!approve}
        onReject={() => {
          setApprove(false);
        }}
        onConfirm={() => handleApproveReject()}
        termoAssunto={row.assunto}
        handleCloseModal={() => setOpenModal(false)}
      />
      <DataGridPage
        styleFormBackground={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
        title="Termos"
        crumb={[{ name: 'Dashboard', link: '/dashboard' }]}
        minHeight="600px"
        rows={rows}
        formData={filterInputs}
        columns={[
          ...columnsTable,
          {
            field: 'acoes',
            headerName: 'Ações',
            width: 100,
            align: 'center',
            renderCell: event => {
              return (
                <RenderActionsCell
                  event={event}
                  actionsComponent={
                    <Tooltip title="Ver Termo">
                      <button
                        style={{ display: 'flex' }}
                        onClick={() => {
                          setOpenModal(true);
                          setRow({
                            id: event.row.id,
                            template: event.row.template,
                            assunto: event.row.assunto,
                            idTermoClienteConta: event.row.idTermoClienteConta,
                            idTermoCorrentista: event.row.idTermoCorrentista,
                          });
                        }}
                      >
                        <FaEye size={18} color={theme.palette.primary.main} />
                      </button>
                    </Tooltip>
                  }
                  openMenuActions={openMenuActions}
                  setOpenMenuActions={setOpenMenuActions}
                  rowId={row.id}
                />
              );
            },
          },
        ]}
        onRowClick={params => setRow({ id: params.id })}
        loading={loading}
        rowsPerPageOptions={[10, 50, 100]}
        rowCount={rows?.length}
        paginationMode={'client'}
        // page={data?.pageable?.pageNumber}
        pageSize={20}
        textNoRows={'Nenhuma linha'}
        // onPageChangeFunction={page => setPage(page)}
      />
    </>
  );
};

export default TermosPage;
