import React, { useState } from 'react';
import { Typography, Box, alpha, makeStyles } from '@material-ui/core';
import { currencyFormatter } from '../columsTable';
import { ModalOrdemPagamento } from './../../Esteira/ModalOrdemPagemento';
import { MdChangeCircle } from 'react-icons/md';
import Buttons from '../../../components/Buttons/ConfirmButton';

const Totalizador = ({ data, qntTitulos, ordensDePagamento }) => {
  const [open, setOpen] = useState(false);
  const useStyles = makeStyles(theme => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    box: {
      display: 'flex',
      gap: '15px',
      width: '100%',
      marginTop: '5px',
      marginBottom: 5,
      justifyContent: 'space-evenly',
      alignItems: 'center',
    },
    boxBorderSaldoTotal: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '5px',
      backgroundColor: alpha(theme.palette.primary.light, 0.5),
      borderRadius: '10px',
      width: '100%',
    },
    boxBorder: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '5px',
      backgroundColor: alpha(theme.palette.primary.light, 0.2),
      borderRadius: '10px',
      width: '100%',
    },
  }));

  const classes = useStyles();

  function sumField(field) {
    return data?.reduce((total, obj) => total + obj[field], 0);
  }

  return (
    <Box width={'100%'}>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        width={'100%'}
        alignContent={'center'}
        alignItems={'center'}
      >
        <Box display={'flex'} justifyContent={'flex-start'}>
          <Typography
            style={{
              fontWeight: '500',
              fontFamily: 'sans-serif',
              marginRight: '5px',
              marginBottom: '10px',
            }}
          >
            Quantidade de títulos:
          </Typography>
          <Typography
            fontSize={15}
            style={{
              fontWeight: '700',
              fontFamily: 'sans-serif',
            }}
          >
            {qntTitulos}
          </Typography>
        </Box>
        {ordensDePagamento.length > 1 && (
          <Buttons
            color="primary"
            variant="contained"
            title="Outras Ordens"
            style={{ marginBottom: '10px' }}
            onClick={() => setOpen(true)}
            startIcon={<MdChangeCircle color={'background'} size={20} />}
          ></Buttons>
        )}
      </Box>
      <ModalOrdemPagamento
        setShowModal={setOpen}
        showModal={open}
        ordens={ordensDePagamento}
        ordem={data}
      />
      <Box className={classes.box}>
        <Box className={classes.boxBorder}>
          <Typography
            variant="inherit"
            textAlign={'center'}
            style={{
              fontWeight: '500',
            }}
          >
            Valor Devedor
          </Typography>
          <Typography
            variant="inherit"
            textAlign={'center'}
            style={{
              fontWeight: '700',
            }}
          >
            {currencyFormatter(sumField('valorDevedor') || 0)}
          </Typography>
        </Box>

        <Typography
          fontSize={16}
          style={{
            fontWeight: 'bold',
          }}
        >
          +
        </Typography>

        <Box className={classes.boxBorder}>
          <Typography
            variant="inherit"
            textAlign={'center'}
            style={{
              fontWeight: '500',
            }}
          >
            Valor Custas
          </Typography>
          <Typography
            variant="inherit"
            textAlign={'center'}
            style={{
              fontWeight: '700',
            }}
          >
            {currencyFormatter(sumField('valorCustas') || 0)}
          </Typography>
        </Box>

        <Typography
          fontSize={16}
          style={{
            fontWeight: 'bold',
          }}
        >
          +
        </Typography>

        <Box className={classes.boxBorder}>
          <Typography
            variant="inherit"
            textAlign={'center'}
            style={{
              fontWeight: '500',
            }}
          >
            Valor Pendência
          </Typography>
          <Typography
            variant="inherit"
            style={{
              fontWeight: '700',
            }}
          >
            {currencyFormatter(sumField('valorPrincipalPendenteFace') || 0)}
          </Typography>
        </Box>

        <Typography
          fontSize={16}
          style={{
            fontWeight: 'bold',
          }}
        >
          -
        </Typography>

        <Box className={classes.boxBorder}>
          <Typography
            variant="inherit"
            textAlign={'center'}
            style={{
              fontWeight: '500',
            }}
          >
            Valor Pago
          </Typography>
          <Typography
            variant="inherit"
            textAlign={'center'}
            style={{
              fontWeight: '700',
            }}
          >
            {currencyFormatter(sumField('valorAbatimento') || 0)}
          </Typography>
        </Box>

        <Typography
          fontSize={16}
          style={{
            fontWeight: 'bold',
          }}
        >
          =
        </Typography>

        <Box className={classes.boxBorderSaldoTotal}>
          <Typography
            fontSize={12}
            style={{
              fontWeight: '500',
            }}
            textAlign={'center'}
          >
            Saldo Aberto
          </Typography>
          <Typography
            variant="inherit"
            style={{
              fontWeight: '700',
            }}
          >
            {currencyFormatter(sumField('valorTotalPagar') || 0)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Totalizador;
