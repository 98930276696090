export function listarFinalidadeRequest() {
  return {
    type: 'LISTAR_FINALIDADE_REQUEST',
  };
}
export function listarFinalidadeSuccess(finalidades) {
  return {
    type: 'LISTAR_FINALIDADE_SUCCESS',
    payload: finalidades,
  };
}
export function listarFinalidadeError() {
  return {
    type: 'LISTAR_FINALIDADE_ERROR',
  };
}
