import React from 'react';

import Data from '../../components/InputDate';
import { IconButton, Tooltip } from '@material-ui/core';
import { useRetornoPagamento } from '../../Context/RetornoPagamento';
import { DataGridPage } from '../../components/DataGrid/DataGridPaginationServer';
import RenderActionsCell from '../../components/DataGrid/DataGridPaginationServer/Actions';
import { MdDownload } from 'react-icons/md';

const Content = () => {
  const {
    rowsTable,
    loading,
    rowCount,
    rowsState,
    onPage,
    handlePageSize,
    textNoRows,
    dataInicial,
    setRowsState,
    setDataInicial,
    dataFinal,
    setDataFinal,
    clearFilter,
    submitFilter,
  } = useRetornoPagamento();

  const [rowId, setRowId] = React.useState(null);
  const [openMenuActions, setOpenMenuActions] = React.useState(false);

  const columnsTable = [
    { field: 'id', headerName: 'Código', width: 140 },
    { field: 'nomeArquivo', headerName: 'Arquivo', flex: 1 },
    { field: 'tipoArquivo', headerName: 'Tipo', width: 140 },
    { field: 'dataMovimento', headerName: 'Data Movimento', width: 250 },
    {
      field: 'acoes',
      headerName: 'Ações',
      width: 100,
      align: 'center',
      sortable: false,
      renderCell: event => {
        return (
          <RenderActionsCell
            event={event}
            actionsComponent={
              <Tooltip title="Download">
                <IconButton
                  target="retorno"
                  variant="outlined"
                  color="primary"
                  size="small"
                  href={event.row.url}
                >
                  <MdDownload variant="contained" />
                </IconButton>
              </Tooltip>
            }
            openMenuActions={openMenuActions}
            setOpenMenuActions={setOpenMenuActions}
            rowId={rowId}
          />
        );
      },
    },
  ];

  const filterInputs = {
    inputsData: [
      {
        html: () => (
          <Data
            callback={setDataInicial}
            label="Data Inicial"
            id="data-inicial"
            value={dataInicial}
            dataMinima={'1999-01-01'}
            variant="outlined"
            size="small"
            InputProps={{
              style: { borderRadius: '20px' },
            }}
          />
        ),
        grid: { xs: 12, sm: 5, md: 5 },
      },
      {
        html: () => (
          <Data
            callback={setDataFinal}
            size="small"
            label="Data Final"
            id="data-final"
            value={dataFinal}
            dataMinima={'1999-01-01'}
            variant="outlined"
            InputProps={{
              style: { borderRadius: '20px' },
            }}
          />
        ),
        grid: { xs: 12, sm: 5, md: 5 },
      },
    ],
    searchButton: {
      searchButton: () => submitFilter(),
    },
    cleanButton: {
      cleanButton: () => clearFilter(),
    },
  };

  return (
    <DataGridPage
      title="Retorno de Pagamento"
      crumb={[
        { name: 'Dashboard', link: '/dashboard' },
        { name: 'Pagamento em lote', link: '/Pagamento-Lote' },
      ]}
      formData={filterInputs}
      minHeight="600px"
      rows={rowsTable}
      columns={columnsTable}
      loading={loading}
      pagination
      rowCount={rowCount}
      {...rowsState}
      onRowClick={params => setRowId(params.id)}
      setDatagrid={setRowsState}
      rowsPerPageOptions={[10, 20, 50]}
      paginationMode="server"
      onPageChangeFunction={onPage}
      textNoRows={textNoRows}
      onPageSizeChangeFunction={newPageSize => handlePageSize(newPageSize)}
    />
  );
};

export default Content;
