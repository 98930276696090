export function listarFavoritosRequest(data) {
  return {
    type: 'LISTAR_FAVORITOS_REQUEST',
    payload: data,
  };
}
export function listarFavoritosSuccess(listBeneficiarios) {
  return {
    type: 'LISTAR_FAVORITOS_SUCCESS',
    payload: listBeneficiarios,
  };
}
export function listarFavoritosError(error) {
  return {
    type: 'LISTAR_FAVORITOS_ERROR',
    payload: error,
  };
}
