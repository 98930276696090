import produce from 'immer';

const INITIAL_STATE = {
  userAccount: {
    id: '',
  },
};

const user = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'LISTAR_USER_SUCCESS': {
      return produce(state, draft => {
        draft.userAccount.id = action.payload;
        sessionStorage.setItem(
          'user_account',
          JSON.stringify(draft.userAccount),
        );
      });
    }

    case 'LISTAR_USER_ERROR': {
      return state;
    }

    default: {
      return state;
    }
  }
};

export default user;
