export function pesquisarFavoritosRequest(dataSearch) {
  return {
    type: 'PESQUISAR_FAVORITOS_REQUEST',
    payload: dataSearch,
  };
}
export function pesquisarFavoritosSuccess(listFavorecidos) {
  return {
    type: 'PESQUISAR_FAVORITOS_SUCCESS',
    payload: listFavorecidos,
  };
}
export function pesquisarFavoritosError(error) {
  return {
    type: 'PESQUISAR_FAVORITOS_ERROR',
    payload: error,
  };
}
