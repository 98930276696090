import React from 'react';
import { styled as MuyStyled } from '@material-ui/core';
import { alpha, makeStyles, useTheme } from '@material-ui/core/styles';
import { GridOverlay } from '@material-ui/data-grid';

export const Container = MuyStyled('div')(({ theme }) => {
  return {
    '&  .MuiPaper-root': {
      overflow: 'auto',
      boxShadow: 'none',
      textTransform: 'capitalize',
      padding: '1rem',
      border: 'none',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 'bold',
      wordWrap: 'break-word',
      lineHeight: '110%',
      fontSize: '13px',
      alignItems: 'center',
      display: 'flex',
      whiteSpace: 'break-spaces',
    },
    '&  .MuiDataGrid-columnHeaderWrapper': {
      border: 'none',
      borderTopLeftRadius: '10px',
      borderTopRightRadius: '10px',
      backgroundColor: alpha(theme.palette.primary.dark, 0.2),
    },

    '& .MuiDataGrid-root': {
      border: 'none',
      boxShadow: 'none',
      backgroundColor: theme.palette.background.paper,
    },

    '& .MuiDataGrid-iconButtonContainer': {
      visibility: 'hidden',
      backgroundColor: 'transparent',
      width: '1px',
    },
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
      // '&::before': {
      //   content: "''",
      //   width: '1px',
      //   marginTop: '25px',
      //   marginLeft: '11px',
      //   display: 'block',
      //   height: '45px',
      //   backgroundColor: alpha(theme.palette.text.disabled, 0.2),
      // },
    },

    '& .mfe-MuiTablePagination-root': {
      backgroundColor: theme.palette.background.paper,
      scrollbarColor: theme.palette.primary.main,
      scrollbarWidth: 'thin',

      '&::-webkit-scrollbar': {
        width: '8px',
        maxHeight: '8px',
      },

      '&::-webkit-scrollbar-thumb': {
        backgroundColor: theme.palette.primary.main,
        borderRadius: '3px',
      },
    },

    '& .MuiDataGrid-window': {
      overflowX: 'visible',
      scrollbarWidth: 'thin',
      backgroundColor:
        theme.palette.type === 'light'
          ? 'rgba(0, 0, 0, 0)'
          : 'rgba(255, 255, 255, 0)',

      '&::-webkit-scrollbar': {
        width: '8px',
        maxHeight: '8px',
      },

      '&::-webkit-scrollbar-track-piece': {
        backgroundColor:
          theme.palette.type === 'light'
            ? theme.palette.background.default
            : theme.palette.background.dark,
        borderRadius: '3px',
      },

      '&::-webkit-scrollbar-thumb': {
        backgroundColor: theme.palette.primary.main,
        borderRadius: '3px',
      },
    },

    '& .MuiDataGrid-overlay': {
      color: theme.palette.type === 'light' ? '#000' : '#fff',
      backgroundColor: 'rgba(255, 255, 255, 0)',
    },

    '& .headerButtons': {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: '1rem',
      marginBottom: '1rem',
    },
  };
});

export const useStylesDatagrid = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '20px',

    '& .backOffice-MuiInputBase-root': {
      height: '40px',
      borderRadius: '20px',
    },
  },
  legendStyle: {
    fontWeight: 500,
    fontSize: '0.800rem',
    lineHeight: 1.57,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(2),
    padding: '0 5px',
  },
  gridLoadingButton: {
    display: 'flex',
    alignSelf: 'center',
    alignItems: 'center',
    padding: '0 10px',
    gap: '0.5rem',
  },
  formBackground: {
    width: '100%',
    borderTopLeftRadius: '20px',
    borderTopRightRadius: '20px',
    margin: '0',
    backgroundColor: theme.palette.background.paper,
    padding: '1.5rem 1rem 0',
    gap: '0',
    boxShadow: `${alpha(theme.palette.primary.dark, 0.2)} 0px 0px 45px -15px`,
  },
  headerBold: {
    fontWeight: 'bold',
  },
  buttonProgress: {
    color:
      theme.palette.type === 'dark'
        ? theme.palette.primary.main
        : theme.palette.secondary.main,
    marginRight: '5px',
  },
  dataGridPaper: {
    padding: '.5rem 1.5rem',
    marginBottom: '2rem',
    borderTopLeftRadius: '0',
    borderTopRightRadius: '0',
    borderBottomLeftRadius: '20px',
    borderBottomRightRadius: '20px',
    backgroundColor: theme.palette.background.paper,
    // boxShadow: `0px 8px 45px -15px ${alpha(theme.palette.primary.dark, 0.2)}`,
  },
  loadingButton: {
    boxShadow: 'none',
    textTransform: 'initial',
    fontSize: '8px',
    backgroundColor: alpha(
      theme.palette.appBar[theme.palette.type].bgMenu,
      0.7,
    ),
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.getContrastText(theme.palette.primary.main),
    },
    '&:disabled': {
      color: theme.palette.getContrastText(theme.palette.primary.light),
      backgroundColor: alpha(
        theme.palette.appBar[theme.palette.type].bgMenu,
        0.2,
      ),
    },
  },
  loadingButtonIconClean: {
    padding: '3px',
  },
  loadingButtonIconSearch: {
    padding: '3px',
  },
}));

export const CustomNoRowsOverlay = ({ textRow }) => {
  const theme = useTheme();
  return (
    <GridOverlay style={{ backgroundColor: theme.palette.background.paper }}>
      <div>{textRow}</div>
    </GridOverlay>
  );
};
