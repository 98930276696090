import { alpha, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(0),
    width: '100%',
    minWidth: 120,
  },
}));
