import produce from 'immer';

const INITIAL_STATE = false

const popUp = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SHOW_POPUP': {
    console.log('===============> Action', action.payload)

      return action.payload;
    }

    default: {
      return state;
    }
  }
};

export default popUp;
