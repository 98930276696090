const INITIAL_STATE = {};

const saldo = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SALDO_REQUEST_SUCCESS': {
      return action.payload;
    }

    case 'SALDO_ERROR': {
      return action.payload;
    }

    default: {
      return state;
    }
  }
};

export default saldo;
