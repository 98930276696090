import React, { useCallback, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import ContainerComponent from '../Container';
import Button from '../Buttons/ConfirmButton';
import DateComponent from '../InputDate';
import getValidationErros from '../../util/FormattedError';
import InputText from '../../components/InputText';
import InputTextMask from '../../components/Inputs/MaskPersonalizavel';
import { InputMoney } from '../../components/Inputs/Money';
import InputPersonalizeMask from '../../components/Inputs/MaskPersonalizavel';
import CPF from '../../components/Inputs/CPF';
import * as Yup from 'yup';
import {
  Container,
  Subcontainer,
  ContainerDocumentoValor,
  ContainerDataValue,
  ContainerButton,
} from './styles';
import { useDispatch } from 'react-redux';
import { setDigitacaoDadosBoletoSuccess } from '../../store/modules/digitacaoBoleto/actions';
import {MdNavigateNext} from "react-icons/md";
import Grid from '@material-ui/core/Grid';
import { toastComponentError } from '../Toast';
import { TextField } from '@material-ui/core';

const DadosBoleto = ({ selecteAba, isSalve, isEditing }) => {
  const dispatch = useDispatch();
  const savedData = JSON.parse(localStorage.getItem('data_pagamento_boleto2'));
  const carteira_cliente = JSON.parse(localStorage.getItem('carteira_cliente'));
  const [valorTitulo, setValorTitulo] = useState({
    value: !!savedData?.valorTitulo ? savedData.valorTitulo : 0,
    error: '',
    disabled: false,
  });
  const [numeroTitulo, setNumeroTitulo] = useState(
    !!savedData?.numeroTitulo ? savedData.numeroTitulo : null,
  );
  const [valorAbatimento, setValorAbatimento] = useState({
    value: !!savedData?.valorAbatimento ? savedData.valorAbatimento : 0,
    error: '',
    disabled: false,
  });

  const formRef = useRef(null);
  const [dataVencimento, setDataVencimento] = useState(
    savedData?.dataVencimentoNoFormatted
      ? moment(savedData?.dataVencimentoNoFormatted, 'YYYY-MM-DD').format(
          'YYYY-MM-DD',
        )
      : moment(Date.now()).add(1, 'days').format('YYYY-MM-DD'),
  );
  const [dataLimitePagamento, setDataLimitePagamento] = useState(
    savedData?.dataLimiteNoFormatted
      ? moment(savedData?.dataLimiteNoFormatted, 'YYYY-MM-DD').format(
          'YYYY-MM-DD',
        )
      : moment(Date.now())
          .add(carteira_cliente?.diasLimitePagamento || 0, 'days')
          .format('YYYY-MM-DD'),
  );

  // moment(startdate, 'DD-MM-YYYY').add(5, 'days');

  useEffect(() => {
    setDataLimitePagamento(
      moment(dataVencimento)
        .add(carteira_cliente?.diasLimitePagamento || 0, 'days')
        .format('YYYY-MM-DD'),
    );
  }, [dataVencimento]);

  const submitForm = useCallback(
    async data => {
      try {
        isSalve(false);
        const schmeCadastro = Yup.object().shape({
          // valorTitulo: Yup.string().required('Campo obrigatorio'),
        });
        if (valorTitulo.value < 1) {
          setValorTitulo({
            ...valorTitulo,
            error: 'Valor deve ser maior que zero',
          });
          return;
        }

        if (Number(data.percAbatimento) > 0) {
          if (Number(data.percAbatimento) > 100) {
            formRef.current?.setErrors({
              percAbatimento: 'Valor percentual não pode ser maior que 100',
            });
            return;
          }
        }

        if (moment(dataLimitePagamento).isBefore(dataVencimento)) {
          toastComponentError(
            'Data limite pagamento não pode ser inferior a data pagamento',
          );
          return;
        }
        await schmeCadastro.validate(data, {
          abortEarly: false,
        });

        data.dataLimitePagamento = moment(dataLimitePagamento).format(
          'YYYY-MM-DD',
        );
        data.dataVencimento = moment(dataVencimento).format('YYYY-MM-DD');
        data.dataLimiteNoFormatted = dataLimitePagamento;
        data.dataVencimentoNoFormatted = dataVencimento;
        data.valorTitulo = valorTitulo.value;
        // data.valorTitulo = data.valorTitulo.replace('.', '').replace(',', '.');
        data.valorAbatimento = valorAbatimento.value;
        data.numeroTitulo = numeroTitulo;

        selecteAba(2);
        dispatch(setDigitacaoDadosBoletoSuccess(data));
        localStorage.setItem('data_pagamento_boleto', JSON.stringify(data));
      } catch (err) {
        console.log('err', err);
        if (err instanceof Yup.ValidationError) {
          console.log(err.inner);
          const erros = getValidationErros(err);
          formRef.current?.setErrors(erros);
        }
      }
    },
    [dataLimitePagamento, dataVencimento, valorTitulo, valorAbatimento],
  );

  return (
    <Container ref={formRef} initialData={savedData} onSubmit={submitForm}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <TextField
            id="numeroTitulo"
            name="numeroTitulo"
            onChange={e => {
              setNumeroTitulo(e.target.value);
            }}
            required
            size="small"
            style={{ width: '100%' }}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              maxLength: 10,
            }}
            label="Nº Documento"
            value={numeroTitulo}
            disabled={isEditing}
            // required
          />
        </Grid>
        <Grid item xs={12} sm={4} >
          <InputMoney
            value={valorTitulo.value}
            onChange={val =>
              setValorTitulo({
                ...valorTitulo,
                value: val,
                error: '',
              })
            }
            error={valorTitulo.error}
            disabled={valorTitulo.disabled}
            label="Valor"
          />
        </Grid>
        <Grid item xs={12} sm={4} >
          <InputTextMask
            name="nossoNumero"
            id="nossoNumero"
            label="Nosso Número"
            mask="************"
            disabled={
              carteira_cliente?.origemNossoNumero === 'BANCO' ? true : false
            }
            required={
              carteira_cliente?.origemNossoNumero === 'BANCO' ? false : true
            }
          />
        </Grid>
        <Grid item xs={12} sm={4} >
          <DateComponent
            callback={setDataVencimento}
            label="Data Vencimento"
            id="dataVencimento"
            value={dataVencimento}
            defaultValue={savedData?.dataVencimento}
            size="small"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={4} >
          <DateComponent
            callback={setDataLimitePagamento}
            label="Data Limite Pagamento"
            id="dataLimitePagamento"
            value={dataLimitePagamento}
            defaultValue={savedData?.dataLimite}
            size="small"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={4} >
          <InputMoney
            value={valorAbatimento.value}
            onChange={val =>
              setValorAbatimento({
                ...valorAbatimento,
                value: val,
                error: '',
              })
            }
            error={valorAbatimento.error}
            disabled={valorAbatimento.disabled}
            label="Valor Abatimento"
          />
        </Grid>
        <Grid item xs={12} style={{justifyContent:"flex-end",display:"flex"}}  >
          <Button
            startIcon={<MdNavigateNext color={"background"}/>}
            color="primary"
            variant="contained"
            disabled={false}
            width={100}
            type="submit"
            title="Avançar"
          >

          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default DadosBoleto;
