import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import moment from 'moment';
import jwt_decode from 'jwt-decode';
import { useSelector } from 'react-redux';

import {
  Typography,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Hidden,
  useTheme,
} from '@material-ui/core';

import { Close, Send } from '@material-ui/icons';

import SaldoAtual from '../../components/SaldoAtualDash';
import ConsultaMercado from '../../components/ConsultaMercado';
import Expenses from '../../components/Despesas';
import GoalsExpenses from '../../components/MetasDespesasConta';
import Aprovacao from '../../components/Esteira';
import Wrapper from '../../components/Wrapper';

import { useAuthContext } from '../../Context/AuthContext';
import {
  api_multipague_configuracao,
  api_multipague_conta,
} from '../../services/api';
import CobrancaSimplesMobile from '../../components/CobrancaSimples/CobrancaSimplesMobile';
import AgendaFinanceira from '../../components/AgendaFinanceira/index';
import CobrancaSimplesEGarantias from '../../components/CobrancaSimples/index';
import { Termos } from '../../components/Termos';
import {
  toastComponentError,
  toastComponentSuccess,
} from '../../components/Toast';

export default function Dashboard() {
  const history = useHistory();
  const theme = useTheme();
  const useCpf = localStorage.getItem('userCpf');
  const { contaPadrao } = useSelector(state => state.contas);
  const idCliente = localStorage.getItem('contaId') || contaPadrao?.id;
  const token = localStorage.getItem('tkn-access');
  if (!token) {
    window.location.href = '/login';
  }
  const { permissionMercado } = useAuthContext();

  const { realm_access } = jwt_decode(token);
  const [verificarPerfilMercado, setVerificarPerfilMercado] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [avisos, setAvisos] = useState([]);
  const [termos, setTermos] = useState([]);
  const [openModals, setOpenModals] = useState({});
  const [approve, setApprove] = useState({ 0: true });
  const [loadingConfirmTerm, setLoadingConfirmTerm] = React.useState(false);

  useEffect(() => {
    const verify = realm_access.roles.filter(
      val => val.toUpperCase() === 'consulta_mercado'.toUpperCase(),
    );

    if (verify.length === 0) {
      setVerificarPerfilMercado(false);
    } else {
      setVerificarPerfilMercado(true);
    }
  }, [realm_access]);

  function paginaNoticias() {
    history.push('/novidades-da-versao');
  }

  const USER_TOKEN = localStorage.getItem('tkn-access');
  const AuthStr = 'Bearer '.concat(USER_TOKEN);
  const URLsearch = `/avisos?ativo=${true}&vigenteEm=${moment(
    new Date(),
  ).format('YYYY-MM-DD')}`;

  const getTermsCorrentista = async idClienteProp => {
    const URL = `/correntista/${useCpf}/termo?vigentes=true&situacao=PENDENTE&idCliente=${idClienteProp}`;
    const { data } = await api_multipague_conta.get(URL);

    return data;
  };

  const approveRejectTerm = async (
    cnpjCpf,
    idTermoClienteConta,
    idTermoCorrentista,
    approve,
  ) => {
    const URL = `/correntista/${cnpjCpf}/termo`;
    const { data } = await api_multipague_conta.put(URL, {
      idTermoClienteConta: idTermoClienteConta,
      idTermoCorrentista: idTermoCorrentista,
      situacao: approve ? 'APROVADO' : 'REPROVADO',
    });

    return data;
  };

  const handleApproveReject = async index => {
    setLoadingConfirmTerm(true);
    await approveRejectTerm(
      useCpf,
      termos[index]?.idTermoClienteConta,
      termos[index]?.idTermoCorrentista,
      approve[index],
    )
      .then(() => {
        setLoadingConfirmTerm(false);
        toastComponentSuccess(
          `Termo ${approve[index] ? 'APROVADO' : 'REPROVADO'} com sucesso`,
        );
        handleNextTerm(index);
      })
      .catch(error => {
        setLoadingConfirmTerm(false);
        const errorMessage =
          error?.response?.data?.mensagem || 'Erro desconhecido';
        toastComponentError(errorMessage, theme);
        handleNextTerm(index);
      });
  };

  const handleConfirm = index => {
    handleApproveReject(index);
  };

  const handleCloseModal = index => {
    setOpenModals(prev => ({ ...prev, [index]: false, [index + 1]: true }));
  };

  const handleNextTerm = index => {
    setOpenModals(prev => ({ ...prev, [index]: false, [index + 1]: true }));
    setApprove(prev => ({ ...prev, [index + 1]: true }));
  };

  const handleApprove = index => {
    setApprove(prev => ({ ...prev, [index]: true }));
  };

  const handleReject = index => {
    setApprove(prev => ({ ...prev, [index]: false }));
  };

  useEffect(() => {
    if (!!useCpf && !!idCliente) {
      getTermsCorrentista(idCliente)
        .then(terms => {
          setTermos(terms);
          setOpenModals(
            terms.reduce((acc, _, index) => ({ ...acc, [index]: false }), {}),
          );
          if (terms.length > 0) {
            setOpenModals(prev => ({ ...prev, [0]: true }));
          }
        })
        .catch(error => {
          console.error('Erro ao obter termos:', error);
        });
    }
  }, [useCpf, idCliente]);

  useEffect(() => {
    api_multipague_configuracao
      .get(URLsearch, { headers: { Authorization: AuthStr } })
      .then(response => {
        const aquiTable = response.data.content.map(data => ({
          ...data,
          id: data.id,
          ativo: data.ativo,
          caminhoStorage: data.caminhoStorage,
          observacao: data.observacao,
          urlRedirecionamento: data.urlRedirecionamento,
          vigenciaInicio: moment(new Date(data.vigenciaInicio)).format(
            'DD/MM/YYYY',
          ),
          vigenciaFim: moment(new Date(data.vigenciaFim)).format('DD/MM/YYYY'),
        }));
        setAvisos(aquiTable[0]);
        aquiTable.length > 0 && setOpenModal(true);
        console.log('Aviso Aqui: ', aquiTable[0]);
      })
      .catch(error => {});
  }, []);

  return (
    <Wrapper title="Dashboard">
      {termos.map((termo, index) => {
        return (
          <Termos
            onAprove={() => handleApprove(index)}
            aprove={!!approve[index]}
            reject={!approve[index]}
            loadingButton={loadingConfirmTerm}
            key={index}
            onReject={() => handleReject(index)}
            onConfirm={() => handleConfirm(index)}
            termoAssunto={termo?.assunto}
            openModal={!!openModals[index]}
            handleCloseModal={() => handleCloseModal(index)}
          >
            {termo?.template}
          </Termos>
        );
      })}

      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <img
            src={avisos?.caminhoStorage}
            style={{ marginTop: '8px' }}
            id="simple-modal-title"
            width="100%"
          />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Typography variant="h6" component="h6">
              {avisos?.observacao}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            marginTop: '15px',
            marginBottom: '15px',
            display: 'flex',
            justifyContent: 'space-around',
          }}
        >
          <Button
            variant="outlined"
            color="default"
            endIcon={<Close />}
            onClick={() => setOpenModal(false)}
          >
            Fechar
          </Button>
          {!!avisos?.urlRedirecionamento &&
            avisos?.urlRedirecionamento != 'undefined' && (
              <Button
                variant="outlined"
                color="primary"
                target="_blank"
                href={
                  avisos?.urlRedirecionamento?.includes('http')
                    ? avisos?.urlRedirecionamento
                    : 'https://' + avisos?.urlRedirecionamento
                }
                endIcon={<Send />}
                onClick={() => setOpenModal(false)}
              >
                Confira
              </Button>
            )}
        </DialogActions>
      </Dialog>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <SaldoAtual />
        </Grid>
        {!permissionMercado && (
          <Hidden mdDown>
            <Grid item xs={12} sm={12} md={6} lg={5}>
              <Aprovacao />
            </Grid>
          </Hidden>
        )}

        <Hidden lgUp>
          <Grid item xs={12} sm={12} md={12}>
            <CobrancaSimplesMobile />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <AgendaFinanceira mobile />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={5}>
            <Aprovacao />
          </Grid>
        </Hidden>
        <Hidden mdDown>
          <Grid
            item
            xs={12}
            sm={12}
            md={permissionMercado ? 8 : 6}
            lg={permissionMercado ? 8 : 7}
          >
            <AgendaFinanceira />
          </Grid>
        </Hidden>
        {permissionMercado && (
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <ConsultaMercado />
          </Grid>
        )}
        {permissionMercado && (
          <Hidden mdDown>
            <Grid
              item
              xs={12}
              sm={12}
              md={permissionMercado ? 12 : 6}
              lg={permissionMercado ? 12 : 7}
            >
              <CobrancaSimplesEGarantias />
            </Grid>
          </Hidden>
        )}
        {permissionMercado ? (
          <>
            <Hidden mdDown>
              <Grid item xs={12} sm={12} md={6}>
                <Aprovacao />
              </Grid>
            </Hidden>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Expenses />
            </Grid>
          </>
        ) : (
          <>
            <Hidden mdDown>
              <Grid item xs={12} sm={12} md={6} lg={7}>
                <CobrancaSimplesEGarantias />
              </Grid>
            </Hidden>
            <Grid item xs={12} sm={12} md={permissionMercado ? 6 : 6} lg={5}>
              <Expenses />
            </Grid>
          </>
        )}

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <GoalsExpenses />
        </Grid>
      </Grid>
    </Wrapper>
  );
}
