import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
@font-face {
  font-family: 'CoreLigth';
  src: local('CoreLigth'), url('../fonts/CoreSansExtraLigth.otf') format('otf');
  font-display: swap;
}
@font-face {
  font-family: 'CoreSans';
  src: local('CoreSans'), url('../fonts/CoreSansMedio.otf') format('otf');
  font-display: swap;
}
:root {
  /* --ligthblue: #6DE4E9;
  --lightBlueStrong: #59BFCE; */
  /* --darkGreen: #283B46; */
  /* --ligthGreen: #399094; */
  /* --greenAqua: #394C59; */
  /* --ligthBrown: #707070; */
  /* --ligthGrey: #C6C6C6; */
  /* --aguaGrey: #C4CAD0; */
  /* --red: #FF5050; */
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  :focus {
    font-size: 16px;
  }
}

.toast-custom {
  background-color: transparent;
  color: transparent;
  box-shadow: none;
}
.Toastify__progress-bar{
  color: transparent;
  background-color: transparent;
}

.Toastify__close-button{
  color: transparent;
  background-color: transparent;
}

body {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  font-family: 'CoreLigth', sans-serif !important;
  font-weight: 100;
  font-size: 14px;
  -webkit-font-smoothing: antialiased !important;
  min-height: 100vh;
  height: 100%;
}
body,
input,
button,
textarea {
  font-family: 'CoreLigth', sans-serif;
  -webkit-font-smoothing: antialiased !important;
  font-size: 14px !important;
}

button {
  border: none;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}
.button {
  text-transform: 'none';
  text-decoration: 'none';
}

a:link {
  text-decoration: none;
}
/* ======= Tag Utilizada para titulos ========= */
h1 {
  font-family: 'CoreLigth', sans-serif;
}
/* ======= Tag Utilizada para subtitulos ========= */
h2 {
  font-family: 'CoreLigth', sans-serif;
}
/* ======= Tag Utilizada para menu ========= */
h3 {
  font-family: 'CoreLigth', sans-serif;
}
/* ======= Tag Utilizada para buttons ========= */
span {
  font-family: 'CoreLigth', sans-serif;
}
.MuiInputBase-input .MuiOutlinedInput-input{
  font-size: 14px!important;
  font-family: 'CoreLigth', sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
}
.MuiTypography-body1 {
  font-size: 14px!important;
  font-family: 'CoreLigth', sans-serif !important;
}
.MuiGrid-item {
  height: 100%!important;
}

.MuiDataGrid-row{
  font-size: 13px;
  font-weight: lighter;
}

.MuiMenuItem-root {
  font-size: 12px !important;
  font-family: 'CoreLigth', sans-serif !important;
}
.MuiSelect-outlined.MuiSelect-outlined {
  font-size: 12px !important;
  font-family: 'CoreLigth', sans-serif !important;
}

.MuiDataGrid-root{
  box-shadow: rgb(0 0 0 / 20%) 10px 15px 30px;
}
.MuiFormLabel-root {
  font-size: 14px;
  font-family: 'CoreLigth', sans-serif !important;
}
.MuiFormControl-root {
  label{
    font-size: 12px;
  }
}
.makeStyles-root-1{
  min-height: 100vh;
  height: 100%;
}
.makeStyles-content{
  height: 100vh;
}
.makeStyles-root-82{
  height: 100vh;
}
.MuiListItemText-root{
  font-size: 14px;
  font-family: 'CoreLigth', sans-serif !important;
}
.mfe-MuiOutlinedInput-root{
  border-radius: 20px;
}

.mfe-MuiInputBase-root.mfe-MuiOutlinedInput-root {
  border-radius: 20px;
}

.mfe-MuiInputLabel-outlined.mfe-MuiInputLabel-shrink {
  font-family: 'CoreLigth', sans-serif !important;
   font-weight: 100;
   font-size: 14px;
}

.mfe-MuiButton-root {
  box-shadow: none !important;
  border-radius: 20px !important;
}
.mfe-MuiDialog-paper.mfe-MuiDialog-paperScrollPaper.mfe-MuiDialog-paperWidthSm.mfe-MuiPaper-elevation24.mfe-MuiPaper-rounded{
border-radius: 20px;
}

.mfe-MuiSelect-root.mfe-MuiSelect-select.mfe-MuiSelect-selectMenu {
  border-radius: 20px !important;
}

.mfe-MuiPaper-root.mfe-MuiMenu-paper.mfe-MuiPopover-paper {
  border-radius: 10px;
}

#comprovanteAreaPageId {
   page-break-before: always;
}

@media print {
    @page {
      size: portrait;
      margin: 0;
    }
  }
`;
