import React, { useRef, useEffect, useState } from 'react';
import ReactInputMask, { Props as InputProps } from 'react-input-mask';
import { useField } from '@unform/core';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function InputMask({
  name,
  isLoading,
  mask,
  hasIcon,
  icon,
  callBack,
  ...rest
}) {
  const inputRef = useRef(null);
  const {
    fieldName,
    registerField,
    defaultValue,
    error,
    clearError,
  } = useField(name);
  const [size, setSize] = useState(0);

  function retornarLengthText(value) {
    setSize(value.length);
  }
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref, value) {
        ref.setInputValue(value);
      },
      clearValue(ref) {
        ref.setInputValue('');
      },
    });
  }, [fieldName, registerField]);
  return (
    <ReactInputMask
      mask={mask}
      maskChar=""
      ref={inputRef}
      defaultValue={defaultValue}
      onChange={event => {
        if (callBack) {
          callBack(event.target.value);
        }
      }}
      onFocus={clearError}
      {...rest}
    >
      {() => (
        <TextField
          size="small"
          style={{ width: '100%' }}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          helperText={error}
          error={!!error}
          InputProps={
            (isLoading && {
              endAdornment: (
                <CircularProgress
                  color="inherit"
                  size={24}
                  circleDisableShrink
                />
              ),
            },
            hasIcon && {
              startAdornment: (
                <InputAdornment position="start">{icon}</InputAdornment>
              ),
            })
          }
          {...rest}
        />
      )}
    </ReactInputMask>
  );
}
