import { toastComponentError, toastComponentSuccess } from '../components/Toast';
import { api_multipague_cobranca } from './api';
const service = '/cobranca-simples/pagadores';

const findAll = async (params = {}) => {
  return api_multipague_cobranca.get(`${service}`, { params });
};

const cadastrarPagador = async (idCliente, body) => {
  try {
    await api_multipague_cobranca.post(
      `${service}?idCliente=${idCliente}`,
      body,
    );
    toastComponentSuccess('Pagador cadastrado com sucesso!');
  } catch (error) {
    toastComponentError('Erro ao cadastrar pagador!');
  }
};

const editaPagador = async (idPagador, body) => {
  try {
    await api_multipague_cobranca.put(`${service}/${idPagador}`, body);
    toastComponentSuccess('Pagador atualizado com sucesso!');
  } catch (error) {
    toastComponentError('Erro ao cadastrar pagador!');
  }
};

const getPagadorDocumento = (idCliente, documento) => {
  return api_multipague_cobranca.get(`${service}/documento/${documento}`, {
    params: {
      idCliente,
    },
  });
};

export { findAll, cadastrarPagador, editaPagador, getPagadorDocumento };
