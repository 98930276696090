export function listarMotivoRequest(motivo) {
  return {
    type: 'LISTAR_MOTIVO_REQUEST',
    payload: motivo,
  };
}
export function listarMotivoSuccess(motivos) {
  return {
    type: 'LISTAR_MOTIVO_SUCCESS',
    payload: motivos,
  };
}

export function listarMotivoError() {
  return {
    type: 'LISTAR_MOTIVO_ERROR',
  };
}
