import React, { useEffect, useRef, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Chart } from 'react-google-charts';
import AssessmentIcon from '@material-ui/icons/Assessment';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { DashboardCard } from './DashboardCards';
import { MessageErrorComponent } from './DashboardCards/ErrorMessage';
import { useGetDespesas } from '../hooks/despesas';

const useStyles = makeStyles(theme => ({
  rootChart: {
    '& svg': {
      // width: '120%',
      '& text': {
        flexWrap: 'wrap',
        fontSize: 11,

        fill: theme.palette.getContrastText(theme.palette.background.paper),
      },
      '& rect:nth-child(1)': {
        fill: theme.palette.background.paper,
      },
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function App() {
  const theme = useTheme();
  const classes = useStyles();
  const chartContainerRef = useRef(null);
  const dispatch = useDispatch();
  const infoRedux = useSelector(state => state.contas);
  const [value, setValue] = React.useState(0);

  const optionsChart = {
    backgroundColor: theme.palette.background.paper,
    title: 'Despesas',
    titleTextStyle: {
      color: theme.palette.getContrastText(theme.palette.background.paper),
    },
    legend: {
      textStyle: {
        color: theme.palette.getContrastText(theme.palette.background.paper),
      },
      position: 'left',
    },
    colors: [theme.palette.primary.main, '#394c59', '#2962ff', '#ffab00'],
  };

  const [options, setOptions] = useState(optionsChart);

  const [infoGraficos, setInfoGraficos] = useState([]);

  const { data, isLoading, isError } = useGetDespesas(infoRedux, true);

  useEffect(() => {
    if (data) {
      const formattedInfo = data.dataCentroCustos.reduce((acc, item) => {
        const percentuais = item.centrosCustos.reduce((innerAcc, innerItem) => {
          innerAcc[innerItem.nomeCentroCusto] = innerItem.valorCentroCusto;
          return innerAcc;
        }, {});

        const filledPercentuais = [
          'GERAL',
          'Favorecidos',
          'Pagadores',
          'Clientes',
        ].map(nomeCentroCusto => percentuais[nomeCentroCusto] || 0);

        acc.push([moment(item.data).format('MMMM'), ...filledPercentuais]);
        return acc;
      }, []);

      formattedInfo.unshift([
        'Mês',
        'Geral',
        'Favorecidos',
        'Pagadores',
        'Clientes',
      ]);
      setInfoGraficos(formattedInfo);
    }
  }, [infoRedux, data]);

  useEffect(() => {
    setOptions(optionsChart);
  }, [theme.palette.type]);

  useEffect(() => {
    const chartContainer = chartContainerRef.current;
    if (chartContainer) {
      chartContainer.style.backgroundColor = theme.palette.primary.main;
    }
  }, [theme.palette.primary.main]);

  return (
    <DashboardCard
      isChart
      title={'Despesas'}
      icon={
        <AssessmentIcon
          size={50}
          style={{
            marginRight: 5,
            color: theme.palette.primary.main,
          }}
        />
      }
      carregando={isLoading}
      children={
        <>
          <TabPanel value={value} index={0}>
            {isError ? (
              <div
                style={{
                  backgroundColor: 'transparent',
                  transform: 'translate(0%, 150%)',
                }}
              >
                <MessageErrorComponent />
              </div>
            ) : (
              <div ref={chartContainerRef} className={classes.rootChart}>
                <Chart
                  className={classes.rootChart}
                  width={'100%'}
                  chartType="Bar"
                  data={infoGraficos}
                  options={options}
                />
              </div>
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            Item Two
          </TabPanel>
          <TabPanel value={value} index={2}>
            Item Three
          </TabPanel>
        </>
      }
    />
  );
}

export default App;
