import React, { useState, useRef, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { BiDetail } from 'react-icons/bi';
import {
  api_multipague_cobranca,
  api_multipague_cobranca_cnab,
} from '../../services/api';
import formattedMoney from '../../util/FormatteMoney';
import InputText from '../../components/InputText';
import { useDispatch, useSelector } from 'react-redux';
import {
  hideLoading,
  hideLoadingButton,
  showLoading,
} from '../../store/modules/loading/actions';
import { useHistory } from 'react-router';

import ReplayIcon from '@material-ui/icons/Replay';
import { RedoOutlined } from '@material-ui/icons';
import VisibilityIcon from '@material-ui/icons/Visibility';
import RefreshIcon from '@material-ui/icons/Refresh';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import { Chip, Tooltip } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { useTheme } from '@material-ui/core/styles';
import { DataGridPage } from '../../components/DataGrid/DataGridPaginationServer';
import { Form } from '@unform/web';
import RenderActionsCell from '../../components/DataGrid/DataGridPaginationServer/Actions';
import {
  toastComponentError,
  toastComponentSuccess,
} from '../../components/Toast';

const ProcessCnab = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [infoProcessados, setInfoProcessados] = useState([]);
  const [guardaCodigo, setGuardaCodigo] = useState();
  const [open, setOpen] = React.useState(false);
  const [textNoRows, setTextNoRows] = React.useState(false);
  const { contaPadrao } = useSelector(state => state.contas);
  const { loadingButton } = useSelector(state => state.loading);
  const formRef = useRef(null);
  const [rowsTable, setRowsTable] = useState([]);
  const [rowCount, setRowCount] = useState();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowId, setRowId] = useState(null);
  const [openMenuActions, setOpenMenuActions] = useState(false);

  function reprocessarPost(idArquivo) {
    api_multipague_cobranca
      .post(`/cobranca-simples/arquivo/${idArquivo}/gerar-retorno`)
      .then(() => {
        toastComponentSuccess('Solicitação encaminhada para processamento');
      })
      .catch(err => {
        toastComponentError(
          err.response.data.mensagem
            ? err.response.data.mensagem
            : 'Não foi possível conectar-se ao servidor',
        );
      });
  }

  const columnsTable = [
    { field: 'codigo', headerName: 'Codigo', width: 150 },
    { field: 'nome_arquivos', headerName: 'Nome arquivo', flex: 1 },
    {
      field: 'quantidade',
      headerName: 'Quantidade',
      width: 120,
    },
    {
      field: 'erro',
      headerName: 'Erro/Sucesso',
      width: 120,
      renderCell: event => {
        return <p>{`${event.row.erro}/${event.row.sucesso}`}</p>;
      },
    },
    {
      field: 'situacao',
      headerName: 'Situação',
      width: 250,
      renderCell: event => {
        return event.row.situacao === 'Arquivo em processamento' ? (
          <Chip variant="outlined" label={'Processando'} color={'primary'} />
        ) : event.row.situacao === 'Arquivo processado com sucesso' ? (
          <Chip
            variant="outlined"
            label={'Sucesso'}
            color={'secondary'}
            style={{
              color:
                theme.palette.type === 'dark'
                  ? theme.palette.getContrastText(
                    theme.palette.background.paper,
                  )
                  : theme.palette.appBar[theme.palette.type].bgMenu,
              border: `1px solid ${theme.palette.type === 'dark'
                  ? theme.palette.getContrastText(
                    theme.palette.background.paper,
                  )
                  : theme.palette.appBar[theme.palette.type].bgMenu
                }`,
            }}
          />
        ) : (
          <Chip
            variant="outlined"
            label={'Erro'}
            color={'error'}
            style={{ color: '#df6c6c', border: '1px solid #df6c6c' }}
          />
        );
      },
    },
    {
      field: 'valor_total',
      headerName: 'Valor total',
      width: 150,
    },
    {
      field: 'detalhes',
      headerName: 'Ações',
      sortable: false,
      align: 'center',
      width: 100,
      disableClickEventBubbling: true,
      renderCell: event => {
        return (
          <RenderActionsCell
            event={event}
            actionsComponent={
              <>
                <Tooltip title="Detalhes">
                  <button
                    onClick={() =>
                      history.push(`/Detalhamento/Cnab/${event.row.codigo}`, {
                        situacao: event.row.situacao,
                      })
                    }
                  >
                    <BiDetail
                      style={{ color: theme.palette.primary.main }}
                      color="primary"
                      size={24}
                    />
                  </button>
                </Tooltip>
                {(event.row.situacao === 'Arquivo em processamento' ||
                  event.row.situacao === 'Arquivo processado com sucesso' ||
                  event.row.situacao === 'Arquivo processado com erros') && (
                    <Tooltip title="Acompanhar o processamento">
                      <button onClick={() => handleClickOpen(event.row)}>
                        <VisibilityIcon size={24} color="primary" />
                      </button>
                    </Tooltip>
                  )}
                {event.row.situacao === 'Arquivo processado com erros' ||
                  event.row.situacao === 'Arquivo processado com sucesso' ? (
                  <Tooltip title="Gerar Arquivo Retorno">
                    <button onClick={() => reprocessarPost(event.row.codigo)}>
                      <RedoOutlined color="primary" size={24} />
                    </button>
                  </Tooltip>
                ) : (
                  ''
                )}
                {event.row.permiteReprocessar ? (
                  <Tooltip title="Reprocessar">
                    <button onClick={() => reprocessar(event)}>
                      <ReplayIcon color="primary" size={24} />
                    </button>
                  </Tooltip>
                ) : (
                  ''
                )}
              </>
            }
            openMenuActions={openMenuActions}
            setOpenMenuActions={setOpenMenuActions}
            rowId={rowId}
          />
        );
      },
    },
  ];

  const onPage = page => {
    setPage(page);
    pesquisarArquivos(contaPadrao.id, page, pageSize);
  };

  const atualizar = () => {
    setPage(0);
    setPageSize(10);
    pesquisarArquivos(contaPadrao.id);
  };

  const limpar = () => {
    formRef?.current?.setData({ nome_arquivo: '' });
    pesquisarArquivos(contaPadrao.id, 0, pageSize);
  };

  const handleClose = value => {
    setOpen(false);
  };
  function SimpleDialog(props) {
    const { onClose, selectedValue, open } = props;

    const handleClose = () => {
      onClose(selectedValue);
    };

    const handleNameStatus = status => {
      switch (status) {
        case 'PENDENTE_REGISTRO':
          return 'AGUARDANDO_REGISTRO';
        case 'BAIXADO_CEDENTE':
          return 'CANCELADO';
        case 'PENDENTE_BAIXA_CEDENTE':
          return 'AGUARDANDO_CANCELAMENTO';
        case 'ALTERACA_PENDENTE':
          return 'AGUARDANDO_ALTERAÇÃO';
        default:
          return status;
      }
    };

    return (
      <div>
        <Dialog
          onClose={handleClose}
          aria-labelledby="simple-dialog-title"
          open={open}
        >
          <DialogTitle id="simple-dialog-title">
            Acompanhando o processamento
          </DialogTitle>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <RefreshIcon onClick={handleReprocessa} />
          </div>
          <List>
            {infoProcessados.map(item => (
              <ListItem button>
                <ListItemAvatar>
                  <Avatar
                    style={{
                      backgroundColor: theme.palette.primary.main,
                    }}
                  >
                    <BiDetail
                      style={{
                        color: theme.palette.getContrastText(
                          theme.palette.primary.main,
                        ),
                      }}
                    />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={handleNameStatus(item.status)} />
                <ListItemText
                  primary={item.quantidade}
                  style={{ display: 'flex', justifyContent: 'flex-end' }}
                />
              </ListItem>
            ))}
          </List>
        </Dialog>
      </div>
    );
  }
  SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
  };

  function reprocessar(event) {
    try {
      api_multipague_cobranca.put(
        `/cobranca-simples/arquivo/${event.row.codigo}/reprocessar?clienteConta=${contaPadrao.id}`,
      ),
        toastComponentSuccess(`Arquivo reprocessado com sucesso`);
    } catch (err) {
      dispatch(hideLoadingButton(false));
      toastComponentError(`Houve um erro`);
    }
  }

  useEffect(() => {
    if (contaPadrao.id) {
      pesquisarArquivos(contaPadrao.id, page, pageSize);
      sessionStorage.setItem('idContaPadrao', contaPadrao.id);
    }
  }, [contaPadrao]);

  useEffect(() => {
    if (contaPadrao.id) {
      setPage(0);
      pesquisarArquivos(contaPadrao.id, 0, pageSize);
    }
  }, [pageSize]);

  const pesquisarArquivos = async (id, page = 0, pageSize = 10) => {
    dispatch(showLoading(true));
    try {
      let config = {
        headers: {
          ContentType: 'application/paged+json',
        },
      };
      const { data } = await api_multipague_cobranca_cnab.get(
        `/cobranca-simples/arquivo?clienteConta=${id}&nomeArquivo=${formRef?.current?.getData().nome_arquivo
        }&page=${page}&size=${pageSize}`,
        config,
      );

      const formattedRows = data.content.map(date => ({
        codigo: date.codigoArquivoBancario,
        nome_arquivos: date.nomeArquivo,
        quantidade: date.quantidadeRegistros,
        situacao: date.descricaoSituacao,
        valor_total: formattedMoney(date.valorTotal),
        permiteReprocessar: date.permiteReprocessar,
        situacaoProcessamento: date.situacaoProcessamento,
        id: uuidv4(),
        erro: date.erro,
        sucesso: date.sucesso,
      }));

      setRowsTable(formattedRows);
      setRowCount(data.totalElements);

      dispatch(hideLoading(false));
    } catch (err) {
      dispatch(hideLoading(false));
      setRowsTable([]);
      setRowCount(0);
      const textError =
        err.response.status === 404 ? 'Nenhum CNAB encontrado' : '';
      setTextNoRows(textError);
      if (err.response.status != 404) {
        toastComponentError(
          ` Codigo: ${err.response.data.codigo} - ${err.response.data.mensagem}`,
        );
      }
    }
  };

  function handleClickOpen(event) {
    const idContaPadrao = sessionStorage.getItem('idContaPadrao');
    if (idContaPadrao) {
      setOpen(true);

      api_multipague_cobranca
        .get(
          `/cobranca-simples/arquivo/${event.codigo}/relatorio-processamento?clienteConta=${idContaPadrao}`,
        )
        .then(response => {
          const formatted = response.data.quantidadeBoletosPorStatus;
          setInfoProcessados(formatted);
          setGuardaCodigo(event.codigo);
        })
        .catch(error => {
          console.log(error);
        });
    }
  }

  const handleReprocessa = useCallback(
    async event => {
      if (contaPadrao?.id) {
        setOpen(true);
        console.log(contaPadrao);
        await api_multipague_cobranca
          .get(
            `/cobranca-simples/arquivo/${guardaCodigo}/relatorio-processamento?clienteConta=${contaPadrao.id}`,
          )
          .then(response => {
            const formatted = response.data.quantidadeBoletosPorStatus;
            setInfoProcessados(formatted);
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    [contaPadrao, guardaCodigo],
  );

  const filterInputs = {
    inputsData: [
      {
        html: () => (
          <InputText
            id="nome_arquivo"
            name="nome_arquivo"
            variant="outlined"
            label="Nome do Arquivo"
          />
        ),
        grid: { xs: 12, sm: 5, md: 5 },
      },
    ],
    searchButton: {
      searchButton: () => pesquisarArquivos(contaPadrao.id, 0, pageSize),
    },
    cleanButton: {
      cleanButton: () => limpar(),
    },
    updateButton: {
      updateButton: () => atualizar(),
    },
  };

  return (
    <Form ref={formRef}>
      <SimpleDialog open={open} onClose={handleClose} />
      <DataGridPage
        title="Acompanhamento"
        crumb={[
          { name: 'Minhas Contas', link: '/dashboard' },
          { name: 'Cobrança Simples', caminho: '/home/Cnab' },
        ]}
        formData={filterInputs}
        rows={rowsTable}
        columns={columnsTable}
        minHeight="600px"
        rowCount={rowCount}
        page={page}
        pageSize={pageSize}
        onRowClick={params => setRowId(params.id)}
        textNoRows={textNoRows}
        onPageChangeFunction={onPage}
        onPageSizeChangeFunction={newPageSize => setPageSize(newPageSize)}
      />
    </Form>
  );
};

export default ProcessCnab;
