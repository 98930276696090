export function listarInstrucoesRequest() {
  return {
    type: 'LISTAR_INSTRUCOES_REQUEST',
  };
}
export function listarInstrucoesSuccess(instrucoes) {
  return {
    type: 'LISTAR_INSTRUCOES_SUCCESS',
    payload: instrucoes,
  };
}

export function listarInstrucoesError() {
  return {
    type: 'LISTAR_INSTRUCOES_ERROR',
  };
}
