import { all, takeLatest, select, call, put } from 'redux-saga/effects';
import { api_multipague_configuracao } from '../../../services/api';
import { hideLoading, showLoading } from '../loading/actions';
import { listarEspecieSuccess, listarEspecieError } from './actions';
import { toastComponentError } from '../../../components/Toast';


function* listarEspecies() {
  try {
    yield put(showLoading(true));

    const { data } = yield call(
      api_multipague_configuracao.get,
      `/especie`,
    );
    yield put(hideLoading(false));

    yield put(listarEspecieSuccess(data))
    console.log('==========> Motivos', data);


  } catch (err) {
    yield put(listarEspecieError())
    yield put(hideLoading(false));
    toastComponentError(` Codigo: ${err.response.data.codigo} - ${err.response.data.mensagem}`,);
  }
}

export default all([takeLatest('LISTAR_ESPECIE_REQUEST', listarEspecies)]);
