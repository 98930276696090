import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Chart } from 'react-google-charts';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import { api_multipague_esteira_dashboard } from '../../src/services/api';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { MessageErrorComponent } from './DashboardCards/ErrorMessage';
import { DashboardCard } from './DashboardCards';
import { useRef } from 'react';

const useStyles = makeStyles(theme => ({
  rootChart: {
    marginBottom: 20,
    '& svg': {
      '& text': {
        fontSize: 11,
        flexWrap: 'wrap',
        fill: theme.palette.getContrastText(theme.palette.background.paper),
      },
      '& rect:nth-child(1)': {
        fill: theme.palette.background.paper,
      },
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function App() {
  const theme = useTheme();
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const chartContainerRef = useRef(null);
  const dispatch = useDispatch();
  const [error, setError] = React.useState(false);

  const [carregando, setCarregando] = React.useState(false);
  const infoRedux = useSelector(state => state.contas);

  const optionsChart = {
    width: 450,
    backgroundColor: 'transparent',
    titleTextStyle: {
      color: theme.palette.getContrastText(theme.palette.background.paper),
    },
    legend: {
      position: 'left',
      textStyle: {
        color: theme.palette.getContrastText(theme.palette.background.paper),
      },
    },
    title: 'Esteira',
    colors: [theme.palette.primary.main, '#394c59', '#2962ff', '#ffab00'],
  };

  const [options, setOptions] = useState(optionsChart);

  const [informacoes, setInformacoes] = useState({
    etapasConsolidada: [],
  });
  const [infoGraficos, setInfoGraficos] = useState([]);

  const dataInicial = moment(new Date().getFullYear() + '-01-01').format(
    'YYYY-MM-DD',
  );
  const dataFinal = moment(new Date().getFullYear() + '-12-31').format(
    'YYYY-MM-DD',
  );
  const URL = `/esteira/resumo?dataInicial=${dataInicial}&dataFinal=${dataFinal}&contextoConsolidado=true`;

  useEffect(() => {
    setCarregando(true);
    api_multipague_esteira_dashboard
      .get(URL)
      .then(response => {
        setError(false);
        setCarregando(false);

        setInformacoes(response.data);

        const situacoesConsolidadas = response.data.situacoesConsolidada;
        const situacoesExcluidas = [
          'AGUARDANDO_PROCESSAMENTO_ARQUIVO',
          'PARCIALMENTE_APROVADO',
          'ARQUIVO_SEM_REGISTRO_SUCESSO',
        ];
        const situacoes = [
          'APROVADO',
          'NEGADO',
          'EXPIRADO',
          'PENDENTE',
          'CANCELADO',
          'REJEITADO',
        ];

        const formattedInfo = situacoes.map(situacao => {
          const situacaoEncontrada = situacoesConsolidadas.find(
            item => item.situacao === situacao,
          );
          return [
            situacao,
            situacaoEncontrada ? situacaoEncontrada.quantidadeRegistros : 0,
          ];
        });

        const missingSituacoes = situacoes.filter(
          situacao => !formattedInfo.find(item => item[0] === situacao),
        );

        missingSituacoes.forEach(situacao => {
          formattedInfo.push([situacao, 0]);
        });

        formattedInfo.unshift(['Situação', 'Registros']);

        const filteredInfo = formattedInfo.filter(
          item => item[1] !== 0 || !situacoesExcluidas.includes(item[0]),
        );

        setInfoGraficos(filteredInfo);
      })
      .catch(err => {
        setError(true);
        setCarregando(false);
      });
  }, [infoRedux, dispatch]);

  useEffect(() => {
    setOptions(optionsChart);
  }, [theme.palette.type]);

  useEffect(() => {
    const chartContainer = chartContainerRef.current; // Obtém o elemento do gráfico
    if (chartContainer) {
      chartContainer.style.backgroundColor = theme.palette.primary.main; // Aplica a cor de fundo personalizada
    }
  }, [theme.palette.primary.main]);

  return (
    <DashboardCard
      title={'Aprovação'}
      icon={
        <AssignmentTurnedInIcon
          size={50}
          style={{
            marginRight: 5,
            color: theme.palette.primary.main,
          }}
        />
      }
      isChart
      carregando={carregando}
      children={
        <>
          <TabPanel value={value} index={0}>
            {error ? (
              <div
                style={{
                  backgroundColor: 'transparent',
                  transform: 'translate(0%, 150%)',
                }}
              >
                <MessageErrorComponent />
              </div>
            ) : (
              <div ref={chartContainerRef} className={classes.rootChart}>
                <Chart
                  className={classes.rootChart}
                  width={'100%'}
                  chartType="Bar"
                  data={infoGraficos}
                  options={options}
                />
              </div>
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            Item Two
          </TabPanel>
          <TabPanel value={value} index={2}>
            Item Three
          </TabPanel>
        </>
      }
    />
  );
}

export default App;
