import styled from 'styled-components';
import { Modal } from '@material-ui/core';
import Box from '../Box';
import { styled as MuyStyled } from '@material-ui/core';

export const Container = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Wrapper = styled(Box)`
  padding: 0px !important;
  width: 30vw;
  overflow: hidden;
`;

export const Header = MuyStyled('div')(({ theme }) => {
  return {
    padding: '1rem',
    backgroundColor: theme.palette.background.paper,
    borderBottom: `solid 1px ${theme.palette.background.paper}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '1rem',
  };
});

export const Content = MuyStyled('div')(({ theme }) => {
  return {
    backgroundColor: theme.palette.background.paper,
    padding: '1rem',
  };
});
