import React, { useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Wrapper from '../../components/Wrapper';
import Box from '../../components/Box';
import {
  api_multipague_transacao,
  api_multipague_transacaoPaged,
} from '../../services/api';
import { useDispatch, useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';

import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  CircularProgress,
  Grid,
  ListItem,
  ListItemText,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core';
import RadioGroup from '../../components/RadioButton';
import {
  chavePixMap,
  initialStateSearchKey,
  INPUT_CHAVEPIX_TIPO,
  TIPO_CHAVEPIX,
} from './constants';
import ReactInputMask from 'react-input-mask';
import ConfirmButton from '../../components/Buttons/ConfirmButton';
import { MdSearch, MdSwapVert } from 'react-icons/md';
import {
  toastComponentError,
  toastComponentSuccess,
} from '../../components/Toast';
import { showPopUp } from './../../store/modules/popUp/actions';
import PopUp from '../../components/PopUp';
import Password from '../../components/Password';
import { useAuthContext } from '../../Context/AuthContext';
import { extratoTedRequest } from '../../store/modules/extratoTed/actions';
import { useHistory } from 'react-router-dom';
import DateComponent from '../../components/InputDate';
import useDialogModalComponent from '../Transferencia/DialogModalComponent';
import { listarTodosFavoritosRequest } from '../../store/modules/beneficiarios/listar_todos/actions';
import AutoCompleteMui from '../../components/AutoCompleteMui';
import { InputMoney } from '../../components/Inputs/Money';
import { paymentDateInitialObject } from '../Transferencia/DialogModalComponent/objects';
import CardInfoPix from './CardInfoPix';
import moment from 'moment';
import ModalConfirmPix from './ModalConfirmPix';
import CardLastTransactions from './CardLastTransactions';
import { maskCpfCnpj } from '../../util/mask';
import Loader from '../../components/Loader';

const PIX = () => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const { permissionAdmin } = useAuthContext();
  const token = localStorage.getItem('tkn-access');
  const isAccountEscrow =
    JSON.parse(localStorage.getItem('accounts_default'))?.tipoControle ===
    'ESCROW';

  const {
    agendamentoFormaPagamento,
    setAgendamentoFormaPagamento,
    dataAgendamento,
    setDataAgendamento,
  } = useDialogModalComponent('PIX');
  const { userAccount } = useSelector(state => state.user);
  const infoRedux = useSelector(state => state.contas);
  const listFavoreds = useSelector(state => state.listTodosFavoritos);
  const idContaPadrao = infoRedux?.contaPadrao?.id;
  const [getKey, setGetKey] = React.useState(initialStateSearchKey);
  const [typeKeySelected, setTypeKeysSelected] = React.useState(
    isAccountEscrow ? 'Favorecido' : null,
  );
  const [transactionValue, setTransactionValue] = React.useState();
  const [valueKey, setValueKey] = useState();
  const [isLoading, setIsLoading] = useState({ search: false, create: false });
  const [cardLastTransactions, setCardLastTransactions] = useState([]);
  const [cardLastTransactionSelected, setCardLastTransactionSelected] =
    useState(false);
  const [hasError, setHasError] = useState({
    typeKey: false,
    valueKey: false,
    value: false,
    favored: false,
  });
  const [paymentDate, setPaymentDate] = useState(paymentDateInitialObject);
  const [favoredList, setFavoredList] = React.useState([]);
  const [favored, setFavored] = React.useState();
  const [openModal, setOpenModal] = React.useState(false);

  const searchKey = (valueKey, favored) => {
    setIsLoading(prev => ({ ...prev, search: true }));
    api_multipague_transacao
      .get(
        `/chaves-pix/consulta-chave-pix?chave=${valueKey}${
          !!favored ? `&idFavorecido=${favored}` : ''
        }`,
      )
      .then(({ data }) => {
        setIsLoading(prev => ({ ...prev, search: false }));
        setGetKey(data);
      })
      .catch(error => {
        setIsLoading(prev => ({ ...prev, search: false }));
        setGetKey(initialStateSearchKey);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 409 ||
          error.response.status == 500
        ) {
          toastComponentError(
            error?.response?.data?.mensagem
              ? error.response.data.mensagem
              : 'Não foi possível conectar ao servidor',
          );
        }
      });
  };

  const createPix = async senha => {
    if (!getKey?.sucesso) {
      dispatch(showPopUp(false));
      toastComponentError('Selecione uma chave válida');
    } else {
      setIsLoading(prev => ({ ...prev, create: true }));
      dispatch(showPopUp(false));
      if (!cardLastTransactionSelected) {
        setHasError({
          typeKey: !typeKeySelected,
          valueKey: !valueKey,
          value: !transactionValue,
          favored: !favored?.id,
        });
      } else {
        setHasError(prev => ({ ...prev, value: !transactionValue }));
      }
      try {
        setIsLoading(prev => ({ ...prev, create: true }));

        const body = {
          dataAgendamento:
            agendamentoFormaPagamento.agendado &&
            agendamentoFormaPagamento.selected
              ? moment(dataAgendamento).format('DD/MM/YYYY')
              : moment(Date.now()).format('DD/MM/YYYY'),
          modalidade: 'PIX',
          clienteConta: {
            id: idContaPadrao,
          },
          favorecido: !favored || !!favored.chavePix ? null : favored,
          favorecidoPix:
            !favored || !!favored.chavePix
              ? {
                  ...getKey,
                }
              : null,
          finalidade: { id: 10 },
          modalidade: 'PIX',
          valor: transactionValue,
        };

        const { data } = await api_multipague_transacao.post(
          '/transferencia',
          body,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              ContentType: 'application/json',
              IdempotencyKey: uuidv4(),
              'x-assinatura-financeira': senha,
            },
          },
        );

        if (!!data) {
          if (data.esteira) {
            toastComponentSuccess('Transferência encaminhada para análise!');
            history.push('/dashboard');
          } else {
            if (
              agendamentoFormaPagamento.agendado &&
              agendamentoFormaPagamento.selected
            ) {
              toastComponentSuccess(
                'A transação será efetivada mediante saldo disponível as 8:00 horas da data agendada',
              );
              window.open(`/ComprovanteTransferencia`);
              history.push('/dashboard');
            } else {
              if (permissionAdmin) {
                toastComponentSuccess('Transferência realizada com sucesso');
                dispatch(extratoTedRequest(data.id));
                history.push('/dashboard');
                window.open(`/ComprovanteTransferencia`);
              } else {
                toastComponentSuccess('Transferência enviada para análise');
                history.push('/dashboard');
              }
            }
          }
        }
        setIsLoading(prev => ({ ...prev, create: false }));
      } catch (err) {
        setIsLoading(prev => ({ ...prev, create: false }));
        toastComponentError(
          !!err
            ? ` Codigo: ${err.response.data.codigo} - ${err.response.data.mensagem}`
            : 'Não foi possível concluir a operação',
        );
      }
    }
  };

  const getTeds = () => {
    api_multipague_transacaoPaged
      .get(
        `/transferencia?size=5&clienteConta=${idContaPadrao}&modalidade=PIX&status=EFETIVADO`,
      )
      .then(response => {
        const formattedData = response.data.content.map(dados => ({
          id: uuidv4(),
          documento:
            dados.favorecido != null
              ? maskCpfCnpj(dados.favorecido?.cnpjCpf)
              : 'Sem informações',
          nome:
            dados.favorecido != null
              ? dados.favorecido.nome
              : 'Sem informações',
          agencia:
            dados.clienteConta != null
              ? dados.clienteConta.agencia
              : 'Sem informações',
          banco:
            dados.clienteConta != null
              ? dados.clienteConta.banco.nome
              : 'Sem informações',
          valor: dados.valor.toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          }),
          ...dados,
        }));
        setCardLastTransactions(formattedData);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const mostrarDataAgendamento = useMemo(() => {
    if (
      agendamentoFormaPagamento.agendado &&
      agendamentoFormaPagamento.selected
    ) {
      return (
        <DateComponent
          id="data-pagamento-agendado"
          label="Agendar Pagamento"
          name="agendamento"
          callback={setDataAgendamento}
          value={dataAgendamento}
          defaultValue={dataAgendamento}
        />
      );
    }
  }, [agendamentoFormaPagamento, dataAgendamento]);

  function selecionarDiaPagamento(data) {
    const dataSelecionada = paymentDate.map(val =>
      val.value === data
        ? {
            ...val,
            selected: true,
          }
        : {
            ...val,
            selected: false,
          },
    );
    const filterDalaSelecionada = dataSelecionada.filter(date => date.selected);
    setAgendamentoFormaPagamento(filterDalaSelecionada[0]);
    setPaymentDate(dataSelecionada);
  }

  const replaceKeyFormats = data => {
    return data
      ?.replace(/\./g, '')
      .replace(/-/g, '')
      ?.replace(/[.\-/()]/g, '')
      ?.replace(/ /g, '');
  };

  React.useEffect(() => {
    if (listFavoreds?.length) {
      const formatted = listFavoreds.map(list => ({
        label: `${list.nomeBanco} - ${list.nome}`,
        value: list.id,
        ...list,
      }));
      setFavoredList(formatted);
    }
  }, [listFavoreds]);

  React.useEffect(() => {
    if (userAccount?.id) {
      dispatch(
        listarTodosFavoritosRequest({
          id: userAccount.id,
          ativo: true,
          situacao: 'APROVADO',
          idClienteConta: idContaPadrao,
        }),
      );
    }
    getTeds();
  }, [userAccount, idContaPadrao]);

  return (
    <Wrapper
      title={'PIX'}
      crumb={[
        {
          name: 'Minhas Contas',
          link: '/MinhasContas',
        },
        {
          name: 'Lista de PIX',
          link: '/ListaPIX',
        },
      ]}
    >
      <PopUp>
        <Password callback={data => createPix(data)} />
      </PopUp>
      <ModalConfirmPix
        openModal={openModal}
        setOpenModal={setOpenModal}
        getKey={getKey}
        isLoading={isLoading}
        transactionValue={transactionValue}
        confirmPix={() => {
          const jwtToken = jwt_decode(localStorage.getItem('tkn-access'));
          const contaId = localStorage.getItem('idConta');

          if (jwtToken?.permissaoContas) {
            const conta = jwtToken?.permissaoContas?.find(item =>
              item.contas.find(conta => conta.id == contaId),
            );

            if (conta?.role === 'correntista_operador') {
              createPix();
            } else {
              dispatch(showPopUp(true));
            }
          }
          setOpenModal(false);
        }}
      />
      <Box>
        <Grid container spacing={2}>
          <Grid item sm={6} xs={12}>
            <Autocomplete
              id="combo-box-demo"
              size="small"
              options={isAccountEscrow ? ['Favorecido'] : TIPO_CHAVEPIX}
              value={isAccountEscrow ? 'Favorecido' : typeKeySelected}
              getOptionLabel={option => option}
              onChange={(_, option) => {
                setFavored('');
                setTransactionValue('');
                setValueKey('');
                setGetKey(initialStateSearchKey);
                if (option) {
                  setTypeKeysSelected(option ? option : '');
                } else {
                  setTypeKeysSelected('');
                }
              }}
              style={{ width: '100%' }}
              renderInput={params => (
                <TextField
                  {...params}
                  label={
                    isAccountEscrow ? 'Tipo Chave Pix' : 'Selecione a Chave Pix'
                  }
                  placeholder={
                    isAccountEscrow ? 'Tipo Chave Pix' : 'Selecione a Chave Pix'
                  }
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  required
                  error={cardLastTransactionSelected ? false : hasError.typeKey}
                />
              )}
              InputProps={{
                style: { borderRadius: '20px' },
              }}
            />
          </Grid>
          {typeKeySelected !== 'Favorecido' &&
          typeKeySelected !== 'E-mail' &&
          typeKeySelected !== 'Chave Aleatória' ? (
            <Grid item sm={6} xs={12}>
              <ReactInputMask
                mask={
                  typeKeySelected !== 'E-mail' &&
                  typeKeySelected != 'Chave Aleatória' &&
                  INPUT_CHAVEPIX_TIPO[typeKeySelected]
                }
                maskChar={
                  typeKeySelected !== 'E-mail' &&
                  typeKeySelected != 'Chave Aleatória'
                    ? ''
                    : null
                }
                value={valueKey}
                onBlur={() => {
                  if (valueKey) searchKey(replaceKeyFormats(valueKey));
                  setHasError(prev => ({ ...prev, valueKey: !valueKey }));
                }}
                onChange={event => setValueKey(event.target.value)}
              >
                {() => (
                  <TextField
                    id="valueKey"
                    name="valueKey"
                    size="small"
                    type={
                      INPUT_CHAVEPIX_TIPO[typeKeySelected] === 'Email' &&
                      'email'
                    }
                    label={typeKeySelected}
                    variant="outlined"
                    required
                    error={cardLastTransactionSelected ? false : hasError.valueKey}
                    placeholder={'Selecione a Chave Pix'}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={
                      isLoading.search
                        ? {
                            endAdornment: (
                              <CircularProgress
                                color="inherit"
                                size={24}
                                circleDisableShrink
                              />
                            ),
                            style: { borderRadius: '20px' },
                          }
                        : {
                            endAdornment: (
                              <MdSearch
                                onClick={() => {
                                  if (valueKey) searchKey(valueKey);
                                }}
                                style={{ cursor: 'pointer' }}
                                color="inherit"
                                size={24}
                              />
                            ),
                          }
                    }
                  />
                )}
              </ReactInputMask>
            </Grid>
          ) : typeKeySelected !== 'Favorecido' ? (
            <Grid item sm={6} xs={12}>
              <TextField
                id="valueKey"
                name="valueKey"
                size="small"
                type={
                  INPUT_CHAVEPIX_TIPO[typeKeySelected] === 'Email' && 'email'
                }
                value={valueKey}
                onBlur={() => {
                  if (valueKey) searchKey(valueKey);
                  setHasError(prev => ({ ...prev, valueKey: !valueKey }));
                }}
                onChange={event => setValueKey(event.target.value)}
                label={typeKeySelected}
                variant="outlined"
                required
                error={cardLastTransactionSelected ? false : hasError.valueKey}
                placeholder={'Selecione a Chave Pix'}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={
                  isLoading.search
                    ? {
                        endAdornment: (
                          <CircularProgress
                            color="inherit"
                            size={24}
                            circleDisableShrink
                          />
                        ),
                        style: { borderRadius: '20px' },
                      }
                    : {
                        endAdornment: (
                          <MdSearch
                            onClick={() => {
                              if (valueKey) searchKey(valueKey);
                            }}
                            style={{ cursor: 'pointer' }}
                            color="inherit"
                            size={24}
                          />
                        ),
                      }
                }
              />
            </Grid>
          ) : null}
          {typeKeySelected === 'Favorecido' ? (
            <Grid item sm={6} xs={12}>
              <AutoCompleteMui
                style={{
                  borderRadius: '20px',
                  backgroundColor: theme.palette.background.paper,
                }}
                label={'Favorecido'}
                name={'idFavorecido'}
                error={cardLastTransactionSelected ? false : hasError.favored}
                onBlur={() => {
                  setHasError(prev => ({ ...prev, favored: !favored?.id }));
                }}
                onChange={(_, option) => {
                  if (option) {
                    setFavored(option);
                    setGetKey(initialStateSearchKey);
                    setValueKey('');
                  } else {
                    setGetKey(initialStateSearchKey);
                    setFavored('');
                    setValueKey('');
                  }
                }}
                inputPropEndAdornment={
                  isLoading.search ? (
                    <CircularProgress
                      color="inherit"
                      size={24}
                      circleDisableShrink
                    />
                  ) : (
                    <MdSearch
                      onClick={() => {
                        if (!!favored?.chavePix) {
                          searchKey(
                            favored?.tipoChavePix === 'CPF' ||
                              favored?.tipoChavePix === 'CNPJ' ||
                              favored?.tipoChavePix === 'TELEFONE'
                              ? replaceKeyFormats(favored?.chavePix)
                              : favored?.chavePix,

                            favored?.id,
                          );
                        } else {
                          setGetKey({
                            sucesso: true,
                            nomeCorrentista: favored?.nome,
                            documentoFederal: favored?.cnpjCpf,
                            conta: {
                              conta: favored?.conta + favored?.digitoConta,
                              agencia: favored?.agencia,
                            },
                            banco: {
                              descricao: favored?.nomeBanco,
                            },
                          });
                        }
                      }}
                      style={{ cursor: 'pointer' }}
                      color="inherit"
                      size={24}
                    />
                  )
                }
                value={favored}
                options={favoredList}
                renderOption={option => {
                  return (
                    <ListItem style={{ padding: 0 }}>
                      <ListItemText
                        primary={option.label}
                        secondary={
                          <React.Fragment>
                            {`Agência ${option.agencia} / Conta: ${option.conta}-${option.digitoConta} / `}
                            <span
                              style={{
                                fontWeight: '700',
                              }}
                            >
                              {`Chave Pix - ${
                                option.tipoChavePix &&
                                option.tipoChavePix !== 'null'
                                  ? option.tipoChavePix + ': '
                                  : 'Não possui'
                              } ${option.chavePix || ''}`}
                            </span>
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                  );
                }}
              />
            </Grid>
          ) : null}
          {!!getKey.sucesso &&
            (isLoading.search ? (
              <Grid
                md={12}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <Loader size="table" />
              </Grid>
            ) : (
              <CardInfoPix keys={getKey} />
            ))}
          <Grid item sm={6} xs={12}>
            <InputMoney
              value={transactionValue}
              onChange={event => {
                setHasError(prev => ({ ...prev, value: !transactionValue }));
                setTransactionValue(event);
              }}
              label="Valor"
              error={hasError.value}
            />
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Grid item sm={6} xs={12}>
              <RadioGroup
                optionDefault={paymentDate[0].value}
                titulo="Data Pagamento"
                options={paymentDate}
                name="data_pagamento"
                callback={selecionarDiaPagamento}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              {mostrarDataAgendamento}
            </Grid>
            <Grid
              item
              xs={2}
              md={2}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <ConfirmButton
                color="primary"
                variant="contained"
                width={100}
                isLoading={isLoading.create}
                disabled={isLoading.create}
                title={'Transferir'}
                onClick={() => {
                  if (!transactionValue) {
                    setHasError(prev => ({
                      ...prev,
                      value: !transactionValue,
                    }));
                  }
                  if (getKey?.sucesso && !!transactionValue) {
                    setOpenModal(true);
                  }
                }}
                type="submit"
                startIcon={<MdSwapVert color={'background'} />}
              ></ConfirmButton>
            </Grid>
          </Grid>
        </Grid>
        {cardLastTransactions.length > 0 && (
          <Grid
            md={12}
            style={{
              margin: '15px 0 20px',
              padding: 15,
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: '20px',
            }}
          >
            <Typography
              variant="body2"
              style={{ fontSize: '15px', fontWeight: 600, marginLeft: 10 }}
            >
              Últimas transações
            </Typography>
            <div
              style={{
                overflowX: 'auto',
                overflowY: 'hidden',
                width: '100%',
                scrollbarWidth: 'thin',
              }}
            >
              <Grid
                container
                spacing={2}
                style={{
                  display: 'flex',
                  width: '100vw',
                  backgroundColor: theme.palette.background.paper,
                  justifyContent: 'space-between',
                  flexWrap: 'nowrap',
                  padding: 5,
                }}
              >
                {cardLastTransactions.map(item => (
                  <CardLastTransactions
                    keys={item}
                    onSelectCard={() => {
                      setCardLastTransactionSelected(true);
                      setFavored(
                        favoredList.filter(
                          item2 => item2.id === item.favorecido.id,
                        )[0],
                      );
                      if (!!item?.favorecido?.chavePix) {
                        setGetKey({
                          sucesso: true,
                        });
                        searchKey(item?.favorecido?.chavePix);
                      } else {
                        setGetKey({
                          sucesso: true,
                          nomeCorrentista: item?.favorecido?.nome,
                          documentoFederal: item?.favorecido?.cnpjCpf,
                          conta: {
                            documentoFederal: item?.documendo,
                            conta:
                              item?.favorecido?.conta +
                              item?.favorecido?.digitoConta,
                            agencia: item?.favorecido?.agencia,
                          },
                          banco: {
                            descricao: item?.favorecido?.bancoDTO?.nome,
                          },
                        });
                      }
                    }}
                  />
                ))}
              </Grid>
            </div>
          </Grid>
        )}
      </Box>
    </Wrapper>
  );
};

export default PIX;
