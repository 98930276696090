import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MdMonetizationOn } from 'react-icons/md';
import { Typography, useTheme } from '@material-ui/core';
import { ThumbUp } from '@material-ui/icons';
import * as Yup from 'yup';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import jwt_decode from 'jwt-decode';

import Buttons from '../../../components/Buttons/ConfirmButton';
import SmsToken from '../../../components/Inputs/SmsToken';
import { showPopUp } from '../../../store/modules/popUp/actions';
import { valorTedInitialObject } from './objects';
import { useAuthContext } from '../../../Context/AuthContext';
import getValidationErros from '../../../util/FormattedError';
import {
  toastComponentError,
  toastComponentSuccess,
} from '../../../components/Toast';
import DialogModal from '../../../components/DialogModal';
import {
  api_multipague_conta,
  api_multipague_transacao,
} from '../../../services/api';
import PopUp from '../../../components/PopUp';
import Password from '../../../components/Password';
import {
  hideLoadingButton,
  showLoadingButton,
} from '../../../store/modules/loading/actions';

import { extratoTedRequest } from '../../../store/modules/extratoTed/actions';

export const useDialogModalComponent = tipo => {
  const dispatch = useDispatch();
  const { permissionAdmin } = useAuthContext();
  const history = useHistory();
  const infoRedux = useSelector(state => state.contas);
  const { contaPadrao } = useSelector(state => state.contas);

  const submitFormRef = React.useRef(null);

  const [open, setOpen] = React.useState(false);
  const [valorSms, setValorSms] = React.useState('');
  const [openModalBMP, setOpenModalBMP] = React.useState(false);
  const [errorFavorecido, setErrorFavorecido] = React.useState(false);
  const [valorTed, setValorTed] = React.useState(valorTedInitialObject);
  const [agendamentoFormaPagamento, setAgendamentoFormaPagamento] =
    React.useState({});
  const [dataAgendamento, setDataAgendamento] = React.useState('');
  const [dataArquivoId, setDataArquivoId] = React.useState(null);
  const [favorecido, setFavorecido] = React.useState({
    nome: '',
    banco: '',
    id: 0,
    cnpj: 0,
    label: '',
  });
  const [ordemdePagamento, setOrdemdePagamento] = React.useState();

  function handleClose() {
    setOpen(false);
  }

  function enviarSms() {
    handleClose();
    dispatch(showPopUp(true));
  }

  function handleOpen() {
    setOpen(true);
    api_multipague_conta
      .post(`/token/send-token/${contaPadrao.id}`)
      .then(response => {
        setOpen(true);
      })
      .catch(err => {
        console.log(err);
      });
  }

  async function submitTed(senha) {
    dispatch(showPopUp(false));
    dispatch(showLoadingButton(true));
    const formInfo = submitFormRef.current.getData();
    try {
      const body = {
        ...formInfo,
        dataAgendamento:
          agendamentoFormaPagamento.agendado &&
          agendamentoFormaPagamento.selected
            ? moment(dataAgendamento).format('DD/MM/YYYY')
            : moment(Date.now()).format('DD/MM/YYYY'),

        clienteConta: {
          id: Number(contaPadrao.id),
        },
        valor: valorTed.value,
        centroCusto: {
          id: Number(formInfo.idCentroCusto),
        },
        favorecido: {
          id: Number(favorecido?.id),
        },
        finalidade: {
          id: Number(formInfo.idFinalidade),
        },
        idArquivoOrdemPagamento: dataArquivoId,
        ordensPagamento: ordemdePagamento?.map(ordem => ordem?.id),
        modalidade: tipo,
      };

      const token = localStorage.getItem('tkn-access');

      const { data } = await api_multipague_transacao.post(
        '/transferencia',
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            ContentType: 'application/json',
            IdempotencyKey: uuidv4(),
            'x-assinatura-financeira': senha,
            token: valorSms,
          },
        },
      );

      if (data.id) {
        if (data.esteira) {
          toastComponentSuccess('Transferência encaminhada para análise!');
          history.push('/dashboard');
        } else {
          if (
            agendamentoFormaPagamento.agendado &&
            agendamentoFormaPagamento.selected
          ) {
            toastComponentSuccess(
              'A transação será efetivada mediante saldo disponível as 8:00 horas da data agendada',
            );
            window.open(`/ComprovanteTransferencia`);
            history.push('/dashboard');
          } else {
            if (permissionAdmin) {
              toastComponentSuccess('Transferência realizada com sucesso');
              dispatch(extratoTedRequest(data.id));
              history.push('/dashboard');
              window.open(`/ComprovanteTransferencia`);
            } else {
              toastComponentSuccess('Transferência enviada para análise');
              history.push('/dashboard');
            }
          }
        }
      }
      dispatch(hideLoadingButton(false));
    } catch (err) {
      dispatch(hideLoadingButton(false));
      toastComponentError(
        ` Codigo: ${err.response.data.codigo} - ${err.response.data.mensagem}`,
      );
    }
  }

  async function submitData() {
    try {
      const schema = Yup.object().shape({
        idFinalidade: Yup.string().required('Finalidade é obrigatória'),
      });
      setErrorFavorecido(favorecido?.id > 0 ? false : true);

      await schema.validate(submitFormRef.current.getData(), {
        abortEarly: false,
      });
      if (valorTed.value < 1) {
        setValorTed({
          ...valorTed,
          error: 'Digite um valor acima de 1',
        });
        return;
      } else if (valorTed.value === 0) {
        setValorTed({
          ...valorTed,
          error: 'Digite um valor',
        });
        return;
      }

      if (!permissionAdmin) {
        submitTed('');
      } else if (
        permissionAdmin &&
        infoRedux.contaPadrao?.tokenPorTransacao == true
      ) {
        handleOpen();
      } else {
        const jwtToken = jwt_decode(localStorage.getItem('tkn-access'));
        const contaId = localStorage.getItem('idConta');

        if (jwtToken?.permissaoContas) {
          const conta = jwtToken?.permissaoContas?.find(item =>
            item.contas.find(conta => conta.id == contaId),
          );

          if (conta?.role === 'correntista_operador') {
            submitTed('');
          } else {
            dispatch(showPopUp(true));
          }
        }
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationErros(err);
        submitFormRef.current?.setErrors(erros);
        if (valorTed.value < 2) {
          setValorTed({
            ...valorTed,
            error: 'Digite um valor',
          });
          return;
        }
      } else {
        toastComponentError(
          ` Código: ${err.response.data.codigo} - ${err.response.data.mensagem}`,
        );
      }
    }
  }

  const dialog = (
    <>
      <DialogModal
        open={open}
        onClose={handleClose}
        dialogContent={
          <SmsToken
            id="outlined-basic"
            variant="outlined"
            placeholder="******"
            size="small"
            type="password"
            name="smsToken"
            inputProps={{ maxLength: 6 }}
            onChange={e => setValorSms(e.target.value)}
          />
        }
        dialogTitle={'Insira o token que você recebeu via SMS'}
        dialogActions={
          <div style={{ margin: 'auto' }}>
            <Buttons
              color="primary"
              width={150}
              title={'Prosseguir'}
              style={{ marginTop: '20px', marginBottom: '10px' }}
              onClick={() => enviarSms()}
              startIcon={
                <MdMonetizationOn color={useTheme().palette.background.paper} />
              }
            />
          </div>
        }
      />

      <DialogModal
        open={openModalBMP}
        onClose={() => setOpenModalBMP(false)}
        dialogTitle={'Confirma transferência entre contas do mesmo banco?'}
        dialogContent={
          <>
            <div>
              <Typography variant="body2" style={{ fontWeight: 600 }}>
                Favorecido:
                <Typography
                  variant="p"
                  style={{ fontWeight: 400, paddingLeft: '.5rem' }}
                >
                  {favorecido?.nome}
                </Typography>
              </Typography>
            </div>
            <Typography variant="body2" style={{ fontWeight: 600 }}>
              Banco:
              <Typography
                variant="p"
                style={{ fontWeight: 400, paddingLeft: '.5rem' }}
              >
                {favorecido?.banco}
              </Typography>
            </Typography>
          </>
        }
        dialogActions={
          <div style={{ margin: 'auto', paddingTop: '10px' }}>
            <Buttons
              color="primary"
              title={'OK'}
              onClick={() => {
                setOpenModalBMP(false);
                submitData();
              }}
              startIcon={
                <ThumbUp
                  style={{ color: useTheme().palette.background.paper }}
                  color="background"
                />
              }
            ></Buttons>
          </div>
        }
      />
    </>
  );

  const dialogPassword = (
    <PopUp>
      <Password callback={data => submitTed(data)} />
    </PopUp>
  );

  return {
    setOpenModalBMP,
    favorecido,
    setFavorecido,
    valorTed,
    setValorTed,
    agendamentoFormaPagamento,
    setAgendamentoFormaPagamento,
    dataAgendamento,
    setDataAgendamento,
    dataArquivoId,
    setDataArquivoId,
    ordemdePagamento,
    setOrdemdePagamento,
    dialog,
    dialogPassword,
    submitFormRef,
    submitData,
    errorFavorecido,
    setErrorFavorecido,
  };
};

export default useDialogModalComponent;
