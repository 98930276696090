import Keycloak from 'keycloak-js';
import { keycloakConfig } from '../util/constants';

const KEYCLOAK_URL = process.env.KEYCLOAK_URL;

if (!KEYCLOAK_URL) {
  throw new Error('KEYCLOAK_URL not defined');
}

const keycloak = new Keycloak({
  url: KEYCLOAK_URL + '/auth',
  realm: keycloakConfig.realm,
  clientId: keycloakConfig.resource,
});

const doLogin = keycloak.login;

const doLogout = keycloak.logout;

const initKeycloak = onAuthenticated => {
  keycloak
    .init({
      onLoad: 'check-sso',
    })
    .then(authenticated => {
      if (authenticated) {
        onAuthenticated(authenticated);
      }
    })
    .catch(() => {});
};

const logoutMfe = () => {
  const keycloak = process.env;
  localStorage.clear();
  sessionStorage.clear();

  window.location.href = `${keycloak.MSKEYCLOAK['auth-server-url']}realms/${
    keycloak.MSKEYCLOAK['realm']
  }/protocol/openid-connect/logout?client_id=${
    keycloak.MSKEYCLOAK['resource']
  }&post_logout_redirect_uri=${window.location.origin + '/'}login`;
};

const KeycloakService = {
  doLogin,
  doLogout,
  keycloak,
  initKeycloak,
  logoutMfe,
};

export default KeycloakService;
