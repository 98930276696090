import React,{useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AiFillCloseCircle } from 'react-icons/ai';
import { ContainerPopUp, InfoPopUp, Close } from './styles';
import { showPopUp } from '../../store/modules/popUp/actions';
import { useTheme } from '@material-ui/core';

const PopUp = ({ children }) => {
  const dispatch = useDispatch();
  const show = useSelector(state => state.PopUp);
  const [showInfo, setShowInfo] = useState(false);

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        setShowInfo(true);
      }, .5)
    }
  },[show])

  return (
    <ContainerPopUp show={show}>
      <InfoPopUp show={showInfo}>
        <Close>
          <button type="button" onClick={() => dispatch(showPopUp(false))}>
            <AiFillCloseCircle size={ 18 } color={useTheme().palette.primary.main} />
          </button>
        </Close>
        { children }
      </InfoPopUp>
    </ContainerPopUp>
  )
}

export default PopUp;
