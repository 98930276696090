import TextField from '@material-ui/core/TextField';
import { Form } from '@unform/web';
import styled from 'styled-components';
import DateInput from '../../components/InputDate';
import { styled as MuiStyled } from '@material-ui/core';


export const Container = styled(Form)`
  width: 100%;

  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;
export const Subcontainer = styled.div`
  width: 100%;
  display: grid;
  row-gap: 20px;
  @media only screen and (min-width: 1024px) {
    display: grid;
    row-gap: 40px;
  }
  @media only screen and (min-width: 1440px) {
  }
`;

export const DigitoBoleto = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  button {
    margin-left: 10px;
    svg {
      width: 24px;
      height: 24px;
    }
  }

  @media only screen and (min-width: 1024px) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    button {
      margin-left: 10px;
      svg {
        width: 32px;
        height: 32px;
      }
    }
  }
  @media only screen and (min-width: 1440px) {
  }
`;
export const AgendamentoPagamento = styled.div`
  display: grid;
  row-gap: 20px;
  align-items: center;
  @media only screen and (min-width: 1024px) {
    justify-content: flex-start;
    align-items: center;
  }
  @media only screen and (min-width: 1440px) {
  }
`;

export const DadosCadastrais = styled.div`
  display: grid;
  row-gap: 20px;
  @media only screen and (min-width: 1024px) {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 40px;
    row-gap: 40px;
  }

  @media only screen and (min-width: 1440px) {
  }
`;
export const NomeFantasia = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (min-width: 1024px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media only screen and (min-width: 1440px) {
  }
`;
export const CpfCnpjBeneficiario = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (min-width: 1024px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media only screen and (min-width: 1440px) {
  }
`;

export const DadosBoleto = styled.div`
  display: grid;
  row-gap: 20px;
  @media only screen and (min-width: 1024px) {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 40px;
  }

  @media only screen and (min-width: 1440px) {
  }
`;

export const BancoPagador = styled.div`
  width: 100%;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  @media only screen and (min-width: 1024px) {
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
  }
  @media only screen and (min-width: 1440px) {
  }
`;
export const DataVencimento = styled.div`
  display: grid;
  justify-content: flex-start;
  align-items: center;
  grid-template-columns: 100%;
  row-gap: 20px;
  @media only screen and (min-width: 1024px) {
    grid-template-columns: auto auto;
    column-gap: 40px;
  }
  @media only screen and (min-width: 1440px) {
  }
`;
export const Valor = styled.div`
  display: grid;
  row-gap: 20px;
  @media only screen and (min-width: 1024px) {
    display: grid;
    grid-template-columns: auto auto;
    -webkit-column-gap: 40px;
    column-gap: 40px;
    row-gap: 40px;
  }
  @media only screen and (min-width: 1440px) {
  }
`;
export const DadosSecundariosBoleto = styled.div`
  display: grid;
  row-gap: 20px;
  @media only screen and (min-width: 1024px) {
    column-gap: 40px;
    row-gap: 40px;
    grid-template-columns: auto;
  }
  @media only screen and (min-width: 1440px) {
  }
`;

export const InputText = styled(TextField)`
  width: 100%;
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;
export const Data = styled(DateInput)`
  width: 100%;
  margin: 0 !important;

  @media only screen and (min-width: 1024px) {
    margin: 0 !important;
  }
  @media only screen and (min-width: 1440px) {
    margin: 0 !important;
  }
`;

export const ContainerButton = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media only screen and (min-width: 1024px) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  @media only screen and (min-width: 1440px) {
  }
`;
export const Dates = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  /* justify-content: flex-start; */
  /* align-items: center; */
  row-gap: 20px;
  @media only screen and (min-width: 1024px) {
    grid-template-columns: auto auto;
    column-gap: 40px;
    /* justify-content: flex-start; */
    /* align-items: center; */
  }
  @media only screen and (min-width: 1440px) {
  }
`;

export const Info = MuiStyled('div')(({ theme }) => {
  return {
    padding: '0 0 40px 20px',
    '& li': {
      color: theme.palette.system.dark,
      fontWeight: 'bold',
    },
  };
});