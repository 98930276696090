export function addToCartRequest(listCart) {
  return {
    type: 'ADD_TO_CART_REQUEST',
    payload: listCart,
  };
}
export function addToCartSuccess(listCart) {
  return {
    type: 'ADD_TO_CART_SUCCESS',
    payload: listCart,
  };
}
export function addToCartError(listCart) {
  return {
    type: 'ADD_TO_CART_ERROR',
    payload: listCart,
  };
}
