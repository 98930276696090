import styled, { css } from 'styled-components';
import TextField from '@material-ui/core/TextField';
import { styled as MuiStyled, Box } from '@material-ui/core';

export const Container = styled.div`
  width: 100%;
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;
export const Subcontainer = styled.div`
  width: 100%;
  display: grid;
  row-gap: 20px;
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;
export const ContainerInputFile = styled.div`
  width: 100%;
  display: grid;
  row-gap: 20px;
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;
export const ContainerNomeFiles = styled.div`
  width: 100%;

  ${props =>
    props.existFiles
      ? css`
          display: grid;
          border-radius: 20px;
          row-gap: 10px;
          border: solid 1px #e8e8e8;
        `
      : css`
          display: flex;
          justify-content: center;
          align-items: center;
          p {
            font-weight: bold;
          }
        `}

  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;
export const Files = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  &:nth-child(even) {
    background-color: #e8e8e8;
  }

  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;
export const NameFile = styled.div`
  width: 100%;
  display: grid;
  row-gap: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;
export const DeleteFile = styled.div`
  width: 100%;
  display: grid;
  row-gap: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  svg {
    cursor: pointer;
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;

export const ContainerIcon = MuiStyled("div")(({ theme }) => {
  return {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",

    '& div': {
      height: "40px",
      width: "40px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "5px",
      border: `solid 2px ${theme.palette.primary.main}`,
      borderRadius: "80px",
    },

    '& svg':{
      cursor: "pointer",
      minHeight: "100px",
    },
    "& .displayinput" :{
      display: "none"
    },
  };
});

export const InputText = styled(TextField)`
  width: 100%;
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;
export const ContainerButton = styled.div`
  display: flex;
  justify-content: center;
  border-radius: "30px";
  align-items: center;
  @media only screen and (min-width: 1024px) {
    display: flex;
    justify-content: flex-end;
    border-radius: "30px";
    align-items: center;
  }
  @media only screen and (min-width: 1440px) {
  }
`;
