import React, { createContext, useState,useContext, useEffect, useCallback } from 'react';
import jwt_decode from 'jwt-decode';
import { useSelector } from 'react-redux';


const Context = createContext();

function AuthProvider({ children }) {
  const roleBackEmpresa = "backoffice-empresa";//Backoffice com permissão de acesso ao MFE (apenas consulta)
  const roleBackMultiplica = "backoffice-multiplica";//Backoffice com permissão de acesso ao MFE (consulta e Movimentação)
  const roleConsultaMercado = "consulta_mercado";// Libera acesso ao Consulta Mercado
  const roleCorrentistaAdmin = "correntista_administrador"//Correntista Master
  const roleAnalistaCredito = "analista_credito"//Correntista Master
  const roleCorrentistaOper = "correntista_operador";//Correntista Operador não tem senha financeira para aprovar movimentações. Envia para a Esteira.
  const roleUserMaster = "usuario_master";//essa role libera acesso TOTAL no backOffice, inclusive criação de novos usuários.


  const [permissionAdmin, setPermissionAdmin] = useState(false);
  const [permissionMercado, setPermissionMercado] = useState(false);
  const [permissionBackEmpresa, setPermissionBackEmpresa] = useState(false);
  const [permissionBackMultiplica, setPermissionBackMultiplica] = useState(false);

  const [roles, setRoles] = useState([]);
  const [permissaoContas, setPermissaoContas] = useState([]);

  const stateContas = useSelector(state => state.contas);
  const token = localStorage.getItem('tkn-access');

  const isAdminByPerfil = useCallback(()=>{
    if(roles.includes(roleCorrentistaAdmin) || roles.includes(roleAnalistaCredito)){
      return true;
    }else{
      return false;
    }
  },[roles])

  const isConsultaMercadoByPerfil = useCallback(()=>{
    if(roles.includes(roleConsultaMercado)){
      setPermissionMercado(true);
    }else{
      setPermissionMercado(false);
    }
  },[roles])

  const isConsultaBackEmpresaByPerfil = useCallback(()=>{
    if(roles.includes(roleBackEmpresa)){
      setPermissionBackEmpresa(true);
    }else{
      setPermissionBackEmpresa(false);
    }
  },[roles])

  const isBackMultiplicaByPerfil = useCallback(()=>{
    if(roles.includes(roleBackMultiplica)){
      setPermissionBackMultiplica(true);
    }else{
      setPermissionBackMultiplica(false);
    }
  },[roles])




  const checkPermissaoAdmin = useCallback(()=>{
    if(permissaoContas.length > 0){
      const idContaPadrao = stateContas.contaPadrao.id;
      let contaPermissao = {}
        permissaoContas.map(item=>{
          if(item.contas.length > 0 ){
            item.contas.map(conta=>{
              if(conta.id === idContaPadrao){
                contaPermissao = {...conta,role:item.role};
              }
          })
        }
        })
      if(contaPermissao.role === "correntista_administrador"){
        return contaPermissao.role === roleCorrentistaAdmin
      }else if
        (contaPermissao.role === "correntista_operador" ){
          return contaPermissao.role === false
      }else if
        (contaPermissao.role === "analista_credito" ){
          return contaPermissao.role === roleAnalistaCredito
      }
      else{
        return isAdminByPerfil();
      }
    }else{
      return isAdminByPerfil();
    }

  },[roles,stateContas.contaPadrao])

  useEffect(()=>{
    isConsultaMercadoByPerfil();
    isConsultaBackEmpresaByPerfil();
    isBackMultiplicaByPerfil();
    const checkAdmin = checkPermissaoAdmin();
    setPermissionAdmin(checkAdmin);
  },[roles,stateContas.contaPadrao])


  const setPermissionAcess = useCallback(()=>{
    if(token){
      const jwtToken = jwt_decode(token);
      setRoles(jwtToken.realm_access.roles);
      if(jwtToken.permissaoContas){
        setPermissaoContas(jwtToken.permissaoContas);
      }
    }

  },[token])

  useEffect(()=>{
    setPermissionAcess();
  },[])

  return (
    <Context.Provider value={{ permissionAdmin ,permissionMercado,permissionBackEmpresa,permissionBackMultiplica}}>{children}</Context.Provider>
  );
}

function useAuthContext() {
  const context = useContext(Context)
  return context;
}


export { AuthProvider,useAuthContext };
