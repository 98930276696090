import styled, { css } from 'styled-components';

export const ButtonTable = styled.button.attrs(props => ({
  disabled: !props.hasError,
}))`
  ${props =>
    props.hasError
      ? css`
          cursor: pointer;
        `
      : css`
          cursor: no-drop;
        `}/* svg{
  ${props => (props.hasError ? css`` : css``)}
} */
`;
