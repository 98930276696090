import { alpha, makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

export const useStyles = makeStyles(theme => ({
  paperTimeline: {
    marginTop: '1.5rem',
    padding: '1rem',
    borderRadius: '20px',
    boxShadow: 'none',
  },
  boxTimeline: {
    padding: '30px',
    borderRadius: '20px',
    backgroundColor: alpha(theme.palette.background.default, 0.5),
  },
  timeline: {
    margin: '5% auto 0',
    width: '100%',
    padding: '0 2%',
    display: 'flex',
    justifyContent: 'center',
    minHeight: '8vh',

    [theme.breakpoints.down('md')]: {
      margin: '5% 0',
      padding: '0 5%',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '10% 0',
    },
  },
  stateHistoricoFavorecico: {
    textTransform: 'uppercase',
    padding: '5px 0 5px',
    '& span': {
      textTransform: 'capitalize',
      fontWeight: '600',
      fontSize: '15px',
    },
  },
  stateHistorico: {
    padding: '5px 0 5px',
    '& span': {
      fontWeight: '600',
      fontSize: '15px',
    },
  },
  stateHistoricoSituacao: {
    padding: '5px 0 5px',
    '& span': {
      fontWeight: '600',
      fontSize: '15px',
    },
  },
  iconHistory: {
    width: '40px',
    borderRadius: '3px',
    height: '45px',
    cursor: 'pointer',
    position: 'relative',
    backgroundColor: theme.palette.background.paper,

    '&:hover': {
      borderRadius: '40px',
      height: '40px',
      backgroundColor: theme.palette.background.paper,
      border: `1px solid ${grey[300]}`,
    },
  },
  textHistory: {
    width: '1000%',
    margin: 'auto',
    paddingTop: '3%',
    position: 'absolute',
    wordWrap: 'break-word',
    transform: 'translate(-30%, -100%)',
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down('lg')]: {
      width: '800%',
    },
    [theme.breakpoints.down('md')]: {
      width: '700%',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
      width: '600%',
      transform: 'translate(-40%, -100%)',
      margin: '5%',
    },
  },
  tipographyClienteCorrentistaNome: {
    overflowWrap: 'break-word',
    wordBreak: 'break-all',
    overflow: 'auto',
    display: 'flex',
    justifyContent: 'stretch',
    alignItems: 'space-around',
  },
  divHistoricoWithIconAndTimeline: {
    width: '100%',
    margin: '4% 0',
    borderTop: `1px solid ${theme.palette.text.disabled}`,
    position: 'relative',

    [theme.breakpoints.down('lg')]: {
      margin: '4% 0',
    },
    [theme.breakpoints.down('md')]: {
      margin: '10% 0',
    },
    [theme.breakpoints.down('xs')]: {
      margin: '20% 0',
    },
  },
  divHistoricoIcon: {
    margin: 'auto',
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1,
  },
}));
