import { all, takeLatest, call, put } from 'redux-saga/effects';
import { api_multipague_conta } from '../../../services/api';
import { saldoSuccess, saldoError } from './actions';

function* saldo({ payload }) {
  const { showSaldo } = payload;
  try {
    yield put(saldoSuccess(showSaldo));

  } catch (err) {
    yield put(saldoError("erro"));
  }
}

export default all([takeLatest('SALDO_REQUEST', saldo)]);
