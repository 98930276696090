import produce from 'immer';
const INITIAL_STATE = [];

const carrinho = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'LISTAR_TODOS_FAVORITOS_SUCCESS': {
      return [
        ...action.payload
      ]
    }

    case 'LISTAR_TODOS_FAVORITOS_ERROR':{
      return [
        state
      ]
    }

    default: {
      return state;
    }
  }
};

export default carrinho;
