import moment from 'moment';

const date = new Date();

export const initialDateState = {
  dataDe: moment(new Date(date.getFullYear(), date.getMonth(), 1)).format(
    'YYYY-MM-DD',
  ),
  dataAte: moment(new Date(date.getFullYear(), date.getMonth() + 1, 0)).format(
    'YYYY-MM-DD',
  ),
  dataPagDe: moment(new Date(date.getFullYear(), date.getMonth(), 1)).format(
    'YYYY-MM-DD',
  ),
  dataPagAte: moment(
    new Date(date.getFullYear(), date.getMonth() + 1, 0),
  ).format('YYYY-MM-DD'),
};

export const selecionarSituacao = [
  {
    id: 'PENDENTE',
    descricao: 'Pendente',
  },
  {
    id: 'AGUARDANDO_EFETIVACAO',
    descricao: 'Aguardando Efetivação',
  },
  {
    id: 'EFETIVADO',
    descricao: 'Efetivado',
  },
  {
    id: 'REJEITADO',
    descricao: 'Rejeitado',
  },
  {
    id: 'CANCELADO',
    descricao: 'Cancelado',
  },
  {
    id: 'EXPIRADO',
    descricao: 'Expirado',
  },
];
