import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import LocalAtm from '@material-ui/icons/LocalAtm';
import { DashboardCard } from './DashboardCards';

export default function RecipeReviewCard() {
  const theme = useTheme();

  return (
    <DashboardCard
      title={'Folha de Pagamento'}
      icon={
        <LocalAtm
          size={50}
          style={{
            marginRight: 5,
            color: theme.palette.primary.main,
          }}
        />
      }
      children={
        <Typography
          style={{
            color: theme.palette.text.primary,
            alignItems: 'center',
            display: 'flex',
            fontWeight: 'bold',
            transform: 'translate(35%, 40%)',
            alignContent: 'center',
          }}
        >
          <AiOutlineExclamationCircle
            size={25}
            style={{ paddingRight: '5px' }}
            color={'red'}
          />
          Em breve!
        </Typography>
      }
    />
  );
}
