import produce from 'immer';
const INITIAL_STATE = [];

const carrinho = (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case 'ADD_FAVORITOS_SUCCESS': {

    }

    case 'ADD_FAVORITOS_ERROR':{

    }

    default: {
      return state;
    }
  }
};

export default carrinho;
