import React, { useEffect } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  alpha,
  useTheme,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import ConfirmButton from '../../../components/Buttons/ConfirmButton';
import { FaCopy, FaSave } from 'react-icons/fa';
import {
  api_multipague_conta,
  api_multipague_transacao,
} from '../../../services/api';
import { useStyles } from './styles';
import {
  toastComponentError,
  toastComponentSuccess,
} from '../../../components/Toast';
import { useSelector } from 'react-redux';
import { MdCheckBox, MdDone } from 'react-icons/md';
import { maskCpfCnpj, maskTelefone } from '../../../util/mask';
import { v4 as uuidv4 } from 'uuid';

export const useAccordion = () => {
  const theme = useTheme();
  const classes = useStyles();
  const infoRedux = useSelector(state => state.contas);
  const idContaPadrao = infoRedux?.contaPadrao?.id;
  const idClienteConta = infoRedux?.contaPadrao?.cliente?.id;
  const cnpjCpfCliente = infoRedux?.contaPadrao?.cliente?.cnpjCpf;
  const [expanded, setExpanded] = React.useState('panel1');
  const [open, setOpen] = React.useState(false);
  const [openTipoMfa, setOpenTipoMfa] = React.useState(false);
  const [keySelected, setKeySelected] = React.useState();
  const [codigoAutenticacao, setCodigoAutenticacao] = React.useState('teste');
  const [tipoMfa, setTipoMfa] = React.useState('SMS');
  const [codigoMfa, setCodigoMfa] = React.useState('');
  const [loadingCreateKey, setLoadingCreateKey] = React.useState(false);
  const [loadingCode, setLoadingCode] = React.useState(false);
  const [loadingClient, setLoadingClient] = React.useState(false);
  const [copied, setCopied] = React.useState({});
  const [formValues, setFormValues] = React.useState({
    CELULAR: '',
    CPF: '',
    CNPJ: '',
    EMAIL: '',
    ALEATORIA: '',
  });
  const [keys, setKeys] = React.useState({
    CELULAR: '',
    CPF: '',
    CNPJ: '',
    EMAIL: '',
    ALEATORIA: '',
  });

  useEffect(() => {
    setFormValues(prev => ({
      ...prev,
      CPF: cnpjCpfCliente?.length <= 11 ? cnpjCpfCliente : '',
      CNPJ: cnpjCpfCliente?.length >= 14 ? cnpjCpfCliente : '',
    }));
  }, [cnpjCpfCliente]);

  const listClientContact = idClient => {
    setLoadingClient(true);
    api_multipague_conta
      .get(`/conta/cliente/${idClient}`)
      .then(({ data }) => {
        setLoadingClient(false);
        setFormValues(prev => ({
          ...prev,
          CELULAR: data.pessoa?.pessoaContatos[0]?.telefone,
          EMAIL: data.pessoa?.pessoaContatos[0]?.email,
        }));
      })
      .catch(error => {
        setLoadingClient(false);
        console.log('error:', error);
      });
  };

  const listKey = () => {
    api_multipague_transacao
      .get(`/chaves-pix/findAllByClienteConta`)
      .then(({ data }) => {
        setKeys({
          CELULAR: data.find(element => element.tipo === 'CELULAR')?.chavePix,
          CPF:
            cnpjCpfCliente?.length <= 11
              ? data.find(element => element.tipo === 'CPF')?.chavePix
              : data.find(element => element.tipo === 'CPF')?.chavePix,
          CNPJ:
            cnpjCpfCliente?.length >= 14
              ? data.find(element => element.tipo === 'CNPJ')?.chavePix
              : data.find(element => element.tipo === 'CNPJ')?.chavePix,
          EMAIL: data.find(element => element.tipo === 'EMAIL')?.chavePix,
          ALEATORIA: data.find(element => element.tipo === 'ALEATORIA')
            ?.chavePix,
        });
      })
      .catch(error => {
        setDataGridState(prev => ({ ...prev, loading: false }));
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 409 ||
          error.response.status == 500
        ) {
          toastComponentError(
            error.response.data.mensagem
              ? error.response.data.mensagem
              : 'Não foi possível conectar ao servidor',
          );
        }
      });
  };

  useEffect(() => {
    listKey();
    if (!!idClienteConta) listClientContact(idClienteConta);
  }, [idClienteConta]);

  const handleChange = panel => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const criarChave = () => {
    setOpenTipoMfa(false);
    setLoadingCreateKey(true);

    const payload = {
      idClienteConta: idContaPadrao,
      codigoAutenticacao:
        keySelected === 'CNPJ' || keySelected === 'CPF'
          ? null
          : codigoAutenticacao,
      codigoMfa:
        keySelected === 'CNPJ' || keySelected === 'CPF' ? null : codigoMfa,
      tipoChave: keySelected,
    };

    if (keySelected !== 'CNPJ' && keySelected !== 'CPF') {
      payload.chave = formValues[keySelected];
    }

    if (keySelected === 'ALEATORIA') {
      delete payload.codigoMfa;
      delete payload.chave;
      delete payload.codigoAutenticacao;
    }

    const idempotencyKey = uuidv4();

    api_multipague_transacao
      .post('/chaves-pix', payload, {
        headers: {
          IdempotencyKey: idempotencyKey,
        },
      })
      .then(response => {
        listKey();
        setLoadingCreateKey(false);
        toastComponentSuccess('Chave criada com sucesso');
        setOpen(false);
        setCodigoMfa(null);
      })
      .catch(error => {
        setLoadingCreateKey(false);
        toastComponentError(
          error.response.data.mensagem ||
            'Não foi possível conectar ao servidor',
        );
        setOpen(false);
      });
  };

  const solicitarCodigo = () => {
    const idempotencyKey = uuidv4();

    setLoadingCode(true);
    api_multipague_transacao
      .get(
        `/chaves-pix/solicitar-mfa?idClienteConta=${idContaPadrao}&tipoMfa=${
          keySelected === 'EMAIL' ? 'EMAIL' : tipoMfa
        }&destinoEnvio=${
          keySelected === 'EMAIL'
            ? encodeURIComponent(formValues[keySelected])
            : formValues[keySelected]
        }`,
        {
          headers: {
            IdempotencyKey: idempotencyKey,
          },
        },
      )
      .then(response => {
        setLoadingCode(false);
        setCodigoAutenticacao(response?.data?.codigoAutenticacao);
        toastComponentSuccess(
          `Código enviado para ${keySelected === 'EMAIL' ? 'EMAIL' : tipoMfa}`,
        );
        setOpenTipoMfa(false);
        setOpen(true);
      })
      .catch(error => {
        setLoadingCode(false);
        toastComponentError(
          error?.response?.data?.mensagem ||
            'Não foi possível conectar ao servidor',
        );
        setOpenTipoMfa(false);
      });
  };

  const handleCopy = keyValue => {
    navigator.clipboard.writeText(keyValue);
    setCopied(prevState => ({
      ...prevState,
      [keyValue]: true,
    }));
  };

  const TypographyStyle = ({ children, keyValue = null }) => {
    return (
      <Box display={'flex'} alignItems={'center'}>
        <Typography
          style={{
            padding: '5px 15px',
            marginRight: 5,
            minWidth: '50%',
            overflowWrap: 'anywhere',
            color: alpha(theme.palette.divider, 0.7),
            backgroundColor: theme.palette.background.paper,
            borderRadius: '15px',
            border: `1px solid ${theme.palette.divider}`,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {loadingClient ? <CircularProgress size={20} /> : children}
        </Typography>
        <Tooltip title={copied[keyValue] ? 'Copiado' : 'Copiar'}>
          <IconButton
            onClick={() => {
              handleCopy(keyValue);
              setTimeout(() => {
                setCopied({});
              }, 3000);
            }}
            disabled={!keyValue}
          >
            {copied[keyValue] ? (
              <MdCheckBox
                size={20}
                color={
                  !keyValue
                    ? theme.palette.text.disabled
                    : theme.palette.primary.main
                }
              />
            ) : (
              <FaCopy
                size={20}
                color={
                  !keyValue
                    ? theme.palette.text.disabled
                    : theme.palette.primary.main
                }
              />
            )}
          </IconButton>
        </Tooltip>
      </Box>
    );
  };

  const accordion = (panel, dataInputName, label) => {
    return (
      <>
        <Accordion
          expanded={expanded === panel}
          key={panel}
          onClick={() => setKeySelected(dataInputName)}
          onChange={handleChange(panel)}
          elevation={0}
          style={{
            border: expanded === panel && `1px solid ${theme.palette.divider}`,
            borderRadius: expanded === panel && '20px',
            backgroundColor:
              expanded === panel
                ? theme.palette.type === 'dark'
                  ? theme.palette.grey['900']
                  : theme.palette.grey['100']
                : theme.palette.background.paper,
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMore sx={{ fontSize: '0.9rem' }} />}
            className={classes.summary}
            aria-controls="panel1d-content"
            id="panel1d-header"
          >
            <Typography style={{ fontWeight: '500' }}>{label}</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <Grid item xs={12} sm={8}>
              {dataInputName === 'EMAIL' ? (
                <TypographyStyle
                  children={
                    !formValues['EMAIL']
                      ? 'Não há e-mail cadastrado'
                      : formValues['EMAIL']
                  }
                  keyValue={formValues['EMAIL']}
                />
              ) : dataInputName === 'ALEATORIA' ? (
                <TypographyStyle
                  children={
                    !keys.ALEATORIA ? 'Cadastrar chave' : keys.ALEATORIA
                  }
                  keyValue={!keys.ALEATORIA ? '' : keys.ALEATORIA}
                />
              ) : dataInputName === 'CPF' ? (
                <TypographyStyle
                  children={
                    cnpjCpfCliente?.length <= 11
                      ? maskCpfCnpj(cnpjCpfCliente)
                      : 'Não há CPF cadastrado'
                  }
                  keyValue={
                    cnpjCpfCliente?.length <= 11
                      ? maskCpfCnpj(cnpjCpfCliente)
                      : ''
                  }
                />
              ) : dataInputName === 'CNPJ' ? (
                <TypographyStyle
                  children={
                    cnpjCpfCliente?.length >= 14
                      ? maskCpfCnpj(cnpjCpfCliente)
                      : 'Não há CNPJ cadastrado'
                  }
                  keyValue={
                    cnpjCpfCliente?.length >= 14
                      ? maskCpfCnpj(cnpjCpfCliente)
                      : ''
                  }
                />
              ) : dataInputName === 'CELULAR' ? (
                <TypographyStyle
                  children={
                    !formValues['CELULAR']
                      ? 'Não há celular cadastrado'
                      : maskTelefone(formValues['CELULAR'])
                  }
                  keyValue={maskTelefone(formValues['CELULAR'])}
                />
              ) : (
                <TypographyStyle children={formValues[dataInputName]} />
              )}
            </Grid>

            {keys[dataInputName] ? (
              <Tooltip title="Chave Cadastrada">
                <IconButton
                  style={{
                    cursor: 'no-drop',
                    boxShadow: 'none',
                    textTransform: 'initial',
                    fontSize: '10px',
                    backgroundColor: alpha(theme.palette.divider, 0.1),
                  }}
                  variant="contained"
                  color="primary"
                >
                  <MdDone color="background" />
                </IconButton>
              </Tooltip>
            ) : (
              <ConfirmButton
                variant="contained"
                key={dataInputName}
                title={'Criar'}
                isLoading={loadingCreateKey}
                startIcon={<FaSave size="1em" color={'background'} />}
                onClick={() => {
                  if (
                    !!formValues[dataInputName] ||
                    dataInputName === 'ALEATORIA'
                  ) {
                    if (dataInputName === 'CELULAR') {
                      setOpenTipoMfa(true);
                    } else if (dataInputName === 'EMAIL') {
                      solicitarCodigo();
                      setOpen(true);
                    } else {
                      setOpen(true);
                    }
                  }
                }}
              />
            )}
          </AccordionDetails>
        </Accordion>
      </>
    );
  };

  return {
    open,
    setOpen,
    openTipoMfa,
    setOpenTipoMfa,
    keySelected,
    accordion,
    formValues,
    codigoMfa,
    setCodigoMfa,
    codigoAutenticacao,
    solicitarCodigo,
    tipoMfa,
    setTipoMfa,
    criarChave,
    loadingCode,
    loadingCreateKey,
  };
};
