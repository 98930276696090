import React, { useState, useCallback, useEffect, useRef } from 'react';
import * as Yup from 'yup';
import InputText from '../../components/InputText';
import InputWithMask from '../../components/Inputs/MaskPersonalizavel';
import { InputMoney } from '../../components/Inputs/Money';
import Button from '../../components/Buttons/ConfirmButton';
import SelectComponente from '../../components/Select';
import getValidationErros from '../../util/FormattedError';
import { api_multipague_configuracao } from '../../services/api';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';
import Alert from '@material-ui/lab/Alert';

import {
  ContainerOpcoes,
  Options,
  TituloContainer,
  Label,
  ContainerInputsOptions,
  Container,
  Subcontainer,
  ContainerButton,
  ContainerTitle,
  ContainerInfoPrimaria,
  Especie,
  Banco,
  DiasLimitePagamento,
  ContainerFaixas,
} from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { listarBancosRequest } from '../../store/modules/bancos/actions';
import { listarEspecieoRequest } from '../../store/modules/especie/actions';
import { hideLoading, showLoading } from '../../store/modules/loading/actions';

import Box from '../../components/Box';
import Wrapper from '../../components/Wrapper';

import { MdSave, MdArrowBack } from 'react-icons/md';
import { useTheme } from '@material-ui/core';
import { toastComponentError, toastComponentSuccess } from '../../components/Toast';

const Carteira = () => {
  const dispatch = useDispatch();
  const listBanks = useSelector(state => state.bancos);
  const especies = useSelector(state => state.especies);
  const { contaPadrao } = useSelector(state => state.contas);
  const { userAccount } = useSelector(state => state.user);
  const formRef = useRef(null);
  const [bancos, setBancos] = useState([]);
  const [especiesCarteira, setEspeciesCarteira] = useState([]);
  const [origemNumero, setOrigemNumero] = useState([]);
  const [disabledValueJuros, setDisabledValueJuros] = useState(false);
  const [isPercentualValueJuros, setIsPercentualValueJuros] = useState(false);
  const [isPercentualValueMulta, setIsPercentualValueMulta] = useState(false);
  const [disabledMultaJuros, setDisabledMultaJuros] = useState(false);
  const [especie, setEspeciel] = useState('');
  const [limiteDias, setLimiteDias] = useState('');
  const [codigoBeneficiario, setCodigoBeneficiario] = useState('');
  const [tipoCalculoJuros, setTipoCalculoJuros] = useState('');
  const [valorCalculoJuros, setValorCalculoJuros] = useState('');
  const [tipoCalculoMulta, setTipoCalculoMulta] = useState('');
  const [valorCalculoMulta, setValorCalculoMulta] = useState('');
  const [carteiraDefault, setCarteiraDefault] = useState([]);
  const [origemNossoNumero, setOrigemNossoNumero] = useState('BANCO');

  const [disabledOptions, setDisabledOptions] = useState({
    especie: false,
    limite_dias: false,
    tipoCalculoJuros: false,
    valorJuros: false,
    tipoCalculoMulta: false,
    valorMulta: false,
  });

  const [valorJuros, setValorJuros] = useState({
    value: 0,
    error: '',
    disabled: false,
  });
  const [valorMulta, setValorMulta] = useState({
    value: 0,
    error: '',
    disabled: false,
  });

  function callBackJuros(value) {
    setTipoCalculoJuros(value);
    if (value === 'ISENTO') {
      setDisabledValueJuros(true);
      setValorJuros({
        ...valorJuros,
        disabled: true,
        value: 0,
      });
    } else if (value === 'PERCENTUAL_DIA' || value === 'PERCENTUAL_MES') {
      setIsPercentualValueJuros(true);
      setDisabledValueJuros(false);
    } else {
      setDisabledValueJuros(false);
      setIsPercentualValueJuros(false);
      setValorJuros({
        ...valorJuros,
        disabled: false,
      });
    }
  }
  function callBackMulta(value) {
    setTipoCalculoMulta(value);
    if (value === 'ISENTO') {
      setDisabledMultaJuros(true);
      setIsPercentualValueMulta(false);
      setValorMulta({
        ...valorMulta,
        disabled: true,
        value: 0,
      });
    } else if (value === 'PERCENTUAL') {
      setIsPercentualValueMulta(true);
      setDisabledMultaJuros(false);
    } else {
      setDisabledMultaJuros(false);
      setIsPercentualValueMulta(false);
      setValorMulta({
        ...valorMulta,
        disabled: false,
      });
    }
  }

  const [data, setData] = useState({
    juros: Date.now(),
    multa: Date.now(),
    desconto: Date.now(),
  });

  const [tipoJuros, setTipoJuros] = useState([]);
  const [tipoMulta, setTipoMulta] = useState([]);

  useEffect(() => {
    dispatch(listarBancosRequest());
    dispatch(listarEspecieoRequest());
  }, []);

  useEffect(() => {
    if (listBanks.length) {
      const banksFormatted = listBanks.map(data => ({
        label: data.nome,
        value: data.id,
        ...data,
      }));

      setBancos(banksFormatted);
    }
  }, [listBanks]);

  useEffect(() => {
    if (especies.length) {
      const formatted = especies.map(data => ({
        label: data.descricao,
        value: data.valor,
      }));

      setEspeciesCarteira(formatted);
    }
  }, [especies]);

  useEffect(() => {
    loadJuros();
    loadMultas();
    loadOrigemNossoNumero();
  }, []);

  async function loadJuros() {
    try {
      dispatch(showLoading(true));

      const { data } = await api_multipague_configuracao.get('/tipo-juros');
      if (data.length) {
        const formatted = data.map(data => ({
          label: data.descricao,
          value: data.valor,
        }));
        setTipoJuros(formatted);
      }

      dispatch(hideLoading(false));
    } catch (err) {
      dispatch(hideLoading(false));
      toastComponentError(
        ` Codigo: ${err.response.data.codigo} - ${err.response.data.mensagem}`,
      );
    }
  }

  async function loadMultas() {
    try {
      dispatch(showLoading(true));

      const { data } = await api_multipague_configuracao.get('/tipo-multa');

      if (data.length) {
        const formatted = data.map(data => ({
          label: data.descricao,
          value: data.valor,
        }));
        setTipoMulta(formatted);
      }

      dispatch(hideLoading(false));
    } catch (err) {
      dispatch(hideLoading(false));
      toastComponentError(
        ` Codigo: ${err.response.data.codigo} - ${err.response.data.mensagem}`,
      );
    }
  }

  async function loadOrigemNossoNumero() {
    try {
      dispatch(showLoading(true));

      const { data } = await api_multipague_configuracao.get(
        '/origem-nosso-numero',
      );

      if (data.length) {
        const formatted = data.map(data => ({
          label: data,
          value: data,
        }));
        setOrigemNumero(formatted);
      }

      dispatch(hideLoading(false));
    } catch (err) {
      dispatch(hideLoading(false));
      toastComponentError(
        ` Codigo: ${err.response.data.codigo} - ${err.response.data.mensagem}`,
      );
    }
  }

  function validationFields() {
    // disabledValueJuros
    // disabledMultaJuros

    if (disabledValueJuros && disabledMultaJuros) {
      return {
        especie: Yup.string().required('Campo obrigatorio'),
        origemNossoNumero: Yup.string().required('Campo obrigatorio'),
        diasLimitePagamento: Yup.string().required('Campo obrigatorio'),
        tipoJuros: Yup.string().required('Campo obrigatorio'),
        tipoMulta: Yup.string().required('Campo obrigatorio'),
      };
    } else if (disabledValueJuros) {
      return {
        especie: Yup.string().required('Campo obrigatorio'),
        origemNossoNumero: Yup.string().required('Campo obrigatorio'),
        diasLimitePagamento: Yup.string().required('Campo obrigatorio'),
        tipoJuros: Yup.string().required('Campo obrigatorio'),
        tipoMulta: Yup.string().required('Campo obrigatorio'),
      };
    } else if (disabledMultaJuros) {
      return {
        especie: Yup.string().required('Campo obrigatorio'),
        origemNossoNumero: Yup.string().required('Campo obrigatorio'),
        diasLimitePagamento: Yup.string().required('Campo obrigatorio'),
        tipoJuros: Yup.string().required('Campo obrigatorio'),
        tipoMulta: Yup.string().required('Campo obrigatorio'),
      };
    } else {
      return {
        especie: Yup.string().required('Campo obrigatorio'),
        origemNossoNumero: Yup.string().required('Campo obrigatorio'),
        diasLimitePagamento: Yup.string().required('Campo obrigatorio'),
        tipoJuros: Yup.string().required('Campo obrigatorio'),
        tipoMulta: Yup.string().required('Campo obrigatorio'),
      };
    }
  }

  async function submitForm(formSubmit) {
    try {
      console.log(validationFields());

      const schmeCadastro = Yup.object().shape(validationFields());
      await schmeCadastro.validate(formSubmit, {
        abortEarly: false,
      });

      const isPercentualJuros =
        formSubmit.tipoJuros === 'PERCENTUAL_DIA' ||
        formSubmit.tipoJuros === 'PERCENTUAL_MES';
      const isPercentualMulta = formSubmit.tipoMulta === 'PERCENTUAL';

      if (isPercentualJuros) {
        if (Number(formSubmit.valorJuros) > 0) {
          if (Number(formSubmit.valorJuros) > 100) {
            formRef.current?.setErrors({
              valorJuros: 'Valor percentual não pode ser maior que 100',
            });
            return;
          }
        }
      }
      if (isPercentualMulta) {
        if (Number(formSubmit.valorMulta) > 0) {
          if (Number(formSubmit.valorMulta) > 100) {
            formRef.current?.setErrors({
              valorMulta: 'Valor percentual não pode ser maior que 100',
            });
            return;
          }
        }
      }

      if (!valorJuros.disabled && valorJuros.value < 1) {
        setValorJuros({
          ...valorJuros,
          error: 'Valor não pode ser zero',
        });
        return;
      }

      if (!valorMulta.disabled && valorMulta.value < 1) {
        setValorMulta({
          ...valorMulta,
          error: 'Valor não pode ser zero',
        });
        return;
      }

      const body = {
        ...formSubmit,
        codigoClienteConta: contaPadrao.id,
        tipoDesconto: '',
        valorDesconto: 0,
        valorJuros: valorJuros.value,
        valorMulta: valorMulta.value,
        tipoCarteira: 'MANUAL',
        enviarBoletoCorreio: true,
        enviarBoletoEmail: true,
        valorDesconto: 0,
      };
      dispatch(showLoading(true));

      const { data } = await api_multipague_configuracao.post(
        '/carteira/associar',
        body,
      );

      toastComponentSuccess('Carteira salva com sucesso');
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationErros(err);
        formRef.current?.setErrors(erros);
      } else {
        toastComponentError(
          ` Codigo: ${err.response.data.codigo} - ${err.response.data.mensagem}`,
        );
      }
    } finally {
      dispatch(hideLoading(false));
    }
  }

  async function loadDefaultValueCarteira() {
    try {
      dispatch(showLoading(true));
      const { data } = await api_multipague_configuracao.get(
        `/carteira?cliente=${userAccount.id}&clienteConta=${contaPadrao.id}`,
      );
      if (data.length) {
        setCarteiraDefault(data);
        setOrigemNossoNumero(data[0].origemNossoNumero);
        setTipoCalculoJuros(data[0].tipoJuros);
        setTipoCalculoMulta(data[0].tipoMulta);
        setEspeciel(data[0].especie);
        setLimiteDias(data[0].diasLimitePagamento);
        setCodigoBeneficiario(data[0].codigoBeneficiario);
        setDisabledValueJuros(true);
        setDisabledMultaJuros(true);
        setDisabledOptions({
          especie: true,
          limite_dias: true,
          tipoCalculoJuros: true,
          valorJuros: true,
          tipoCalculoMulta: true,
          valorMulta: true,
        });
        setValorCalculoJuros(data[0].taxaJuros);
        setValorCalculoMulta(data[0].taxaMulta);
        // setValorCalculoMulta(formattMoney(data[0].taxaMulta));
      }
      dispatch(hideLoading(false));
    } catch (err) {
      dispatch(hideLoading(false));
      // toastComponentError(
      //   ` Codigo: ${err.response.data.codigo} - ${err.response.data.mensagem}`,
      // );
    }
  }

  useEffect(() => {
    if (!!userAccount?.id && !!contaPadrao?.id) {
      loadDefaultValueCarteira();
    }
  }, [userAccount?.id, contaPadrao?.id]);

  return (
    <Wrapper
      title="Carteira"
      crumb={[{ name: 'Dashboard', link: '/dashboard' }, { name: 'Cobrança Simples' }]}
    >
      <Box style={{ borderRadius: '20px', boxShadow: 'none' }}>
        <Container ref={formRef} onSubmit={submitForm}>
          <Alert severity="info"> Carteira Tipo 1 - Boleto Online </Alert>
          <div style={{ height: '1rem' }} />

          <ContainerInfoPrimaria>
            <Especie>
              <InputWithMask
                id="diasLimitePagamento"
                name="diasLimitePagamento"
                label="Código Beneficiário"
                variant="outlined"
                // mask={'999'}
                callBack={val => setLimiteDias(val)}
                value={codigoBeneficiario}
                // disabled={disabledOptions.limite_dias}
                disabled
              />
            </Especie>

            <Especie>
              <SelectComponente
                id="especie"
                name="especie"
                label="Espécie"
                itens={especiesCarteira}
                callback={val => setEspeciel(val)}
                value={especie}
                disabled={disabledOptions.especie}
              />
            </Especie>
            <Banco>
              <SelectComponente
                id="origemNossoNumero"
                name="origemNossoNumero"
                label="Nosso Número"
                itens={origemNumero}
                callback={val => setOrigemNossoNumero(val)}
                value={origemNossoNumero}
                disabled={disabledOptions.especie}
              />
            </Banco>
            <Tooltip
              title="Quantidade de dias que o boleto poderá ser pago após o vencimento"
              aria-label="descricao"
            >
              <DiasLimitePagamento>
                <InputWithMask
                  id="diasLimitePagamento"
                  name="diasLimitePagamento"
                  label="Limite de dias"
                  variant="outlined"
                  mask={'999'}
                  callBack={val => setLimiteDias(val)}
                  value={limiteDias}
                  disabled={disabledOptions.limite_dias}
                />
              </DiasLimitePagamento>
            </Tooltip>
          </ContainerInfoPrimaria>
          <div style={{ height: '1rem' }} />
          <ContainerOpcoes>
            <Options>
              <TituloContainer>
                <Label>Tipo de Juros</Label>
              </TituloContainer>
              <ContainerInputsOptions>
                <SelectComponente
                  itens={tipoJuros}
                  label="Tipo de Cálculo"
                  id="tipoJuros"
                  name="tipoJuros"
                  callback={callBackJuros}
                  value={tipoCalculoJuros}
                  disabled={disabledOptions.tipoCalculoJuros}
                />
                {!!valorCalculoJuros ? (
                  <InputText
                    id="valorJuros"
                    name="valorJuros"
                    label="Valor Juros"
                    variant="outlined"
                    disabled={disabledValueJuros}
                    value={valorCalculoJuros}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">%</InputAdornment>
                      ),
                    }}
                  />
                ) : (
                  <InputMoney
                    value={valorJuros.value}
                    onChange={val =>
                      setValorJuros({
                        ...valorJuros,
                        value: val,
                        error: '',
                      })
                    }
                    error={valorJuros.error}
                    disabled={disabledOptions.valorJuros}
                    label="Valor"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">R$</InputAdornment>
                      ),
                    }}
                  />
                )}
              </ContainerInputsOptions>
            </Options>
            <Options>
              <TituloContainer>
                <Label>Cálculo Multa</Label>
              </TituloContainer>
              <ContainerInputsOptions>
                <SelectComponente
                  itens={tipoMulta}
                  label="Tipo de Cálculo"
                  id="tipoMulta"
                  name="tipoMulta"
                  callback={callBackMulta}
                  value={tipoCalculoMulta}
                  disabled={disabledOptions.tipoCalculoMulta}
                />

                {valorCalculoMulta ? (
                  <InputText
                    id="valorMulta"
                    name="valorMulta"
                    label="Valor %"
                    variant="outlined"
                    value={valorCalculoMulta}
                    disabled={true}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">%</InputAdornment>
                      ),
                    }}
                  />
                ) : (
                  <InputMoney
                    value={valorMulta.value}
                    onChange={val =>
                      setValorMulta({
                        ...valorMulta,
                        value: val,
                        error: '',
                      })
                    }
                    error={valorMulta.error}
                    disabled={disabledOptions.valorMulta}
                    label="Valor"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">R$</InputAdornment>
                      ),
                    }}
                  />
                )}
              </ContainerInputsOptions>
            </Options>
          </ContainerOpcoes>
          {!carteiraDefault.length && (
            <ContainerButton>
              <Button
                color="default"
                variant="contained"
                title={"Voltar"}
                startIcon={<MdArrowBack color={"background"}/>}
              >
                Voltar
              </Button>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                title={'Salvar'}
                startIcon={
                  <MdSave color={useTheme().palette.background.paper} />
                }
              >
                Salvar
              </Button>
            </ContainerButton>
          )}
        </Container>
      </Box>
    </Wrapper>
  );
};

export default Carteira;
