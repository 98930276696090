import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Button from '../../components/Buttons/ConfirmButton';
import { MdPrint } from 'react-icons/md';

const useStyles = makeStyles(theme => ({
  root: {
    height: 280,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  buttonOff: {
    display: 'none',
  },
  comprovante: {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  grey: {
    color: 'grey',
  },
  icons: {
    marginTop: '3px',
  },
}));

export default function RecipeReviewCard() {
  const classes = useStyles();
  const theme = useTheme();
  function printDiv() {
    var css = '@page { size: portrait; }',
      head = document.head || document.getElementsByTagName('head')[0],
      style = document.createElement('style');

    style.type = 'text/css';
    style.media = 'print';

    if (style.styleSheet) {
      style.styleSheet.cssText = css;
    } else {
      style.appendChild(document.createTextNode(css));
    }

    head.appendChild(style);
    document.getElementById('printButton').classList.add('buttonOff');
    document.getElementById('printButton').style.display = 'none';
    window.print();
    document.getElementById('printButton').style.display = 'inline';
  }

  const evento = localStorage.getItem('print-evento');
  const data = localStorage.getItem('print-data');
  const valor = localStorage.getItem('print-valor');
  const correntistaOrigem = localStorage.getItem('correntista-origem');
  const correntistaDestino = localStorage.getItem('correntista-destino');
  const origemBanco = localStorage.getItem('print-origem-banco');
  const origemAgencia = localStorage.getItem('print-origem-agencia');
  const origemAgenciaDigito = localStorage.getItem(
    'print-origem-agencia-digito',
  );
  const origemConta = localStorage.getItem('print-origem-conta');
  const origemContaDigito = localStorage.getItem('print-origem-conta-digito');
  const destinoBanco = localStorage.getItem('print-destino-banco');
  const destinoAgencia = localStorage.getItem('print-destino-agencia');
  const destinoConta = localStorage.getItem('print-destino-conta');
  const destinoContaDigito = localStorage.getItem('print-destino-conta-digito');

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="sm">
        <div id="printableArea">
          <img alt="" height="64" src={theme.config.loginLogo} />
          <Typography variant="h5" component="h5">
            Comprovante de Transferência
          </Typography>
          <Typography component="body2" className={classes.grey}>
            {data}
          </Typography>
          <div
            className={classes.comprovante}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div>
              <Typography component="body1">Valor</Typography>
            </div>
            <div>
              <Typography component="body1" className={classes.grey}>
                {valor}
              </Typography>
            </div>
          </div>
          <div
            className={classes.comprovante}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div>
              <Typography component="body1">Evento</Typography>
            </div>
            <div>
              <Typography component="body1" className={classes.grey}>
                {evento}
              </Typography>
            </div>
          </div>
          <Divider />
          <div className={classes.comprovante}>
            <Typography component="subtitle1">
              <LocalAtmIcon className={classes.icons} />
              <ArrowDropDownIcon className={classes.icons} />
              Destino
            </Typography>
          </div>
          <Divider />
          <div
            className={classes.comprovante}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div>
              <Typography component="body1">Nome</Typography>
            </div>
            <div>
              <Typography component="body1" className={classes.grey}>
                {correntistaDestino}
              </Typography>
            </div>
          </div>
          <div
            className={classes.comprovante}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div>
              <Typography component="body1">Banco</Typography>
            </div>
            <div>
              <Typography component="body1" className={classes.grey}>
                {destinoBanco}
              </Typography>
            </div>
          </div>
          <div
            className={classes.comprovante}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div>
              <Typography component="body1">Agência</Typography>
            </div>
            <div>
              <Typography component="body1" className={classes.grey}>
                {destinoAgencia}
              </Typography>
            </div>
          </div>
          <div
            className={classes.comprovante}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div>
              <Typography component="body1">Conta</Typography>
            </div>
            <div>
              <Typography component="body1" className={classes.grey}>
                {destinoConta}
              </Typography>
            </div>
          </div>

          <Divider />
          <div className={classes.comprovante}>
            <Typography component="subtitle1">
              <LocalAtmIcon className={classes.icons} />
              <ArrowDropUpIcon className={classes.icons} />
              Origem
            </Typography>
          </div>
          <Divider />
          <div
            className={classes.comprovante}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div>
              <Typography component="body1">Nome</Typography>
            </div>
            <div>
              <Typography component="body1" className={classes.grey}>
                {correntistaOrigem}
              </Typography>
            </div>
          </div>
          <div
            className={classes.comprovante}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div>
              <Typography component="body1">Banco</Typography>
            </div>
            <div>
              <Typography component="body1" className={classes.grey}>
                {origemBanco}
              </Typography>
            </div>
          </div>
          <div
            className={classes.comprovante}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div>
              <Typography component="body1">Agência</Typography>
            </div>
            <div>
              <Typography component="body1" className={classes.grey}>
                {origemAgencia}
              </Typography>
            </div>
          </div>
          <div
            className={classes.comprovante}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div>
              <Typography component="body1">Conta</Typography>
            </div>
            <div>
              <Typography component="body1" className={classes.grey}>
                {origemConta}
              </Typography>
            </div>
          </div>
        </div>
      </Container>
      <div
        className={classes.comprovante}
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <Button
          title={'Imprimir'}
          startIcon={<MdPrint color="background" />}
          variant="contained"
          color="primary"
          id="printButton"
          onClick={printDiv}
        >
          Imprimir
        </Button>
      </div>
    </React.Fragment>
  );
}
