export const tipoContas = [
  {
    label: 'Conta Corrente',
    value: 1,
  },
  {
    label: 'Poupança',
    value: 2,
  },
  {
    label: 'Conta pagamento',
    value: 3,
  },
];

export const optionsTypeObject = [
  {
    label: 'Pessoa Física',
    value: 'PF',
    selected: true,
  },
  {
    label: 'Pessoa Jurídica',
    value: 'PJ',
    selected: false,
  },
];
