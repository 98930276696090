import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(theme => ({
  paper: {
    ...theme.palette.background.paper,
    padding: '1.3rem 1rem 1rem',
    marginBottom: '1rem',
  },
}));

const Box = ({ elevetion, className, children, style }) => {
  const classes = useStyles();
  return (
    <Paper
      elevation={elevetion || 2}
      className={`${classes.paper} ${className}`}
      style={{ ...style, borderRadius: '20px', boxShadow: 'none' }}
    >
      {children}
    </Paper>
  );
};

export default Box;
