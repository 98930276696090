import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useTheme, alpha } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import { AiOutlineExclamationCircle } from 'react-icons/ai';

export const MessageErrorComponent = ({ maxWidth }) => {
  const theme = useTheme();
  const matchesPoint = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Typography
      style={{
        color: theme.palette.text.primary,
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        transform: 'translate(-2%, -10%)',
      }}
    >
      <AiOutlineExclamationCircle
        size={25}
        style={{ paddingRight: '5px' }}
        color={'red'}
      />
      <Typography
        style={{
          maxWidth: '80%',
          overflowWrap: 'break-word',
          display: 'flex',
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          fontSize: matchesPoint ? '12px' : '15px',
          flexDirection: matchesPoint ? 'column' : 'row',
        }}
      >
        <span style={{ fontWeight: 'bold' }} class="font-padrao">
          Sem dados
        </span>
      </Typography>
    </Typography>
  );
};
