import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { DataGridPage } from '../../components/DataGrid/DataGridPaginationServer';
import { Container } from './style';
import ErrorIcon from '@material-ui/icons/Error';

import Button from '@material-ui/core/Button';
import { useImpressaoBoletos } from '../../Context/ImpressaoBoletosContext';
import Data from '../../components/InputDate';
import { IconButton, Popover, Tooltip } from '@material-ui/core';
import { MdDownload } from 'react-icons/md';
import RenderActionsCell from '../../components/DataGrid/DataGridPaginationServer/Actions';

const Content = () => {
  const [rowId, setRowId] = useState(null);
  const [openMenuActions, setOpenMenuActions] = useState(false);

  const history = useHistory();
  const {
    rowsTable,
    loading,
    rowCount,
    rowsState,
    onPage,
    handlePageSize,
    textNoRows,
    dataInicial,
    setDataInicial,
    dataFinal,
    setDataFinal,
    setRowsState,
    clearFilter,
    submitFilter,
  } = useImpressaoBoletos();

  const columnsTable = [
    { field: 'codigo', headerName: 'Código', width: 100 },
    { field: 'nomeArquivo', headerName: 'Arquivo', flex: 1 },
    { field: 'dataReferencia', headerName: 'Data', width: 250 },
    {
      field: 'acoes',
      headerName: 'Ações',
      align: 'center',
      width: 100,
      renderCell: params => {
        return (
          <RenderActionsCell
            event={params}
            actionsComponent={
              <Tooltip title="Download">
                <IconButton
                  variant="outlined"
                  color="primary"
                  size="small"
                  href={params.row.url}
                >
                  <MdDownload variant="contained" />
                </IconButton>
              </Tooltip>
            }
            openMenuActions={openMenuActions}
            setOpenMenuActions={setOpenMenuActions}
            rowId={rowId}
          />
        );
      },
    },
    {
      field: 'erros',
      headerName: 'Boletos com Erro',
      align: 'center',
      width: 200,
      renderCell: ({ row }) =>
        row.existeErros ? (
          <Button
            onClick={() =>
              history.push('/boleto/impressao-lote-erros', {
                id: row.id ? row?.id : 0,
              })
            }
            size="small"
          >
            <ErrorIcon color="error" />
          </Button>
        ) : (
          <></>
        ),
    },
  ];

  const filterInputs = {
    inputsData: [
      {
        html: () => (
          <Data
            callback={setDataInicial}
            label="Data Inicial"
            id="data-inicial"
            value={dataInicial}
            dataMinima={'1999-01-01'}
            variant="outlined"
            size="small"
            InputProps={{
              style: { borderRadius: '20px' },
            }}
          />
        ),
        grid: { xs: 12, sm: 5, md: 5 },
      },
      {
        html: () => (
          <Data
            callback={setDataFinal}
            size="small"
            label="Data Final"
            id="data-final"
            value={dataFinal}
            dataMinima={'1999-01-01'}
            variant="outlined"
            InputProps={{
              style: { borderRadius: '20px' },
            }}
          />
        ),
        grid: { xs: 12, sm: 5, md: 5 },
      },
    ],
    searchButton: {
      searchButton: () => submitFilter(),
    },
    cleanButton: {
      cleanButton: () => clearFilter(),
    },
  };

  return (
    <>
      <Container>
        <DataGridPage
          title="Impressão de Boleto em Lote"
          crumb={[
            { name: 'Dashboard', link: '/dashboard' },
            { name: 'Cobrança Simples' },
          ]}
          minHeight="600px"
          rows={rowsTable}
          formData={filterInputs}
          columns={columnsTable}
          loading={loading}
          rowCount={rowCount}
          onRowClick={params => setRowId(params.id)}
          {...rowsState}
          setDatagrid={setRowsState}
          onPageChangeFunction={onPage}
          textNoRows={textNoRows}
          onPageSizeChangeFunction={newPageSize => handlePageSize(newPageSize)}
        />
      </Container>
    </>
  );
};

export default Content;
