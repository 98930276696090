export function editarFavoritosRequest(beneficiario) {
  return {
    type: 'EDITAR_FAVORITOS_REQUEST',
    payload: beneficiario,
  };
}
export function editarFavoritosSuccess(beneficiario) {
  return {
    type: 'EDITAR_FAVORITOS_SUCCESS',
    payload: beneficiario,
  };
}
export function editarFavoritosError(beneficiario) {
  return {
    type: 'EDITAR_FAVORITOS_ERROR',
    payload: beneficiario,
  };
}
