import React from 'react';
import { Typography } from '@material-ui/core';
import { MdArrowBack, MdDone } from 'react-icons/md';
import ConfirmButton from '../../../components/Buttons/ConfirmButton';
import DialogModal from '../../../components/DialogModal';
import CardInfoPix from '../CardInfoPix';

const ModalConfirmPix = ({
  openModal,
  setOpenModal,
  confirmPix,
  isLoading,
  getKey,
  transactionValue,
}) => {
  return (
    <DialogModal
      open={openModal}
      dialogTitle={'Confirmar PIX'}
      onClose={() => setOpenModal(false)}
      dialogActions={
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-evenly',
            padding: '10px',
          }}
        >
          <ConfirmButton
            title={'Cancelar'}
            variant="contained"
            color="primary"
            startIcon={<MdArrowBack color="background" />}
            onClick={() => setOpenModal(false)}
          />
          <ConfirmButton
            variant="contained"
            color="primary"
            title={'Transferir'}
            isLoading={isLoading?.create}
            disabled={isLoading?.create}
            onClick={confirmPix}
            startIcon={<MdDone color="background" />}
          />
        </div>
      }
      dialogContent={
        <>
          <Typography variant="body1" style={{ paddingBottom: 10 }}>
            Confirma PIX para a conta abaixo?
          </Typography>
          <CardInfoPix keys={getKey} transactionValue={transactionValue} />
        </>
      }
    />
  );
};

export default ModalConfirmPix;
