import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  summary: {
    borderRadius: '20px',
  },
  details: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTop: theme.palette.divider,
    borderBottom: theme.palette.divider,
    padding: theme.spacing(2),
  },
  input: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '20px',
  },
  formControl: { paddingBottom: 20 },
  dialogModalDiv: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-evenly',
    padding: '0 20px 20px',
  },
  dialogContentTypography: {
    paddingBottom: 10,
    paddingTop: 10,
    width: '250px',
  },
}));
