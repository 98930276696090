export function pesquisarBoletoRequest(dados) {
  return {
    type: 'LISTAR_BOLETO_REQUEST',
    payload: dados,
  };
}
export function pesquisarBoletoSuccess(boleto) {
  return {
    type: 'LISTAR_BOLETO_SUCCESS',
    payload: boleto,
  };
}

export function pesquisarBoletoError() {
  return {
    type: 'LISTAR_BOLETO_ERROR',
  };
}
