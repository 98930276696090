import React, { useRef, useEffect, useState } from 'react';
import ReactInputMask, { Props as InputProps } from 'react-input-mask';
import { useField } from '@unform/core';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function InputMask({ name, isLoading, callback, ...rest }) {
  const inputRef = useRef(null);
  const {
    fieldName,
    registerField,
    defaultValue,
    error,
    clearError,
  } = useField(name);
  const [size, setSize] = useState(0);

  function retornarLengthText(value) {
    if (callback && value.length === 47) {
      callback(value);
    }
    setSize(value.length);
  }
  function retornarLengthTextConsumo(value) {
    if (callback && value.length === 48) {
      callback(value);
    }
    setSize(value.length);
  }

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref, value) {
        ref.setInputValue(value);
      },
      clearValue(ref) {
        ref.setInputValue('');
      },
    });
  }, [fieldName, registerField]);
  return (
    <ReactInputMask
      // mask={'99999.99999 99999.999999 99999.999999 9 99999999999999'}
      mask={'999999999999999999999999999999999999999999999999'}
      maskChar=""
      ref={inputRef}
      defaultValue={defaultValue}
      onChange={event => event.target.value.length == 47 && event.target.value.charAt(0) != 8 ? retornarLengthText(event.target.value) : event.target.value.length == 48 && retornarLengthTextConsumo(event.target.value)}
      onFocus={clearError}
      disabled={isLoading}
      {...rest}
    >
      {() => (
        <TextField
          style={{ width: '100%' }}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          size="small"
          helperText={error}
          error={!!error}
          InputProps={
            isLoading && {
              endAdornment: (
                <CircularProgress
                  color="inherit"
                  size={24}
                  circleDisableShrink
                />
              ),
            }
          }
          disabled={isLoading}
          {...rest}
        />
      )}
    </ReactInputMask>
  );
}
