import React, { useEffect } from 'react';
import { Form } from '@unform/web';
import { Grid, useTheme } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { MdSearch, MdClear, MdEdit } from 'react-icons/md';
import Box from '../../components/Box';
import Wrapper from '../../components/Wrapper';
import DataGrid from '../../components/DataGrid';
import { maskCpfCnpj } from '../../util/mask';
import Button from '../../components/Buttons/ConfirmButton';
import RadioButton from '../../components/RadioButton';
import CPF from '../../components/Inputs/CPF';
import CNPJ from '../../components/Inputs/CNPJ';
import InputText from '../../components/InputText';
import { maskCep } from '../../util/mask';
import { ModalManager } from './ModalManager';
import * as PagadorService from '../../services/PagadorService';
import { hideLoading, showLoading } from '../../store/modules/loading/actions';
import { isNumber } from 'util';
import { DataGridPage } from '../../components/DataGrid/DataGridPaginationServer';
import { ButtonComponent } from '../../components/Buttons/ConfirmButton/styles';
import { AiFillEdit } from 'react-icons/ai';

const Pagador = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const formRef = React.useRef(null);
  const [rowsTable, setRowsTable] = React.useState([]);
  const [modalForm, setModalForm] = React.useState(false);
  const [pagadorSelected, setPagadorSelected] = React.useState(null);
  const [textNoRows, setTextNoRows] = React.useState('');
  const [paginate, setPaginate] = React.useState({
    size: 10,
    page: 0,
  });
  const { userAccount } = useSelector(state => state.user);

  const optionsTypePerson = [
    {
      label: 'Pessoa Física',
      value: 'PF',
      selected: true,
    },
    {
      label: 'Pessoa Jurídica',
      value: 'PJ',
      selected: false,
    },
  ];
  const [typePersonSelected, setTypePersonSelected] = React.useState(
    optionsTypePerson[0].selected
      ? optionsTypePerson[0].value
      : optionsTypePerson[1].value,
  );

  const columnsTable = [
    { field: 'documento', headerName: 'CNPJ/CPF', width: 140 },
    { field: 'nome', headerName: 'Nome', flex: 1 },
    { field: 'endereco', headerName: 'Endereço', flex: 1 },
    {
      field: 'edicao',
      headerName: 'Editar',
      sortable: false,
      width: 90,
      disableClickEventBubbling: true,
      renderCell: event => {
        return (
          <ButtonComponent
            variant="outlined"
            onClick={() => editarPagador(event.row)}
            style={{ borderRadius: '20px' }}
            color="primary"
          >
            <AiFillEdit
              color={theme.palette.primary.main}
              size={20}
            />
          </ButtonComponent>
        );
      },
    },
  ];

  const getPagadores = React.useCallback(
    async params => {
      dispatch(showLoading(true));

      const { data } = await PagadorService.findAll({
        ...params,
        idCliente: userAccount.id,
        size: paginate.size,
      }).catch(() => {
        dispatch(showLoading(false));
      });
      if (data.content.length === 0) {
        setTextNoRows('Nenhum pagador encontrado');
      }

      if (data) {
        const { content, ...rest } = data;
        setPaginate(old => ({
          ...old,
          ...rest,
          page: params.page,
        }));
        if (content) {
          const serialize = data.content.map(e => ({
            ...e,
            nome: e.nome,
            documento: maskCpfCnpj(e.documentoFederal),
            endereco: `${e.logradouro}, ${e.numero ? `${e.numero} - ` : ''}  ${
              e.bairro
            }, ${e.cidade} - ${e.uf}, ${maskCep(e.cep)}`,
          }));
          setRowsTable(serialize);
        }
      } else {
        setRowsTable([]);
      }
      dispatch(showLoading(false));
    },
    [userAccount, paginate],
  );

  const filtrarFavorecidos = (data, page = 0) => {
    if (data?.documentoFederal) {
      data.documentoFederal = data?.documentoFederal?.replace(/\D/g, '');
    }
    getPagadores({
      ...data,
      tipoPessoa: typePersonSelected,
      page: isNumber(page) ? page : 0,
    });
  };

  useEffect(() => {
    filtrarFavorecidos(formRef?.current?.getData(), 0);
  }, [paginate.size]);

  function clearFilter() {
    setTypePersonSelected('PF');
    formRef.current.reset();
    filtrarFavorecidos({});
  }

  const cadastrarPagador = () => {
    setModalForm(true);
    setPagadorSelected(null);
  };
  const editarPagador = pagador => {
    setModalForm(true);
    setPagadorSelected(pagador);
  };

  const handlePageSize = newPageSize => {
    setPaginate({ page: 0, size: newPageSize });
  };

  React.useEffect(() => {
    filtrarFavorecidos({});
  }, [userAccount]);

  const filterInputs = {
    inputsData: [
      {
        html: () => (
          <RadioButton
            titulo=""
            options={optionsTypePerson}
            optionDefault={typePersonSelected}
            callback={setTypePersonSelected}
          />
        ),
        grid: { xs: 12, sm: 12, md: 12 },
      },
      {
        html: () => (
          <InputText
            variant="outlined"
            label={
              typePersonSelected === 'PF' ? 'Nome Completo' : 'Razão Social'
            }
            name="nome"
            id="nome_completo"
          />
        ),
        grid: { xs: 12, sm: 4, md: 4 },
      },
      {
        html: () =>
          typePersonSelected === 'PF' ? (
            <CPF
              name="documentoFederal"
              id="documentoFederal_id"
              variant="outlined"
              label="CPF"
            />
          ) : (
            <CNPJ
              name="documentoFederal"
              id="documentoFederal_id"
              variant="outlined"
              label="CNPJ"
            />
          ),
        grid: { xs: 12, sm: 4, md: 4 },
      },
    ],
    searchButton: {
      searchButton: () =>
        filtrarFavorecidos(formRef?.current?.getData(), paginate.page),
    },
    cleanButton: {
      cleanButton: () => clearFilter(),
    },
  };

  return (
    <Form ref={formRef} onSubmit={filtrarFavorecidos}>
      {modalForm && (
        <ModalManager
          close={() => {
            setModalForm(false);
            setPagadorSelected(null);
          }}
          pagador={pagadorSelected}
          idCliente={userAccount.id}
          refetch={() => {
            filtrarFavorecidos(formRef.current.getData(), paginate.page);
          }}
        />
      )}
      <DataGridPage
        title="Pagador"
        crumb={[{ name: 'Dashboard', link: '/dashboard' }, { name: 'Cobrança Simples' }]}
        newRegister={cadastrarPagador}
        rows={rowsTable}
        formData={filterInputs}
        columns={columnsTable}
        pageSize={paginate.size}
        page={paginate.page}
        minHeight="600px"
        rowCount={paginate?.totalElements}
        onPageChangeFunction={page => {
          filtrarFavorecidos(formRef?.current?.getData(), page);
        }}
        textNoRows={textNoRows}
        onPageSizeChangeFunction={newPageSize => handlePageSize(newPageSize)}
      />
    </Form>
  );
};

export default Pagador;
