import React from "react"
import RetornoRemessaProvider from "../../Context/RetornoRemessaContext"
import Content from "./Content"

const RetornoRemessa = () => {
    return(<RetornoRemessaProvider>
        <Content />
    </RetornoRemessaProvider>)
}

export default RetornoRemessa