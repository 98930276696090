import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { useTheme } from '@material-ui/core/styles';
import { Form } from '@unform/web';
import TextField from '@material-ui/core/TextField';
import { toastComponentError } from '../../../components/Toast';
import { api_multipague_conta } from '../../../services/api';
import { DataGridPage } from '../../../components/DataGrid/DataGridPaginationServer';

import { columnsTable } from './dataTable';
import { MdDownload } from 'react-icons/md';
import {
  Tooltip,
  alpha,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Autocomplete from '@material-ui/lab/Autocomplete';

const ArquivosGerados = () => {
  const theme = useTheme();
  const formRef = useRef(null);
  const history = useHistory();
  const formInfo = history?.location?.state;

  const { contaPadrao } = useSelector(state => state.contas);
  const [dataGridState, setDataGridStateArquivosGerados] = React.useState({
    rows: [],
    page: 0,
    pageSize: 10,
    loading: false,
    rowCount: 0,
  });
  const [dataInicial, setDataInicial] = useState('');
  const [dataFinal, setDataFinal] = useState('');
  const [situacao, setSituacao] = useState('');
  const [resetForm, setResetForm] = useState(false);
  const [selectedSituacao, setSelectedSituacao] = React.useState(null);

  const selecionarSituacao = [
    {
      id: 'GERANDO',
      descricao: 'Gerando',
    },
    {
      id: 'SOLICITADO',
      descricao: 'Solicitado',
    },
    {
      id: 'ERRO',
      descricao: 'Erro',
    },
    {
      id: 'FINALIZADO',
      descricao: 'Finalizado',
    },
  ];

  function formataDados(dados) {
    try {
      const formattedData = dados.content.map(dado => ({
        id: dado.id,
        key: uuidv4(),
        situacao: dado.situacao,
        dataCadastro: moment(dado.dataCadastro).format('DD/MM/YYYY HH:mm:ss'),
        caminhoStorage: dado.caminhoArquivo,
      }));
      return formattedData;
    } catch {
      return [];
    }
  }

  useEffect(() => {
    if (Object.keys(contaPadrao).length !== 0) {
      listArquivosGerados(0, 10);
    }
  }, [contaPadrao]);

  useEffect(() => {
    if(resetForm){
      listArquivosGerados(0, 10);
      setResetForm(false);
    }
  }, [resetForm]);

  const listArquivosGerados = (page, pageSize) => {
    setDataGridStateArquivosGerados(prev => ({
      ...prev,
      page: page,
      pageSize: pageSize,
      loading: true,
    }));

    api_multipague_conta
      .get(
        `/extrato/exportacao?page=${page}&size=${pageSize}&idClienteConta=${
          contaPadrao.id
        }&situacao=${situacao}${
          !!dataInicial
            ? '&dataInicial=' + moment(dataInicial).format('YYYY-MM-DD')
            : ''
        }${
          !!dataFinal
            ? '&dataFinal=' + moment(dataFinal).format('YYYY-MM-DD')
            : ''
        }`,
      )
      .then(response => {
        var dadosFormatados = formataDados(response.data);
        setDataGridStateArquivosGerados(prev => ({
          ...prev,
          rows: dadosFormatados,
          rowCount: response?.data?.totalElements,
          loading: false,
        }));
      })
      .catch(err => {
        toastComponentError(
          ` Codigo: ${err.response.data.codigo} - ${err.response.data.mensagem}`,
        );
        setDataGridStateArquivosGerados(prev => ({
          ...prev,
          rows: [],
          rowCount: 0,
          loading: false,
        }));
      });
  };

  function clearFilter() {
    setDataInicial('');
    setDataFinal('');
    setSituacao('');
    setSelectedSituacao(null);
    formRef.current.reset();
    setResetForm(true);
  }

  const filterInputs = {
    inputsData: [
      {
        html: () => (
          <TextField
            variant="outlined"
            size="small"
            required
            id="dataInicial"
            placeholder="DD/MM/AAAA"
            label="Data Início"
            value={dataInicial}
            type="date"
            fullWidth
            name="dataInicial"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={e => {
              setDataInicial(e.target.value);
            }}
          />
        ),
        grid: { xs: 12, sm: 12, md: 3, lg: 3 },
      },
      {
        html: () => (
          <TextField
            variant="outlined"
            size="small"
            required
            id="dataFinal"
            placeholder="DD/MM/AAAA"
            label="Data Fim"
            value={dataFinal}
            type="date"
            fullWidth
            name="dataFinal"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={e => {
              setDataFinal(e.target.value);
            }}
          />
        ),
        grid: { xs: 12, sm: 12, md: 3, lg: 3 },
      },
      {
        html: () => (
          <Autocomplete
            id="combo-box-demo"
            size="small"
            options={selecionarSituacao}
            value={selectedSituacao}
            getOptionLabel={option => option.descricao}
            onChange={(event, option) => {
              setSituacao(option ? option.id : '')
              setSelectedSituacao(option);
            }}
            style={{ width: '100%' }}
            renderInput={params => (
              <TextField
                {...params}
                label="Situação"
                placeholder={'Selecione a situação'}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
              />
            )}
            InputProps={{
              style: { borderRadius: '20px' },
            }}
          />
        ),
        grid: { xs: 12, sm: 12, md: 5, lg: 5 },
      },
    ],
    searchButton: {
      searchButton: () => listArquivosGerados(0, dataGridState.pageSize),
    },
    cleanButton: {
      cleanButton: () => clearFilter(),
    },
  };

  return (
    <Form ref={formRef}>
      <DataGridPage
        title="Extratos Gerados"
        arrowBack
        goBack={() => {
          history.push('/extrato', { formInfo: formInfo });
        }}
        crumb={[
          { name: 'Dashboard', link: '/dashboard' },
          { name: 'Minha Conta' },
          { name: 'Extrato', link: '/extrato' },
        ]}
        formData={filterInputs}
        rows={dataGridState.rows}
        columns={[
          ...columnsTable,
          {
            field: 'detalhes',
            headerName: 'Ação',
            sortable: false,
            width: 90,
            disableClickEventBubbling: true,
            renderCell: event => {
              return event.row.caminhoStorage ? (
                <a
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                  href={event.row.caminhoStorage}
                  download={event.row.caminhoStorage}
                >
                  <MdDownload size={20} color={theme.palette.primary.main} />
                </a>
              ) : (
                <Tooltip title="Download">
                  <a
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <MdDownload
                      size={22}
                      color={alpha(theme.palette.text.disabled, 0.2)}
                    />
                  </a>
                </Tooltip>
              );
            },
          },
        ]}
        key={dataGridState.rows.key}
        rowCount={dataGridState.rowCount}
        {...dataGridState}
        setDatagrid={setDataGridStateArquivosGerados}
        minHeight={'600px'}
        onPageSizeChangeFunction={newPageSize => {
          listArquivosGerados(0, newPageSize);
        }}
        onPageChangeFunction={page =>
          listArquivosGerados(page, dataGridState.pageSize)
        }
        pagination="server"
        page={dataGridState.page}
        componentsProps={{
          pagination: { classes: null },
        }}
        paginationMode="server"
        rowsPerPageOptions={[10, 20, 50, 100]}
      />
    </Form>
  );
};

export default ArquivosGerados;
