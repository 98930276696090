import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React from 'react';
import { MdCheck, MdError, MdWarning } from 'react-icons/md';

export const ToastComponent = textSucces => {
  return toast.success(textSucces, {
    position: 'top-right',
    autoClose: 5000,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};

export const toastComponentError = error => {
  const CustomToast = () => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
    >
      <MdError size={23} style={{ marginRight: 5, flexShrink: 0 }} />
      <span>{error}</span>
    </div>
  );

  return toast.error(<CustomToast />, {
    className: 'toast-custom',
    position: 'top-right',
    autoClose: 5000,
    closeOnClick: true,
    progress: undefined,
    hideProgressBar: false,
    draggable: true,
    theme: 'light',
    pauseOnHover: true,
    toastComponent: CustomToast,
  });
};

export const toastComponentWarn = error => {
  const CustomToast = () => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
    >
      <MdWarning size={23} style={{ marginRight: 5, flexShrink: 0 }} />
      <span>{error}</span>
    </div>
  );

  return toast.warn(<CustomToast />, {
    className: 'toast-custom',
    position: 'top-right',
    autoClose: 5000,
    closeOnClick: true,
    progress: undefined,
    hideProgressBar: false,
    draggable: true,
    theme: 'light',
    pauseOnHover: true,
    toastComponent: CustomToast,
  });
};

export const toastComponentSuccess = error => {
  const CustomToast = () => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
    >
      <MdCheck size={25} style={{ marginRight: 5, flexShrink: 0 }} />
      <span>{error}</span>
    </div>
  );

  return toast.success(<CustomToast />, {
    className: 'toast-custom',
    position: 'top-right',
    autoClose: 3000,
    closeOnClick: true,
    progress: undefined,
    hideProgressBar: false,
    draggable: true,
    theme: 'light',
    pauseOnHover: true,
    toastComponent: CustomToast,
  });
};
