import styled, { css } from 'styled-components';
import TextField from '@material-ui/core/TextField';
import { styled as MuyStyled, alpha } from '@material-ui/core';

export const Container = styled.div`
  width: 100%;

  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;
export const Subcontainer = styled.div`
  width: 100%;
  display: grid;
  row-gap: 20px;
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;
export const ContainerHeader = styled.div`
  width: 100%;
  display: grid;
  row-gap: 20px;
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;
export const OptionSelected = styled.div`
  width: 100%;

  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;
export const ContainerAbasSelection = styled.div`
  width: 100%;
  display: grid;
  row-gap: 2rem;
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;
export const ContainerAbas = styled.div`
  width: 100%;
  display: grid;
  row-gap: 40px;
  @media only screen and (min-width: 1024px) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, auto));
    column-gap: 1rem;
    row-gap: 10px;
  }
  @media only screen and (min-width: 1440px) {
  }
`;

export const Abas = MuyStyled('div')(({ theme, active }) => {
  return {
    width: '100%',
    cursor: 'pointer',
    padding: '10px',
    minHeight: '50px',
    display: ' flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    boxShadow: `0px 2px 0px ${alpha(theme.palette.primary.dark, 0.2)}`,
    borderRadius: '20px',

    ...(active
      ? {
          border: `solid .1px ${theme.palette.primary.main}`,
          borderBottom: `solid 4px ${theme.palette.primary.main}`,
          boxShadow: `0px 2px 5px ${alpha(theme.palette.primary.dark, 0, 2)}`,
          cursor: 'default',
        }
      : {
          '&:hover': {
            cursor: 'not-allowed',
          },
        }),
    '@media (min-width:  1024px)': {
      flexDirection: 'column',
      height: '40px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  };
});

export const InputText = styled(TextField)`
  width: 100%;
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;

export const ContainerErrorDescription = MuyStyled('div')(({ theme }) => {
  return {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '& p': {
      color: theme.palette.system.dark,
    },
  };
});
export const Row = styled.div`
  display: flex;
  gap: 1rem;
`;
