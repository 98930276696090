import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '../../components/Buttons/ConfirmButton';
import { MdPrint } from 'react-icons/md';
import formattedMoney from '../../util/FormatteMoney/index';

const useStyles = makeStyles(theme => ({
  root: {
    height: 280,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  buttonOff: {
    display: 'none',
  },
  comprovante: {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  tableHeader: {
    fontWeight: '700',
    fontSize: '15px',
  },
  tableCell: {
    whiteSpace: 'normal',
    wordBreak: 'break-word',
  },
}));

export default function ExtractPdf() {
  const classes = useStyles();
  const theme = useTheme();

  function printDiv() {
    var css = '@page { size: landscape; margin: 10px}',
      head = document.head || document.getElementsByTagName('head')[0],
      style = document.createElement('style');

    style.type = 'text/css';
    style.media = 'print';

    if (style.styleSheet) {
      style.styleSheet.cssText = css;
    } else {
      style.appendChild(document.createTextNode(css));
    }

    const printDateTimeElement = document.getElementById('printDateTime');
    const now = new Date();
    printDateTimeElement.textContent = `${now.toLocaleString()}`;

    head.appendChild(style);

    document.getElementById('printButton').classList.add('buttonOff');
    document.getElementById('printDateTime').style.display = 'inline';
    document.getElementById('printButton').style.display = 'none';
    window.print();
    document.getElementById('printDateTime').style.display = 'none';
    document.getElementById('printButton').style.display = 'inline';
  }

  const rows = JSON.parse(localStorage.getItem('pdfExtrato'));

  React.useEffect(() => {
    function handleKeyPress(event) {
      if (event.ctrlKey && event.key === 'p') {
        event.preventDefault();
        printDiv();
      }
    }

    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="md">
        <div id="printableArea">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <img alt="" height="80" src={theme.config.loginLogo} />
            <div id="printDateTime"></div>
          </div>
          <div
            className={classes.comprovante}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Button
              variant="contained"
              color="primary"
              id="printButton"
              title={'Imprimir'}
              onClick={printDiv}
              startIcon={<MdPrint color="background" />}
            >
              Imprimir
            </Button>
            <br />
          </div>
          <div
            className={classes.comprovante}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Typography variant="h5" component="h5">
              Extrato
            </Typography>
          </div>
          <TableContainer
            component={Paper}
            style={{
              backgroundColor: 'transparent',
              boxShadow: 'none',
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHeader}>Data</TableCell>
                  <TableCell className={classes.tableHeader}>
                    Centro de Custo
                  </TableCell>
                  <TableCell className={classes.tableHeader}>
                    Histórico
                  </TableCell>
                  <TableCell className={classes.tableHeader}>
                    Complemento
                  </TableCell>
                  <TableCell className={classes.tableHeader}>Valor</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows?.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.data || '-'}</TableCell>
                    <TableCell className={classes.tableCell}>
                      {row.centroCusto || '-'}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {row.historicoEvento || '-'}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {row.complemento + row.complementoNome || '-'}
                    </TableCell>
                    <TableCell>
                      {row.tipoEvento === 'C'
                        ? `+ ${formattedMoney(parseFloat(row.valor))}`
                        : `- ${formattedMoney(parseFloat(row.valor))}`}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Container>
    </React.Fragment>
  );
}
