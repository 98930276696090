import TextField from '@material-ui/core/TextField';
import styled from 'styled-components';
import DateInput from '../../../components/InputDate';
import CurrencyInput from 'react-currency-input';
import { styled as MuiStyled } from '@material-ui/core';

export const Container = styled.div`
  display: grid;
  /* justify-content: center;
  align-items: center; */
  background-color: #fff;
  padding: 20px 0;
  row-gap: 20px;

  @media only screen and (min-width: 1024px) {
    display: grid;
    /* justify-content: center;
    align-items: center; */
    row-gap: 20px;
  }
`;
export const Subcontainer = styled.div`
  /* grid-template-columns: 100%; */
  height: auto;
  display: grid;
  width: 100%;
  row-gap: 20px;

  @media only screen and (min-width: 1024px) {
    /* width: 900px; */
    height: auto;
    display: grid;
    row-gap: 20px;
  }
`;
export const InputText = styled(TextField)`
  width: 100%;
  @media only screen and (min-width: 1024px) {
  }
`;
export const ContainerValorPagamento = styled.div`
  width: 100%;
  display: grid;
  row-gap: 20px;

  @media only screen and (min-width: 1024px) {
    width: 100%;
    display: flex;
    row-gap: 20px;
    column-gap: 50px;
    justify-content: flex-start;
    align-items: center;
  }
`;
export const ContainerAgendamentoPagamento = styled.div`
  width: 100%;
  display: grid;
  row-gap: 20px;

  @media only screen and (min-width: 1024px) {
    display: grid;
    grid-template-columns: 50%;
    row-gap: 20px;
    align-items: center;
    width: 100%;
  }
`;
export const ContainerRadioGroup = styled.div`
  display: grid;
  justify-content: flex-start;
  align-items: center;
  background-color: #fff;

  @media only screen and (min-width: 1024px) {
    display: grid;
    justify-content: flex-start;
    align-items: center;
  }
`;
export const SubContainerRadioGroup = styled.div`
  /* width: 330px; */
  height: auto;
  display: grid;
  row-gap: 20px;

  @media only screen and (min-width: 1024px) {
    /* width: 900px; */
    height: auto;
    display: grid;
    row-gap: 20px;
    justify-content: flex-start;
    align-items: center;
  }
`;
export const AgendamentoPagamento = styled.div`
  /* width: 330px; */
  height: auto;
  display: grid;
  row-gap: 20px;

  @media only screen and (min-width: 1024px) {
    /* width: 900px; */
    height: auto;
    display: grid;
    justify-content: flex-start;
    align-items: center;
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  @media only screen and (min-width: 1024px) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`;

export const Data = styled(DateInput)`
  width: 100%;
  margin: 0 !important;

  @media only screen and (min-width: 1024px) {
    margin: 0 !important;
  }
  @media only screen and (min-width: 1440px) {
    margin: 0 !important;
  }
`;

export const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export const MoneyTeste = styled(CurrencyInput)`
  font: inherit;
  color: currentColor;
  width: 100%;
  border: 0;
  height: 1.1876em;
  margin: 0;
  display: block;
  padding: 6px 0 7px;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  animation-name: mui-auto-fill-cancel;
  letter-spacing: inherit;
  animation-duration: 10ms;
  padding-top: 10.5px;
  padding-bottom: 10.5px;
  font-size: 14px !important;
`;

export const Info = MuiStyled('div')(({ theme }) => {
  return {
    padding: '0 0 40px 20px',
    '& li': {
      color: theme.palette.system.dark,
      fontWeight: 'bold',
    },
  };
});
