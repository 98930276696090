import { useQuery } from 'react-query';
import { api_multipague_cobranca_dashboard } from '../../services/api';

export const useGetOperacoes = (infoRedux, conta, options) => {
  return useQuery(
    'getOperacoes',
    async () => {
      try {
        if (infoRedux?.contaPadrao?.id) {
          const response = await api_multipague_cobranca_dashboard.get(
            !!conta
              ? `/cobranca-simples/resumo?contextoConsolidado=true`
              : `/cobranca-simples/resumo?cliente=${localStorage.getItem(
                  'contaId',
                )}&clienteConta=${infoRedux?.contaPadrao?.id}`,
          );

          return response.data;
        }
      } catch (error) {
        console.error('Error fetching agenda financeira:', error);
        throw new Error('Failed to fetch agenda financeira');
      }
    },
    {
      ...options,
      enabled: !!infoRedux?.contaPadrao?.id,
    },
  );
};
