export function listarContasRequest(id) {
  return {
    type: 'LISTAR_CONTAS_REQUEST',
    payload: id
  };
}
export function listarContasSuccess(contasCliente) {
  return {
    type: 'LISTAR_CONTAS_SUCCESS',
    payload: contasCliente,
  };
}
export function setContaPadrao(contasCliente) {
  return {
    type: 'SET_CONTA_DEFAULT_SUCCESS',
    payload: contasCliente,
  };
}
export function listarContasError() {
  return {
    type: 'LISTAR_CONTAS_ERROR',
  };
}
