import { all, takeLatest, select, call, put } from 'redux-saga/effects';
import { api_multipague_configuracao } from '../../../services/api';
import { hideLoading, showLoading } from '../loading/actions';
import { listarInstrucoesSuccess, listarInstrucoesError } from './actions';
import { toastComponentError } from '../../../components/Toast';


function* listarInstrucoes() {
  try {
    yield put(showLoading(true));

    const { data } = yield call(
      api_multipague_configuracao.get,
      `/tipo-instrucao`,
    );
    yield put(hideLoading(false));

    yield put(listarInstrucoesSuccess(data))
    console.log('==========> INSTRUCOES', data);


  } catch (err) {
    yield put(listarInstrucoesError())
    yield put(hideLoading(false));
    toastComponentError(` Codigo: ${err.response.data.codigo} - ${err.response.data.mensagem}`,);
  }
}

export default all([takeLatest('LISTAR_INSTRUCOES_REQUEST', listarInstrucoes)]);
