import produce from 'immer';
const INITIAL_STATE = {};

const beneficiarios = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'PESQUISAR_FAVORITOS_SUCCESS': {
      return action.payload;
    }

    case 'PESQUISAR_FAVORITOS_ERROR': {
      return action.payload;
    }

    default: {
      return state;
    }
  }
};

export default beneficiarios;
