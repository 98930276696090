import React from 'react';
import { DataGrid } from '@material-ui/data-grid';
import { Paper, useTheme } from '@material-ui/core';

import { ptBR } from './locale';

import { Container } from './style';

export default ({
  headerComponent,
  rows,
  columns,
  pageSize,
  loading,
  textNoRows,
  height,
  ...rest
}) => {
  const getHeight = () => {
    if (headerComponent) {
      return `calc(${height} + 40px)`;
    }
    return 'auto';
  };
  return (
    <Container style={{ height: getHeight() }}>
      <Paper elevation={0} data-testid="container-datagrid">
        {headerComponent && (
          <div className="headerButtons">
            {React.createElement(headerComponent)}
          </div>
        )}
        <div style={{ height }}>
          <DataGrid
            disableColumnMenu
            keepNonExistentRowsSelected
            rows={rows}
            columns={columns}
            pageSize={pageSize}
            loading={loading}
            {...rest}
            localeText={ptBR(textNoRows)}
          />
        </div>
      </Paper>
    </Container>
  );
};
