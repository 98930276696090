import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import Switch from '@material-ui/core/Switch';
import Link from '@material-ui/core/Link';
import SaveIcon from '@material-ui/icons/Save';
import LoadingBtn from '../../../components/Buttons/ConfirmButton';
import CssBaseline from '@material-ui/core/CssBaseline';
import TopBar from '../../../components/TopBar';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { api_multipague_configuracao } from '../../../services/api';
import { toastComponentError, toastComponentSuccess } from '../../../components/Toast';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    top: '25%',
    overflow: 'scroll',
    height: '90vh',
    width: '90vw',
    display: 'block',
  },
  fixedHeight: {
    height: '300px',
  },
  bread: {
    paddingTop: theme.spacing(2),
    marginLeft: '25px',
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.light,
  },
  rootform: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '25ch',
  },
  btnprint: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '20px',
  },
  btnpdf: {
    marginRight: '10px',
  },
  formControl: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: '10px',
    width: '25ch',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  positive: {
    color: '#00ff00',
  },
  grid: {
    marginBottom: '20px',
  },
  buttonProgress: {
    color: theme.palette.appBar[theme.palette.type].bgMenu,
    marginRight: '5px',
  },
}));

export default function CadastroEventos() {
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme()
  const [loadButton, setLoadButton] = useState(false);

  const editCodigo = localStorage.getItem('assEditId');
  const editEvento = localStorage.getItem('assEditEvento');
  const editDescricao = localStorage.getItem('assEditBanco');
  const editParceiro = JSON.parse(localStorage.getItem('assEditParceiro'));
  const editIdEventoBanco = localStorage.getItem('assEditIdEventoBanco');

  const editVlrMin = localStorage.getItem('assEditVlrMin');
  const editVlrMax = localStorage.getItem('assEditVlrMax');
  const editVlrHoraMin = localStorage.getItem('assEditHoraMin');
  const editVlrHoraMax = localStorage.getItem('assEditHoraMax');
  const editDiasLimite = localStorage.getItem('assEditDias');

  const [selecionarConta, setSelecionarConta] = useState([]);
  const [descricao, setDescricao] = useState('');
  const [vlrMin, setVlrMin] = useState(editVlrMin);
  const [vlrMax, setVlrMax] = useState(editVlrMax);
  const [horaMin, setHoraMin] = useState(editVlrHoraMin);
  const [horaMax, setHoraMax] = useState(editVlrHoraMax);
  const [diasLimite, setDiasLimite] = useState(editDiasLimite);
  const [selectContas, setSelectContas] = useState(editEvento);

  const CustomSwitch = withStyles({
    switchBase: {
      color: '#6b6b6b',
      '&$checked': {
        color: theme.palette.primary.main,
      },
      '&$checked + $track': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    checked: {},
    track: {},
  })(Switch);

  const [state, setState] = React.useState({
    checkedA: editParceiro,
  });
  const [valorInput, setValorInput] = useState({});
  localStorage.setItem('valorIdDetalhes', valorInput);

  const novoCadastro = {
    codigoEventoBanco: null,
    diasCompensacao: 0,
    horaMaximo: horaMax,
    horaMinimo: horaMin,
    idBanco: parseInt(editCodigo),
    idEvento: selectContas,
    quantidadeDiasLimitePagamento: parseInt(diasLimite),
    utilizaSenhaParceiro: state.checkedA,
    valorMaximo: parseInt(vlrMax),
    valorMinimo: parseInt(vlrMin),
  };

  useEffect(() => {
    api_multipague_configuracao
      .get(`/evento`)
      .then(response => {
        setSelecionarConta(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  const handleSalvar = value => {
    setLoadButton(true);

    api_multipague_configuracao
      .put(`/eventoBanco/${editIdEventoBanco}`, novoCadastro)
      .then(function (response) {
        setLoadButton(false);
        console.log(response);
        toastComponentSuccess('Dados editados com sucesso!');
        setTimeout(function () {
          history.push('/backoffice/associacao-bancos-lista');
          localStorage.clear();
        }, 5000);
      })
      .catch(function (error) {
        console.log(error);
        setLoadButton(false);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 500
        ) {
          toastComponentError(error.response.data.mensagem);
        }
      });
  };

  const handleCancel = event => {
    localStorage.clear();
    history.push('/backoffice/bancos-lista');
  };

  const handleDescricao = event => {
    setDescricao(event.target.value);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <TopBar />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <div className={classes.bread}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href="/dashboard">
              Home
            </Link>
            <Link color="inherit" href="/consulta-mercado">
              Consulta Mercado
            </Link>
            <Typography color="textPrimary">Consulta</Typography>
          </Breadcrumbs>
        </div>
        <Container maxWidth="lg" className={classes.container}>
          <ToastContainer />
          <Grid container spacing={3}>
            <Grid item xs={12} md={4} lg={4}>
              <TextField
                fullWidth
                label="Banco"
                id="outlined-size-small"
                value={editDescricao}
                onChange={handleDescricao}
                variant="outlined"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Autocomplete
                id="combo-box-demo"
                size="small"
                options={selecionarConta}
                getOptionLabel={option => option.descricao}
                onChange={(event, option) => {
                  setSelectContas(option.id);
                }}
                style={{ width: '100%' }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Evento"
                    placeholder={selectContas}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <TextField
                fullWidth
                label="Valor Mínimo"
                id="outlined-size-small"
                value={vlrMin}
                onChange={e => {
                  setVlrMin(e.target.value);
                }}
                variant="outlined"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <TextField
                fullWidth
                label="Valor Máximo"
                id="outlined-size-small"
                value={vlrMax}
                onChange={e => {
                  setVlrMax(e.target.value);
                }}
                variant="outlined"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <TextField
                fullWidth
                label="Horário min permitido para transações"
                id="outlined-size-small"
                type="time"
                value={horaMin}
                onChange={e => {
                  setHoraMin(e.target.value);
                }}
                variant="outlined"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <TextField
                fullWidth
                label="Horário max permitido para transações"
                id="outlined-size-small"
                type="time"
                value={horaMax}
                onChange={e => {
                  setHoraMax(e.target.value);
                }}
                variant="outlined"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <TextField
                fullWidth
                label="Dias limite de pagamento"
                id="outlined-size-small"
                value={diasLimite}
                onChange={e => {
                  setDiasLimite(e.target.value);
                }}
                variant="outlined"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={2} lg={2}>
              <FormLabel component="legend">Senha</FormLabel>
              <CustomSwitch
                checked={state.checkedA}
                onChange={e => {
                  setState({ ...state, [e.target.name]: e.target.checked });
                }}
                color="primary"
                name="checkedA"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            </Grid>
            <Grid item xs={12} md={2} lg={2}>
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.grid}>
            <Grid item xs={12} md={4}>
            </Grid>
            <Grid item xs={12} md={4}></Grid>
            <div
              style={{
                margin: '10px',
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <LoadingBtn
                variant="contained"
                color="disabled"
                width="115"
                onClick={() => {
                  handleCancel();
                }}
              >
                Cancelar
              </LoadingBtn>
              <LoadingBtn
                variant="contained"
                color="primary"
                width="115"
                isLoading={loadButton}
                startIcon={!loadButton && <SaveIcon />}
                onClick={() => {
                  handleSalvar();
                }}
              >
                Salvar
              </LoadingBtn>
            </div>
          </Grid>
        </Container>
      </main>
    </div>
  );
}
