import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import React from 'react';

const DialogModal = ({
  open,
  onClose,
  dialogTitle,
  dialogActions,
  dialogContent,
}) => {
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <Box style={{ padding: 10 }}>
        {dialogTitle && (
          <DialogTitle id="form-dialog-title">
            <Typography variant="inherit" component="h5">
              {dialogTitle}
            </Typography>
          </DialogTitle>
        )}
        <DialogContent>{dialogContent}</DialogContent>
        <DialogActions>{dialogActions}</DialogActions>
      </Box>
    </Dialog>
  );
};

export default DialogModal;
