import { all, takeLatest, select, call, put } from 'redux-saga/effects';
import { api_multipague_configuracao } from '../../../services/api';
import { hideLoading, showLoading } from '../loading/actions';
import { listarMotivoSuccess, listarMotivoError } from './actions';
import { toastComponentError } from '../../../components/Toast';


function* listarMotivos({payload}) {
  try {
    yield put(showLoading(true));

    const { data } = yield call(
      api_multipague_configuracao.get,
      `/motivo?evento=${payload}`,
    );
    yield put(hideLoading(false));
    yield put(listarMotivoSuccess(data))
    console.log('==========> Motivos', data);

  } catch (err) {
    yield put(listarMotivoError())
    yield put(hideLoading(false));
    toastComponentError(` Codigo: ${err.response.data.codigo} - ${err.response.data.mensagem}`,);
  }
}

export default all([takeLatest('LISTAR_MOTIVO_REQUEST', listarMotivos)]);
