export function listarUserRequest(user) {
  return {
    type: 'LISTAR_USER_REQUEST',
    payload: user,
  };
}
export function listarUserSuccess(user) {
  return {
    type: 'LISTAR_USER_SUCCESS',
    payload: user,
  };
}

export function listarUserError() {
  return {
    type: 'LISTAR_USER_ERROR',
  };
}
