import { all, takeLatest, select, call, put } from 'redux-saga/effects';
import { hideLoading, showLoading } from '../loading/actions';
import { pesquisarBoletoSuccess, pesquisarBoletoError } from './actions';
import { api_multipague_cobranca } from '../../../services/api';
import { toastComponentError } from '../../../components/Toast';

function* pesquisarBoleto({ payload }) {
  try {
    yield put(showLoading(true));

    const { data } = yield call(
      api_multipague_cobranca.get,
      `/cobranca-simples/${payload.idBoleto}?clienteConta=${payload.idConta}`,
    );
    yield put(hideLoading(false));
    yield put(pesquisarBoletoSuccess(data));
  } catch (err) {
    console.log('ERROR =========>', error)
    yield put(hideLoading(false));
    toastComponentError(
      ` Codigo: ${err.response.data.codigo} - ${err.response.data.mensagem}`,
    );
    yield put(pesquisarBoletoError());
  }
}

export default all([takeLatest('LISTAR_BOLETO_REQUEST', pesquisarBoleto)]);
