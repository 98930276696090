import React from 'react';
import { useSelector } from 'react-redux';
import { Container } from './styles';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import beyondgif from './beyond.gif';

const Loading = () => {
  const { showLoading } = useSelector(state => state.loading);
  return (
    <Container showPopUp={showLoading}>
      {/* <Loader
        type="Rings"
        color="#EEE"
        height={150}
        width={150}
        // timeout={3000} //3 secs
      /> */}
      <img
        height={150}
        width={250}
        alt=""
        src="https://storageappbeyondus.blob.core.windows.net/multisolucoes/tema/loadingbeyondbank.gif"
      />
    </Container>
  );
};

export default Loading;
