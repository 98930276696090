import React from "react"
import ImpressaoBoletosProvider from "../../Context/ImpressaoBoletosContext"
import Content from "./Content"

const RetornoRemessa = () => {
    return(<ImpressaoBoletosProvider>
        <Content />
    </ImpressaoBoletosProvider>)
}

export default RetornoRemessa