import React from 'react';
import {
  Backdrop,
  Fade,
  Modal,
  Box,
  Button,
  Grid,
  Typography,
} from '@material-ui/core';
import { useStyles } from '../styles';
import { useHistory } from 'react-router-dom';

export const ModalOrdemPagamento = ({
  showModal,
  setShowModal,
  ordens,
  ordem = 0,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const onChange = event => {
    if (!!event) {
      history.push('/ordem-pagamento', {
        ordemPagamento: event,
        ordensPagamento: ordens,
      });
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={showModal}
      onClose={() => {
        setShowModal(false);
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      style={{ padding: 15 }}
    >
      <Fade in={showModal}>
        <Box className={classes.paper}>
          <Typography component={'h3'} variant="inherit" color="secondary">
            Selecione a ordem de pagamento
          </Typography>
          <Grid container spacing={3} style={{ marginTop: 15 }}>
            {ordens?.map(item => {
              return (
                <Grid
                  item
                  md={ordens?.length > 3 ? 2 : 3}
                  key={item}
                  style={{ marginLeft: 10 }}
                >
                  <Button
                    color="primary"
                    fullWidth
                    onClick={() => onChange(item)}
                    variant={
                      item === ordem[0]?.idOrdemPagamento
                        ? 'contained'
                        : 'outlined'
                    }
                  >
                    <Typography variant="inherit" color="secondary">
                      {item}
                    </Typography>
                  </Button>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Fade>
    </Modal>
  );
};
