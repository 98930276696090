import styled from 'styled-components';
import { Form } from '@unform/web';
import {
  styled as MuyStyled,
  alpha,
  makeStyles,
} from '@material-ui/core/styles';

export const Container = styled(Form)``;
export const Subcontainer = styled.div`
  display: grid;
  row-gap: 60px;
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;

export const Filtros = styled.div`
  display: grid;
  row-gap: 20px;
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;

export const Evento = styled.div``;

// =========== Datas ===========

export const ContainerDates = styled.div`
  display: grid;
  row-gap: 20px;
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;
export const Label = styled.div``;
export const Dates = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 20px;

  div:nth-child(1) {
    grid-area: 1/1/2/4;
  }

  div:nth-child(2) {
    grid-area: 2/1/3/2;
  }
  div:nth-child(3) {
    grid-area: 2/2/3/3;
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;

// =========== Datas ===========

export const InfoEmpresa = styled.div`
  display: grid;
  row-gap: 20px;

  @media only screen and (min-width: 1024px) {
    display: grid;
    row-gap: 20px;
    grid-template-columns: 40% auto;
    column-gap: 20px;
  }
  @media only screen and (min-width: 1440px) {
  }
`;

export const BotoesPrint = styled.div`
  display: flex;
  justify-content: flex-start;
  row-gap: 20px;
  width: 100%;

  @media only screen and (max-width: 680px) {
    display: block;
    text-align: center;
    gap: 5px;
    align-items: center;
  }
`;
export const BotoesPrint2 = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;

  @media only screen and (max-width: 680px) {
    display: block;
    margin: auto;
  }
`;

export const Checks = styled.div``;

export const ContainerTable = styled.div``;
export const BodyTable = styled.div`
  width: auto;
  height: 500px;
  @media only screen and (min-width: 1024px) {
    width: 100%;
    height: 500px;
  }
  @media only screen and (min-width: 1440px) {
  }
`;

export const ContainerPopUp = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
`;

export const ButtonTable = styled.button.attrs(props => ({
  disabled: false,
}))`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContainerButton = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap:1rem;
  @media only screen and (min-width: 1024px) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  @media only screen and (min-width: 1440px) {
  }
  @media only screen and (max-width: 798px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
  }
`;

export const AdicionarParecer = styled(Form)`
  width: 100%;
  height: 100%;
  padding: 20px;
  display: grid;
  row-gap: 20px;

  @media only screen and (min-width: 1024px) {
    min-width: 500px;
    height: auto;
  }
  @media only screen and (min-width: 1440px) {
  }
`;

export const InputParecer = styled.div`
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;
export const ActionsParecer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;

export const ParecerList = MuyStyled('div')(({ theme }) => {
  return {
    maxHeight: '150px',
    overflowY: 'auto',

    '& div:nth-child(even)': {
      backgroundColor: theme.palette.action.focus,
    },

    '&::-webkit-scrollbar': {
      width: '8px',
      maxHeight: '8px',
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '3px',
    },

    display: 'grid',
    rowGap: '10px',
    gridTemplateRows: 'min-content',
  };
});

export const ListagemParecer = styled.div`
  width: 100%;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    cursor: pointer;
  }

  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;

export const useStyles = makeStyles(theme => ({
  buttonProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '20px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  buttonsHeaderGrid: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: '5px',
    },
  },
  rowContent: {
    lineHeight: '150%',
    height: '50px',
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    whiteSpace: 'break-spaces',
  },
  rowCorrentistaAbsolute: {
    position: 'absolute',
    height: 20,
    width: '115px',
    marginLeft: -100,
    marginBottom: 25,
    backgroundColor: alpha(theme.palette.text.disabled, 0.58),
    borderRadius: '3px',
  },
}));
