import React, { useEffect } from 'react';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useTheme, alpha } from '@material-ui/core/styles';
import { Link, useLocation } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  iconItemMenu: {
    marginLeft: '2px',
    fill: theme.palette.primary.main,
    width: '20px',
  },
  iconItemMenuActive: {
    marginLeft: '2px',
    fontWeight: '900',
    fill: theme.palette.primary.main,
    width: '22px',
  },
  menu: {
    marginLeft: '8%',
    paddingRight: '18%',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    color: theme.palette.appBar[theme.palette.type].text,
  },
  iconItemInactive: {
    marginLeft: '2px',
    width: '20px',
    fill: theme.palette.primary.main,
  },
}));

const ItemSubmenu = ({ to, name, path, tooltip }) => {
  const classes = useStyles();
  const location = useLocation();
  const theme = useTheme();

  const getListItemStyleMenuActive = path => {
    if (location.pathname === path) {
      return {
        marginLeft: '8%',
      };
    } else {
      return { marginLeft: '8%' };
    }
  };

  const listItemStyle = getListItemStyleMenuActive(to);

  return (
    <div >
      <Link to={to} replace >
        <ListItem button className={classes.menu} style={listItemStyle}>
          <ListItemIcon
            style={{
              marginLeft: tooltip ? '25px' : '0',
            }}
          >
            {tooltip ? (
              <Tooltip placement="bottom" title={name}>
                <SubdirectoryArrowRightIcon
                  class={`${classes.iconItemMenu} ${
                    location.pathname !== to && classes.iconItemMenuActive
                  }`}
                />
              </Tooltip>
            ) : (
              <SubdirectoryArrowRightIcon
                class={`${classes.iconItemMenu} ${
                  location.pathname !== to && classes.iconItemMenuActive
                }`}
              />
            )}
          </ListItemIcon>
          <ListItemText
            style={{
              marginLeft: tooltip ? '30px' : '-15px',
              color:
                location.pathname === to
                  ? theme.palette.primary.main
                  : 'inherit',
            }}
          >
            <Typography
              style={{
                fontWeight: location.pathname === to ? '600' : '400',
                fontSize: location.pathname === to ? '16px' : '15px',
              }}
            >
              {name}
            </Typography>
          </ListItemText>
        </ListItem>
      </Link>
    </div>
  );
};

export default ItemSubmenu;
