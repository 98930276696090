import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { api_multipague_arquivo } from '../../services/api';
import { api_multipague_arquivo_paged } from '../../services/api';
import DeleteIcon from '@material-ui/icons/Delete';
import { FiUpload } from 'react-icons/fi';
import { AiOutlineCloudDownload } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Tooltip from '@material-ui/core/Tooltip';
import { v4 as uuidv4 } from 'uuid';
import { TextField, Typography, Button } from '@material-ui/core';
import Buttons from '../../components/Buttons/ConfirmButton';
import Data from '../../components/InputDate';
import { BsInfoCircle } from 'react-icons/bs';
import Box from '../../components/Box';
import Wrapper from '../../components/Wrapper';
import DataGrid from '../../components/DataGrid';
import { MdDownload } from 'react-icons/md';
import {
  hideLoadingButton,
  showLoadingButton,
} from '../../store/modules/loading/actions';
import {
  ContainerButton,
  ContainerIcon,
  ContainerInputFile,
  ContainerNomeFiles,
  DeleteFile,
  Files,
  NameFile,
  Subcontainer,
} from './styles';
import { useTheme } from '@material-ui/core';
import { MdClear, MdSearch, MdSend } from 'react-icons/md';
import ReplayIcon from '@material-ui/icons/Replay';
import Modal from '../../components/Modal';
import { DataGridPage } from '../../components/DataGrid/DataGridPaginationServer';
import RenderActionsCell from '../../components/DataGrid/DataGridPaginationServer/Actions';
import { toastComponentError, toastComponentSuccess } from '../../components/Toast';

export const PagamentoLote = () => {
  const theme = useTheme();
  const [dataGridState, setDataGridState] = React.useState({
    page: 0,
    pageSize: 10,
    loading: false,
    total: 0,
    carregaDatagrid: false,
  });
  const history = useHistory();
  const { loadingButton } = useSelector(state => state.loading);
  const { contaPadrao } = useSelector(state => state.contas);
  const dispatch = useDispatch();
  const [uploadFiles, setUploadFiles] = useState([]);
  const [textNoRows, setTextNoRows] = useState('');
  const [rowId, setRowId] = useState(null);
  const [openMenuActions, setOpenMenuActions] = useState(false);
  const fileRef = useRef(null);

  const initialParams = {
    nome: '',
    dataInicial: '',
    dataFinal: '',
  };
  const [params, setParams] = useState(initialParams);
  const [rowsTable, setRowsTable] = useState([]);
  const columnsTable = [
    { field: 'codigo', headerName: 'Código', width: 160 },
    { field: 'arquivo', headerName: 'Arquivo', width: 300 , flex: 1},
    { field: 'tipo', headerName: 'Tipo', width: 130 },
    {
      field: 'data',
      headerName: 'Data',
      type: 'string',
      width: 150,
    },
    { field: 'situacao', headerName: 'Situação', width: 180 },
    {
      field: 'acoes',
      headerName: 'Ações',
      sortable: false,
      align: 'center',
      width: 150,
      disableClickEventBubbling: true,
      renderCell: event => {
        return (
          <RenderActionsCell
            event={event}
            actionsComponent={
              <div style={{ flexDirection: 'column', display: 'flex' }}>
                <Tooltip title="Enviar Detalhes">
                  <button onClick={() => enviarDetalhes(event)}>
                    <BsInfoCircle
                      size={20}
                      color={theme.palette.primary.main}
                    />
                  </button>
                </Tooltip>
                <Tooltip title="Download">
                  <a
                    href={event.row.caminhoStorage}
                    download={event.row.nomeArquivo}
                    style={{ marginTop: '5px' }}
                  >
                    <MdDownload size={24} color={theme.palette.primary.main} />
                  </a>
                </Tooltip>
                <Tooltip title="Efetuar Retorno">
                  {event.row.permiteGerarRetorno ? (
                    <button onClick={() => reprocessar(event)}>
                      <ReplayIcon
                        size={20}
                        color={theme.palette.primary.main}
                      />
                    </button>
                  ) : (
                    <></>
                  )}
                </Tooltip>
              </div>
            }
            openMenuActions={openMenuActions}
            setOpenMenuActions={setOpenMenuActions}
            rowId={rowId}
          />
        );
      },
    },
  ];

  function reprocessar(event) {
    api_multipague_arquivo
      .post(
        `/arquivo/pagamento/gerar-retorno?idArquivoPagamento=${event.row.id}&idClienteConta=${contaPadrao.id}`,
      )
      .then(response => {
        if (response.status == 202) {
          toastComponentSuccess(`Arquivo de retorno solicitado com sucesso`);
        }
      })
      .catch(err => {
        dispatch(hideLoadingButton(false));
        toastComponentError(err.response.data.mensagem);
      });
  }

  function chosenFile() {
    if (fileRef.current) fileRef.current.click();
  }
  function setInputFiles(data) {
    const oldState = uploadFiles;
    if (data.length > 1) {
      const formattedArrayFile = [...data].map(teste => ({
        itemFile: teste,
        id: uuidv4(),
      }));

      const NewState = oldState.concat(formattedArrayFile);
      setUploadFiles(NewState);
    } else {
      const formattedArrayFile = [...data].map(teste => ({
        itemFile: teste,
        id: uuidv4(),
      }));

      setUploadFiles([...uploadFiles, formattedArrayFile[0]]);
    }
  }
  function deleteFile(id) {
    const removeFile = uploadFiles.filter(filt => filt.id !== id);
    setUploadFiles(removeFile);
  }
  async function sendFiles() {
    try {
      dispatch(showLoadingButton(true));

      const formData = new FormData();
      for (var i = 0; i < uploadFiles.length; i++) {
        formData.append('file', uploadFiles[i].itemFile);
      }

      const { data } = await api_multipague_arquivo.post(
        `/arquivo/pagamento/upload?clienteConta=${contaPadrao.id}`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        },
      );

      dispatch(hideLoadingButton(false));
      toastComponentSuccess('Upload realizado com sucesso');
      setUploadFiles([]);
    } catch (err) {
      dispatch(hideLoadingButton(false));
      toastComponentError(
        ` Codigo: ${err.response.data.codigo} - ${err.response.data.mensagem}`,
      );
    }
  }

  async function listFiles(params = initialParams, page = 0, pageSize = 10) {
    try {
      setDataGridState(prev => ({ ...prev, page: page, loading: true }));
      const { data } = await api_multipague_arquivo_paged.get(
        `/arquivo/pagamento?clienteConta=${contaPadrao.id}&page=${page}&size=${pageSize}&nome=${params.nome}&dataInicial=${params.dataInicial}&dataFinal=${params.dataFinal}`,
      );

      const qtdItems = data.totalElements;
      const formatted = data.content.map(files => ({
        ...files,
        codigo: files.id,
        arquivo: files.nomeArquivo,
        tipo: files.extensao,
        data: moment(files.dataCadastro).format('DD/MM/YYYY'),
        situacao: files.situacaoProcessamento,
      }));
      setDataGridState(prev => ({ ...prev, total: qtdItems }));
      setRowsTable(formatted);
    } catch (err) {
      setRowsTable([]);
      setDataGridState(prev => ({ ...prev, total: 0 }));
      if (err.response.status != 404) {
        toastComponentError(err?.response?.data?.mensagem);
      } else if (err.response.status === 404) {
        setTextNoRows('Nenhum arquivo encontrado');
      }
      console.log(err);
    } finally {
      setDataGridState(prev => ({ ...prev, loading: false }));
    }
  }

  const clearFilter = () => {
    setParams(initialParams);
    listFiles(initialParams, 0, dataGridState.pageSize);
  };

  useEffect(() => {
    if (contaPadrao?.id) {
      listFiles(params, 0, dataGridState.pageSize);
    }
  }, [contaPadrao]);

  useEffect(() => {
    if (dataGridState.carregaDatagrid) {
      listFiles(params, 0, dataGridState.pageSize);
    }
  }, [dataGridState.pageSize]);

  const InitialModal = {
    title: '',
    open: false,
    message: '',
  };
  const [modal, setModal] = useState(InitialModal);

  function enviarDetalhes(event) {
    if (['ERRO', 'RECEBIDO'].indexOf(event.row.situacao) != -1) {
      showModalDetail(event);
    } else {
      localStorage.setItem('idPagamentoLote', event.row.id);
      history.push('/Pagamento-Lote/Detalhe');
    }
  }

  function showModalDetail(event) {
    const { arquivo, mensagem, situacao } = event.row;
    console.log('eventModal', event.row);
    setModal({
      title: `Detalhes do arquivo ${arquivo}`,
      open: true,
      message:
        situacao == 'ERRO'
          ? mensagem
          : 'O arquivo foi recebido e está sendo processado!',
    });
  }

  const handleField = (name, value) => {
    setParams(prev => ({ ...prev, [name]: value }));
  };

  const handlePageSize = newPageSize => {
    setDataGridState(prev => ({
      ...prev,
      page: 0,
      pageSize: newPageSize,
      carregaDatagrid: true,
    }));
  };

  const filterInputs = {
    inputsData: [
      {
        html: () => (
          <Data
            callback={value => handleField('dataInicial', value)}
            label="Data Inicial"
            id="data-inicial"
            value={params.dataInicial}
            dataMinima={'1999-01-01'}
            variant="outlined"
            size="small"
            InputProps={{
              style: { borderRadius: '20px' },
            }}
          />
        ),
        grid: { xs: 12, sm: 6, md: 6 },
      },
      {
        html: () => (
          <Data
            callback={value => handleField('dataFinal', value)}
            size="small"
            label="Data Final"
            id="data-final"
            value={params.dataFinal}
            dataMinima={'1999-01-01'}
            variant="outlined"
            InputProps={{
              style: { borderRadius: '20px' },
            }}
          />
        ),
        grid: { xs: 12, sm: 6, md: 6 },
      },
      {
        html: () => (
          <TextField
            name="file_name"
            label="Nome do arquivo"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            size="small"
            style={{ width: '100%' }}
            value={params.nome}
            InputProps={{
              style: { borderRadius: '20px' },
            }}
            onChange={event => handleField('nome', event.target.value)}
          />
        ),
        grid: { xs: 12, sm: 12, md: 12 },
      },
    ],
    searchButton: {
      searchButton: () => listFiles(params, 0, dataGridState.pageSize),
    },
    cleanButton: {
      cleanButton: () => clearFilter(),
    },
  };

  return (
    <>
      <Modal
        open={modal.open}
        close={() => setModal(InitialModal)}
        title={modal.title}
      >
        <Typography>{modal.message}</Typography>
      </Modal>

      <DataGridPage
        children={
          <Box style={{ borderRadius: '20px', boxShadow: 'none' }}>
            <Subcontainer>
              <ContainerInputFile>
                <ContainerNomeFiles existFiles={!!uploadFiles.length}>
                  {uploadFiles.length > 0 ? (
                    uploadFiles.map(file => (
                      <Files key={file.id}>
                        <NameFile>
                          <p>{file.itemFile.name}</p>
                        </NameFile>
                        <DeleteFile>
                          <DeleteIcon
                            color="error"
                            size={15}
                            onClick={() => deleteFile(file.id)}
                          />
                        </DeleteFile>
                      </Files>
                    ))
                  ) : (
                    <p>Nenhum Arquivo Anexado</p>
                  )}
                </ContainerNomeFiles>
                <ContainerIcon>
                  <div>
                    <FiUpload
                      color={theme.palette.primary.main}
                      size={30}
                      onClick={() => chosenFile()}
                    />
                  </div>
                  <input
                    type="file"
                    className="displayinput"
                    id="arquivos_input"
                    name="arquivos_input"
                    onChange={() => setInputFiles(fileRef.current?.files)}
                    ref={fileRef}
                    multiple
                  />
                </ContainerIcon>
              </ContainerInputFile>
              <ContainerButton>
                <Buttons
                  color="primary"
                  variant="contained"
                  style={{ borderRadius: '20px' }}
                  disabled={!uploadFiles.length}
                  width={100}
                  title={'Enviar'}
                  onClick={() => sendFiles()}
                  isLoading={loadingButton}
                  startIcon={
                    <MdSend
                      color={!uploadFiles.length ? 'disabled' : 'background'}
                    />
                  }
                ></Buttons>
              </ContainerButton>
            </Subcontainer>
          </Box>
        }
        title="Pagamento em Lote"
        crumb={[{ name: 'Dashboard', link: '/dashboard' }]}
        rows={rowsTable}
        columns={columnsTable}
        loading={dataGridState.loading}
        page={dataGridState.page}
        pageSize={dataGridState.pageSize}
        minHeight="500px"
        rowCount={dataGridState.total}
        onPageChange={page => listFiles(params, page, dataGridState.pageSize)}
        formData={filterInputs}
        onRowClick={params => setRowId(params.id)}
        key={rowsTable.key}
        setDatagrid={setDataGridState}
        onPageChangeFunction={page =>
          listFiles(params, page, dataGridState.pageSize)
        }
        textNoRows={textNoRows}
        onPageSizeChangeFunction={newPageSize => handlePageSize(newPageSize)}
      />
    </>
  );
};
