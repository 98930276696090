export function listarEspecieoRequest() {
  return {
    type: 'LISTAR_ESPECIE_REQUEST',
  };
}
export function listarEspecieSuccess(especies) {
  return {
    type: 'LISTAR_ESPECIE_SUCCESS',
    payload: especies,
  };
}

export function listarEspecieError() {
  return {
    type: 'LISTAR_ESPECIE_ERROR',
  };
}
