import React, { useEffect, useState } from 'react';

import { useTheme } from '@material-ui/core/styles';
import { Box, Divider, Typography } from '@material-ui/core';

import { api_multipague_cobranca_dashboard } from '../../services/api';
import formattedMoney from '../../util/FormatteMoney/index';
import { useSelector } from 'react-redux';
import {
  AiOutlineCheckCircle,
  AiOutlineExclamationCircle,
} from 'react-icons/ai';
import SubtitlesIcon from '@material-ui/icons/Subtitles';
import { DashboardCard } from '../DashboardCards';
import { MessageErrorComponent } from '../DashboardCards/ErrorMessage';
import ColumnsCard from '../DashboardCards/ColumnsCards';
import { useGetOperacoes } from '../../hooks/operacoes';

export default function CobrancaSimplesEGarantias({ conta = false }) {
  const infoRedux = useSelector(state => state.contas);
  const theme = useTheme();

  const { data, isLoading, isError } = useGetOperacoes(infoRedux, conta);

  const cobrancaSimples = data?.cobrancas[0]?.cobrancaSimplesTotalizador.filter(
    item => item.tipoOperacaoBoleto === 'COBRANCA_SIMPLES',
  )[0];

  const operacaoGarantia =
    data?.cobrancas[0]?.cobrancaSimplesTotalizador.filter(
      item => item.tipoOperacaoBoleto === 'OPERACAO_GARANTIA',
    )[0];

  return (
    <DashboardCard
      title={'Operações'}
      carregando={isLoading}
      isTipoOperacao
      children={
        <>
          {isError ? (
            <MessageErrorComponent />
          ) : (
            <Box
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'space-between'}
            >
              <Box width={'100%'}>
                <Box
                  borderBottom={1}
                  style={{
                    borderBottom: '1px solid' + theme.palette.grey['300'],
                  }}
                  width={'100%'}
                >
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginTop: '41px',
                    }}
                  >
                    <Box paddingTop={2}>
                      <Typography
                        style={{
                          fontWeight: 'bold',
                          color: theme.palette.text.primary,
                          alignItems: 'center',
                          display: 'flex',
                          height: '36px',
                          whiteSpace: 'nowrap',
                          fontSize: '.95vw',
                        }}
                      >
                        <AiOutlineCheckCircle
                          size={25}
                          style={{ paddingRight: '5px' }}
                          color={'green'}
                        />
                        Vencendo hoje:
                      </Typography>
                    </Box>
                  </Box>
                  <Divider />
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Box paddingTop={2}>
                      <Typography
                        style={{
                          fontWeight: 'bold',
                          color: theme.palette.text.primary,
                          alignItems: 'center',
                          display: 'flex',
                          height: '36px',
                          whiteSpace: 'nowrap',
                          fontSize: '.95vw',
                        }}
                      >
                        <AiOutlineExclamationCircle
                          size={25}
                          style={{ paddingRight: '5px' }}
                          color={'orange'}
                        />
                        À vencer:
                      </Typography>
                    </Box>
                  </Box>
                  <Divider />
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Box paddingTop={2}>
                      <Typography
                        style={{
                          fontWeight: 'bold',
                          color: theme.palette.text.primary,
                          alignItems: 'center',
                          display: 'flex',
                          height: '36px',
                          whiteSpace: 'nowrap',
                          fontSize: '.95vw',
                        }}
                      >
                        <AiOutlineExclamationCircle
                          size={25}
                          style={{ paddingRight: '5px' }}
                          color={'red'}
                        />
                        Vencidas:
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <ColumnsCard
                title={'Cobrança Simples'}
                values={[
                  !cobrancaSimples || cobrancaSimples?.vencendoHoje == 0
                    ? 'R$ 0,00'
                    : formattedMoney(parseFloat(cobrancaSimples?.vencendoHoje)),
                  !cobrancaSimples || cobrancaSimples?.aVencer == 0
                    ? 'R$ 0,00'
                    : formattedMoney(parseFloat(cobrancaSimples?.aVencer)),
                  !cobrancaSimples || cobrancaSimples?.vencido == 0
                    ? 'R$ 0,00'
                    : formattedMoney(parseFloat(cobrancaSimples?.vencido)),
                ]}
              />
              <ColumnsCard
                title={'Garantias'}
                values={[
                  !operacaoGarantia || operacaoGarantia?.vencendoHoje == 0
                    ? 'R$ 0,00'
                    : formattedMoney(
                        parseFloat(operacaoGarantia?.vencendoHoje),
                      ),
                  !operacaoGarantia || operacaoGarantia?.aVencer == 0
                    ? 'R$ 0,00'
                    : formattedMoney(parseFloat(operacaoGarantia?.aVencer)),
                  !operacaoGarantia || operacaoGarantia?.vencido == 0
                    ? 'R$ 0,00'
                    : formattedMoney(parseFloat(operacaoGarantia?.vencido)),
                ]}
              />
              <ColumnsCard
                title={'Total'}
                values={[
                  data?.total?.vencendoHoje == 0
                    ? 'R$ 0,00'
                    : formattedMoney(parseFloat(data?.total?.vencendoHoje)),
                  data?.total?.aVencer == 0
                    ? 'R$ 0,00'
                    : formattedMoney(parseFloat(data?.total?.aVencer)),
                  data?.total?.vencido == 0
                    ? 'R$ 0,00'
                    : formattedMoney(parseFloat(data?.total?.vencido)),
                ]}
              />
            </Box>
          )}
        </>
      }
      icon={
        <SubtitlesIcon
          size={50}
          style={{
            marginRight: 5,
            color: theme.palette.primary.main,
          }}
        />
      }
    />
  );
}
