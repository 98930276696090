import produce from 'immer';
const INITIAL_STATE = [];

const extrato = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'EXTRATO_TED_SUCCESS': {
      return [action.payload];
    }

    case 'EXTRATO_TED_ERROR': {
      return [...state];
    }

    default: {
      return state;
    }
  }
};

export default extrato;
