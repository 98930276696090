import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import { Form } from '@unform/web';

export const Container = styled(Form)`
  width: 100%;

  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;
export const Subcontainer = styled.div`
  width: 100%;
  display: grid;
  row-gap: 20px;
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;
export const InstrucoesContainer = styled.div`
  width: 100%;
  display: grid;
  row-gap: 30px;
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;
export const ContainerOpcoes = styled.div`
  width: 100%;
  display: grid;
  row-gap: 20px;
  column-gap: 20px;
  grid-template-columns: repeat(3, auto);
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;

// ============================== // ======================

export const Options = styled.div`
  width: 100%;
  display: grid;
  row-gap: 20px;
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;
export const TituloContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  row-gap: 20px;
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;
export const Label = styled.p`
  width: 100%;
  font-weight: bold;
  font-size: 15px;

  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;
export const ContainerInputsOptions = styled.div`
  width: 100%;
  display: grid;
  row-gap: 20px;
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;

export const ContainerButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media only screen and (min-width: 1024px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  @media only screen and (min-width: 1440px) {
  }
`;

// ============================== // ======================
export const InputText = styled(TextField)`
  width: 100%;
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;
export const ContainerMensagens = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 20px;
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;
export const ContainerIsEditing = styled.div`
  display: grid;
  row-gap: 10px;

  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;
