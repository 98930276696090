import { useQuery } from 'react-query';
import { api_multipague_transacao } from '../../services/api';

export const useGetOrdemDePagamentoItens = (
  ordemPagamentoId,
  page,
  options,
) => {
  return useQuery(
    ['getOrdemDePagamentoItens', ordemPagamentoId, page],
    async () => {
      try {
        let endPoint = `beyond/ordem-pagamento/${ordemPagamentoId}/itens?page=${
          page || 0
        }`;
        const { data } = await api_multipague_transacao.get(`${endPoint}`);

        return data;
      } catch (error) {
        throw new Error('Failed to fetch saldo');
      }
    },
    {
      ...options,
      enabled: !!ordemPagamentoId,
    },
  );
};
