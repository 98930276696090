import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Loader from '../Loader';
import { InputAdornment } from '@material-ui/core';

const AutoCompleteMui = ({
  name,
  label,
  options,
  renderOption,
  onChange,
  onBlur,
  error,
  multiple,
  isLoading,
  inputPropEndAdornment,
  ...rest
}) => {
  return (
    <Autocomplete
      name={name}
      label={label}
      limitTags={2}
      options={options}
      onBlur={onBlur}
      id={name}
      multiple={multiple}
      loading={isLoading}
      loadingText={<Loader size="table" />}
      renderOption={renderOption}
      // disablePortal
      size="small"
      onChange={onChange}
      getOptionLabel={option => option.label}
      {...rest}
      renderInput={params => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: inputPropEndAdornment && (
              <InputAdornment
                style={{
                  marginRight: '-7%',
                }}
              >
                {inputPropEndAdornment}
              </InputAdornment>
            ),
          }}
          label={label}
          error={error}
          helperText={error && `${label} é obrigatório`}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
        />
      )}
    />
  );
};

export default AutoCompleteMui;
