import { createStore, applyMiddleware } from 'redux';
import rootReducers from './modules/rootReducers';
import { composeWithDevTools } from 'redux-devtools-extension';
import CreateSaga from 'redux-saga';
import rootSaga from './modules/rootSaga';

const reduxSaga = CreateSaga();
const sagas = [reduxSaga];

const store = createStore(
  rootReducers,
  composeWithDevTools(applyMiddleware(...sagas)),
);

reduxSaga.run(rootSaga);

export default store;
