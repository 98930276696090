/* eslint react/prop-types: 0 */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState,useEffect } from 'react';
import {
  ContainerFormRadio,
  OptionsRadio,
  RadioContainerGroup,
  RadioLabel,
  Radios,
} from './styles';

const RadioButton = ({
  options,
  titulo,
  callback,
  optionDefault = '',
  styleContainerRadio = '',
  row = true,
  ...rest
}) => {

  const [value, setValue] = useState(optionDefault);
  const handleChange = event => {
    if (callback) {
      callback(event.target.value);
    }
    setValue(event.target.value);
  };
  useEffect(()=>{
    setValue(optionDefault);
  },[optionDefault])

  return (
    <ContainerFormRadio color="primary" {...rest}>
      <RadioLabel color="primary">{titulo}</RadioLabel>
      <RadioContainerGroup
        aria-label="gender"
        name="bancos"
        value={value}
        onChange={handleChange}
        row={row}
        styleradiocontainergroup={styleContainerRadio}
      >
        {options.length &&
          options.map(option => (
            <OptionsRadio
              key={option.value}
              value={option.value}
              control={<Radios color="primary" />}
              label={option.label}
            />
          ))}
      </RadioContainerGroup>
    </ContainerFormRadio>
  );
};

export default RadioButton;
