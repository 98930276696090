import { all, put, takeLatest } from 'redux-saga/effects';
import { hideLoading, showLoading } from '../loading/actions';
import { listarUserError, listarUserSuccess } from './actions';
import { toastComponentError } from '../../../components/Toast';

function* listarInfoUser({ payload }) {
  try {
    yield put(showLoading(true));

    // const { data } = yield call(
    //   api_multipague_conta.get,
    //   `/conta`,
    // );
    yield put(hideLoading(false));
    yield put(listarUserSuccess(payload));
  } catch (error) {
    yield put(hideLoading(false));
    toastComponentError('Erro ao carregar informaçõoes do usuario.');
    yield put(listarUserError());
  }
}

export default all([takeLatest('LISTAR_USER_REQUEST', listarInfoUser)]);
