import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import { Form } from '@unform/web';

export const Container = styled(Form)`
  width: 100%;

  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;
// ============================== // ======================
export const Subcontainer = styled.div`
  width: 100%;
  display: grid;
  row-gap: 20px;
  @media only screen and (min-width: 1024px) {
    display: grid;
    row-gap: 40px;
  }
  @media only screen and (min-width: 1440px) {
  }
`;
// ============================== // ======================
export const ContainerTitle = styled.div`
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;

// ============================== // ======================
export const ContainerInfoPrimaria = styled.div`
  display: grid;
  row-gap: 10px;

  @media only screen and (min-width: 1024px) {
    row-gap: 20px;
    grid-template-columns: repeat(3, 30%);
    column-gap: 5%;
  }
  @media only screen and (min-width: 1440px) {
  }
`;
export const Especie = styled.div`

  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;
export const Banco = styled.div`

  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;
export const DiasLimitePagamento = styled.div`

  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;

// ============================== // ======================
export const ContainerOpcoes = styled.div`
  width: 100%;
  display: grid;
  row-gap: 30px;
  @media only screen and (min-width: 1024px) {
    display: grid;
    row-gap: 30px;
    grid-template-columns: repeat(2, auto);
    column-gap: 30px;
  }
  @media only screen and (min-width: 1440px) {
  }
`;

export const Options = styled.div`
  width: 100%;
  display: grid;
  row-gap: 20px;
  margin-right: 10px;
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;
export const TituloContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  row-gap: 20px;
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;
export const Label = styled.p`
  width: 100%;
  font-weight: bold;
  font-size: 16px;
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;
export const ContainerInputsOptions = styled.div`
  width: 100%;
  display: grid;
  row-gap: 20px;
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;
// ============================== // ======================

export const ContainerButton = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap:1rem;
  @media only screen and (min-width: 1024px) {

  }
  @media only screen and (min-width: 1440px) {
  }
`;

export const ContainerFaixas = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  column-gap: 20px;

  @media only screen and (min-width: 1024px) {

  }
  @media only screen and (min-width: 1440px) {
  }
`;
