import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import React, { useEffect } from 'react';
import formattedMoney from '../../util/FormatteMoney/index';
import { useSelector } from 'react-redux';
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from 'react-icons/ai';
import SubtitlesIcon from '@material-ui/icons/Subtitles';
import { DashboardCard } from '../DashboardCards';
import { MessageErrorComponent } from '../DashboardCards/ErrorMessage';
import ColumnsCard from '../DashboardCards/ColumnsCards';
import { useGetAgendaFinanceira } from '../../hooks/agenda-financeira';
import { useMediaQuery } from '@material-ui/core';
import AgendaFinanceiraMobile from './AgendaFinanceiraMobile';

export default function AgendaFinanceira({ conta = false, mobile = false }) {
  const theme = useTheme();
  const infoRedux = useSelector(state => state.contas);
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  const { data, isLoading, isError, refetch } = useGetAgendaFinanceira(
    infoRedux,
    conta,
  );

  const cobrancaSimples = data?.divisaoCobrancas?.filter(
    item => item.tipoOperacao === 'COBRANCA_SIMPLES',
  )[0];
  const operacaoGarantia = data?.divisaoCobrancas?.filter(
    item => item.tipoOperacao === 'OPERACAO_GARANTIA',
  )[0];

  useEffect(() => {
    refetch();
  }, [isMediumScreen, mobile]);

  return isMediumScreen && mobile ? (
    <AgendaFinanceiraMobile
      data={data}
      error={isError}
      carregando={isLoading}
    />
  ) : (
    <DashboardCard
      title={'Agenda Financeira'}
      carregando={isLoading}
      isTipoOperacao
      children={
        <>
          {isError ? (
            <MessageErrorComponent />
          ) : (
            <Box
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'space-between'}
            >
              <Box width={'100%'}>
                <Box width={'100%'}>
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginTop: '37px',
                    }}
                  >
                    <Box paddingTop={2}>
                      <Typography
                        style={{
                          fontWeight: 'bold',
                          color: theme.palette.text.primary,
                          alignItems: 'center',
                          display: 'flex',
                          fontSize: '.95vw',

                          wordWrap: 'unset',
                          whiteSpace: 'nowrap',
                          height: '40px',
                        }}
                      >
                        <AiOutlinePlusCircle
                          size={25}
                          style={{ paddingRight: '5px' }}
                          color={'green'}
                        />
                        Valor de entrada:
                      </Typography>
                    </Box>
                  </Box>
                  <Divider />
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Box paddingTop={2}>
                      <Typography
                        style={{
                          fontWeight: 'bold',
                          color: theme.palette.text.primary,
                          alignItems: 'center',
                          display: 'flex',
                          fontSize: '.95vw',
                          whiteSpace: 'nowrap',
                          height: '36px',
                        }}
                      >
                        <AiOutlineMinusCircle
                          size={25}
                          style={{ paddingRight: '5px' }}
                          color={'orange'}
                        />
                        Valor de saída:
                      </Typography>
                    </Box>
                  </Box>
                  <Divider />
                </Box>
              </Box>
              <ColumnsCard
                financialAgenda
                title={'Cobrança Simples'}
                values={[
                  !cobrancaSimples || cobrancaSimples?.valorTitulo == 0
                    ? 'R$ 0,00'
                    : formattedMoney(parseFloat(cobrancaSimples?.valorTitulo)),
                  '-',
                ]}
              />
              <ColumnsCard
                financialAgenda
                title={'Garantias'}
                values={[
                  !operacaoGarantia || operacaoGarantia?.valorTitulo == 0
                    ? 'R$ 0,00'
                    : formattedMoney(parseFloat(operacaoGarantia?.valorTitulo)),
                  '-',
                ]}
              />
              <ColumnsCard
                title={'Total'}
                values={[
                  operacaoGarantia?.valorTitulo ||
                  0 + cobrancaSimples?.valorTitulo ||
                  0 !== 0
                    ? formattedMoney(
                        parseFloat(
                          operacaoGarantia?.valorTitulo +
                            cobrancaSimples?.valorTitulo,
                        ),
                      )
                    : 'R$ 0,00',
                  !data || data?.valorTotalSaida == 0
                    ? 'R$ 0,00'
                    : formattedMoney(parseFloat(data?.valorTotalSaida)),
                ]}
              />
            </Box>
          )}
        </>
      }
      icon={
        <SubtitlesIcon
          size={50}
          style={{
            marginRight: 5,
            color: theme.palette.primary.main,
          }}
        />
      }
    />
  );
}
