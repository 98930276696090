export function showLoading(loading) {
  return {
    type: 'SHOW_LOADING',
    payload: loading
  }
}
export function hideLoading(loading) {
  return {
    type: 'HIDE_LOADING',
    payload: loading
  }
}
export function showLoadingButton(loading) {
  return {
    type: 'SHOW_LOADING_BUTTON',
    payload: loading
  }
}
export function hideLoadingButton(loading) {
  return {
    type: 'HIDE_LOADING_BUTTON',
    payload: loading
  }
}
