import React from 'react';
import { alpha, Grid, Typography, useTheme } from '@material-ui/core';

import { maskCpfCnpj } from '../../../util/mask';
import Box from '../../../components/Box';

const CardInfoPix = ({ keys, transactionValue }) => {
  const theme = useTheme();

  const TypographyCustomCard = ({ name, value }) => {
    return (
      <Typography
        key={name}
        style={{
          color: alpha(theme.palette.text.primary, 0.5),
          fontWeight: '600',
          display: 'flex',
        }}
      >
        {name}
        <Typography
          style={{
            color: alpha(theme.palette.text.primary, 0.5),
            marginLeft: 8,
          }}
        >
          {value}
        </Typography>
      </Typography>
    );
  };

  return (
    <Grid item sm={12} xs={12}>
      <Box
        style={{
          borderRadius: '20px',
          border: `1px solid ${theme.palette.divider}`,
          padding: 20,
          marginBottom: 0,
        }}
      >
        <TypographyCustomCard name={'Nome: '} value={keys?.nomeCorrentista} />
        <TypographyCustomCard
          name={'Documento Federal: '}
          value={maskCpfCnpj(keys?.documentoFederal)}
        />
        <TypographyCustomCard name={'Banco: '} value={keys?.banco?.descricao} />
        <TypographyCustomCard name={'Agência: '} value={keys?.conta?.agencia} />
        <TypographyCustomCard name={'Conta: '} value={keys?.conta?.conta} />
        {!!transactionValue && (
          <TypographyCustomCard
            name={'Valor: '}
            value={new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(transactionValue)}
          />
        )}
      </Box>
    </Grid>
  );
};

export default CardInfoPix;
