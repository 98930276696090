import { all, takeLatest, select, call, put } from 'redux-saga/effects';
import api from '../../../services/api';
import { addToCartError, addToCartSuccess } from './actions';

function* ListarBancosCadastrados({ payload }) {
  console.log(payload);
  console.log('Action de adicao de um novo banco disparada', payload);

  const { data } = yield call(api.get, `/stock/${payload.id}`);
  console.log('response data', data);

  const quantidadeProduto = yield select(state => {
    return state.carrinho.find(item => item.id === payload.id)?.quantidade ?? 0;
  });

  if (data.quantity > quantidadeProduto) {
    console.log('ADD');
    yield put(addToCartSuccess(payload));
  } else {
    yield put(addToCartError(payload));
  }

  console.log('=======> Produto retornado', quantidadeProduto);
}

export default all([
  takeLatest('ADD_TO_CART_REQUEST', ListarBancosCadastrados),
]);
