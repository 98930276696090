import { toast } from 'react-toastify';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { api_multipague_configuracao } from '../../../../services/api';
import { hideLoading, showLoading } from '../../loading/actions';
import { listarCentroCustoError, listarCentroCustoSuccess } from './actions';

function* listarCentroCusto({ payload }) {
  try {
    yield put(showLoading(true));

    const { data } = yield call(
      api_multipague_configuracao.get,
      `/centroCusto/${payload}`,
    );

    yield put(hideLoading(false));

    if (data.length) {
      yield put(listarCentroCustoSuccess(data));
    } else {
      yield put(listarCentroCustoError());
    }
  } catch (err) {
    yield put(hideLoading(false));
    // toast.error(
    //   ` Codigo: ${err.response.data.codigo} - ${err.response.data.mensagem}`,
    // );
  }
}

export default all([
  takeLatest('LISTAR_CENTRO_CUSTO_REQUEST', listarCentroCusto),
]);
