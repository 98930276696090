import React, { useState, useRef, useCallback, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';
import { BiDetail, BiError } from 'react-icons/bi';
import { api_multipague_cobranca_cnab } from '../../services/api';
import formattedMoney from '../../util/FormatteMoney';
import { ButtonTable } from './styles';
import ErrorIcon from '@material-ui/icons/Error';
import { useDispatch, useSelector } from 'react-redux';
import { hideLoading, showLoading } from '../../store/modules/loading/actions';

import Wrapper from '../../components/Wrapper';
import DataGrid from '../../components/DataGrid';

import { newEditCnab } from '../ConsultaBoleto';
import { Paper, Tab, Tooltip } from '@material-ui/core';
import { toastComponentError } from '../../components/Toast';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';

const DetalhamentoArquivosCnab = () => {
  const history = useHistory();

  const situacao = history?.location?.state;

  const { idArquivo } = useParams();
  const dispatch = useDispatch();
  const { contaPadrao } = useSelector(state => state.contas);
  const [rowsTable, setRowsTable] = useState([]);
  const [rowsTableCnab, setRowsTableCnab] = useState([]);
  const [rowCount, setRowCount] = useState();
  const [rowCountCnab, setRowCountCnab] = useState();
  const [textNoRows, setTextNoRows] = useState();
  const [textNoRowsCnab, setTextNoRowsCnab] = useState();
  const [rowsState, setRowsState] = React.useState({
    page: 0,
    pageSize: 10,
  });
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const columnsTable = [
    {
      field: 'cpf_cnpf',
      headerName: 'CPF/CNPJ',
      width: 150,
    },
    {
      field: 'nosso_numero',
      headerName: 'Nosso número',
      width: 200,
    },
    {
      field: 'nome_pagador',
      headerName: 'Nome Pagador',
      flex: 1,
    },
    { field: 'data_vencimento', headerName: 'Data Vencimento', width: 120 },
    {
      field: 'documento',
      headerName: 'Documento',
      width: 150,
    },
    {
      field: 'valor_total',
      headerName: 'Valor total',
      width: 150,
    },
    {
      field: 'error',
      headerName: 'Erro',
      sortable: false,
      width: 110,
      disableClickEventBubbling: true,
      renderCell: event => {
        return (
          <ButtonTable
            hasError={!!event.row.codigoErro}
            onClick={() => newEditCnab(event.row, history, contaPadrao)}
          >
            <BiError
              size={24}
              color={!!event.row.codigoErro ? '#dc3545' : '#dcdcdc'}
            />
          </ButtonTable>
        );
      },
    },
  ];

  const columnsTableCnab = [
    {
      field: 'linha',
      headerName: 'Linha',
      width: 150,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'situacao',
      headerName: 'Situação',
      width: 200,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'data_cadastro',
      headerName: 'Data Cadastro',
      width: 200,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'numeroTitulo',
      headerName: 'Número Título',
      width: 200,
      headerAlign: 'center',
      align: 'center',
      renderCell: event => (
        <>
          {event.row.numeroTitulo !== null ? (
            <>
              <p>{event.row.numeroTitulo}</p>
                <Tooltip title={'O Número de título pode estar incorreto devido a não conformidade do layout do arquivo CNAB processo!'}>
                  <ErrorIcon color="primary" style={{ marginLeft: '5px' }} />
                </Tooltip>
            </>
          ) : (
            <>
              <p>{event.row.numeroTitulo}</p>
            </>
          )}
        </>
      ),
    },
    {
      field: 'observacao',
      headerName: 'Observação',
      flex: 1,
      renderCell: event => {
        return (
          <p style={{
            lineHeight: "150%",
            height: "50px",
            alignItems: "center",
            display: "flex",
            flexWrap: "wrap",
            whiteSpace: "break-spaces"
          }}
          >{event.row.observacao}</p>
        );
      },
      headerAlign: "center",
      align: "center"
    },
  ];

  const onPage = page => {
    setRowsState(prev => ({ ...prev, page }));
  };

  useEffect(() => {
    localStorage.removeItem('data_pagamento_boleto2');
  }, []);

  useEffect(() => {
    if (idArquivo && contaPadrao?.id) {
      pesquisarBoletosErro(idArquivo, contaPadrao?.id, rowsState);
      pesquisarCnab(idArquivo, contaPadrao?.id, rowsState);
    }
  }, [contaPadrao, rowsState]);

  const pesquisarBoletosErro = useCallback(
    async (arquivoId, contaId, stateRow) => {
      dispatch(showLoading(true));

      const URLBOLETOS = `/cobranca-simples/arquivo/${arquivoId}/boleto/erros?clienteConta=${contaId}&page=${stateRow.page}&size=${stateRow.pageSize}`;

      try {
        const { data } = await api_multipague_cobranca_cnab.get(URLBOLETOS);

        const formattedRows = data.content.map(date => ({
          data_vencimento: moment(date.dataVencimento).format('DD/MM/YYYY'),
          nosso_numero: date.nossoNumero,
          valor_total: formattedMoney(date.valorTitulo),
          documento: date.numeroTitulo,
          nome_pagador: date.nomePagador,
          cpf_cnpf: date.documentoFederal,
          data_cadastro: moment(date.dataCadastro).format('DD/MM/YYYY'),
          id: uuidv4(),
          ...date,
        }));
        const qtdItems = data.totalElements;
        setRowCount(qtdItems);
        setRowsTable(formattedRows);
        dispatch(hideLoading(false));
      } catch (err) {
        dispatch(hideLoading(false));
        if (err.response.status === 404) {
          setTextNoRows('Nenhum boleto com erro encontrado');
        } else {
          err.response.data.mensagem.includes(
            'Nenhum boleto com erro encontrado',
          )
            ? null
            : toastComponentError(
                ` Codigo: ${err.response.data.codigo} - ${err.response.data.mensagem}`,
              );
        }
      }
    },
    [],
  );

  const pesquisarCnab = useCallback(async (arquivoId, contaId, stateRow) => {
    dispatch(showLoading(true));

    const URLCNAB = `/cobranca-simples/arquivo/${arquivoId}/cnab?clienteConta=${contaId}&page=${stateRow.page}&size=${stateRow.pageSize}`;

    try {
      const { data } = await api_multipague_cobranca_cnab.get(URLCNAB);

      const formattedRows = data.content.map(date => ({
        data_cadastro: moment(date.dataCadastro).format('DD/MM/YYYY'),
        id: uuidv4(),
        ...date,
      }));

      const qtdItems = data.totalElements;
      setRowCountCnab(qtdItems);
      setRowsTableCnab(formattedRows);

      dispatch(hideLoading(false));
    } catch (err) {
      dispatch(hideLoading(false));
      if (err.response.status === 404) {
        setTextNoRowsCnab('Nenhum boleto com erro encontrado');
      } else {
        err.response.data.mensagem.includes(
          'Nenhum arquivo de remessa encontrado',
        )
          ? null
          : toastComponentError(
              ` Codigo: ${err.response.data.codigo} - ${err.response.data.mensagem}`,
            );
      }
    }
  }, []);

  return (
    <Wrapper
      title="Detalhamento CNAB"
      crumb={[
        { name: 'Minhas Contas', link: '/MinhasContas' },
        {
          link: '/Processamento/Cnab',
          name: 'Processamento CNAB',
        },
      ]}
    >
      <TabContext value={value}>
        <Paper style={{ padding: 20, borderRadius: '20px', marginTop: 25 }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Inconsistência Parceiro" value="1" />
            <Tab label="Inconsistência Arquivo " value="2" />
          </TabList>
          <TabPanel value="1">
            <DataGrid
              rows={rowsTable}
              columns={columnsTable}
              height={600}
              rowCount={rowCount}
              pagination
              {...rowsState}
              paginationMode="server"
              onPageChange={onPage}
              rowsPerPageOptions={[10, 20, 50]}
              componentsProps={{
                pagination: { classes: null },
              }}
              textNoRows={textNoRows}
              onPageSizeChange={newPageSize =>
                setRowsState({ page: 0, pageSize: newPageSize })
              }
            />
          </TabPanel>
          <TabPanel value="2">
            <DataGrid
              rows={rowsTableCnab}
              columns={columnsTableCnab}
              height={600}
              rowCount={rowCountCnab}
              pagination
              {...rowsState}
              paginationMode="server"
              onPageChange={onPage}
              rowsPerPageOptions={[10, 20, 50]}
              componentsProps={{
                pagination: { classes: null },
              }}
              textNoRows={textNoRowsCnab}
              onPageSizeChange={newPageSize =>
                setRowsState({ page: 0, pageSize: newPageSize })
              }
            />
          </TabPanel>
        </Paper>
      </TabContext>
    </Wrapper>
  );
};

export default DetalhamentoArquivosCnab;
