import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { initialDateState, selecionarSituacao } from './constants';

export const useDataGridFilters = () => {
  const [nomeFavorecido, setNomeFavorecido] = React.useState('');
  const [docBeneficiario, setDocBeneficiario] = React.useState('');
  const [situacao, setSituacao] = React.useState('');
  const [selectedSituacao, setSelectedSituacao] = React.useState(null);
  const [dateState, setDateState] = React.useState(initialDateState);
  const [checkAll, setCheckAll] = React.useState(false);
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [rowsState, setRowsState] = React.useState({
    page: 0,
    pageSize: 10,
  });

  function limpar() {
    setNomeFavorecido('');
    setSituacao('');
    setCheckAll(false);
    setSelectedSituacao(null);
    setDocBeneficiario('');
    setSelectionModel([]);
    setRowsState({ page: 0, pageSize: rowsState.pageSize });
    setDateState(initialDateState);
  }

  const filterInputs = {
    inputsData: [
      {
        html: () => (
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            value={nomeFavorecido}
            label="Nome Favorecido"
            onChange={e => {
              setNomeFavorecido(e.target.value);
            }}
            InputProps={{
              style: { borderRadius: '20px' },
            }}
          />
        ),
        grid: { xs: 12, sm: 12, md: 3, lg: 3 },
      },
      {
        html: () => (
          <TextField
            fullWidth
            label="CPF/CNPJ"
            id="outlined-size-small"
            value={docBeneficiario}
            onChange={e => {
              setDocBeneficiario(e.target.value);
            }}
            variant="outlined"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
          />
        ),
        grid: { xs: 12, sm: 12, md: 3, lg: 3 },
      },
      {
        html: () => (
          <Autocomplete
            id="combo-box-demo"
            size="small"
            options={selecionarSituacao}
            value={selectedSituacao}
            getOptionLabel={option => option.descricao}
            onChange={(event, option) => {
              setSituacao(option ? option.id : '');
              setSelectedSituacao(option);
            }}
            style={{ width: '100%' }}
            renderInput={params => (
              <TextField
                {...params}
                label="Status"
                placeholder={'Selecione o Status'}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
              />
            )}
            InputProps={{
              style: { borderRadius: '20px' },
            }}
          />
        ),
        grid: { xs: 12, sm: 3 },
      },
      {
        html: () => (
          <TextField
            variant="outlined"
            size="small"
            required
            id="dataDe"
            placeholder="DD/MM/AAAA"
            label="Data Cadastro - De:"
            type="date"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            value={dateState.dataDe}
            onChange={event =>
              setDateState(prevState => ({
                ...prevState,
                dataDe: event.target.value,
              }))
            }
            InputProps={{
              style: { borderRadius: '20px' },
            }}
          />
        ),
        grid: { xs: 12, sm: 3 },
      },
      {
        html: () => (
          <TextField
            variant="outlined"
            size="small"
            required
            id="dataAte"
            placeholder="DD/MM/AAAA"
            label="Data Cadastro - Até:"
            value={dateState.dataAte}
            onChange={event =>
              setDateState(prevState => ({
                ...prevState,
                dataAte: event.target.value,
              }))
            }
            type="date"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              style: { borderRadius: '20px' },
            }}
          />
        ),
        grid: { xs: 12, sm: 3 },
      },
      {
        html: () => (
          <TextField
            variant="outlined"
            size="small"
            required
            id="dataPagDe"
            placeholder="DD/MM/AAAA"
            label="Data Pagamento - De:"
            type="date"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            value={dateState.dataPagDe}
            onChange={event =>
              setDateState(prevState => ({
                ...prevState,
                dataPagDe: event.target.value,
              }))
            }
            InputProps={{
              style: { borderRadius: '20px' },
            }}
          />
        ),
        grid: { xs: 12, sm: 3 },
      },
      {
        html: () => (
          <TextField
            variant="outlined"
            size="small"
            required
            id="dataPagAte"
            placeholder="DD/MM/AAAA"
            label="Data Pagamento - Até:"
            type="date"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            value={dateState.dataPagAte}
            onChange={event =>
              setDateState(prevState => ({
                ...prevState,
                dataPagAte: event.target.value,
              }))
            }
          />
        ),
        grid: { xs: 12, sm: 3 },
      },
    ],
    searchButton: {
      searchButton: () => {
        setCheckAll(false);
        setSelectionModel([]);
        setRowsState({ page: 0, pageSize: rowsState.pageSize });
      },
    },
    cleanButton: {
      cleanButton: () => limpar(),
    },
  };

  return {
    filterInputs,
    nomeFavorecido,
    docBeneficiario,
    situacao,
    dateState,
    rowsState,
    setRowsState,
    checkAll,
    setCheckAll,
    selectedSituacao,
    selectionModel,
    setSelectionModel,
    setSituacao,
    setDocBeneficiario,
    setNomeFavorecido,
    setSelectedSituacao,
    setDateState,
  };
};

export default useDataGridFilters;
