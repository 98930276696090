import { all, takeLatest, select, call, put } from 'redux-saga/effects';
import { api_multipague_configuracao } from '../../../services/api';
import { hideLoading, showLoading } from '../loading/actions';
import { listarFinalidadeSuccess, listarFinalidadeError } from './actions';

import { toast } from 'react-toastify';

function* listarFinalidades() {
  try {
    yield put(showLoading(true));

    const { data } = yield call(
      api_multipague_configuracao.get,
      `/finalidadeTransferencia`,
    );
    yield put(hideLoading(false));

    if (data.length) {
      yield put(listarFinalidadeSuccess(data));
    } else {
      yield put(listarFinalidadeError());
    }
  } catch (err) {
    yield put(hideLoading(false));
    // toast.error(
    //   ` Codigo: ${err.response.data.codigo} - ${err.response.data.mensagem}`,
    // );
  }
}

export default all([
  takeLatest('LISTAR_FINALIDADE_REQUEST', listarFinalidades),
]);
