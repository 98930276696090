import produce from 'immer';
const INITIAL_STATE = [];

const carrinho = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'ADD_TO_CART_SUCCESS': {
      return produce(state, draft => {
        const findIndex = draft.findIndex(
          item => item.id === action.payload.id,
        );

        if (findIndex >= 0) {
          draft[findIndex].quantidade++;
        } else {
          draft.push({
            nome: action.payload.title,
            id: action.payload.id,
            preco: action.payload.price,
            quantidade: 1,
          });
        }
      });

    }
    default: {
      return state;
    }
  }
};

export default carrinho;
