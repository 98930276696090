import React from 'react';

import { useTheme } from '@material-ui/core/styles';
import { Box, Divider, Typography } from '@material-ui/core';

export default function ColumnsCard({ title, values, conta }) {
  const theme = useTheme();

  return (
    <Box width={'96%'}>
      <Typography
        style={{
          fontWeight: 'bold',
          paddingBottom: 5,
          color: theme.palette.text.primary,
          alignItems: 'center',
          marginBottom: 5,
          fontSize: '.95vw',
          display: 'flex',
          justifyContent: 'flex-end',
          whiteSpace: 'nowrap',
          height: '36px',
        }}
      >
        {title}
      </Typography>
      <Box width={'100%'}>
        {values.map((item, index) => {
          return (
            <div key={index}>
              <Box
                key={item}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Box
                  paddingTop={2}
                  display={'flex'}
                  justifyContent={'flex-end'}
                >
                  <Box>
                    <Typography
                      style={{
                        color: theme.palette.text.primary,
                        alignItems: 'center',
                        display: 'flex',
                        whiteSpace: 'nowrap',
                        fontSize: '.95vw',
                        height: '36px',
                        lineHeight: index === 0 ? '140%' : '180%',
                      }}
                    >
                      {item}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Divider color={theme.palette.grey['300']} />
            </div>
          );
        })}
      </Box>
    </Box>
  );
}
