export function listarTodosFavoritosRequest(data) {
  return {
    type: 'LISTAR_TODOS_FAVORITOS_REQUEST',
    payload: data,
  };
}
export function listarTodosFavoritosSuccess(listBeneficiarios) {
  return {
    type: 'LISTAR_TODOS_FAVORITOS_SUCCESS',
    payload: listBeneficiarios,
  };
}
export function listarTodosFavoritosError() {
  return {
    type: 'LISTAR_TODOS_FAVORITOS_ERROR',
  };
}
