import React, { useEffect, useState } from 'react';
import { Chart } from 'react-google-charts';
import PropTypes from 'prop-types';
import { makeStyles, useTheme, alpha } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { api_multipague_despesa_dashboard } from '../../src/services/api';
import moment from 'moment';
import { useSelector } from 'react-redux';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import { DashboardCard } from './DashboardCards';
import { useMediaQuery } from '@material-ui/core';
import { MessageErrorComponent } from './DashboardCards/ErrorMessage';

const useStyles = makeStyles(theme => ({
  root2: {
    flexGrow: 1,
    borderRadius: '20px',
    boxShadow: 'none',
    border: 'none',
    backgroundColor: theme.palette.background.paper,
  },
  rootChart: {
    '& svg': {
      '& text': {
        fill: theme.palette.getContrastText(theme.palette.background.paper),
      },
    },
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function MetasDespesasDashboard() {
  const classes = useStyles();
  const theme = useTheme();
  const matchesPoint = useMediaQuery(theme.breakpoints.down('sm'));
  const [value, setValue] = React.useState(0);
  const [carregando, setCarregando] = React.useState(false);

  const [informacoes, setInformacoes] = useState({
    nomeCentroCusto: '',
    valorMeta: '',
    valorRealizado: '',
    codigoClienteConta: '',
    centrosCustos: [],
  });
  const [infoGraficos, setInfoGraficos] = useState([]);
  const optionsChart = {
    title: 'Metas x Despesas',
    chartArea: { width: '50%' },
    colors: ['#394c59', '#2962ff'],
    hAxis: {
      title: 'Metas x Despesas',
      minValue: 0,
    },
    vAxis: {
      title: 'Comparativo',
    },
    backgroundColor: theme.palette.background.paper,
    titleTextStyle: {
      color: theme.palette.getContrastText(theme.palette.background.paper),
    },
    legend: {
      textStyle: {
        color: theme.palette.getContrastText(theme.palette.background.paper),
      },
    },
  };
  const [options, setOptions] = useState(optionsChart);
  const [error, setError] = React.useState(false);


  const infoRedux = useSelector(state => state.contas);

  useEffect(() => {
    setCarregando(true);
    const dataInicial = moment(new Date().getFullYear() + '-01-01').format(
      'YYYY-MM-DD',
    );
    const dataFinal = moment(new Date().getFullYear() + '-12-31').format(
      'YYYY-MM-DD',
    );
    api_multipague_despesa_dashboard
      .get(`/meta-realizado?dataInicial=${dataInicial}&dataFinal=${dataFinal}`)
      .then(response => {
        setCarregando(false);
        setInformacoes(response.data);
        const formattedInfo = response.data.centrosCustos.map(item => [
          item.nomeCentroCusto,
          item.valorMeta,
          item.valorRealizado,
        ]);
        formattedInfo.unshift(['Parâmetro', 'Metas', 'Despesas']);
        setInfoGraficos(formattedInfo);
      })
      .catch(err => {
        setCarregando(false);
        setError(true);
      });
  }, [infoRedux]);

  useEffect(() => {
    setOptions(optionsChart);
  }, [theme.palette.type]);

  return (
    <DashboardCard
      isMetaDespesa
      title={'Metas x Despesas'}
      carregando={carregando}
      icon={
        <TrendingUpIcon
          size={50}
          style={{
            marginRight: 5,
            color: theme.palette.primary.main,
          }}
        />
      }
      children={
        <>
          <TabPanel value={value} index={0}>
            {error ? (
              <MessageErrorComponent

                maxWidth={'500px'}
              />
            ) : (
              <>
                <Chart
                  className={classes.rootChart}
                  width={'100%'}
                  height={'300px'}
                  chartType="BarChart"
                  loader={<div>Loading Chart</div>}
                  data={infoGraficos}
                  options={options}
                  rootProps={{ 'data-testid': '4' }}
                />
              </>
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            Item Two
          </TabPanel>
          <TabPanel value={value} index={2}>
            Item Three
          </TabPanel>
        </>
      }
    />
  );
}
