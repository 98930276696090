import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { MdMonetizationOn } from 'react-icons/md';

import {
  api_multipague_conta,
  api_multipague_esteira,
  api_multipague_transacao,
  api_multipague_transacaoPaged,
} from '../../../../src/services/api';
import { maskCpfCnpj } from '../../../util/mask';
import ErrorIcon from '@material-ui/icons/Error';
import { Cancel, HistoryOutlined } from '@material-ui/icons';
import { CircularProgress, Tooltip } from '@material-ui/core';
import { DataGridPage } from '../../../components/DataGrid/DataGridPaginationServer';
import RenderActionsCell from '../../../components/DataGrid/DataGridPaginationServer/Actions';
import {
  toastComponentError,
  toastComponentSuccess,
} from '../../../components/Toast';
import useDataGridFilters from '../useDataGridFilters';
import { Chip, Grid, useTheme } from '@material-ui/core';
import { showLoading } from '../../../store/modules/loading/actions';
import Checkbox from '@material-ui/core/Checkbox';
import PopUp from '../../../components/PopUp';
import Password from '../../../components/Password';
import { showPopUp } from '../../../store/modules/popUp/actions';
import DialogModal from '../../../components/DialogModal';
import SmsToken from '../../../components/Inputs/SmsToken';
import Buttons from '../../../components/Buttons/ConfirmButton';
import jwt_decode from 'jwt-decode';
import { useAuthContext } from '../../../Context/AuthContext';
import { selecionarSituacao } from '../constants';

const ListaTransferencia = () => {
  const dispatch = useDispatch();
  const {
    filterInputs,
    nomeFavorecido,
    docBeneficiario,
    situacao,
    dateState,
    rowsState,
    setRowsState,
    checkAll,
    setCheckAll,
    selectionModel,
    setSelectionModel,
    setSituacao,
    setDateState,
    setDocBeneficiario,
    setSelectedSituacao,
    setNomeFavorecido,
  } = useDataGridFilters();
  const { permissionAdmin } = useAuthContext();
  const infoRedux = useSelector(state => state.contas);
  const [rowCount, setRowCount] = useState();
  const history = useHistory();
  const formInfo = history?.location?.state?.formInfo;
  const { contaPadrao } = useSelector(state => state.contas);
  const [rowsTable, setRowsTable] = useState([]);
  const [textNoRows, setTextNoRows] = useState('Nenhuma linha');
  const [loadingTable, setLoadingTable] = useState(false);
  const [rowId, setRowId] = useState(0);
  const [loadingHistorico, setLoadingHistorico] = useState(false);
  const [loadingCancel, setLoadingCancel] = useState(false);
  const [openMenuActions, setOpenMenuActions] = useState(false);
  const [loadingButtonReprocessa, setLoadingButtonReprocessa] = useState(false);
  const [carregandoDadosNoPaged, setCarregandoDadosNoPaged] = useState(false);
  const [valorSms, setValorSms] = React.useState('');
  const [open, setOpen] = React.useState(false);

  const columnsTable = [
    {
      field: '__check__',
      type: 'checkboxSelection',
      resizable: false,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      disableReorder: true,
      disableExport: true,
      renderHeader: () => {
        return carregandoDadosNoPaged ? (
          <CircularProgress size={24} />
        ) : (
          <Checkbox
            checked={
              selectionModel.length !== 0 && rowCount === selectionModel.length
                ? true
                : checkAll
            }
            onChange={(_e, value) => {
              changeCheckboxAll(value);
            }}
            color="primary"
          />
        );
      },
      renderCell: event => {
        return (
          <Checkbox
            checked={
              selectionModel.includes(event.id) &&
              event.row.status === 'REJEITADO'
            }
            onChange={(_e, checked) => {
              if (!checked && event.row.status === 'REJEITADO') {
                setCheckAll(false);
                setSelectionModel(old => old.filter(id => id !== event.id));
              }
            }}
            disabled={event.row.status !== 'REJEITADO'}
            color="primary"
          />
        );
      },
    },
    { field: 'documento', headerName: 'CNPJ/CPF', width: 140 },
    { field: 'nome', headerName: 'Nome', flex: 1, minWidth: 150 },
    {
      field: 'banco',
      headerName: 'Banco',
      type: 'string',
      width: 120,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'agencia',
      headerName: 'Agencia',
      type: 'string',
      width: 130,
    },
    {
      field: 'dataCadastros1',
      headerName: 'Data Cadastro',
      type: 'string',
      width: 130,
    },
    {
      field: 'dataAgendamento1',
      headerName: 'Data Pagamento',
      type: 'string',
      width: 130,
    },
    {
      field: 'situacao',
      headerName: 'Situação',
      width: 130,
      renderCell: event => (
        <>
          {event.row.status == 'REJEITADO' ? (
            <>
              <p>{event.row.status}</p>
              {event.row?.transferenciaErro != null ? (
                <Tooltip title={event.row?.transferenciaErro?.mensagemErro}>
                  <ErrorIcon color="error" style={{ marginLeft: '5px' }} />
                </Tooltip>
              ) : (
                <Tooltip title={'Houve um erro!'}>
                  <ErrorIcon color="error" style={{ marginLeft: '5px' }} />
                </Tooltip>
              )}
            </>
          ) : (
            <>
              <p>{event.row.status}</p>
            </>
          )}
        </>
      ),
    },
    {
      field: 'valor',
      headerName: 'Valor',
      width: 130,
      renderCell: event =>
        event.row.valor.toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        }),
    },
    {
      field: 'acoes',
      headerName: 'Ações',
      width: 100,
      align: 'center',
      renderCell: event => {
        return (
          <RenderActionsCell
            event={event}
            actionsComponent={
              <div>
                <Tooltip title="Histórico de aprovações">
                  <button
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {loadingHistorico && event.row.id === rowId ? (
                      <CircularProgress size={24} />
                    ) : (
                      <HistoryOutlined
                        onClick={() => handleHistorico(event.row.id)}
                        color="primary"
                        size={24}
                      />
                    )}
                  </button>
                </Tooltip>
                {event.row.situacao === 'PENDENTE' ? (
                  <Tooltip title="Cancelar">
                    <button
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {loadingCancel && event.row.id === rowId ? (
                        <CircularProgress size={24} />
                      ) : (
                        <Cancel
                          onClick={() => handleCancel(event.row)}
                          color="primary"
                          size={24}
                        />
                      )}
                    </button>
                  </Tooltip>
                ) : (
                  <Tooltip title="Cancelamento não permitido">
                    <button
                      disabled
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Cancel color="disabled" size={24} />
                    </button>
                  </Tooltip>
                )}
              </div>
            }
            openMenuActions={openMenuActions}
            setOpenMenuActions={setOpenMenuActions}
            rowId={rowId}
          />
        );
      },
    },
  ];

  const getTeds = () => {
    dispatch(showLoading(true));
    setLoadingTable(true);
    api_multipague_transacaoPaged
      .get(
        `/transferencia?page=${rowsState.page}&size=${
          rowsState.pageSize
        }&clienteConta=${contaPadrao.id}&dataCadastroInicial=${
          dateState.dataDe
        }&dataCadastroFinal=${dateState.dataAte}&dataPagamentoInicial=${
          dateState.dataPagDe
        }&dataPagamentoFinal=${
          dateState.dataPagAte
        }&status=${situacao}&nomeFavorecido=${nomeFavorecido}&documentoFavorecido=${docBeneficiario.replace(
          /[^\d]/g,
          '',
        )}&modalidade=TED`,
      )
      .then(response => {
        dispatch(showLoading(false));
        setLoadingTable(false);
        const qtdItems = response.data.totalElements;
        const formattedData = response.data.content.map(dados => ({
          id: uuidv4(),
          documento:
            dados.favorecido != null
              ? maskCpfCnpj(dados.favorecido?.cnpjCpf)
              : 'Sem informações',
          nome:
            dados.favorecido != null
              ? dados.favorecido.nome
              : 'Sem informações',
          situacao: dados.status != null ? dados.status : 'Sem informações',
          dataAgendamento1: dados.dataAgendamento,
          dataCadastros1: moment(dados.dataCadastro).format('DD/MM/YYYY'),
          agencia:
            dados.clienteConta != null
              ? dados.clienteConta.agencia
              : 'Sem informações',
          banco:
            dados.clienteConta != null
              ? dados.clienteConta.banco.nome
              : 'Sem informações',
          valor: dados.valor.toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          }),
          ...dados,
        }));
        setRowCount(qtdItems);
        setRowsTable(formattedData);
      })
      .catch(err => {
        dispatch(showLoading(false));
        setTextNoRows(
          err.response.status === 404
            ? 'Nenhuma transferência encontrada'
            : 'Nenhuma linha',
        );
        setLoadingTable(false);
        setRowsTable([]);
        setRowCount(0);
        console.log(err);
      });
  };

  const changeCheckboxAll = async checked => {
    setCheckAll(checked);
    setCarregandoDadosNoPaged(true);
    if (checked) {
      const { data } = await api_multipague_transacao
        .get(
          `/transferencia?page=${rowsState.page}&size=${
            rowsState.pageSize
          }&clienteConta=${contaPadrao.id}&dataCadastroInicial=${
            dateState.dataDe
          }&dataCadastroFinal=${dateState.dataAte}&dataPagamentoInicial=${
            dateState.dataPagDe
          }&dataPagamentoFinal=${
            dateState.dataPagAte
          }&status=REJEITADO&nomeFavorecido=${nomeFavorecido}&documentoFavorecido=${docBeneficiario.replace(
            /[^\d]/g,
            '',
          )}&modalidade=TED`,
        )
        .catch(error => {
          toastComponentError(error?.response?.data?.mensagem);
          setCarregandoDadosNoPaged(false);
          setTextNoRows(
            error?.response?.status === 404
              ? 'Nenhuma transferência encontrada'
              : 'Nenhuma linha',
          );
        });
      dispatch(showLoading(false));
      setLoadingTable(false);
      const qtdItems = data.totalElements;
      const formattedData = data.map(dados => ({
        id: uuidv4(),
        documento:
          dados.favorecido != null
            ? maskCpfCnpj(dados.favorecido?.cnpjCpf)
            : 'Sem informações',
        nome:
          dados.favorecido != null ? dados.favorecido.nome : 'Sem informações',
        situacao: dados.status != null ? dados.status : 'Sem informações',
        dataAgendamento1: dados.dataAgendamento,
        dataCadastros1: moment(dados.dataCadastro).format('DD/MM/YYYY'),
        agencia:
          dados.clienteConta != null
            ? dados.clienteConta.agencia
            : 'Sem informações',
        banco:
          dados.clienteConta != null
            ? dados.clienteConta.banco.nome
            : 'Sem informações',
        valor: dados.valor.toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        }),
        ...dados,
      }));
      setRowCount(qtdItems);
      setRowsTable(formattedData);
      setCarregandoDadosNoPaged(false);
      setSelectionModel(() => formattedData.map(e => e.id));
    } else {
      setCarregandoDadosNoPaged(false);
      setSelectionModel([]);
      setLoadingTable(false);
      getTeds();
    }
  };

  useEffect(() => {
    getTeds();
  }, [contaPadrao, rowsState]);

  useEffect(() => {
    if (!!formInfo) {
      setNomeFavorecido(formInfo.nomeFavorecido);
      setDocBeneficiario(formInfo.docBeneficiario);
      setDateState({
        dataDe: formInfo.dataDe,
        dataAte: formInfo.dataAte,
        dataPagDe: formInfo.dataPagDe,
        dataPagAte: formInfo.dataPagAte,
      });
      setSituacao(formInfo.situacao ? formInfo.situacao : '');
      setSelectedSituacao(
        formInfo.situacao
          ? {
              id: formInfo.situacao,
              descricao: selecionarSituacao.find(
                item => item.id === formInfo.situacao,
              )?.descricao,
            }
          : '',
      );
    }
  }, [formInfo]);

  const handlePageSize = pageSize => {
    setRowsState({ page: 0, pageSize: pageSize });
  };

  const handleCancel = value => {
    setRowId(value.id);
    const idTransferencia = value.id;
    const cancelCliente = value?.clienteConta?.cliente?.id;
    setLoadingCancel(true);
    api_multipague_transacao
      .put(
        `/transferencia/${idTransferencia}/cancelar?cliente=${cancelCliente}`,
      )
      .then(response => {
        toastComponentSuccess('Evento cancelado com sucesso!');
        setLoadingCancel(false);
        setRowsState({ page: rowsState.page, pageSize: rowsState.pageSize });
      })
      .catch(error => {
        setLoadingCancel(false);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 409 ||
          error.response.status == 500
        ) {
          toastComponentError(error.response.data.mensagem);
        }
      });
  };

  const handleHistorico = idTransacao => {
    setRowId(idTransacao);
    setLoadingHistorico(true);
    let responseData = null;

    console.log('teste id: ', idTransacao);

    api_multipague_esteira
      .get(`/administracao/transferencia?codigoTransferencia=${idTransacao}`)
      .then(response => {
        responseData = response.data;
        setLoadingHistorico(false);
      })
      .catch(err => {
        setLoadingHistorico(false);
        toastComponentError(err.response?.data?.mensagem);
      })
      .finally(() => {
        history.push('/historico-teds', {
          historic: responseData,
          idTransacao,
          formInfo: {
            nomeFavorecido: nomeFavorecido,
            docBeneficiario: docBeneficiario,
            situacao: situacao,
            dataDe: dateState.dataDe,
            dataAte: dateState.dataAte,
            dataPagDe: dateState.dataPagDe,
            dataPagAte: dateState.dataPagAte,
          },
        });
      });
  };

  const onPage = page => {
    setRowsState(prev => ({ ...prev, page }));
  };

  function handleOpen() {
    setOpen(true);
    api_multipague_conta
      .post(`/token/send-token/${contaPadrao.id}`)
      .then(response => {
        setOpen(true);
      })
      .catch(err => {
        console.log(err);
      });
  }

  async function submitTed(senha) {
    setLoadingButtonReprocessa(true);
    dispatch(showPopUp(false));

    const { status } = await api_multipague_transacao
      .post('/transferencia/reprocessar-rejeitados', selectionModel, {
        headers: {
          ContentType: 'application/json',
          IdempotencyKey: uuidv4(),
          'x-assinatura-financeira': senha,
          token: valorSms,
        },
      })
      .then(response => {
        if (response.status === 202) {
          setLoadingButtonReprocessa(false);
          toastComponentSuccess('Transferências encaminhadas para análise');
        }
      })
      .catch(err => {
        setLoadingButtonReprocessa(false);
        toastComponentError(err.response.data.mensagem);
      });
  }

  const submitData = () => {
    if (selectionModel.length > 0) {
      if (!permissionAdmin) {
        submitTed('');
      } else if (
        permissionAdmin &&
        infoRedux.contaPadrao?.tokenPorTransacao == true
      ) {
        handleOpen();
      } else {
        const jwtToken = jwt_decode(localStorage.getItem('tkn-access'));
        const contaId = localStorage.getItem('idConta');

        if (jwtToken?.permissaoContas) {
          const conta = jwtToken?.permissaoContas?.find(item =>
            item.contas.find(conta => conta.id == contaId),
          );

          if (conta?.role === 'correntista_operador') {
            submitTed('');
          } else {
            dispatch(showPopUp(true));
          }
        }
      }
    } else {
      toastComponentError('Nenhuma transferência selecionada!');
    }
  };

  function enviarSms() {
    setOpen(false);
    dispatch(showPopUp(true));
  }

  return (
    <>
      <PopUp>
        <Password callback={data => submitTed(data)} />
      </PopUp>
      <DialogModal
        open={open}
        onClose={() => setOpen(false)}
        dialogContent={
          <SmsToken
            id="outlined-basic"
            variant="outlined"
            placeholder="******"
            size="small"
            type="password"
            name="smsToken"
            inputProps={{ maxLength: 6 }}
            onChange={e => setValorSms(e.target.value)}
          />
        }
        dialogTitle={'Insira o token que você recebeu via SMS'}
        dialogActions={
          <div style={{ margin: 'auto' }}>
            <Buttons
              color="primary"
              width={150}
              title={'Prosseguir'}
              style={{ marginTop: '20px', marginBottom: '10px' }}
              onClick={() => enviarSms()}
              startIcon={
                <MdMonetizationOn color={useTheme().palette.background.paper} />
              }
            />
          </div>
        }
      />
      <DataGridPage
        headerComponent={() => {
          return (
            <Grid container>
              <Grid
                item
                md={12}
                style={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <Chip
                  color="primary"
                  disabled={loadingButtonReprocessa}
                  variant="outlined"
                  style={{ cursor: 'pointer' }}
                  label={
                    loadingButtonReprocessa ? (
                      <div style={{ margin: '0 30px' }}>
                        <CircularProgress size={18} style={{ marginTop: 5 }} />
                      </div>
                    ) : (
                      'Reenviar TED'
                    )
                  }
                  onClick={() => submitData()}
                />
              </Grid>
            </Grid>
          );
        }}
        title="Lista de TED's"
        crumb={[
          { name: 'Dashboard', link: '/dashboard' },
          { name: 'Transferência' },
        ]}
        newRegister={() => history.push('/Transferencia')}
        formData={filterInputs}
        rows={rowsTable}
        key={rowsTable.key}
        rowCount={rowCount}
        setDatagrid={setRowsState}
        columns={columnsTable}
        loading={loadingTable}
        minHeight={'600px'}
        selectionModel={selectionModel}
        pagination
        checkboxSelection
        onSelectionModelChange={newSelection => {
          const ids = Object.values(newSelection);
          const newIds = ids.filter(id => !selectionModel.includes(id));
          setSelectionModel(old => [...old, ...newIds]);
        }}
        onRowClick={params => {
          setRowId(params.id);
          if (params.row.status !== 'REJEITADO') {
            setTimeout(() => {
              setSelectionModel(old => old.filter(item => item !== params.id));
            }, 1);
          }
        }}
        {...rowsState}
        paginationMode="server"
        onPageChange={onPage}
        componentsProps={{
          pagination: { classes: null },
        }}
        textNoRows={textNoRows}
        rowsPerPageOptions={[10, 20, 50]}
        onPageSizeChange={newPageSize => handlePageSize(newPageSize)}
      />
    </>
  );
};

export default ListaTransferencia;
