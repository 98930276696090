import React, { createContext, useState, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as RetornoRemessaService from '../services/RetornoRemessaService';
import { toastComponentWarn } from '../components/Toast';

const RetornoRemessaContext = createContext();

export default function RetornoRemessaProvider({ children }) {
  const [dataInicial, setDataInicial] = useState();
  const [dataFinal, setDataFinal] = useState();
  const [loading, setLoading] = useState(true);
  const [rowsTable, setRowsTable] = useState([]);
  const { contaPadrao } = useSelector(state => state.contas);
  const [rowCount, setRowCount] = useState();
  const [textNoRows, setTextNoRows] = useState();
  const [rowsState, setRowsState] = React.useState({
    page: 0,
    pageSize: 10,
  });

  const handlePageSize = newPageSize => {
    setRowsState({ page: 0, pageSize: newPageSize });
  };

  const clearFilter = () => {
    setDataInicial('');
    setDataFinal('');
    setRowsState(prev => ({ ...prev, page: 0 }));
    getRetornos({}, rowsState.page, rowsState.pageSize);
  };

  const submitFilter = async () => {
    if (dataInicial && dataFinal) {
    setRowsState(prev => ({ ...prev, page: 0 }));
    setRowsTable([]);
    getRetornos({ dataInicial, dataFinal }, 0, rowsState.pageSize);
  } else {
      toastComponentWarn('Data inicial e data final devem ser preenchidas!');
    }
  };

  const onPage = page => {
    setRowsState(prev => ({ ...prev, page }));
    if (dataInicial && dataFinal) {
      getRetornos({ dataInicial, dataFinal }, page, rowsState.pageSize);
    } else {
      getRetornos({}, page, rowsState.pageSize);
    }
  };

  useEffect(() => {
    if (dataInicial && dataFinal) {
      setRowsTable([]);
      getRetornos({ dataInicial, dataFinal }, 0, rowsState.pageSize);
    } else {
      getRetornos();
    }
  }, [contaPadrao]);

  useEffect(() => {
    setRowsState(prev => ({ ...prev, page: 0 }));
    getRetornos({ dataInicial, dataFinal }, 0, rowsState.pageSize);
  }, [rowsState.pageSize]);

  const getRetornos = async (params = {}, page = 0,  pageSize = 10) => {
    if (contaPadrao.id) {
      try {
        setLoading(true);
        const formated = await RetornoRemessaService.findAll({
          ...params,
          clienteConta: contaPadrao.id,
          page: page,
          size: pageSize,
        });
        setRowsTable(formated.formated);
        setLoading(false);
        setRowCount(formated?.dataLength);
        const textError =
          formated.error && formated.error.response.status === 404
            ? 'Nenhum arquivo encontrado'
            : '';
        setTextNoRows(textError);
      } catch {
        setRowCount(0);
        setRowsTable([]);
        setLoading(false);
      }
    }
  };

  return (
    <RetornoRemessaContext.Provider
      value={{
        rowsTable,
        dataInicial,
        setDataInicial,
        dataFinal,
        setDataFinal,
        handlePageSize,
        loading,
        setLoading,
        clearFilter,
        submitFilter,
        getRetornos,
        contaPadrao,
        rowCount,
        rowsState,
        onPage,
        setRowsState,
        textNoRows,
      }}
    >
      {children}
    </RetornoRemessaContext.Provider>
  );
}

export function useRetornoRemessa() {
  const context = useContext(RetornoRemessaContext);
  return context;
}
