import React, { useState, useEffect, useRef } from 'react';
import InputText from '../../components/InputText';
import * as Yup from 'yup';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import getValidationErros from '../../util/FormattedError';
import DateComponent from '../InputDate';
import Button from '../Buttons/ConfirmButton';
import SelectComponente from '../../components/Select';
import { api_multipague_configuracao } from '../../services/api';
import InputWithMask from '../Inputs/MaskPersonalizavel';
import { InputMoney } from '../../components/Inputs/Money';

import {
  Container,
  Subcontainer,
  InstrucoesContainer,
  ContainerOpcoes,
  ContainerInputsOptions,
  Label,
  Options,
  TituloContainer,
  ContainerButton,
  ContainerMensagens,
  ContainerIsEditing,
} from './styles';
import { setDigitacaoInstrucoesBoletoSuccess } from '../../store/modules/digitacaoBoleto/actions';
import { listarMotivoRequest } from '../../store/modules/motivo/actions';
import { listarInstrucoesRequest } from '../../store/modules/instrucao/actions';

import { MdNavigateBefore, MdSave } from 'react-icons/md';
import { useTheme } from '@material-ui/core';
import { toastComponentError } from '../Toast';

const Intrucoes = ({ selecteAba, sendSubmitForm, isEditing, isSalve }) => {
  const theme = useTheme();
  const savedData = JSON.parse(localStorage.getItem('data_instrucao'));
  const infos_dados_boleto = JSON.parse(
    localStorage.getItem('data_pagamento_boleto'),
  );
  const dispatch = useDispatch();
  const [isBack, setIsBack] = useState(false);
  const formRef = useRef(null);
  const instrucoesRedux = useSelector(state => state.instrucoes);
  const motivos = useSelector(state => state.motivos);
  const [disabledValueJuros, setDisabledValueJuros] = useState(false);
  const [disabledMultaJuros, setDisabledMultaJuros] = useState(false);
  const [disabledDescontoJuros, setDisabledDescontoJuros] = useState(false);
  const [isPercentualValueJuros, setIsPercentualValueJuros] = useState(false);
  const [isPercentualMultaJuros, setIsPercentualMultaJuros] = useState(false);
  const [isPercentualDescontoJuros, setIsPercentualDescontoJuros] =
    useState(false);

  // ============ Valores

  const [valorJuros, setValorJuros] = useState({
    value: !!savedData?.valor_juros ? savedData.valor_juros : 0,
    error: '',
    disabled: false,
  });
  const [valorMulta, setValorMulta] = useState({
    value: !!savedData?.valor_multa ? savedData.valor_multa : 0,
    error: '',
    disabled: false,
  });
  const [valorDesconto, setValorDesconto] = useState({
    value: !!savedData?.valor_desconto ? savedData.valor_desconto : 0,
    error: '',
    disabled: false,
  });

  // ============ Valores

  const [dataJuros, setDataJuros] = useState(
    isEditing
      ? savedData?.dataJuros
        ? moment(savedData?.dataJuros, 'YYYY-MM-DD').format('YYYY-MM-DD')
        : moment(infos_dados_boleto?.dataVencimento)
            .add(1, 'days')
            .format('YYYY-MM-DD')
      : moment(infos_dados_boleto?.dataVencimento)
          .add(1, 'days')
          .format('YYYY-MM-DD'),
  );
  const [dataMulta, setDataMulta] = useState(
    isEditing
      ? savedData?.dataMulta
        ? moment(savedData?.dataMulta, 'YYYY-MM-DD').format('YYYY-MM-DD')
        : moment(infos_dados_boleto?.dataVencimento)
            .add(1, 'days')
            .format('YYYY-MM-DD')
      : moment(infos_dados_boleto?.dataVencimento)
          .add(1, 'days')
          .format('YYYY-MM-DD'),
  );
  const [dataDesconto, setDataDesconto] = useState(
    isEditing
      ? savedData?.dataDesconto != 0 &&
        savedData?.dataDesconto != 'Data inválida'
        ? moment(savedData?.dataDesconto, 'YYYY-MM-DD').format('YYYY-MM-DD')
        : moment(infos_dados_boleto?.dataVencimento)
            .subtract(1, 'days')
            .format('YYYY-MM-DD')
      : moment(infos_dados_boleto?.dataVencimento)
          .subtract(1, 'days')
          .format('YYYY-MM-DD'),
  );

  async function ListarInstrucoesBoleto() {
    try {
      const { data } = await api_multipague_configuracao.get(
        `/instrucao-cobranca-boleto`,
      );
      console.log('============> configuracao Data', data);

      const juros = data.filter(
        j =>
          j.valor === 'VALOR_JUROS' ||
          j.valor === 'PERCENTUAL_JUROS_DIA' ||
          j.valor === 'PERCENTUAL_JUROS_MES' ||
          j.valor === 'ISENTO',
      );
      const multa = data.filter(
        j =>
          j.valor === 'VALOR_MULTA' ||
          j.valor === 'PERCENTUAL_MULTA' ||
          j.valor === 'ISENTO',
      );
      const desconto = data.filter(
        j => j.valor === 'VALOR_DESCONTO' || j.valor === 'ISENTO',
      );

      setTipoCalculoListJuros(
        juros.map(j => ({
          ...j,
          label: j.descricao,
          value: j.valor,
        })),
      );
      setTipoCalculoListMulta(
        multa.map(m => ({
          ...m,
          label: m.descricao,
          value: m.valor,
        })),
      );
      setTipoDesconto(
        desconto.map(desconto => ({
          ...desconto,
          label: desconto.descricao,
          value: desconto.valor,
        })),
      );
    } catch (err) {
      toastComponentError(
        ` Codigo: ${err?.response.data.codigo} - ${err?.response.data.mensagem}`,
      );
    }
  }

  useEffect(() => {
    callBackJuros(savedData?.tipo_calculo_juros);
    callBackMulta(savedData?.tipo_calculo_multa);
    callBackDesconto(savedData?.tipo_calculo_desconto);
    ListarInstrucoesBoleto();
  }, []);

  const [tipoCalculoListJuros, setTipoCalculoListJuros] = useState([]);

  const [tipoCalculoListMulta, setTipoCalculoListMulta] = useState([]);

  const [tipoDesconto, setTipoDesconto] = useState([]);

  async function submitForm(data) {
    if (isBack) {
      isSalve(false);
      data.dataJuros = dataJuros;
      data.dataMulta = dataMulta;
      data.dataDesconto = dataDesconto;
      data.dataJurosFormatted = moment(dataJuros).format('YYYY-MM-DD');
      data.dataMultaFormatted = moment(dataMulta).format('YYYY-MM-DD');
      data.dataDescontoFormatted =
        moment(dataDesconto).format('YYYY-MM-DD') === 'Invalid date'
          ? null
          : moment(dataDesconto).format('YYYY-MM-DD');
      data.valor_juros = valorJuros.value;
      data.valor_multa = valorMulta.value;
      data.valor_desconto = valorDesconto.value;
      data.id_multa = isEditing ? savedData.id_multa : null;
      data.id_desconto = isEditing ? savedData.id_desconto : null;
      data.id_juros = isEditing ? savedData.id_juros : null;
      localStorage.setItem('data_instrucao', JSON.stringify(data));
      selecteAba(2);
    } else {
      isSalve(true);
      try {

        const verificaTipoDesconto =
          data.tipo_calculo_desconto === 'PERCENTUAL_DESCONTO';
        const verificaTipoJuros =
          data.tipo_calculo_juros === 'PERCENTUAL_JUROS_DIA' ||
          data.tipo_calculo_juros === 'PERCENTUAL_JUROS_MES';
        const verificaTipoMulta =
          data.tipo_calculo_multa === 'PERCENTUAL_MULTA';

        if (verificaTipoDesconto) {
          if (Number(data.valor_desconto) > 0) {
            if (Number(data.valor_desconto) > 100) {
              formRef.current?.setErrors({
                valor_desconto: 'Valor percentual não pode ser maior que 100',
              });
              return;
            }
          }
        } else {
          if (data.tipo_calculo_desconto != 'ISENTO') {
            if (valorDesconto.value < 0) {
              setValorDesconto({
                ...valorDesconto,
                error: 'Valor deve ser maior que zero',
              });
              return;
            }
          }
        }

        console.log('antes do verifica tipo juros');
        if (verificaTipoJuros) {
          if (Number(data.valor_juros) > 0) {
            if (Number(data.valor_juros) > 100) {
              formRef.current?.setErrors({
                valor_juros: 'Valor percentual não pode ser maior que 100',
              });
              return;
            }
          }
        } else {
          if (data.tipo_calculo_juros != 'ISENTO') {
            if (valorJuros.value < 0) {
              setValorJuros({
                ...valorJuros,
                error: 'Valor deve ser maior que zero',
              });
              return;
            }
          }
        }

        console.log('antes do verifica verificaTipoMulta');

        if (verificaTipoMulta) {
          if (Number(data.valor_multa) > 0) {
            if (Number(data.valor_multa) > 100) {
              formRef.current?.setErrors({
                valor_multa: 'Valor percentual não pode ser maior que 100',
              });
              return;
            }
          }
        } else {
          if (data.tipo_calculo_multa != 'ISENTO') {
            if (valorMulta.value < 0) {
              setValorMulta({
                ...valorMulta,
                error: 'Valor deve ser maior que zero',
              });
              return;
            }
          }
        }

        if (
          !disabledValueJuros &&
          !moment(infos_dados_boleto?.dataVencimento).isBefore(
            moment(dataJuros).format('YYYY-MM-DD'),
          )
        ) {
          toastComponentError(`Data juros deve ser maior que a data vencimento`);
          return;
        }
        if (
          !disabledMultaJuros &&
          !moment(infos_dados_boleto?.dataVencimento).isBefore(
            moment(dataMulta).format('YYYY-MM-DD'),
          )
        ) {
          toastComponentError(`Data multa deve ser maior que a data vencimento`);
          return;
        }

        if (isEditing && !data.motivo) {
          formRef.current?.setErrors({
            motivo: 'Selecione o motivo!',
          });
          return;
        }

        console.log('antes da normalização dos dados');

        data.dataJuros = moment(dataJuros).format('YYYY-MM-DD');
        data.dataMulta = moment(dataMulta).format('YYYY-MM-DD');
        data.dataDesconto = moment(dataMulta).format('YYYY-MM-DD');
        data.dataJurosFormatted = moment(dataJuros).format('YYYY-MM-DD');
        data.dataMultaFormatted = moment(dataMulta).format('YYYY-MM-DD');
        data.dataDescontoFormatted =
          moment(dataDesconto).format('YYYY-MM-DD') === 'Invalid date'
            ? null
            : moment(dataDesconto).format('YYYY-MM-DD');
        data.valor_juros = valorJuros.value;
        data.valor_multa = valorMulta.value;
        data.valor_desconto = valorDesconto.value;
        data.id_multa = isEditing ? savedData.id_multa : null;
        data.id_desconto = isEditing ? savedData.id_desconto : null;
        data.id_juros = isEditing ? savedData.id_juros : null;
        dispatch(setDigitacaoInstrucoesBoletoSuccess(data));
        localStorage.setItem('data_instrucao', JSON.stringify(data));

        console.log('chama o form com o data', data);
        sendSubmitForm();
      } catch (err) {
        console.log('Erro form boleto digitado ', err);
        if (err instanceof Yup.ValidationError) {
          console.log(err.inner);
          const erros = getValidationErros(err);
          formRef.current?.setErrors(erros);
        }
      }
    }
  }

  const [motivoCancelamento, setMotivoCancelamento] = useState([]);
  const [instrucoesFormatted, setInstrucoesFormatted] = useState([]);

  useEffect(() => {
    if (isEditing) {
      dispatch(listarMotivoRequest('BAIXAR_BOLETO'));
      dispatch(listarInstrucoesRequest());
    }
  }, [isEditing]);

  useEffect(() => {
    if (instrucoesRedux.length) {
      const formatted = instrucoesRedux.map(i => ({
        label: i.descricao,
        value: i.id,
        ...i,
      }));

      setInstrucoesFormatted(formatted);
    }
  }, [instrucoesRedux]);

  useEffect(() => {
    const formatted = motivos.map(m => ({
      label: m.descricao,
      value: m.id,
      ...m,
    }));

    setMotivoCancelamento(formatted);
  }, [motivos]);

  function callBackJuros(value) {
    if (!value) {
      return;
    }

    if (value === 'ISENTO') {
      setDisabledValueJuros(true);
    } else {
      setDisabledValueJuros(false);
      setIsPercentualValueJuros(false);
    }

  }
  function callBackMulta(value) {
    if (!value) {
      return;
    }

    if (value === 'ISENTO') {
      setDisabledMultaJuros(true);
    } else {
      setDisabledMultaJuros(false);
      setIsPercentualMultaJuros(false);
    }

  }

  function callBackDesconto(value) {
    if (!value) {
      return;
    }
    if (value === 'ISENTO') {
      setDisabledDescontoJuros(true);
    } else {
      setDisabledDescontoJuros(false);
      setIsPercentualDescontoJuros(false);
    }

  }

  useEffect(() => {
    if (isBack) {
      console.log(formRef);
      formRef.current.submitForm();
    }
  }, [isBack]);

  useEffect(() => {
    if (disabledValueJuros) {
      setDataJuros('');
      setValorJuros({
        ...valorJuros,
        disabled: true,
      });
    } else {
      setDataJuros(
        isEditing
          ? savedData?.dataJuros
            ? moment(savedData?.dataJuros, 'YYYY-MM-DD').format('YYYY-MM-DD')
            : moment(infos_dados_boleto?.dataVencimento)
                .add(1, 'days')
                .format('YYYY-MM-DD')
          : moment(infos_dados_boleto?.dataVencimento)
              .add(1, 'days')
              .format('YYYY-MM-DD'),
      );
      setValorJuros({
        ...valorJuros,
        disabled: false,
      });
    }
  }, [disabledValueJuros]);

  useEffect(() => {
    if (disabledMultaJuros) {
      setDataMulta('');
      setValorMulta({
        ...valorMulta,
        disabled: true,
      });
    } else {
      setDataMulta(
        isEditing
          ? savedData?.dataMulta
            ? moment(savedData?.dataMulta, 'YYYY-MM-DD').format('YYYY-MM-DD')
            : moment(infos_dados_boleto?.dataVencimento)
                .add(1, 'days')
                .format('YYYY-MM-DD')
          : moment(infos_dados_boleto?.dataVencimento)
              .add(1, 'days')
              .format('YYYY-MM-DD'),
      );
      setValorMulta({
        ...valorMulta,
        disabled: false,
      });
    }
  }, [disabledMultaJuros]);
  useEffect(() => {
    if (disabledDescontoJuros) {
      setDataDesconto('');
      setValorDesconto({
        ...valorDesconto,
        disabled: true,
      });
    } else {
      setDataDesconto(
        isEditing
          ? savedData?.dataDesconto != 0 &&
            savedData?.dataDesconto != 'Data inválida'
            ? moment(savedData?.dataDesconto, 'YYYY-MM-DD').format('YYYY-MM-DD')
            : moment(infos_dados_boleto?.dataVencimento)
                .subtract(1, 'days')
                .format('YYYY-MM-DD')
          : moment(infos_dados_boleto?.dataVencimento)
              .subtract(1, 'days')
              .format('YYYY-MM-DD'),
      );
      setValorDesconto({
        ...valorDesconto,
        disabled: false,
      });
    }
  }, [disabledDescontoJuros]);

  return (
    <Container ref={formRef} initialData={savedData} onSubmit={submitForm}>
      <Subcontainer>
        <ContainerMensagens>
          <InputText
            id="mensagem1"
            name="mensagem1"
            label="Mensagem 1 (12 Caracteres)"
            variant="outlined"
            inputProps={{ maxLength: 12 }}
            required
          />
          <InputText
            id="mensagem2"
            name="mensagem2"
            label="Mensagem 2 (30 Caracteres)"
            variant="outlined"
            error={savedData?.mensagem2?.length > 30}
            inputProps={{ maxLength: 30 }}
            required
          />
        </ContainerMensagens>

        <ContainerOpcoes>
          <Options>
            <TituloContainer>
              <Label
                style={{
                  color: theme.palette.appBar[theme.palette.type].bgMenu,
                }}
              >
                Juros
              </Label>
            </TituloContainer>
            <ContainerInputsOptions>
              <DateComponent
                callback={setDataJuros}
                id={`Juros-id`}
                label="Data"
                value={dataJuros}
                defaultValue={savedData?.dataJuros}
                disabled={disabledValueJuros}
              />
              <SelectComponente
                id="tipo_calculo_juros"
                name="tipo_calculo_juros"
                label="Tipo de Cálculo"
                itens={tipoCalculoListJuros}
                callback={callBackJuros}
                required
              />

              {isPercentualValueJuros ? (
                <InputWithMask
                  id="valor_juros"
                  name="valor_juros"
                  label="Valor"
                  variant="outlined"
                  mask={'999'}
                  icon="%"
                  hasIcon={true}
                  disabled={disabledValueJuros}
                  required={!disabledValueJuros}
                />
              ) : (
                <InputMoney
                  value={valorJuros.value}
                  onChange={val =>
                    setValorJuros({
                      ...valorJuros,
                      value: val,
                      error: '',
                    })
                  }
                  error={valorJuros.error}
                  disabled={valorJuros.disabled}
                  label="Valor"
                />
              )}
            </ContainerInputsOptions>
          </Options>

          <Options>
            <TituloContainer>
              <Label>Multa</Label>
            </TituloContainer>
            <ContainerInputsOptions>
              <DateComponent
                callback={setDataMulta}
                id={`Multa-id`}
                label="Data"
                value={dataMulta}
                defaultValue={savedData?.dataMulta}
                disabled={disabledMultaJuros}
              />
              <SelectComponente
                id="tipo_calculo_multa"
                name="tipo_calculo_multa"
                label="Tipo de Cálculo"
                itens={tipoCalculoListMulta}
                callback={callBackMulta}
                required={true}
              />

              {isPercentualMultaJuros ? (
                <InputWithMask
                  id="valor_multa"
                  name="valor_multa"
                  label="Valor"
                  variant="outlined"
                  mask={'999'}
                  icon="%"
                  hasIcon={true}
                  disabled={disabledMultaJuros}
                  required={!disabledMultaJuros}
                />
              ) : (
                <InputMoney
                  value={valorMulta.value}
                  onChange={val =>
                    setValorMulta({
                      ...valorMulta,
                      value: val,
                      error: '',
                    })
                  }
                  error={valorMulta.error}
                  disabled={valorMulta.disabled}
                  label="Valor"
                />
              )}
            </ContainerInputsOptions>
          </Options>

          <Options>
            <TituloContainer>
              <Label>Desconto</Label>
            </TituloContainer>
            <ContainerInputsOptions>
              <DateComponent
                callback={setDataDesconto}
                id={`Desconto-id`}
                label="Data"
                value={dataDesconto === 'Data inválida' ? '' : dataDesconto}
                defaultValue={
                  savedData?.dataDesconto === 'Data inválida'
                    ? ''
                    : dataDesconto
                }
                disabled={disabledDescontoJuros}
              />
              <SelectComponente
                id="tipo_calculo_desconto"
                name="tipo_calculo_desconto"
                label="Tipo de Desconto"
                itens={tipoDesconto}
                callback={callBackDesconto}
                required
              />

              {isPercentualDescontoJuros ? (
                <InputWithMask
                  id="valor_desconto"
                  name="valor_desconto"
                  label="Valor"
                  variant="outlined"
                  mask={'999'}
                  icon="%"
                  hasIcon={true}
                  disabled={disabledDescontoJuros}
                  required={!disabledDescontoJuros}
                />
              ) : (
                <InputMoney
                  value={valorDesconto.value}
                  onChange={val =>
                    setValorDesconto({
                      ...valorDesconto,
                      value: val,
                      error: '',
                    })
                  }
                  error={valorDesconto.error}
                  disabled={valorDesconto.disabled}
                  label="Valor"
                />
              )}
            </ContainerInputsOptions>
          </Options>
        </ContainerOpcoes>
        {isEditing && (
          <ContainerIsEditing>
            <SelectComponente
              id="instrucao"
              name="instrucao"
              value={1}
              itens={instrucoesFormatted}
              label="Instrução"
            />
            <SelectComponente
              id="motivo"
              name="motivo"
              itens={motivoCancelamento}
              label="Motivo"
            />
          </ContainerIsEditing>
        )}
        <InstrucoesContainer>
          <InputText
            id="observacao"
            name="observacao"
            label="Observação"
            required
            multiline
            rows={4}
            variant="outlined"
          />
        </InstrucoesContainer>
        <ContainerButton>
          <Button
            color="default"
            variant="contained"
            disabled={false}
            width={100}
            title={'Voltar'}
            onClick={() => setIsBack(true)}
            type="button"
            startIcon={
              <MdNavigateBefore color={useTheme().palette.background.paper} />
            }
          ></Button>
          <Button
            color="primary"
            variant="contained"
            disabled={false}
            width={100}
            type="submit"
            title={'Salvar'}
            startIcon={<MdSave color={useTheme().palette.background.paper} />}
          ></Button>
        </ContainerButton>
      </Subcontainer>
    </Container>
  );
};

export default Intrucoes;
