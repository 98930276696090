import React from 'react';
import { makeStyles, useTheme, alpha } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    height: 310,
    display: 'flex',
    borderRadius: '15px',
    flexDirection: 'column',
    justifyContent: 'space-between',
    boxShadow: `${alpha(theme.palette.primary.dark, 0.2)} 0px 0px 45px -15px`,
  },
  button: {
    marginBottom: theme.spacing(2),
    width: '80%',
    margin: '0 10%',
    fontWeigth: '500',
    fontSize: '14px',
    textTransform: 'none',
    color: theme.palette.getContrastText(theme.palette.text.primary),
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '10px',
    padding: '0.6rem',
  },
  actions: {
    marginTop: '0',
  },
}));

export default function RecipeReviewCard() {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <Card className={classes.root} elevation={3}>
      <CardHeader
        title={
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <ImportExportIcon
              size={50}
              style={{
                marginRight: 5,
                color: theme.palette.primary.main,
              }}
            />
            <Typography
              variant="inherit"
              style={{
                color: theme.palette.primary.main,
                fontSize: '1.2rem',
              }}
              component="h2"
            >
              Transferências
            </Typography>
          </div>
        }
      />

      <CardContent>
        <Link to="/Cadastro/Favorecidos">
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            startIcon={<PersonAddIcon />}
          >
            Adicionar Favorecido
          </Button>
        </Link>
        <Link to="/Transferencia">
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            startIcon={<ExitToAppIcon />}
          >
            Nova Transferência
          </Button>
        </Link>
      </CardContent>
      <CardActions className={classes.actions}></CardActions>
    </Card>
  );
}
