import React from 'react';
import { DataGrid } from '@material-ui/data-grid';
import { ptBR } from './../locale';
import { MdSearch, MdCleaningServices, MdAutorenew } from 'react-icons/md';
import { useStylesDatagrid, Container, CustomNoRowsOverlay } from './styles';
import {
  Grid,
  IconButton,
  Paper,
  alpha,
  Tooltip,
  useTheme,
} from '@material-ui/core';
import Wrapper from '../../Wrapper';
import { ExcelGrid } from './ExcelGrid';

export const DataGridPage = ({
  URL,
  headerComponent,
  rows,
  columns,
  crumbActive,
  minHeight,
  pageSize,
  loading,
  height,
  title,
  selection,
  paginationMode,
  newRegister,
  arrowBack,
  goBack,
  textNoRows,
  setDatagrid,
  onPageChangeFunction,
  onPageSizeChangeFunction,
  children,
  formData = {
    inputsData: [],
    searchButton: { ...rest, searchButton: Function },
    cleanButton: { ...rest, cleanButton: Function },
    updateButton: { ...rest, updateButton: Function },
  },
  crumb,
  handlePDFCSV = {
    downloadPDF: () => {},
    downloadExcel: () => {},
    rowsExcel: [],
    ExcelColumn: [],
    carregaExcel: false,
    carregaIconExcel: false,
    title: '',
  },
  rowsPerPageOptions,
  ...rest
}) => {
  const onPage = page => {
    setDatagrid(prev => ({ ...prev, page: page }));
  };

  const classes = useStylesDatagrid();
  const theme = useTheme();

  return (
    <>
      <Wrapper
        newRegister={newRegister}
        title={title}
        arrowBack
        goBack={goBack}
        crumbActive={crumbActive}
        crumb={crumb.map(item => item)}
      >
        {children}
        <Grid
          className={classes.formBackground}
          style={rest?.styleFormBackground}
          container
          spacing={2}
        >
          {formData.inputsData?.map(item => (
            <Grid className={classes.root} item {...item.grid}>
              {item.html &&
                item.html({
                  style: { height: '50px', borderRadius: '20px' },
                  ...item,
                })}
            </Grid>
          ))}
          {!!formData.inputsData.length > 0 && (
            <Grid item spacing={2} className={classes.gridLoadingButton}>
              <Tooltip title="Buscar">
                <IconButton
                  variant="contained"
                  color="primary"
                  className={classes.loadingButton}
                  isLoading={formData.searchButton.loadingButtonSearch}
                  onClick={formData.searchButton.searchButton}
                >
                  <MdSearch
                    color={theme.palette.getContrastText(
                      alpha(
                        theme.palette.appBar[theme.palette.type].bgMenu,
                        0.4,
                      ),
                    )}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title="Limpar">
                <IconButton
                  variant="contained"
                  color="primary"
                  className={classes.loadingButton}
                  onClick={formData.cleanButton.cleanButton}
                >
                  <MdCleaningServices
                    color={theme.palette.getContrastText(
                      alpha(
                        theme.palette.appBar[theme.palette.type].bgMenu,
                        0.4,
                      ),
                    )}
                  />
                </IconButton>
              </Tooltip>

              {!!formData?.updateButton && (
                <Tooltip title="Atualizar">
                  <IconButton
                    variant="contained"
                    color="primary"
                    className={classes.loadingButton}
                    onClick={formData.updateButton.updateButton}
                  >
                    <MdAutorenew
                      color={theme.palette.getContrastText(
                        alpha(
                          theme.palette.appBar[theme.palette.type].bgMenu,
                          0.4,
                        ),
                      )}
                    />
                  </IconButton>
                </Tooltip>
              )}
              {!!handlePDFCSV.title && (
                <ExcelGrid handlePDFCSV={handlePDFCSV} />
              )}
            </Grid>
          )}
        </Grid>
        <Container>
          <Paper
            className={classes.dataGridPaper}
            elevation={0}
            data-testid="container-datagrid"
          >
            {headerComponent && (
              <div className="headerButtons">
                {React.createElement(headerComponent)}
              </div>
            )}
            <div style={{ minHeight }}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={pageSize}
                loading={loading}
                {...rest}
                disableColumnMenu
                componentsProps={{
                  pagination: { classes: null },
                }}
                components={{
                  NoRowsOverlay: () => (
                    <CustomNoRowsOverlay textRow={textNoRows} />
                  ),
                }}
                paginationMode={paginationMode ? paginationMode : 'server'}
                pagination
                style={{ minHeight, boxShadow: 'none', position: 'relative' }}
                classes={{
                  headerCell: classes.headerBold,
                }}
                headerHeight={48}
                rowHeight={48}
                onPageChange={
                  !!onPageChangeFunction ? onPageChangeFunction : onPage
                }
                rowsPerPageOptions={
                  rowsPerPageOptions ? rowsPerPageOptions : [10, 20, 50]
                }
                onPageSizeChange={
                  !!onPageSizeChangeFunction
                    ? onPageSizeChangeFunction
                    : newPageSize =>
                        setDatagrid(prev => ({
                          ...prev,
                          page: 0,
                          pageSize: newPageSize,
                        }))
                }
                localeText={ptBR(textNoRows)}
              />
            </div>
          </Paper>
        </Container>
      </Wrapper>
    </>
  );
};
