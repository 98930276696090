import React from 'react';
import Modal from '../../../components/Modal';
import { Form } from '@unform/web';
import { Grid, Button, Box, useTheme } from '@material-ui/core';
import RadioButton from '../../../components/RadioButton';
import CPF from '../../../components/Inputs/CPF';
import CNPJ from '../../../components/Inputs/CNPJ';
import InputText from '../../../components/InputText';
import InputMask from '../../../components/Inputs/MaskPersonalizavel';
import { MdSearch, MdClear, MdSave } from 'react-icons/md';
import {
  hideLoading,
  showLoading,
} from '../../../store/modules/loading/actions';

import { api_multipague_cep } from '../../../services/api';
import { useDispatch } from 'react-redux';
import {
  cadastrarPagador,
  editaPagador,
} from '../../../services/PagadorService';
import ConfirmButton from '../../../components/Buttons/ConfirmButton';

export const ModalManager = ({ close, pagador = null, idCliente, refetch }) => {
  const formRef = React.useRef(null);
  const dispatch = useDispatch();
  const [initialData, setInitialData] = React.useState();

  const optionsTypePerson = [
    {
      label: 'Pessoa Física',
      value: 'PF',
      selected: true,
    },
    {
      label: 'Pessoa Jurídica',
      value: 'PJ',
      selected: false,
    },
  ];
  const [typePersonSelected, setTypePersonSelected] = React.useState('PF');

  const onSubmit = async data => {
    dispatch(showLoading(true));
    data = {
      ...data,
      documentoFederal: data.documentoFederal.replace(/\D/g, ''),
      cep: data.cep.replace(/\D/g, ''),
    };

    if (!!pagador) {
      await editaPagador(pagador.id, {
        ...data,
        tipoPessoa: typePersonSelected,
        idCliente,
      });
    } else {
      await cadastrarPagador(idCliente, {
        ...data,
        tipoPessoa: typePersonSelected,
      });
    }
    close();
    refetch();
  };

  function cancelForm() {
    formRef.current.reset();
    close();
  }

  const pesquisarEndereco = async cep => {
    if (cep.length === 10) {
      try {
        dispatch(showLoading(true));
        const { data } = await api_multipague_cep.get(
          `/endereco/cep/${cep.replace(/[^\d]/g, '')}`,
        );
        const infoForm = formRef.current.getData();
        formRef.current.setData({
          ...infoForm,
          logradouro: data.nomeLogradouro == null ? '' : data.nomeLogradouro,
          cidade: data.nome == null ? '' : data.nome,
          bairro: data.bairro == null ? '' : data.bairro,
          uf: data.siglaUf == null ? '' : data.siglaUf,
        });
        dispatch(hideLoading(false));
      } catch (err) {
        dispatch(hideLoading(false));
      }
    }
  };
  React.useEffect(() => {
    if (!!pagador) {
      setInitialData(pagador);
      setTypePersonSelected(pagador.tipoPessoa);
    } else if (formRef.current) {
      formRef.current.reset();
    }
  }, [pagador]);

  return (
    <Modal
      open={true}
      close={close}
      title={`${!!pagador ? 'Editar' : 'Cadastrar'} Pagador`}
      staticBackdrop={true}
      style={{ width: '60vw' }}
    >
      <Form ref={formRef} onSubmit={onSubmit} initialData={initialData}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <RadioButton
              titulo=""
              options={optionsTypePerson}
              optionDefault={typePersonSelected}
              callback={setTypePersonSelected}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <InputText
              variant="outlined"
              label={
                typePersonSelected === 'PF' ? 'Nome Completo' : 'Razão Social'
              }
              name="nome"
              id="nome"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            {typePersonSelected === 'PF' ? (
              <CPF
                name="documentoFederal"
                id="documentoFederal_id"
                variant="outlined"
                label="CPF"
              />
            ) : (
              <CNPJ
                name="documentoFederal"
                id="documentoFederal_id"
                variant="outlined"
                label="CNPJ"
              />
            )}
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputMask
              mask={'99.999-999'}
              name="cep"
              id="cep"
              variant="outlined"
              label="CEP"
              callBack={pesquisarEndereco}
              required
            />
          </Grid>
          <Grid item xs={12} sm={9} lg={8}>
            <InputText
              variant="outlined"
              label="Logradouro"
              name="logradouro"
              id="logradouro"
            />
          </Grid>
          <Grid item xs={12} sm={2} lg={1}>
            <InputText
              variant="outlined"
              label="Número"
              name="numero"
              id="numero"
            />
          </Grid>
          <Grid item xs={12} sm={5} lg={5}>
            <InputText
              variant="outlined"
              label="Bairro"
              name="bairro"
              id="bairro"
            />
          </Grid>
          <Grid item xs={12} sm={4} lg={6}>
            <InputText
              variant="outlined"
              label="Cidade"
              name="cidade"
              id="Cidade"
            />
          </Grid>
          <Grid item xs={12} sm={1}>
            <InputText variant="outlined" label="UF" name="uf" id="uf" />
          </Grid>
          <Grid item xs={12}>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: '1rem',
              }}
            >
              <ConfirmButton
                color="default"
                variant="contained"
                startIcon={<MdClear color="background" />}
                onClick={cancelForm}
                title="Cancelar"
              >
                Cancelar
              </ConfirmButton>
              <ConfirmButton
                color="primary"
                variant="contained"
                disabled={false}
                title={!!pagador ? 'Salvar' : 'Cadastrar'}
                type="submit"
                startIcon={
                  <MdSave color={useTheme().palette.background.paper} />
                }
              >
                {!!pagador ? 'Salvar' : 'Cadastrar'}
              </ConfirmButton>
            </Box>
          </Grid>
        </Grid>
      </Form>
    </Modal>
  );
};
