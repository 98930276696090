import { all, takeLatest, select, call, put } from 'redux-saga/effects';
import { api_multipague_conta } from '../../../../services/api';
import { hideLoading, showLoading } from '../../loading/actions';
import { listarTodosFavoritosError, listarTodosFavoritosSuccess } from './actions';

import { toastComponentError } from '../../../../components/Toast';

function* listarTodosFavoritos({ payload }) {
  try {
    yield put(showLoading(true));
    const { data } = yield call(
      api_multipague_conta.get,
      `/favorecido?idCliente=${payload.id}&idClienteConta=${payload.idClienteConta}&situacao=${payload.situacao}&ativo=${payload.ativo}`,
    );

    yield put(hideLoading(false));

    if (data.length) {
      yield put(listarTodosFavoritosSuccess(data));
    } else {
      yield put(listarTodosFavoritosError());
    }
  } catch (err) {
    yield put(hideLoading(false));
    yield put(listarTodosFavoritosSuccess([]));

    if (err.response.status >= 401 && err.response.status < 404) {
      toastComponentError(
        'Não foi possivel chamar o serviço. Por favor tente novamente mais tarde.',
      );
    } else {
      // toast.error(
      //   ` Codigo: ${err.response.data.codigo} - ${err.response.data.mensagem}`,
      // );
    }
  }
}

export default all([takeLatest('LISTAR_TODOS_FAVORITOS_REQUEST', listarTodosFavoritos)]);
