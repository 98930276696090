import React from 'react';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';
import DialogModal from '../../../../components/DialogModal';
import { MdArrowBack, MdDone, MdSend } from 'react-icons/md';
import ConfirmButton from '../../../../components/Buttons/ConfirmButton';
import { useStyles } from '../styles';

const ModalCadastro = ({
  onCLoseRadio,
  keySelected,
  openTipoMfa,
  tipoMfa,
  handleChange,
  onConfirmRadio,
  onCloseCadastro,
  open,
  onCancel,
  error,
  codigoMfa,
  onConfirmCreateEmailCelular,
  onChangeCodigo,
  onConfirmCreate,
  loadingCode,
  loadingCreateKey,
}) => {
  const classes = useStyles();
  return (
    <>
      <DialogModal
        onClose={onCLoseRadio}
        key={keySelected}
        open={openTipoMfa}
        dialogTitle={'Enviar código de confirmação'}
        dialogContent={
          <Box style={{ padding: '10px 0' }}>
            <FormControl className={classes.formControl}>
              <FormLabel
                id="demo-row-radio-buttons-group-label"
                style={{ paddingBottom: 5 }}
              >
                Receber código por:
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={tipoMfa}
                onChange={handleChange}
              >
                <FormControlLabel value="SMS" control={<Radio />} label="SMS" />
                <FormControlLabel
                  value=" WHATSAPP"
                  control={<Radio />}
                  label="WhatsApp"
                />
              </RadioGroup>
            </FormControl>
            <ConfirmButton
              style={{ marginTop: 5 }}
              variant="contained"
              color="primary"
              title={'Enviar código'}
              isLoading={loadingCode}
              disabled={loadingCode}
              onClick={onConfirmRadio}
              startIcon={<MdSend color="background" />}
            />
          </Box>
        }
      />
      <DialogModal
        onClose={onCloseCadastro}
        key={keySelected}
        open={open}
        dialogTitle={'Cadastro de chave PIX'}
        dialogActions={
          <div className={classes.dialogModalDiv}>
            <ConfirmButton
              title={'Cancelar'}
              variant="contained"
              color="primary"
              startIcon={<MdArrowBack color="background" />}
              onClick={onCancel}
            />
            {keySelected === 'EMAIL' || keySelected === 'CELULAR' ? (
              <ConfirmButton
                variant="contained"
                color="primary"
                title={'Criar chave'}
                disabled={loadingCreateKey}
                onClick={onConfirmCreateEmailCelular}
                isLoading={loadingCreateKey}
                startIcon={<MdDone color="background" />}
              />
            ) : (
              <ConfirmButton
                variant="contained"
                color="primary"
                disabled={loadingCreateKey}
                title={'Criar chave'}
                isLoading={loadingCreateKey}
                onClick={onConfirmCreate}
                startIcon={<MdDone color="background" />}
              />
            )}
          </div>
        }
        dialogContent={
          <Box style={{ padding: '10px 0 20px' }}>
            <Typography
              variant="body1"
              className={classes.dialogContentTypography}
            >
              {keySelected === 'EMAIL'
                ? 'Código enviado por e-mail'
                : keySelected === 'CELULAR'
                ? 'Adicionar código de confirmação'
                : 'Confirma criação de chave PIX ?'}
            </Typography>
            {keySelected === 'EMAIL' && (
              <TextField
                variant="outlined"
                size="small"
                value={codigoMfa}
                helperText={error && 'Campo obrigatório'}
                error={error}
                onChange={onChangeCodigo}
                style={{ borderRadius: '20px' }}
                name="codigoConfirmacao"
                label="Código de confirmação"
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            )}
            {keySelected === 'CELULAR' && (
              <TextField
                variant="outlined"
                size="small"
                value={codigoMfa}
                helperText={error && 'Campo obrigatório'}
                error={error}
                onChange={onChangeCodigo}
                style={{ borderRadius: '20px' }}
                name="codigoConfirmacao"
                label="Código de confirmação"
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            )}
          </Box>
        }
      />
    </>
  );
};

export default ModalCadastro;
