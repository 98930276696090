import styled, { css } from 'styled-components';
import {
  RadioProps,
  FormControlProps,
  RadioGroupProps,
  FormLabelProps,
  FormControlLabelProps,
} from '@material-ui/core';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

export const ContainerFormRadio = styled(FormControl)``;
export const RadioLabel = styled(FormLabel)``;
export const RadioContainerGroup = styled(RadioGroup)`
  ${props =>
    props.styleradiocontainergroup &&
    css`
      ${props.styleradiocontainergroup}
    `}
`;
export const OptionsRadio = styled(FormControlLabel)``;
export const Radios = styled(Radio)``;
