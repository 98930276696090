import produce from 'immer';
const INITIAL_STATE = [];

const carrinho = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'EDITAR_FAVORITOS_REQUEST': {
      return produce(state, draft => {
        const findFavorito = draft.filter(
          data => data.id === action.payload.id,
        );
        if (findFavorito.length < 1) {
          draft.push(action.payload);
        }
      });
    }
    case 'EDITAR_FAVORITOS_SUCCESS': {
      return [...state, action.payload];
    }

    case 'EDITAR_FAVORITOS_ERROR': {
    }
    default: {
      return state;
    }
  }
};

export default carrinho;
