export function saldoRequest(showSaldo) {
  return {
    type: 'SALDO_REQUEST',
    payload: { showSaldo: showSaldo },
  };
}
export function saldoSuccess(showSaldo) {
  return {
    type: 'SALDO_REQUEST_SUCCESS',
    payload: { showSaldo },
  };
}

export function saldoError(err) {
  return {
    type: 'SALDO_ERROR',
    payload: err,
  };
}
