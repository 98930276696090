import { api_multipague_cobranca, api_multipague_arquivo_paged} from "./api";
import moment from "moment";

const findAll = async (params = {}) => {
    try {
      const {data} = await api_multipague_arquivo_paged.get(`/boleto-cobranca-impresso`, { params });
      const formated = data.content.map((item,idx)=> {
        return {
          ...item,
          codigo:idx,
          dataReferencia: moment(item.dataReferencia).format('DD/MM/YYYY'),
          url:item.caminhoStorage
        }
      })
      const dataLength = data.totalElements
      return {formated, dataLength}
    } catch (error) {
      console.log("error na listagem retorno de remessas",error)
      const formated = []
      const dataLength = 0
      return {formated, dataLength, error}
    }

}

const solicitarImpressao = async (params)=>{
  const {data} = await api_multipague_cobranca.post(`/cobranca-simples/imprimirSelecionados`,params);
  return data;
}

export {
    findAll,
    solicitarImpressao
}
