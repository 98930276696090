import React, { useState, useCallback, useRef, useEffect } from 'react';
import RadioButton from '../RadioButton';
import Button from '../Buttons/ConfirmButton';
import InputText from '../../components/InputText';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import * as Yup from 'yup';
import CPF from '../../components/Inputs/CPF';
import CNPJ from '../../components/Inputs/CNPJ';
import InputMask from '../Inputs/MaskPersonalizavel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import getValidationErros from '../../util/FormattedError';
import { Container } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { setDigitacaoDadosPagadorBoletoSuccess } from '../../store/modules/digitacaoBoleto/actions';
import {
  api_multipague_cep,
  api_multipague_cobranca,
} from '../../services/api';
import { hideLoading, showLoading } from '../../store/modules/loading/actions';
import { cpf, cnpj } from 'cpf-cnpj-validator';

import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md';
import Grid from '@material-ui/core/Grid';

import { getPagadorDocumento } from '../../services/PagadorService';

const DadosPagador = ({ selecteAba, isSalve, isEditing }) => {
  const savedData = JSON.parse(localStorage.getItem('data_pagador'));
  const { userAccount } = useSelector(state => state.user);

  const [enableField, setEnableField] = useState(false);
  const [idPagador, setIdPagador] = useState(null);
  const [nomePessoa, setNomePessoa] = useState([]);

  const formRef = useRef(null);

  const dispatch = useDispatch();

  const [isBack, setIsBack] = useState(false);

  const optionsTypePerson = [
    {
      label: 'Pessoa Física',
      value: 'PF',
      selected: !!savedData?.tipoPessoa
        ? savedData?.tipoPessoa === 'PF'
          ? true
          : false
        : true,
    },
    {
      label: 'Pessoa Jurídica',
      value: 'PJ',
      selected: !!savedData?.tipoPessoa
        ? savedData?.tipoPessoa === 'PJ'
          ? true
          : false
        : false,
    },
  ];

  const [typePersonSelected, setTypePersonSelected] = useState(
    optionsTypePerson[0].selected
      ? optionsTypePerson[0].value
      : optionsTypePerson[1].value,
  );

  useEffect(() => {
    console.log('typePersonSelected', typePersonSelected, optionsTypePerson);
  }, [typePersonSelected, optionsTypePerson]);

  useEffect(() => {
    if (isBack) {
      console.log(formRef);
      formRef.current.submitForm();
    }
  }, [isBack]);

  useEffect(() => {
    if (isEditing) {
      setEnableField(true);
    }
  }, [isEditing]);

  useEffect(() => {
    console.log('idPagador', idPagador);
  }, [idPagador]);

  useEffect(() => {
    const URLpagador = `/cobranca-simples/pagadores?idCliente=${userAccount.id}&size=100`;
    api_multipague_cobranca
      .get(URLpagador)
      .then(response => {
        const selectFilter = response.data.content;
        const aquiSelect = selectFilter.map(key => (
          <MenuItem key={key.id} value={key.documentoFederal}>
            {key.nome}
          </MenuItem>
        ));
        setNomePessoa(aquiSelect);
      })
      .catch(function (error) {
        console.log(error);
        // if (error.response.status == 500 || error.response.status == 400 ) {
        //   toast({
        //     title: 'Ops!',
        //     description: error.response.data.mensagem ,
        //     status: 'warning',
        //     duration: 3000,
        //     isClosable: true,
        //   })
        // }
      });
  }, [userAccount]);

  const validarCpfCnpj = value => {
    if (value.replace(/[^\d]/g, '').length < 14) {
      return cpf.isValid(value);
    } else {
      return cnpj.isValid(value);
    }
  };

  const submitForm = useCallback(
    async data => {
      if (isBack) {
        isSalve(false);
        selecteAba(1);
      } else {
        try {
          isSalve(false);
          const schmeCadastro = Yup.object().shape({
            nomePagador: Yup.string().required('Campo obrigatorio'),
            cepPagador: Yup.string().required('Campo obrigatorio'),
            enderecoPagador: Yup.string().required('Campo obrigatorio'),
            ufPagador: Yup.string().required('Campo obrigatorio'),
            cidadePagador: Yup.string().required('Campo obrigatorio'),
            bairroPagador: Yup.string().required('Campo obrigatorio'),
            documentoFederal: Yup.string().required('Campo obrigatorio'),
            tipoOperacao: Yup.string().required(
              localStorage.getItem('tipodaOperacao'),
            ),
          });

          const validation = validarCpfCnpj(data.documentoFederal);
          if (!validation) {
            formRef.current?.setFieldError(
              'documentoFederal',
              'CPF/CNPJ inválido',
            );
            throw new Error();
          }

          data.tipoPessoa = typePersonSelected;
          data.idPagador = idPagador;
          selecteAba(3);
          dispatch(setDigitacaoDadosPagadorBoletoSuccess(data));
          localStorage.setItem('data_pagador', JSON.stringify(data));
        } catch (err) {
          console.log(err);
          const erros = getValidationErros(err);
          formRef.current?.setErrors(erros);
        }
      }
    },
    [typePersonSelected, isBack, idPagador],
  );

  async function pesquisarEndereco(cep) {
    console.log(cep);
    if (cep.length === 10) {
      try {
        dispatch(showLoading(true));
        const { data } = await api_multipague_cep.get(
          `/endereco/cep/${cep.replace(/[^\d]/g, '')}`,
        );
        const infoForm = formRef.current.getData();

        formRef.current.setData({
          enderecoPagador:
            data.nomeLogradouro == null ? '' : data.nomeLogradouro, //Rua/AV
          cidadePagador: data.nome == null ? '' : data.nome, //Cidade
          bairroPagador: data.bairro == null ? '' : data.bairro,
          ufPagador: data.siglaUf == null ? '' : data.siglaUf,
          cepPagador: infoForm.cepPagador,
          nomePagador: infoForm.nomePagador,
          documentoFederal: infoForm.documentoFederal,
          tipoOperacao: localStorage.getItem('tipodaOperacao'),
        });
        dispatch(hideLoading(false));
      } catch (err) {
        dispatch(hideLoading(false));
      }
    }
    // infos.cpf_cnpj.replace(/[^\d]/g, '')
  }

  const getEnderecoPagador = async documento => {
    console.log('formRef.current', formRef.current);

    setEnableField(true);
    dispatch(showLoading(true));

    const infoForm = formRef.current.getData();

    try {
      const { data } = await getPagadorDocumento(
        userAccount.id,
        documento.replace(/\D/g, ''),
      );
      if (data) {
        console.log('inseri o pagado', data?.id);
        setIdPagador(data.id);
        formRef.current.setData({
          ...infoForm,
          nomePagador: data?.nome,
          documentoFederal: data?.documentoFederal,
          cepPagador: data?.cep,
          enderecoPagador: data?.logradouro, //Rua/AV
          cidadePagador: data?.cidade, //Cidade
          bairroPagador: data?.bairro,
          ufPagador: data?.uf,
        });

        dispatch(hideLoading(false));
      }
    } catch (error) {
      if (!isEditing) {
        formRef.current.setData({
          ...infoForm,
          nomePagador: '',
          cepPagador: '',
          enderecoPagador: '', //Rua/AV
          cidadePagador: '', //Cidade
          bairroPagador: '',
          ufPagador: '',
        });
      }
      const validation = validarCpfCnpj(documento);
      if (!validation) {
        formRef.current?.setFieldError(
          'documentoFederal',
          typePersonSelected === 'PF' ? 'CPF inválido' : 'CNPJ inválido',
        );
        setEnableField(false);
      } else {
        formRef.current?.setFieldError('documentoFederal', null);
        setEnableField(true);
      }
      dispatch(hideLoading(false));
    }
  };

  const checkDoc = {
    placeholder:
      enableField ||
      `Digite o ${
        typePersonSelected === 'PF' ? 'CPF do pagador' : 'CNPJ do pagador'
      }`,
  };

  return (
    <Container ref={formRef} initialData={savedData} onSubmit={submitForm}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <RadioButton
            titulo=""
            options={optionsTypePerson}
            optionDefault={typePersonSelected}
            callback={e => {
              setEnableField(false);
              setTypePersonSelected(e);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <InputLabel
            htmlFor="outlined-age-native-simple-seg-zero"
            style={{ marginBottom: '5px' }}
          >
            Selecione o pagador
          </InputLabel>
          <FormControl
            variant="outlined"
            size="small"
            fullwidth
            style={{ width: '100%', minWidth: '120px' }}
          >
            <Select
              id="nomePagador"
              name="nomePagador"
              displayEmpty
              label="Selecione o pagador"
              onChange={e => getEnderecoPagador(e.target.value)}
            >
              <MenuItem value=""></MenuItem>
              {nomePessoa}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          {typePersonSelected === 'PF' ? (
            <CPF
              id="documentoFederal"
              name="documentoFederal"
              label="CPF"
              onChange={e => {
                const doc = e.target.value;
                if (doc && doc.length === 14) {
                  getEnderecoPagador(doc);
                } else {
                  setEnableField(false);
                }
              }}
              onBlur={e => {
                const doc = e.target.value;
                const validation = validarCpfCnpj(doc);
                if (!validation && doc && doc.length != 14) {
                  formRef.current?.setFieldError(
                    'documentoFederal',
                    'CPF inválido',
                  );
                  setEnableField(false);
                  return;
                } else {
                  formRef.current?.setFieldError('documentoFederal', null);
                  setEnableField(true);
                }
              }}
              autoFocus
              required
            />
          ) : (
            <CNPJ
              id="documentoFederal"
              name="documentoFederal"
              label="CNPJ"
              required
              onChange={e => {
                const doc = e.target.value;
                if (doc && doc.length === 18) {
                  getEnderecoPagador(doc);
                } else {
                  setEnableField(false);
                }
              }}
              onBlur={e => {
                const doc = e.target.value;
                const validation = validarCpfCnpj(doc);
                if (!validation && doc && doc.length != 14) {
                  formRef.current?.setFieldError(
                    'documentoFederal',
                    'CNPJ inválido',
                  );
                  setEnableField(false);
                  return;
                } else {
                  formRef.current?.setFieldError('documentoFederal', null);
                  setEnableField(true);
                }
              }}
              autoFocus
            />
          )}
        </Grid>

        <Grid item xs={12} sm={5}>
          <InputText
            id="nomePagador"
            name="nomePagador"
            variant="outlined"
            label={`${
              typePersonSelected === 'PF' ? 'Nome Completo' : 'Razão Social'
            } `}
            required
            inputProps={{ ...checkDoc }}
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <InputMask
            mask={'99.999-999'}
            name="cepPagador"
            id="cepPagador"
            variant="outlined"
            label="CEP"
            callBack={pesquisarEndereco}
            required
            disabled={!enableField}
            inputProps={{ ...checkDoc }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputText
            name="enderecoPagador"
            id="enderecoPagador"
            variant="outlined"
            label=" Rua/Av/Número"
            required
            disabled={!enableField}
            inputProps={{ ...checkDoc }}
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <InputText
            name="cidadePagador"
            id="cidadePagador"
            variant="outlined"
            label="Cidade"
            required
            disabled={!enableField}
            inputProps={{ ...checkDoc }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <InputText
            name="bairroPagador"
            id="bairroPagador"
            variant="outlined"
            label="Bairro"
            required
            disabled={!enableField}
            inputProps={{ ...checkDoc }}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <InputMask
            mask={'aa'}
            id="ufPagador"
            name="ufPagador"
            variant="outlined"
            label="UF"
            required
            disabled={!enableField}
            inputProps={{ ...checkDoc }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: '1rem',
          }}
        >
          <Button
            color="default"
            variant="contained"
            disabled={false}
            width={100}
            title={'Voltar'}
            onClick={() => setIsBack(true)}
            type="button"
            startIcon={<MdNavigateBefore color={'background'} />}
          ></Button>
          <Button
            color="primary"
            variant="contained"
            disabled={!enableField}
            title={'Avançar'}
            width={100}
            type="submit"
            startIcon={<MdNavigateNext color={'background'} />}
          ></Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default DadosPagador;
