import React, { useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import FindReplaceIcon from '@material-ui/icons/FindReplace';
import { makeStyles, alpha } from '@material-ui/core/styles';
import moment from 'moment';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import SaldoAtualExtrato from '../../components/SaldoAtualExtrato';
import Grid from '@material-ui/core/Grid';
import PrintIcon from '@material-ui/icons/Print';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import {
  api_multipague_conta_paged,
  api_multipague_conta,
} from '../../services/api';
import { useDispatch, useSelector } from 'react-redux';
import formattedMoney from '../../util/FormatteMoney/index';
import { showLoading, hideLoading } from '../../store/modules/loading/actions';
import List from '@material-ui/core/List';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import { MdSearch, MdCleaningServices } from 'react-icons/md';
import { FaRegFileExcel } from 'react-icons/fa';
import Box from '../../components/Box';
import Wrapper from '../../components/Wrapper';
import DataGrid from '../../components/DataGrid';

import { Divider, Tooltip, IconButton } from '@material-ui/core';
import { InputMoney } from '../../components/Inputs/Money';
import { ExcelGrid } from '../../components/DataGrid/DataGridPaginationServer/ExcelGrid';
import { useStylesDatagrid } from '../../components/DataGrid/DataGridPaginationServer/styles';
import {
  toastComponentError,
  toastComponentSuccess,
} from '../../components/Toast';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    MuiTypography: {
      root: {
        wordWrap: 'break-word',
      },
    },
  },
  customRootClass: {
    '&::-webkit-scrollbar': {
      width: '0.1px',
      maxHeight: '5px',
    },
    '&::-webkit-scrollbar-track-piece': {
      backgroundColor:
        theme.palette.type === 'dark'
          ? theme.palette.background.dark
          : theme.palette.background.default,
      '-webkitBorderRadius': '6px',
    },
    '&::-webkit-scrollbar-thumb:horizontal': {
      backgroundColor: theme.palette.primary.main,
      '-webkit-border-radius': '6px',
    },
    scrollbarColor: `${theme.palette.primary.main} #fff`,
    scrollbarWidth: 'thin !important',
  },
  saldoBotao: {
    padding: 10,
    [theme.breakpoints.only('lg', 'md', 'sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    [theme.breakpoints.only('xs')]: {
      display: 'block',
      justifyContent: 'flex-column',
    },
  },
  saldoInicialFinal: {
    [theme.breakpoints.only('lg', 'md', 'sm')]: {
      display: 'flex',
      justifyContent: 'flex-start',
      gap: '1rem',
    },
    [theme.breakpoints.only('xs')]: {
      display: 'block',
      gap: '1rem',
      justifyContent: 'center',
    },
  },
  pdfBotao: {
    [theme.breakpoints.only('lg', 'md')]: {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: '1rem',
    },
    [theme.breakpoints.only('sm', 'xs')]: {
      display: 'block',
      margin: 'auto',
      marginTop: theme.spacing(1),
    },
  },
  overrides: {
    MuiTypography: {
      root: {
        wordWrap: 'break-word',
      },
    },
  },
  toolbar: {
    paddingRight: 24,
  },
  button: {
    margin: theme.spacing(1),
    cursor: 'pointer',
  },
}));

var css = '@page { size: landscape; }',
  head = document.head || document.getElementsByTagName('head')[0],
  style = document.createElement('style');

style.type = 'text/css';
style.media = 'print';

if (style.styleSheet) {
  style.styleSheet.cssText = css;
} else {
  style.appendChild(document.createTextNode(css));
}

head.appendChild(style);

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="R$ "
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const SimpleModal = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const classesDatagrid = useStylesDatagrid();
  const theme = useTheme();
  const formInfo = history?.location?.state;
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [rows, setRows] = useState([]);
  const [rowsDiario, setRowsDiario] = useState();
  const [novaLista, setNovaLista] = useState();
  const [pdfExtrato, setPdfExtrato] = useState([]);
  const [pdfExtratoDiario, setPdfExtratoDiario] = useState([]);
  const [saldoInicial, setSaldoInicial] = useState();
  const [saldoFinal, setSaldoFinal] = useState();
  const [isDiario, setIsDiario] = useState(
    formInfo?.formInfo?.isDiario !== undefined
      ? formInfo?.formInfo?.isDiario
      : true,
  );
  const [mostrarDetalhes, setMostrarDetalhes] = useState(
    formInfo?.formInfo?.mostrarDetalhes !== undefined ||
      formInfo?.formInfo?.mostrarDetalhes !== null
      ? formInfo?.formInfo?.mostrarDetalhes
      : null,
  );
  const [maisFiltros, setMaisFiltros] = useState(
    formInfo?.formInfo?.maisFiltros !== undefined
      ? formInfo?.formInfo?.maisFiltros
      : false,
  );
  async function handleImprimirEmLote() {
    api_multipague_conta
      .get(
        `/comprovantes?codigosTransacoes=${selectionModel}&clienteConta=${infoRedux.contaPadrao?.id}`,
      )
      .then(response => {
        localStorage.setItem('printLote', JSON.stringify(response.data));
        localStorage.setItem(
          'print-codigo-transacao',
          JSON.stringify(selectionModel),
        );
        window.open('/imprimir-comprovante-lote');
      })
      .catch(error => {
        console.log(error);
      });
  }

  const [dataDePadrao, setDataDePadrao] = useState(
    moment(new Date()).format('YYYY-MM-DD'),
  );
  const [dataAtePadrao, setDataAtePadrao] = useState(
    moment(new Date()).format('YYYY-MM-DD'),
  );

  const [dataDe, setDataDe] = useState(
    formInfo?.formInfo?.dataInicial
      ? moment(formInfo?.formInfo?.dataInicial).format('YYYY-MM-DD')
      : moment().format('YYYY-MM-DD'),
  );
  const [dataAte, setDataAte] = useState(
    formInfo?.formInfo?.dataFinal
      ? moment(formInfo?.formInfo?.dataFinal).format('YYYY-MM-DD')
      : moment().format('YYYY-MM-DD'),
  );

  const handleDataDeMinima = () => {
    return moment(new Date(dataAte)).subtract('365', 'days');
  };

  const handleDataAteMaxima = () => {
    return moment(new Date(dataDe)).add('365', 'days');
  };

  const [filtroTransacao, setFiltroTransacao] = useState(
    formInfo?.formInfo?.origemTransacao
      ? formInfo?.formInfo?.origemTransacao
      : null,
  );
  const [filtroFavorecido, setFiltroFavorecido] = useState(
    formInfo?.formInfo?.nome ? formInfo?.formInfo?.nome : null,
  );
  const [filtroDocFavorecido, setFiltroDocFavorecido] = useState(
    formInfo?.formInfo?.documento ? formInfo?.formInfo?.documento : null,
  );
  const [filtroObservacao, setFiltroObservacao] = useState(
    formInfo?.formInfo?.descricao ? formInfo?.formInfo?.descricao : null,
  );
  const [filtroValorDe, setFiltroValorDe] = useState(
    formInfo?.formInfo?.valorInicial ? formInfo?.formInfo?.valorInicial : null,
  );
  const [filtroValorAte, setFiltroValorAte] = useState(
    formInfo?.formInfo?.valorFinal ? formInfo?.formInfo?.valorFinal : null,
  );

  const infoRedux = useSelector(state => state.contas);

  const URL = `/extrato?cliente=${
    infoRedux?.contaPadrao?.id
  }&dataInicial=${dataDe}&dataFinal=${dataAte}&clienteConta=${
    infoRedux?.contaPadrao?.id
  }&origemTransacao=${filtroTransacao != null ? filtroTransacao : ''}&nome=${
    filtroFavorecido != null ? filtroFavorecido : ''
  }&documento=${
    filtroDocFavorecido != null ? filtroDocFavorecido : ''
  }&descricao=${
    filtroObservacao != null ? filtroObservacao : ''
  }&valorInicial=${filtroValorDe != null ? filtroValorDe : ''}&valorFinal=${
    filtroValorAte != null ? filtroValorAte : ''
  }`;
  const URLLimpar = `/extrato?cliente=${
    infoRedux?.contaPadrao?.id
  }&dataInicial=${dataDePadrao}&dataFinal=${dataAtePadrao}&clienteConta=${
    infoRedux.contaPadrao?.id
  }&valorInicial=${filtroValorDe != null ? filtroValorDe : ''}&valorFinal=${
    filtroValorAte != null ? filtroValorAte : ''
  }`;

  useEffect(() => {
    dispatch(showLoading(true));
    let config = {
      headers: {
        ContentType: 'application/group+json;type=data',
      },
    };

    if (infoRedux.contaPadrao?.id) {
      api_multipague_conta_paged
        .get(URL, config)
        .then(response => {
          dispatch(hideLoading(false));
          const transacaoFilter = response.data.extratoClienteContas.filter(
            data => data.codigoClienteConta >= 2,
          );
          const transacoes = transacaoFilter[0].transacoes;
          const saldos = transacaoFilter[0].saldos;
          const resultFirst = saldos[0];
          const resultLast = saldos[1];
          setSaldoInicial(
            resultFirst.valor === 0
              ? 'R$ 0,00'
              : formattedMoney(parseFloat(resultFirst.valor)),
          );
          setSaldoFinal(
            resultLast.valor === 0
              ? 'R$ 0,00'
              : formattedMoney(parseFloat(resultLast.valor)),
          );

          if (transacoes.length) {
            const aquiLista = transacoes.map(data => ({
              ...data,
              id: data.codigoTransacao,
              data: moment(data.data).format('DD/MM/YYYY'),
              saldoAnterior:
                data.saldoAnterior != 0
                  ? formattedMoney(parseFloat(data.saldoAnterior))
                  : '000',
              saldoDia: formattedMoney(parseFloat(data.saldoDia)),
              totalMovimentacaoDia: formattedMoney(
                parseFloat(data.totalMovimentacaoDia),
              ),
              descricaoEvento: data.transacoes[0].descricaoEvento,
              historicoEvento: data.transacoes[0].historicoEvento,
              nomeEvento: data.transacoes[0].nome,
              valor: data.transacoes[0].valor,
              cnpj: localStorage.getItem('printCnpj'),
              nome: localStorage.getItem('printNome'),
              banco: localStorage.getItem('printBanco'),
              agencia: localStorage.getItem('printAgencia'),
              conta:
                localStorage.getItem('printConta') +
                '-' +
                localStorage.getItem('printDigito'),
            }));

            const qualquer = [];
            aquiLista.map(itemListaZero => {
              itemListaZero.transacoes.map(itemTransacao => {
                const itemTransacaoArray = {
                  ...itemListaZero,
                  descricaoEvento: itemTransacao.descricaoEvento,
                  historicoEvento: itemTransacao.historicoEvento,
                  nomePessoa: itemTransacao.nome,
                  valor:
                    itemTransacao.tipoEvento != 'D'
                      ? formattedMoney(parseFloat(itemTransacao.valor))
                      : '-' + formattedMoney(parseFloat(itemTransacao.valor)),
                };
                delete itemTransacaoArray.transacoes;
                qualquer.push(itemTransacaoArray);
              });
            });
            setRowsDiario(qualquer);
            setPdfExtratoDiario(aquiLista);
          } else {
            dispatch(showLoading(false));
          }

          if (transacoes.length) {
            const aquiListaZero = transacoes.map(data => ({
              ...data,
              data: moment(new Date(data.data)).format('DD/MM/YYYY'),
              saldoAnterior:
                data.saldoAnterior != 0
                  ? formattedMoney(parseFloat(data.saldoAnterior))
                  : '000',
              saldoDia: formattedMoney(parseFloat(data.saldoDia)),
              totalMovimentacaoDia: formattedMoney(
                parseFloat(data.totalMovimentacaoDia),
              ),
              id: data.codigoTransacao,
              descricaoEvento: data.transacoes[0].descricaoEvento,
              historicoEvento: data.transacoes[0].historicoEvento,
              valor: data.transacoes[0].valor,
              cnpj: localStorage.getItem('printCnpj'),
            }));

            const aquiTable = transacoes.map(value => (
              <Box
                className={classes.customRootClass}
                style={{
                  padding: isSmallScreen ? '5px' : '20px',
                  overflow: isSmallScreen ? 'scroll' : 'none',
                  margin: '1rem 0',
                  boxShadow: 'none',
                  border: `1px solid ${alpha(theme.palette.primary.dark, 0.2)}`,
                  borderRadius: '10px',
                }}
              >
                <Typography
                  style={{
                    fontSize: isSmallScreen ? '12px' : 'inherit',
                    fontWeight: 'bold',
                    padding: '1rem',
                  }}
                >
                  Data: {moment(value.data).format('DD/MM/YYYY')}
                </Typography>
                <Table
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <TableBody>
                    <TableRow
                      style={{
                        fontSize: isSmallScreen ? '12px' : 'inherit',
                        borderBottom: 'none',
                      }}
                    >
                      <TableCell
                        style={{
                          fontSize: isSmallScreen ? '12px' : 'inherit',
                          borderBottom: 'none',
                        }}
                      >
                        Saldo do dia
                      </TableCell>
                      <TableCell
                        style={{
                          color:
                            value.saldoDia > 0
                              ? theme.palette.type === 'dark'
                                ? theme.palette.primary.light
                                : theme.palette.primary.dark
                              : theme.palette.system.dark,
                          textAlign: 'end',
                          fontSize: isSmallScreen ? '12px' : 'inherit',
                          borderBottom: 'none',
                        }}
                      >
                        {value.saldoDia !== 0
                          ? formattedMoney(parseFloat(value.saldoDia))
                          : 'R$ 0,00'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{
                          fontSize: isSmallScreen ? '12px' : 'inherit',
                          borderBottom: 'none',
                        }}
                      >
                        Saldo anterior
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: isSmallScreen ? '12px' : 'inherit',
                          color:
                            value.saldoDia > 0
                              ? theme.palette.type === 'dark'
                                ? theme.palette.primary.light
                                : theme.palette.primary.dark
                              : theme.palette.system.dark,
                          textAlign: 'end',
                          borderBottom: 'none',
                        }}
                      >
                        {value.saldoDia !== 0
                          ? formattedMoney(parseFloat(value.saldoAnterior))
                          : 'R$ 0,00'}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <Table
                  style={{
                    width: isSmallScreen ? '98%' : '90%',
                    marginLeft: isSmallScreen ? '1%' : '5%',
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          fontSize: isSmallScreen ? '12px' : 'inherit',
                          fontWeight: 'bold',
                        }}
                        align="center"
                      >
                        Data
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: isSmallScreen ? '12px' : 'inherit',
                          fontWeight: 'bold',
                        }}
                        align="center"
                      >
                        Descrição do Evento
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: isSmallScreen ? '12px' : 'inherit',
                          fontWeight: 'bold',
                        }}
                        align="center"
                      >
                        Histórico
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: isSmallScreen ? '12px' : 'inherit',
                          fontWeight: 'bold',
                        }}
                        align="center"
                      >
                        Nome
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: isSmallScreen ? '12px' : 'inherit',
                          fontWeight: 'bold',
                        }}
                        align="center"
                      >
                        Valor
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {value.transacoes.map(index => (
                      <TableRow key={index.codigoTransacao}>
                        <TableCell
                          align="center"
                          style={{
                            fontSize: isSmallScreen ? '12px' : 'inherit',
                          }}
                        >
                          {moment(index.data).format('DD/MM/YYYY HH:mm')}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            fontSize: isSmallScreen ? '12px' : 'inherit',
                          }}
                        >
                          {index.descricaoEvento}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            fontSize: isSmallScreen ? '12px' : 'inherit',
                          }}
                        >
                          {index.historicoEvento}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            fontSize: isSmallScreen ? '12px' : 'inherit',
                          }}
                        >
                          {index.nome}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            padding: 0,
                            fontSize: isSmallScreen ? '12px' : 'inherit',
                            color:
                              index.tipoEvento !== 'D'
                                ? theme.palette.type === 'dark'
                                  ? theme.palette.primary.light
                                  : theme.palette.primary.dark
                                : theme.palette.system.dark,

                            textAlign: 'end',
                          }}
                        >
                          {index.tipoEvento !== 'D' ? (
                            formattedMoney(parseFloat(index.valor))
                          ) : (
                            <div
                              style={{
                                alignItems: 'center',
                                display: 'flex',
                                justifyContent: !isSmallScreen
                                  ? 'flex-end'
                                  : 'center',
                                flexDirection: isSmallScreen ? 'column' : 'row',
                              }}
                            >
                              - {formattedMoney(parseFloat(index.valor))}
                              <PrintIcon
                                color="primary"
                                onClick={() => {
                                  handleGoToPrint(index);
                                }}
                                className={classes.button}
                              />
                            </div>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            ));
            setNovaLista(aquiTable);
          } else {
            dispatch(showLoading(false));
          }
        })
        .catch(response => {
          dispatch(showLoading(false));
          toastComponentError(response.data.mensagem);
        });
    }
  }, [infoRedux.contaPadrao]);

  function mudarParaDiario() {
    dispatch(showLoading(true));
    setIsDiario(true);
    let config = {
      headers: {
        ContentType: 'application/group+json;type=data',
      },
    };

    if (infoRedux.contaPadrao?.id) {
      api_multipague_conta_paged
        .get(URL, config)
        .then(response => {
          dispatch(hideLoading(false));
          const transacaoFilter = response.data.extratoClienteContas.filter(
            data => data.codigoClienteConta >= 2,
          );

          const transacoes =
            transacaoFilter[0].transacoes != ''
              ? transacaoFilter[0].transacoes
              : toastComponentError(
                  'Nenhum dado disponível para esse filtro!',
                  {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  },
                );
          const saldos = transacaoFilter[0].saldos;
          const resultFirst = saldos[0];
          const resultLast = saldos[1];
          setSaldoInicial(
            resultFirst.valor === 0
              ? 'R$ 0,00'
              : formattedMoney(parseFloat(resultFirst.valor)),
          );
          setSaldoFinal(
            resultLast.valor === 0
              ? 'R$ 0,00'
              : formattedMoney(parseFloat(resultLast.valor)),
          );

          if (transacoes?.length) {
            const aquiListaZero = transacoes.map(data => ({
              ...data,
              data: moment(data.data).format('DD/MM/YYYY'),
              saldoAnterior:
                data.saldoAnterior != 0
                  ? formattedMoney(parseFloat(data.saldoAnterior))
                  : '000',
              saldoDia: formattedMoney(parseFloat(data.saldoDia)),
              totalMovimentacaoDia: formattedMoney(
                parseFloat(data.totalMovimentacaoDia),
              ),
              id: data.codigoTransacao,
              nomeEvento: data.transacoes[0].nome,
              descricaoEvento: data.transacoes[0].descricaoEvento,
              historicoEvento: data.transacoes[0].historicoEvento,
              valor: data.transacoes[0].valor,
              cnpj: localStorage.getItem('printCnpj'),
              nome: localStorage.getItem('printNome'),
              banco: localStorage.getItem('printBanco'),
              agencia: localStorage.getItem('printAgencia'),
              conta:
                localStorage.getItem('printConta') +
                '-' +
                localStorage.getItem('printDigito'),
            }));

            const qualquer = [];
            aquiListaZero.map(itemListaZero => {
              itemListaZero.transacoes.map(itemTransacao => {
                const itemTransacaoArray = {
                  ...itemListaZero,
                  descricaoEvento: itemTransacao.descricaoEvento,
                  historicoEvento: itemTransacao.historicoEvento,
                  nomePessoa: itemTransacao.nome,
                  valor:
                    itemTransacao.tipoEvento != 'D'
                      ? formattedMoney(parseFloat(itemTransacao.valor))
                      : '-' + formattedMoney(parseFloat(itemTransacao.valor)),
                };
                delete itemTransacaoArray.transacoes;
                qualquer.push(itemTransacaoArray);
              });
            });

            const aquiLista = transacoes.map(data => ({
              ...data,
              data: moment(data.data).format('DD/MM/YYYY'),
              saldoAnterior:
                data.saldoAnterior != 0
                  ? formattedMoney(parseFloat(data.saldoAnterior))
                  : '000',
              saldoDia: formattedMoney(parseFloat(data.saldoDia)),
              totalMovimentacaoDia: formattedMoney(
                parseFloat(data.totalMovimentacaoDia),
              ),
              id: data.codigoTransacao,
              descricaoEvento: data.transacoes[0].descricaoEvento,
              historicoEvento: data.transacoes[0].historicoEvento,
              nomeEvento: data.transacoes[0].nome,
              valor: data.transacoes[0].valor,
              cnpj: localStorage.getItem('printCnpj'),
              nome: localStorage.getItem('printNome'),
              banco: localStorage.getItem('printBanco'),
              agencia:
                localStorage.getItem('printAgencia') +
                '-' +
                localStorage.getItem('printDigito'),
              conta: localStorage.getItem('printConta'),
            }));
            setRowsDiario(qualquer);
            setPdfExtratoDiario(aquiLista);
          }

          if (transacoes.length) {
            const aquiTable = transacoes.map(value => (
              <Box
                style={{
                  padding: isSmallScreen ? '5px' : '10px',
                  overflow: isSmallScreen ? 'scroll' : 'none',
                  margin: '1rem 0',
                  boxShadow: 'none',
                  border: `1px solid ${alpha(theme.palette.primary.dark, 0.2)}`,
                  borderRadius: '10px',
                }}
              >
                <Typography
                  style={{
                    fontSize: isSmallScreen ? '12px' : 'inherit',
                    fontWeight: 'bold',
                    padding: '1rem',
                  }}
                >
                  Data: {moment(value.data).format('DD/MM/YYYY')}
                </Typography>
                <Table
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <TableBody>
                    <TableRow
                      style={{
                        fontSize: isSmallScreen ? '12px' : 'inherit',
                        borderBottom: 'none',
                      }}
                    >
                      <TableCell
                        style={{
                          fontSize: isSmallScreen ? '12px' : 'inherit',

                          borderBottom: 'none',
                        }}
                      >
                        Saldo do dia
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: isSmallScreen ? '12px' : 'inherit',
                          color:
                            value.saldoDia > 0
                              ? theme.palette.type === 'dark'
                                ? theme.palette.primary.light
                                : theme.palette.primary.dark
                              : theme.palette.system.dark,
                          textAlign: 'end',
                          borderBottom: 'none',
                        }}
                      >
                        {value.saldoDia !== 0
                          ? formattedMoney(parseFloat(value.saldoDia))
                          : 'R$ 0,00'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{
                          fontSize: isSmallScreen ? '12px' : 'inherit',
                          borderBottom: 'none',
                        }}
                      >
                        Saldo anterior
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: isSmallScreen ? '12px' : 'inherit',

                          color:
                            value.saldoDia > 0
                              ? theme.palette.type === 'dark'
                                ? theme.palette.primary.light
                                : theme.palette.primary.dark
                              : theme.palette.system.dark,
                          textAlign: 'end',
                          borderBottom: 'none',
                        }}
                      >
                        {value.saldoDia !== 0
                          ? formattedMoney(parseFloat(value.saldoAnterior))
                          : 'R$ 0,00'}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <Table
                  style={{
                    width: isSmallScreen ? '98%' : '90%',
                    marginLeft: isSmallScreen ? '1%' : '5%',
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          fontSize: isSmallScreen ? '12px' : 'inherit',
                          fontWeight: 'bold',
                        }}
                        align="center"
                      >
                        Data
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: isSmallScreen ? '12px' : 'inherit',
                          fontWeight: 'bold',
                        }}
                        align="center"
                      >
                        Descrição do Evento
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: isSmallScreen ? '12px' : 'inherit',
                          fontWeight: 'bold',
                        }}
                        align="center"
                      >
                        Histórico
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: isSmallScreen ? '12px' : 'inherit',
                          fontWeight: 'bold',
                        }}
                        align="center"
                      >
                        Nome
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: isSmallScreen ? '12px' : 'inherit',
                          fontWeight: 'bold',
                        }}
                        align="center"
                      >
                        Valor
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {value.transacoes.map(index => (
                      <TableRow key={index.codigoTransacao}>
                        <TableCell
                          align="center"
                          style={{
                            fontSize: isSmallScreen ? '12px' : 'inherit',
                          }}
                        >
                          {moment(index.data).format('DD/MM/YYYY HH:mm')}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            fontSize: isSmallScreen ? '12px' : 'inherit',
                          }}
                        >
                          {index.descricaoEvento}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            fontSize: isSmallScreen ? '12px' : 'inherit',
                          }}
                        >
                          {index.historicoEvento}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            fontSize: isSmallScreen ? '12px' : 'inherit',
                          }}
                        >
                          {index.nome}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            padding: 0,
                            fontSize: isSmallScreen ? '12px' : 'inherit',
                            color:
                              index.tipoEvento !== 'D'
                                ? theme.palette.type === 'dark'
                                  ? theme.palette.primary.light
                                  : theme.palette.primary.dark
                                : theme.palette.system.dark,
                            textAlign: 'end',
                          }}
                        >
                          {index.tipoEvento !== 'D' ? (
                            formattedMoney(parseFloat(index.valor))
                          ) : (
                            <div
                              style={{
                                alignItems: 'center',
                                display: 'flex',
                                justifyContent: !isSmallScreen
                                  ? 'flex-end'
                                  : 'center',
                                flexDirection: isSmallScreen ? 'column' : 'row',
                              }}
                            >
                              - {formattedMoney(parseFloat(index.valor))}
                              <PrintIcon
                                color="primary"
                                onClick={() => {
                                  handleGoToPrint(index);
                                }}
                                className={classes.button}
                              />
                            </div>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            ));
            setNovaLista(aquiTable);
          }
        })
        .catch(error => {
          dispatch(showLoading(false));
          setNovaLista([]);
          setPdfExtratoDiario([]);
          setRowsDiario([]);
          toastComponentError(error.response.data.mensagem);
        });
    }
  }

  function mudarParaPadrao() {
    setSelectionModel([]);
    dispatch(showLoading(true));
    setIsDiario(false);

    if (infoRedux.contaPadrao?.id) {
      api_multipague_conta
        .get(URL)
        .then(response => {
          dispatch(hideLoading(false));
          const transacaoFilter = response.data.extratoClienteContas.filter(
            data => data.codigoClienteConta >= 2,
          );
          const transacoesPadrao =
            transacaoFilter[0].transacoes != ''
              ? transacaoFilter[0].transacoes
              : toastComponentError(
                  'Nenhum dado disponível para esse filtro!',
                  {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  },
                );
          const saldos = transacaoFilter[0].saldos;
          const resultFirst = saldos[0];
          const resultLast = saldos[1];
          setSaldoInicial(
            resultFirst.valor === 0
              ? 'R$ 0,00'
              : formattedMoney(parseFloat(resultFirst.valor)),
          );
          setSaldoFinal(
            resultLast.valor === 0
              ? 'R$ 0,00'
              : formattedMoney(parseFloat(resultLast.valor)),
          );

          if (transacoesPadrao?.length) {
            const aquiTable = transacoesPadrao.map(data => ({
              ...data,
              data: moment(data.data).format('DD/MM/YYYY'),
              codigoTransacao: data.codigoTransacao,
              descricaoTransacao: data.descricaoTransacao,
              nsu: data.nsu,
              nome: data.nome,
              historicoEvento: data.historicoEvento,
              tipoEvento: data.tipoEvento,
              valorSimples: data.valor,
              valorDiario: data.valor,
              valorPlanilha:
                data.tipoEvento == 'C'
                  ? '+' + formattedMoney(parseFloat(data.valor))
                  : '-' + formattedMoney(parseFloat(data.valor)),
              centroCusto: data.centroCusto.descricao,
              centroCustoId: data.centroCusto.id,
              complemento: data.documentoFederal,
              complementoPlanilha: data.documentoFederal + data.nome,
              complementoNome: data.nome,
              codigo: data.codigo,
              id: data.codigoTransacao,
              idUrl: data.id,
            }));
            setRows(aquiTable);
            setPdfExtrato(aquiTable);
            setPdfExtratoDiario(aquiTable);
          }
        })
        .catch(error => {
          if (
            error.response.status == 400 ||
            error.response.status == 404 ||
            error.response.status == 409 ||
            error.response.status == 500
          ) {
            toastComponentError(error.response.data.mensagem, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            dispatch(hideLoading(false));
            setRowsDiario([]);
            setRows([]);
            setPdfExtrato();
            setPdfExtratoDiario();
          }
        })
        .catch(error => {
          dispatch(showLoading(false));
          setRowsDiario([]);
          setRows([]);
          setPdfExtrato();
          setPdfExtratoDiario();
          toastComponentError(error.response.data.mensagem);
        });
    }
  }

  function limpar() {
    setSelectionModel([]);
    api_multipague_conta.get(URLLimpar).then(response => {
      setDataDe(dataDePadrao);
      setDataAte(dataAtePadrao);
      setFiltroValorDe('');
      setFiltroValorAte('');
      setFiltroObservacao('');
      setFiltroDocFavorecido('');
      setFiltroFavorecido('');
      setFiltroTransacao('');
      const transacaoFilter = response.data.extratoClienteContas.filter(
        data => data.codigoClienteConta >= 2,
      );
      if (transacaoFilter.length) {
        if (!!transacaoFilter[0].transacoes.length) {
          const aquiTable = transacaoFilter[0].transacoes.map(data => ({
            ...data,
            data: moment(new Date(data.data)).format('DD/MM/YYYY'),
            codigoTransacao: data.codigoTransacao,
            descricaoTransacao: data.descricaoTransacao,
            nsu: data.nsu,
            historicoEvento: data.historicoEvento,
            tipoEvento: data.tipoEvento,
            valorSimples: data.valor,
            valorPlanilha:
              data.tipoEvento == 'C'
                ? '+' + formattedMoney(parseFloat(data.valor))
                : '-' + formattedMoney(parseFloat(data.valor)),
            centroCusto: data.centroCusto.descricao,
            centroCustoId: data.centroCusto.id,
            complemento: data.documentoFederal,
            complementoPlanilha: data.documentoFederal + data.nome,
            complementoNome: data.nome,
            codigo: data.codigo,
            id: data.codigoTransacao,
          }));
          setRows(aquiTable);
          setPdfExtrato(aquiTable);
          setPdfExtratoDiario(aquiTable);
        } else {
          dispatch(showLoading(false));
          setNovaLista([]);
          setPdfExtratoDiario([]);
          setRowsDiario([]);
          setRows([]);
          setPdfExtrato();
          toastComponentError('Nenhum dado disponível para essa data!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
    });
  }

  function detalhesDiarios(data) {
    const aquiDetalhes = data.transacoes.map(value => (
      <List>
        <ListItem>
          <ListItemText
            primary={
              'Data: ' + moment(new Date(value.data)).format('DD/MM/YYYY')
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText primary={'Descrição: ' + value.descricaoEvento} />
        </ListItem>
        <ListItem>
          <ListItemText primary={value.nome} />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              'Evento: ' + value.tipoEvento == 'D' ? 'Débito' : 'Crédito'
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={'Valor: ' + formattedMoney(parseFloat(value.valor))}
          />
        </ListItem>
        <Divider />
      </List>
    ));
    setMostrarDetalhes(aquiDetalhes);
  }

  const handleGoToPrint = value => {
    const valorImprimir = value;
    localStorage.setItem(
      'print-codigo-transacao',
      valorImprimir.codigoTransacao != null
        ? valorImprimir.codigoTransacao
        : '',
    );
    valorImprimir.descricaoEvento != 'PAGAMENTO BOLETO'
      ? api_multipague_conta
          .get(`/comprovantes/${valorImprimir.codigoTransacao}`)
          .then(response => {
            const newContaOrigem = response.data.contaOrigem;
            const newCorrentistaOrigem =
              response.data.correntistaOrigem != null
                ? response.data.correntistaOrigem
                : '';
            const newCorrentistaDestino =
              response.data.correntistaDestino != null
                ? response.data.correntistaDestino
                : '';
            const newContaDestino =
              response.data.contaDestino != null
                ? response.data.contaDestino
                : '';

            localStorage.setItem(
              'print-evento',
              response.data.finalidade != null ? response.data.finalidade : '',
            );
            localStorage.setItem(
              'print-evento-historico',
              value.historicoEvento,
            );
            localStorage.setItem('print-data', valorImprimir.data);
            localStorage.setItem('print-valor', valorImprimir.valor);

            localStorage.setItem(
              'correntista-origem',
              newCorrentistaOrigem.nome != null
                ? newCorrentistaOrigem.nome
                : '',
            );
            localStorage.setItem(
              'correntista-destino',
              newCorrentistaDestino.nome != null
                ? newCorrentistaDestino.nome
                : '',
            );
            localStorage.setItem(
              'print-origem-banco',
              newContaOrigem.nomeBanco,
            );
            localStorage.setItem(
              'print-origem-documento-federal',
              newCorrentistaOrigem.documentoFederal,
            );
            localStorage.setItem(
              'print-origem-agencia',
              newContaOrigem.agencia,
            );
            localStorage.setItem(
              'print-origem-agencia-digito',
              newContaOrigem.agenciaDigito,
            );
            localStorage.setItem(
              'print-origem-conta',
              newContaOrigem.numeroConta,
            );
            localStorage.setItem(
              'print-origem-conta-digito',
              newContaOrigem.numeroContaDigito,
            );
            localStorage.setItem(
              'print-destino-banco',
              newContaDestino.nomeBanco != null
                ? newContaDestino.nomeBanco
                : '',
            );
            localStorage.setItem(
              'print-destino-documento-federal',
              newCorrentistaDestino.documentoFederal != null
                ? newCorrentistaDestino.documentoFederal
                : '',
            );
            localStorage.setItem(
              'print-destino-agencia',
              newContaDestino.agencia != null ? newContaDestino.agencia : '',
            );
            localStorage.setItem(
              'print-destino-conta',
              newContaDestino.numeroConta != null
                ? newContaDestino.numeroConta
                : '',
            );
            localStorage.setItem(
              'print-destino-conta-digito',
              newContaDestino.numeroContaDigito != null
                ? newContaDestino.numeroContaDigito
                : '',
            );
            localStorage.setItem(
              'print-codigo-transacao',
              valorImprimir.codigoTransacao != null
                ? valorImprimir.codigoTransacao
                : '',
            );
            window.open('/imprimir-comprovante');
          })
          .catch(error => {
            if (
              error.response.status == 400 ||
              error.response.status == 404 ||
              error.response.status == 409 ||
              error.response.status == 500
            ) {
              toastComponentError(error.response.data.mensagem, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          })
      : api_multipague_conta
          .get(`/comprovantes/${valorImprimir.codigoTransacao}`)
          .then(response => {
            const newContaOrigem = response.data.contaOrigem;
            const newCorrentistaOrigem = response.data.correntistaOrigem;
            const newCorrentistaDestino = response.data.correntistaDestino;
            const newContaDestino = response.data.boleto;
            const newBanco =
              newContaDestino.banco != null ? newContaDestino.banco : '';

            localStorage.setItem(
              'print-evento',
              response.data.finalidade != null ? response.data.finalidade : '',
            );
            localStorage.setItem('print-data', valorImprimir.data);
            localStorage.setItem('print-valor', newContaDestino.valorPagamento);
            localStorage.setItem('print-titulo', newContaDestino.valorTitulo);
            localStorage.setItem(
              'correntista-origem',
              newCorrentistaOrigem.nome,
            );
            localStorage.setItem(
              'correntista-destino',
              newCorrentistaDestino.nome,
            );
            localStorage.setItem(
              'print-origem-banco',
              newContaOrigem.nomeBanco,
            );
            localStorage.setItem(
              'print-origem-documento-federal',
              newCorrentistaOrigem.documentoFederal,
            );
            localStorage.setItem(
              'print-origem-agencia',
              newContaOrigem.agencia,
            );
            localStorage.setItem(
              'print-origem-agencia-digito',
              newContaOrigem.agenciaDigito,
            );
            localStorage.setItem(
              'print-origem-conta',
              newContaOrigem.numeroConta,
            );
            localStorage.setItem(
              'print-origem-conta-digito',
              newContaOrigem.numeroContaDigito,
            );
            localStorage.setItem('print-destino-banco', newBanco.nome);
            localStorage.setItem(
              'print-destino-documento-federal',
              newCorrentistaDestino.documentoFederal,
            );
            localStorage.setItem(
              'print-destino-agencia',
              newContaDestino.agencia,
            );
            localStorage.setItem(
              'print-destino-conta',
              newContaDestino.numeroConta,
            );
            localStorage.setItem(
              'print-destino-conta-digito',
              newContaDestino.linhaDigitavel,
            );
            localStorage.setItem(
              'print-data-vencimento',
              newContaDestino.dataVencimento,
            );
            window.open('/imprimir-comprovante-boleto');
          })

          .catch(error => {
            if (
              error.response.status == 400 ||
              error.response.status == 404 ||
              error.response.status == 409 ||
              error.response.status == 500
            ) {
              toastComponentError(error.response.data.mensagem, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          });
  };

  const columnsTable = [
    { field: 'data', headerName: 'Data', width: 130 },
    {
      field: isDiario ? 'descricaoEvento' : 'historicoEvento',
      headerName: isDiario ? 'Descrição' : 'Histórico',
      width: 450,
      wordWrap: 'break-word',
    },
    {
      field: 'complemento',
      headerName: 'Complemento',
      width: 450,
      renderCell: event => {
        return (
          <>
            <List component="nav" aria-label="secondary mailbox folders">
              <ListItemText primary={event.row.complemento} />
              <ListItemText primary={event.row.complementoNome} />
            </List>
          </>
        );
      },
    },
    {
      field: isDiario ? 'valorDiario' : 'valor',
      headerName: 'Valor',
      width: 250,
      format: value => value.toLocaleString('pt-BR'),
      renderCell: event => {
        return (
          <>
            {!isDiario ? (
              event.row.tipoEvento == 'D' ? (
                <p
                  style={{
                    color: theme.palette.secondary.main,
                    align: 'right',
                    fontWeight: 'bold',
                  }}
                >
                  {formattedMoney(parseFloat(event.row.valor))}{' '}
                  {event.row.tipoEvento}
                </p>
              ) : (
                <p
                  style={{
                    color: theme.palette.primary.main,
                    fontWeight: 'bold',
                  }}
                >
                  {formattedMoney(parseFloat(event.row.valorDiario))}{' '}
                  {event.row.tipoEvento}
                </p>
              )
            ) : (
              <p
                style={{
                  color: theme.palette.secondary.main,
                  align: 'right',
                  fontWeight: 'bold',
                }}
              >
                {formattedMoney(parseFloat(event.row.valor))}{' '}
                {event.row.tipoEvento}
              </p>
            )}
          </>
        );
      },
    },
    {
      field: 'acoes',
      headerName: 'Ações',
      sortable: false,
      width: 100,
      renderCell: event => {
        return (
          <>
            {' '}
            {event.row.tipoEvento === 'D' && (
              <>
                {' '}
                <PrintIcon
                  color="primary"
                  onClick={() => {
                    handleGoToPrint(event.row);
                  }}
                  className={classes.button}
                />{' '}
              </>
            )}
          </>
        );
      },
    },
  ];

  const columnsTableDiario = [
    {
      field: 'data',
      headerName: 'Data',
      width: 150,
      renderCell: event => {
        return (
          <>
            <p style={{ align: 'right', fontWeight: 'bold' }}>
              {moment(event.row.data).format('DD/MM/YYYY')}
            </p>
          </>
        );
      },
    },
    {
      field: 'cnpj',
      headerName: 'CNPJ',
      width: 250,
      renderCell: event => {
        return (
          <>
            <p style={{ align: 'right', fontWeight: 'bold' }}>
              {localStorage.getItem('printCnpj')}
            </p>
          </>
        );
      },
    },
    {
      field: 'nome',
      headerName: 'Nome',
      width: 250,
      renderCell: event => {
        return (
          <>
            <p style={{ align: 'right', fontWeight: 'bold' }}>
              {localStorage.getItem('printNome')}
            </p>
          </>
        );
      },
    },
    {
      field: 'banco',
      headerName: 'Banco',
      width: 150,
      renderCell: event => {
        return (
          <>
            <p style={{ align: 'right', fontWeight: 'bold' }}>
              {localStorage.getItem('printBanco')}
            </p>
          </>
        );
      },
    },
    {
      field: 'agencia',
      headerName: 'Agencia',
      width: 100,
      renderCell: event => {
        return (
          <>
            <p style={{ align: 'right', fontWeight: 'bold' }}>
              {localStorage.getItem('printAgencia')}
            </p>
          </>
        );
      },
    },
    {
      field: 'conta',
      headerName: 'Conta',
      width: 100,
      format: value => value.toLocaleString('pt-BR'),
      renderCell: event => {
        return (
          <>
            <p style={{ align: 'right', fontWeight: 'bold' }}>
              {localStorage.getItem('printConta')}-
              {localStorage.getItem('printDigito')}
            </p>
          </>
        );
      },
    },
    {
      field: 'saldoAnterior',
      headerName: 'Saldo Anterior',
      width: 250,
      format: value => value.toLocaleString('pt-BR'),
      renderCell: event => {
        return (
          <>
            <p style={{ align: 'right', fontWeight: 'bold' }}>
              {event.row.saldoAnterior}
            </p>
          </>
        );
      },
    },
    {
      field: 'descricaoEvento',
      headerName: 'Descrição do Evento',
      width: 250,
    },
    {
      field: 'historicoEvento',
      headerName: 'Histórico',
      width: 250,
    },
    {
      field: 'valor',
      headerName: 'Valor',
      width: 250,
      format: value => value.toLocaleString('pt-BR'),
      renderCell: event => {
        return (
          <>
            {event.row.tipoEvento != 'D' ? (
              <p style={{ align: 'right', fontWeight: 'bold' }}>
                {formattedMoney(parseFloat(event.row.valor))}
              </p>
            ) : (
              <p style={{ align: 'right', fontWeight: 'bold' }}>
                {'-' + formattedMoney(parseFloat(event.row.valor))}
              </p>
            )}
          </>
        );
      },
    },
    {
      field: 'saldoDia',
      headerName: 'Saldo do Dia',
      width: 250,
      format: value => value.toLocaleString('pt-BR'),
      renderCell: event => {
        return (
          <>
            <p style={{ align: 'right', fontWeight: 'bold' }}>
              {event.row.saldoDia}
            </p>
          </>
        );
      },
    },
    {
      field: 'totalMovimentacaoDia',
      headerName: 'Movimentações',
      width: 200,
      format: value => value.toLocaleString('pt-BR'),
      renderCell: event => {
        return (
          <>
            {event.row.totalMovimentacaoDia < 0 ? (
              <p style={{ align: 'right', fontWeight: 'bold' }}>
                {event.row.totalMovimentacaoDia}
              </p>
            ) : (
              <p style={{ align: 'right', fontWeight: 'bold' }}>
                {event.row.totalMovimentacaoDia}
              </p>
            )}
          </>
        );
      },
    },
    {
      field: 'acoes',
      headerName: 'Ações',
      sortable: false,
      width: 100,
      renderCell: event => {
        return (
          <>
            {' '}
            <SearchIcon
              color="primary"
              onClick={() => {
                detalhesDiarios(event.row);
              }}
              className={classes.button}
            />{' '}
          </>
        );
      },
    },
  ];

  function handlePdf() {
    localStorage.setItem('pdfExtrato', JSON.stringify(pdfExtrato));
    window.open('/pdf-extrato');
  }

  function handleRelatorioDiario(tipo) {
    api_multipague_conta
      .post(
        `/extrato/exportar?clienteConta=${infoRedux.contaPadrao?.id}&dataInicial=${dataDe}&dataFinal=${dataAte}&tipoArquivo=${tipo}&isDiario=${isDiario}`,
      )
      .then(response => {
        toastComponentSuccess('Arquivo entrou na fila de geração');
      })
      .catch(error => {
        console.log(error);
      });
  }

  const headerMapping = {
    Data: 'data',
    Cnpj: 'cnpj',
    Nome: 'nome',
    Banco: 'banco',
    Agencia: 'agencia',
    Conta: 'conta',
    'Saldo Anterior': 'saldoAnterior',
    Descrição: 'descricaoEvento',
    Histórico: 'historicoEvento',
    'Nome Pessoa': 'nomePessoa',
    Valor: 'valor',
    'Saldo do Dia': 'saldoDia',
    Movimentação: 'totalMovimentacaoDia',
  };

  const headerMappingDiario = {
    Data: 'data',
    Nome: 'nome',
    Histórico: 'historicoEvento',
    'Centro de Custo': 'centroCusto',
    Complemento: 'complementoPlanilha',
    Valor: 'valorPlanilha',
  };

  const generateCSV = (rows, headerMapping) => {
    const csvRows = [];

    csvRows.push(Object.keys(headerMapping).join(';'));

    rows.forEach(row => {
      const values = Object.keys(headerMapping).map(header => {
        const field = headerMapping[header];
        const value = row[field];

        if (header === 'Cnpj') {
          return `\t${value.toString().padStart(14, '0')}`;
        }

        return value !== undefined && value !== null ? value : '';
      });

      csvRows.push(values.join(';'));
    });

    return '\uFEFF' + csvRows.join('\n');
  };

  const downloadCSV = (data, header) => {
    const csvData = generateCSV(data, header);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', 'Extrato.csv');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <Wrapper
      title="Extrato"
      crumb={[
        { name: 'Dashboard', link: '/dashboard' },
        { name: 'Minhas Contas' },
      ]}
    >
      <SaldoAtualExtrato />
      <div style={{ height: '1rem' }} />
      <Box style={{ borderRadius: '20px', boxShadow: 'none' }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={2}>
            <TextField
              variant="outlined"
              size="small"
              required
              id="dataDe"
              placeholder="DD/MM/AAAA"
              label="Data - De:"
              value={dataDe}
              InputProps={{
                inputProps: { min: handleDataDeMinima().format('YYYY-MM-DD') },
              }}
              type="date"
              fullWidth
              name="dataDe"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={e => {
                setDataDe(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              variant="outlined"
              size="small"
              required
              id="dataAte"
              type="date"
              label="Data - Até:"
              placeholder="DD/MM/AAAA"
              value={dataAte}
              InputProps={{
                inputProps: { max: handleDataAteMaxima().format('YYYY-MM-DD') },
              }}
              defaultValue="2021-01-31"
              fullWidth
              name="dataAte"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={e => {
                setDataAte(e.target.value);
              }}
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={8}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Grid
              item
              xs={12}
              md={12}
              style={{
                gap: '1rem',
                display: 'flex',
                justifyContent: 'flex-end',
                flexWrap: 'wrap',
              }}
            >
              {!maisFiltros ? (
                <Tooltip title="+ Filtros">
                  <IconButton
                    style={{ padding: '0 8px' }}
                    variant="contained"
                    disabled={isDiario}
                    color="primary"
                    onClick={() => {
                      setMaisFiltros(true);
                    }}
                    className={classesDatagrid.loadingButton}
                  >
                    <AddIcon
                      style={{
                        color: theme.palette.getContrastText(
                          theme.palette.appBar[theme.palette.type].bgMenu,
                        ),
                      }}
                      color={theme.palette.getContrastText(
                        theme.palette.appBar[theme.palette.type].bgMenu,
                      )}
                    />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="- Filtros">
                  <IconButton
                    variant="contained"
                    style={{ padding: '0 8px' }}
                    color="primary"
                    onClick={() => {
                      setMaisFiltros(false);
                    }}
                    className={classesDatagrid.loadingButton}
                  >
                    <RemoveIcon
                      style={{
                        color: theme.palette.getContrastText(
                          theme.palette.appBar[theme.palette.type].bgMenu,
                        ),
                      }}
                      color={theme.palette.getContrastText(
                        theme.palette.appBar[theme.palette.type].bgMenu,
                      )}
                    />
                  </IconButton>
                </Tooltip>
              )}

              <Tooltip title="Buscar">
                <IconButton
                  variant="contained"
                  style={{ padding: isSmallScreen ? '12px 13px' : '0 13px' }}
                  color="primary"
                  onClick={
                    isDiario ? () => mudarParaDiario() : () => mudarParaPadrao()
                  }
                  className={classesDatagrid.loadingButton}
                >
                  <MdSearch
                    color={theme.palette.getContrastText(
                      alpha(
                        theme.palette.appBar[theme.palette.type].bgMenu,
                        0.4,
                      ),
                    )}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title="Limpar">
                <IconButton
                  variant="contained"
                  style={{ padding: isSmallScreen ? '12px 13px' : '0 13px' }}
                  color="primary"
                  onClick={() => {
                    limpar();
                  }}
                  className={classesDatagrid.loadingButton}
                >
                  <MdCleaningServices
                    color={theme.palette.getContrastText(
                      theme.palette.appBar[theme.palette.type].bgMenu,
                    )}
                  />
                </IconButton>
              </Tooltip>
              {isDiario && !mostrarDetalhes ? (
                <Tooltip title="Saldo por período">
                  <IconButton
                    variant="contained"
                    style={{ padding: '0 8px' }}
                    onClick={() => mudarParaPadrao()}
                    className={classesDatagrid.loadingButton}
                  >
                    <FindReplaceIcon
                      style={{
                        color: theme.palette.getContrastText(
                          theme.palette.appBar[theme.palette.type].bgMenu,
                        ),
                      }}
                      color={theme.palette.getContrastText(
                        theme.palette.appBar[theme.palette.type].bgMenu,
                      )}
                    />
                  </IconButton>
                </Tooltip>
              ) : (
                !mostrarDetalhes && (
                  <Tooltip title="Saldo diário">
                    <IconButton
                      variant="contained"
                      style={{ padding: isSmallScreen ? '12px' : '0 8px' }}
                      onClick={() => mudarParaDiario()}
                      className={classesDatagrid.loadingButton}
                    >
                      <FindReplaceIcon
                        style={{
                          color: theme.palette.getContrastText(
                            theme.palette.appBar[theme.palette.type].bgMenu,
                          ),
                        }}
                        color={theme.palette.getContrastText(
                          theme.palette.appBar[theme.palette.type].bgMenu,
                        )}
                      />
                    </IconButton>
                  </Tooltip>
                )
              )}
              {isDiario && (
                <>
                  <Box
                    style={{
                      borderRadius: '20px',
                      padding: 0,
                      margin: 0,
                      boxShadow: 'none',
                      backgroundColor: alpha(theme.palette.primary.main, 0.1),
                    }}
                  >
                    <ExcelGrid
                      handlePDFCSV={{
                        downloadPDF: () => handleRelatorioDiario('PDF'),
                        downloadCSV: () => handleRelatorioDiario('CSV'),
                        carregaExcel: true,
                        carregaIconExcel: false,
                        title: 'MeuExtratoDiario',
                      }}
                    />
                  </Box>
                </>
              )}
              {mostrarDetalhes && (
                <>
                  <Tooltip title="Voltar">
                    <IconButton
                      variant="contained"
                      style={{ padding: '0 15px' }}
                      color="primary"
                      onClick={() => setMostrarDetalhes(false)}
                      className={classesDatagrid.loadingButton}
                    >
                      <FindReplaceIcon
                        color={theme.palette.getContrastText(
                          alpha(
                            theme.palette.appBar[theme.palette.type].bgMenu,
                            0.4,
                          ),
                        )}
                      />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            lg={2}
            style={{
              display: 'flex',
              gap: 20,
              flexWrap: 'wrap',
            }}
          >
            <Chip
              color="primary"
              onClick={() => {
                history.push('/ArquivosGeradosExtrato', {
                  cliente: infoRedux?.contaPadrao?.id,
                  dataInicial: dataDe,
                  dataFinal: dataAte,
                  clienteConte: infoRedux?.contaPadrao?.id,
                  origemTransacao:
                    filtroTransacao != null ? filtroTransacao : '',
                  nome: filtroFavorecido != null ? filtroFavorecido : '',
                  documento:
                    filtroDocFavorecido != null ? filtroDocFavorecido : '',
                  descricao: filtroObservacao != null ? filtroObservacao : '',
                  valorInicial: filtroValorDe != null ? filtroValorDe : '',
                  valorFinal: filtroValorAte != null ? filtroValorAte : '',
                  maisFiltros: maisFiltros,
                  mostrarDetalhes: mostrarDetalhes,
                  isDiario: isDiario,
                });
              }}
              variant="outlined"
              style={{ width: '100%', cursor: 'pointer' }}
              label="Consultar arquivos gerados"
            />
          </Grid>
        </Grid>
      </Box>

      {maisFiltros && !isDiario && (
        <Box style={{ borderRadius: '20px' }}>
          <Grid
            container
            spacing={3}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Grid item xs={12} md={2} lg={2}>
              <FormControl
                variant="outlined"
                size="small"
                style={{ width: '100%' }}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Tipo de Transação
                </InputLabel>
                <Select
                  fullWidth
                  label="Transação"
                  id="outlined-size-small"
                  variant="outlined"
                  value={filtroTransacao}
                  size="small"
                  onChange={e => setFiltroTransacao(e.target.value)}
                >
                  <MenuItem
                    value=""
                    selected={filtroTransacao == null}
                  ></MenuItem>
                  <MenuItem value="TRANSFERENCIA_INTERNA">
                    TRANSFERÊNCIA INTERNA
                  </MenuItem>
                  <MenuItem value="ENVIO_TED">ENVIO DE TED</MenuItem>
                  <MenuItem value="PAGAMENTO_BOLETO">PAG DE BOLETO</MenuItem>
                  <MenuItem value="PAGAMENTO_TITULO_CONCESSIONARIA">
                    PAG TÍTULO CONCESSIONÁRIA
                  </MenuItem>
                  <MenuItem value="RECEB_TRANSF">
                    RECEB DE TRANFERÊNCIA
                  </MenuItem>
                  <MenuItem value="RECEB_TED">RECEB DE TED</MenuItem>
                  <MenuItem value="RECEB_BOLETO">RECEB DE BOLETO</MenuItem>
                  <MenuItem value="DEVOLUCAO_TRANSF">
                    DEVOLUÇÃO DE TRANSFERÊNCIA
                  </MenuItem>
                  <MenuItem value="DEVOLUCAO_TED">DEVOLUÇÃO DE TED</MenuItem>
                  <MenuItem value="DEVOLUCAO_PAGAMENTO_TITULO_CONCESSIONARIA">
                    DEV PAG TÍTULO CONCESSIONÁRIA
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                variant="outlined"
                size="small"
                id="filtroFavorecido"
                label="Favorecido / Beneficiário:"
                value={filtroFavorecido}
                fullWidth
                name="filtroFavorecido"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={e => {
                  setFiltroFavorecido(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                variant="outlined"
                size="small"
                id="filtroDocFavorecido"
                label="Doc. do Favorecido / Beneficiário:"
                value={filtroDocFavorecido}
                fullWidth
                name="filtroDocFavorecido"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={e => {
                  setFiltroDocFavorecido(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                variant="outlined"
                size="small"
                id="filtroFavorecido"
                label="Histórico:"
                value={filtroObservacao}
                fullWidth
                name="filtroObservacao"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={e => {
                  setFiltroObservacao(e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            style={{ display: 'flex', justifyContent: 'flex-start' }}
          >
            <Grid item xs={12} md={2}>
              <InputMoney
                variant="outlined"
                size="small"
                label="Valor de:"
                value={filtroValorDe}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={e => {
                  setFiltroValorDe(e);
                }}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <InputMoney
                variant="outlined"
                size="small"
                label="Valor até:"
                value={filtroValorAte}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={val => {
                  setFiltroValorAte(val);
                }}
              />
            </Grid>
          </Grid>
        </Box>
      )}

      {!mostrarDetalhes ? (
        <>
          {isDiario && !!novaLista && (
            <>
              <Box
                style={{
                  borderRadius: '20px',
                  boxShadow: 'none',
                  backgroundColor: alpha(theme.palette.primary.main, 0.1),
                }}
              >
                {novaLista}
              </Box>
            </>
          )}
          {!isDiario && (
            <Box style={{ padding: 10, borderRadius: '20px' }}>
              <DataGrid
                headerComponent={() => {
                  return (
                    <>
                      <Grid md={12} className={classes.saldoBotao}>
                        <div className={classes.saldoInicialFinal}>
                          <Chip
                            label={`Saldo inicial do período: ${saldoInicial}`}
                            color="default"
                            variant="outlined"
                          />
                          <Chip
                            label={`Saldo final do período: ${saldoFinal}`}
                            color="default"
                            variant="outlined"
                          />
                        </div>
                        <div className={classes.pdfBotao}>
                          <Chip
                            variant="outlined"
                            color="primary"
                            startIcon={<FaRegFileExcel />}
                            label="Imprimir em Lote"
                            style={{ textTransform: 'capitalize' }}
                            onClick={handleImprimirEmLote}
                          />
                          <ExcelGrid
                            handlePDFCSV={{
                              downloadPDF: () => handleRelatorioDiario('PDF'),
                              downloadCSV: () => handleRelatorioDiario('CSV'),
                            }}
                          />
                        </div>
                      </Grid>
                    </>
                  );
                }}
                rows={isDiario ? rowsDiario : rows}
                rowHeight={60}
                columns={isDiario ? columnsTableDiario : columnsTable}
                pageSize={9}
                height="600px"
                checkboxSelection
                onSelectionModelChange={newSelection => {
                  setSelectionModel(newSelection);
                }}
                selectionModel={selectionModel}
                isRowSelectable={params => params.row.tipoEvento == 'D'}
              />
            </Box>
          )}
        </>
      ) : (
        <Box style={{ display: 'block' }}>{mostrarDetalhes}</Box>
      )}
    </Wrapper>
  );
};

export default SimpleModal;
