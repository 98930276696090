import { useQuery } from 'react-query';
import { api_multipague_despesa } from '../../services/api';
import moment from 'moment';

export const useGetDespesas = (infoRedux, conta = false, options) => {
  return useQuery(
    'getDespesas',
    async () => {
      const dataInicial = moment(new Date().getFullYear() + '-01-01').format(
        'YYYY-MM-DD',
      );
      const dataFinal = moment(new Date().getFullYear() + '-12-31').format(
        'YYYY-MM-DD',
      );

      const URL = `/despesa?cliente=${localStorage.getItem(
        'contaId',
      )}&clienteConta=${
        infoRedux.contaPadrao.id
      }&dataInicial=${dataInicial}&dataFinal=${dataFinal}`;

      const URLConta = `/despesa?dataInicial=${dataInicial}&dataFinal=${dataFinal}&contextoConsolidado=true`;

      try {
        if (infoRedux?.contaPadrao?.id) {
          const response = await api_multipague_despesa.get(
            !conta ? URL : URLConta,
          );

          return response.data;
        }
      } catch (error) {
        console.error('Error fetching agenda financeira:', error);
        throw new Error('Failed to fetch agenda financeira');
      }
    },
    {
      ...options,
      enabled: !!infoRedux?.contaPadrao?.id,
    },
  );
};
