import React, { useEffect, useState } from 'react';
import { BsPlusCircle } from 'react-icons/bs';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { api_multipague_conta } from '../../services/api';

import { useDispatch, useSelector } from 'react-redux';
import { hideLoading, showLoading } from '../../store/modules/loading/actions';
import BoxContainer from '../../components/Box';
import Wrapper from '../../components/Wrapper';
import { useAuthContext } from '../../Context/AuthContext';
import { toastComponentError } from '../../components/Toast';
import { defaultInfoClientConta, defaultNovoCorrentista } from './constants';
import { useStyles } from './styles';
import useTariffsClient from './useTariffsClient';

export default function MeuPerfil() {
  const dispatch = useDispatch();
  const { permissionAdmin } = useAuthContext();
  const classes = useStyles();
  const { tariffs } = useTariffsClient();
  const { contaPadrao } = useSelector(state => state.contas);
  const [novoCorrentista, setNovoCorrentista] = useState(
    defaultNovoCorrentista,
  );
  const [showNovoCorrentista, setShowNovoCorrentista] = useState(false);
  const [listaCorrentistas, setListaCorrentistas] = useState([]);

  useEffect(() => {
    if (listaCorrentistas?.length > 1) {
      setShowNovoCorrentista(true);
    } else {
      setShowNovoCorrentista(false);
    }
  }, [listaCorrentistas]);

  const [infoContaCliente, setInfoContaCliente] = useState(
    defaultInfoClientConta,
  );
  const isAccountEscrow = JSON.parse(localStorage.getItem('accounts_default'));

  const URL = `/conta/cliente/${localStorage.getItem('contaId')}`;

  const getInfoClient = () => {
    api_multipague_conta
      .get(URL)
      .then(({ data }) => {
        setListaCorrentistas(data.clienteCorrentistas);
        const admin = data?.clienteContas?.filter(
          clientes => clientes.tipoControle === 'ESCROW',
        );
        const formatted = {
          cnpjCpf: data?.cnpjCpf,
          clienteCorrentistas: data?.clienteCorrentistas,
          checked: !!data?.clienteContas
            ? data?.clienteContas[0]?.assinaturaSolidaria
            : '',
          razaoSocial:
            data?.cnpjCpf?.length < 14
              ? data?.pessoa?.nome
              : data?.pessoa?.razaoSocial,
          id: data?.id,
          nome: data?.nome,
          dataCadastro: data.dataCadastro,
          usuarioAberturaConta: data?.usuarioAberturaConta,
          administrador: !!admin ? admin[0]?.administradorFundo : '',
          fundos: !!admin ? admin[0]?.fundos.map(fundo => fundo?.nome) : [],
          clienteContas: data?.clienteContas,
          pessoa: {
            ...data.pessoa,
            pessoaContatos: data?.pessoa?.pessoaContatos[0],
            pessoasEndereco: data?.pessoa?.pessoasEndereco[0],
            clienteLogins: !!data?.pessoa?.clienteLogins
              ? data?.pessoa?.clienteLogins[0]
              : '',
          },
          situacao: data?.situacao,
        };
        setInfoContaCliente(formatted);
        dispatch(showLoading(false));
        dispatch(hideLoading(false));
      })
      .catch(error => {
        dispatch(showLoading(false));
        dispatch(hideLoading(false));
        console.log(error);
      });
  };

  useEffect(() => {
    dispatch(showLoading(true));
    getInfoClient();
  }, []);

  async function addNewCorrentista() {
    dispatch(showLoading(true));
    try {
      const body = {
        contato: {
          email: novoCorrentista.email,
          nome: novoCorrentista.nome,
        },
        cpf: novoCorrentista.cpf,
        nome: novoCorrentista.nome,
        representanteLegal: true,
        rg: novoCorrentista.rg,
        assinaturaSolidaria: infoContaCliente.checked,
      };
      const { data } = await api_multipague_conta.post(
        `conta/${contaPadrao.id}/correntista`,
        body,
      );

      setListaCorrentistas([...listaCorrentistas, body]);
      getInfoClient();
    } catch (err) {
      toastComponentError(err.response.data.mensagem);
      console.log(err);
    } finally {
      dispatch(hideLoading(false));
    }
  }

  return (
    <Wrapper
      title="Meu Perfil"
      crumb={[{ name: 'Dashboard', link: '/dashboard' }]}
    >
      <React.Fragment>
        <BoxContainer>
          <Grid container spacing={3} style={{ marginBottom: '40px' }}>
            {tariffs}
            <Grid item xs={12} md={12}>
              <Typography variant="subtitle1">
                Dados da Pessoa{' '}
                {infoContaCliente?.cnpjCpf?.length < 14 ? 'Física' : 'Jurídica'}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                size="small"
                disabled
                id="cnpjCpf"
                name="cnpjCpf"
                label={infoContaCliente?.cnpjCpf?.length < 14 ? 'CPF' : 'CNPJ'}
                value={infoContaCliente?.cnpjCpf}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                size="small"
                required
                disabled
                id="razaoSocial"
                name="razaoSocial"
                label={
                  infoContaCliente?.cnpjCpf?.length < 14
                    ? 'Nome'
                    : 'Razão Social'
                }
                value={infoContaCliente.razaoSocial || infoContaCliente.nome}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} sm={12}>
              <TextField
                variant="outlined"
                size="small"
                required
                disabled
                id="nomeFantasia"
                name="nomeFantasia"
                label="Nome Fantasia"
                value={infoContaCliente?.pessoa?.nomeFantasia}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} sm={12}>
              <TextField
                variant="outlined"
                size="small"
                disabled
                id="inscEstadual"
                name="inscEstadual"
                label="Inscrição Estadual"
                value={infoContaCliente?.pessoa?.documentoEstadual}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                autoComplete="shipping address-line2"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                size="small"
                required
                disabled
                id="inscMunicipal"
                name="inscMunicipal"
                label="Inscrição Municipal"
                value={infoContaCliente?.pessoa?.documentoMunicipal}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                autoComplete="shipping address-level2"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                size="small"
                id="dataAbertura"
                disabled
                name="dataAbertura"
                label="Data de Abertura - Ex: dd/mm/aaaa"
                value={infoContaCliente.dataCadastro}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                size="small"
                required
                disabled
                id="paginaWeb"
                name="paginaWeb"
                label="Página Web"
                value={infoContaCliente?.pessoa?.pessoaContatos?.paginaWeb}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                autoComplete="shipping postal-code"
              />
            </Grid>
          </Grid>
        </BoxContainer>
        <BoxContainer>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Box marginRight="8px">
                  <Typography variant="subtitle1">
                    Dados do Representante
                  </Typography>
                </Box>

                {listaCorrentistas?.length < 2 && (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    onClick={() => setShowNovoCorrentista(!showNovoCorrentista)}
                  >
                    {permissionAdmin && <BsPlusCircle size={18} />}
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                variant="outlined"
                size="small"
                required
                disabled
                id="cpf"
                name="cpf"
                label="CPF"
                value={
                  infoContaCliente?.clienteCorrentistas
                    ? infoContaCliente?.clienteCorrentistas[0]?.cpf
                    : ''
                }
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                variant="outlined"
                size="small"
                required
                disabled
                id="rg"
                name="rg"
                label="RG"
                value={
                  infoContaCliente?.clienteCorrentistas
                    ? infoContaCliente?.clienteCorrentistas[0]?.rg
                    : ''
                }
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                autoComplete="shipping postal-code"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                variant="outlined"
                size="small"
                required
                disabled
                id="nome"
                name="nome"
                label="Nome"
                value={
                  infoContaCliente?.clienteCorrentistas
                    ? infoContaCliente?.clienteCorrentistas[0]?.nome
                    : ''
                }
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                autoComplete="shipping postal-code"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                variant="outlined"
                size="small"
                required
                disabled
                id="email"
                name="email"
                label="E-mail"
                value={
                  infoContaCliente?.clienteCorrentistas
                    ? infoContaCliente?.clienteCorrentistas[0]?.contato?.email
                    : ''
                }
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                autoComplete="shipping postal-code"
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <TextField
                variant="outlined"
                size="small"
                required
                disabled
                id="redeSocial"
                name="redeSocial"
                label="Rede Social"
                value={
                  infoContaCliente?.clienteCorrentistas
                    ? infoContaCliente?.clienteCorrentistas[0]?.contato
                        ?.redeSocial
                    : ''
                }
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                autoComplete="shipping postal-code"
              />
            </Grid>
            <Grid item xs={12} md={1}>
              <TextField
                variant="outlined"
                size="small"
                required
                disabled
                id="ddi"
                name="ddi"
                label="DDI"
                value={
                  infoContaCliente?.clienteCorrentistas
                    ? infoContaCliente?.clienteCorrentistas[0]?.contato?.ddi
                    : ''
                }
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                autoComplete="shipping postal-code"
              />
            </Grid>
            <Grid item xs={12} md={1}>
              <TextField
                variant="outlined"
                size="small"
                required
                disabled
                id="ddd"
                name="ddd"
                label="DDD"
                value={
                  infoContaCliente?.clienteCorrentistas
                    ? infoContaCliente?.clienteCorrentistas[0]?.contato?.ddd
                    : ''
                }
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                autoComplete="shipping postal-code"
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <TextField
                variant="outlined"
                size="small"
                required
                disabled
                id="telefone"
                name="telefone"
                label="Telefone"
                value={
                  infoContaCliente?.clienteCorrentistas
                    ? infoContaCliente?.clienteCorrentistas[0]?.contato
                        ?.telefone
                    : ''
                }
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                autoComplete="shipping postal-code"
              />
            </Grid>
          </Grid>
          <div styke={{ height: '1rem' }} />
          <Box>
            {showNovoCorrentista && (
              <>
                <Box marginRight="8px" marginTop="20px" marginBottom="20px">
                  <Typography variant="subtitle1">
                    Dados do Segundo Representante
                  </Typography>
                </Box>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={2}>
                    <TextField
                      disabled={!!(listaCorrentistas?.length > 1)}
                      variant="outlined"
                      size="small"
                      id="cpf2"
                      name="cpf2"
                      label="CPF"
                      value={
                        !!(listaCorrentistas?.length > 1)
                          ? listaCorrentistas[1].cpf
                          : novoCorrentista.cpf
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={e => {
                        setNovoCorrentista({
                          ...novoCorrentista,
                          cpf: e.target.value,
                        });
                      }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      disabled={!!(listaCorrentistas?.length > 1)}
                      variant="outlined"
                      size="small"
                      id="rg2"
                      name="rg2"
                      label="RG"
                      value={
                        !!(listaCorrentistas?.length > 1)
                          ? listaCorrentistas[1].rg
                          : novoCorrentista.rg
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={e => {
                        setNovoCorrentista({
                          ...novoCorrentista,
                          rg: e.target.value,
                        });
                      }}
                      fullWidth
                      autoComplete="shipping postal-code"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      disabled={!!(listaCorrentistas?.length > 1)}
                      variant="outlined"
                      size="small"
                      id="nome2"
                      name="name"
                      label="Nome"
                      value={
                        !!(listaCorrentistas?.length > 1)
                          ? listaCorrentistas[1].nome
                          : novoCorrentista.nome
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={e => {
                        setNovoCorrentista({
                          ...novoCorrentista,
                          nome: e.target.value,
                        });
                      }}
                      fullWidth
                      autoComplete="shipping postal-code"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      disabled={!!(listaCorrentistas?.length > 1)}
                      variant="outlined"
                      size="small"
                      id="nome2"
                      name="email-novo-correntista"
                      label="E-mail"
                      value={
                        !!(listaCorrentistas?.length > 1)
                          ? listaCorrentistas[1]?.contato?.email
                          : novoCorrentista.email
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={e => {
                        setNovoCorrentista({
                          ...novoCorrentista,
                          email: e.target.value,
                        });
                      }}
                      fullWidth
                      autoComplete="shipping postal-code"
                    />
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <TextField
                      disabled={!!(listaCorrentistas?.length > 1)}
                      variant="outlined"
                      size="small"
                      id="redeSocial2"
                      name="redeSocial2-novo-correntista"
                      label="Rede Social"
                      value={
                        !!(listaCorrentistas?.length > 1)
                          ? listaCorrentistas[1]?.contato?.redeSocial
                          : novoCorrentista?.redeSocial
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={e => {
                        setNovoCorrentista({
                          ...novoCorrentista,
                          email: e.target.value,
                        });
                      }}
                      fullWidth
                      autoComplete="shipping postal-code"
                    />
                  </Grid>
                  <Grid item xs={12} md={1}>
                    <TextField
                      disabled={!!(listaCorrentistas?.length > 1)}
                      variant="outlined"
                      size="small"
                      id="ddi2"
                      name="dddi2-novo-correntista"
                      label="DDI"
                      value={
                        !!(listaCorrentistas?.length > 1)
                          ? listaCorrentistas[1]?.contato?.ddi
                          : novoCorrentista.ddi
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={e => {
                        setNovoCorrentista({
                          ...novoCorrentista,
                          ddi: e.target.value,
                        });
                      }}
                      fullWidth
                      autoComplete="shipping postal-code"
                    />
                  </Grid>
                  <Grid item xs={12} md={1}>
                    <TextField
                      disabled={!!(listaCorrentistas?.length > 1)}
                      variant="outlined"
                      size="small"
                      id="ddd2"
                      name="ddd2-novo-correntista"
                      label="DDD"
                      value={
                        !!(listaCorrentistas?.length > 1)
                          ? listaCorrentistas[1]?.contato?.ddd
                          : novoCorrentista.ddd
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={e => {
                        setNovoCorrentista({
                          ...novoCorrentista,
                          email: e.target.value,
                        });
                      }}
                      fullWidth
                      autoComplete="shipping postal-code"
                    />
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <TextField
                      disabled={!!(listaCorrentistas?.length > 1)}
                      variant="outlined"
                      size="small"
                      id="telefone2"
                      name="telefone2-novo-correntista"
                      label="Telefone"
                      value={
                        !!(listaCorrentistas?.length > 1)
                          ? listaCorrentistas[1]?.contato?.telefone
                          : novoCorrentista?.telefone
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={e => {
                        setNovoCorrentista({
                          ...novoCorrentista,
                          telefone: e.target.value,
                        });
                      }}
                      fullWidth
                      autoComplete="shipping postal-code"
                    />
                  </Grid>
                </Grid>
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                  marginTop="20px"
                >
                  <Checkbox
                    disabled={!!(listaCorrentistas?.length > 1)}
                    color="primary"
                    checked={
                      infoContaCliente?.clienteContas
                        ? infoContaCliente?.clienteContas[0]
                            ?.assinaturaSolidaria
                        : ''
                    }
                    onChange={event =>
                      setInfoContaCliente({
                        ...infoContaCliente,
                        clienteContas: infoContaCliente?.clienteContas?.map(
                          accountClient => ({
                            ...accountClient,
                            assinaturaSolidaria: event.target.checked,
                          }),
                        ),
                      })
                    }
                  />
                  <Box marginLeft="10px">
                    <Typography>
                      Assinatura solidária (representantes assinam em conjunto)
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  marginTop="20px"
                >
                  {listaCorrentistas?.length < 2 && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={addNewCorrentista}
                    >
                      Adicionar
                    </Button>
                  )}
                </Box>
              </>
            )}
          </Box>
        </BoxContainer>
        {isAccountEscrow?.tipoControle === 'ESCROW' ? (
          <BoxContainer>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <Typography variant="subtitle1" gutterBottom>
                  Dados da Conta
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  variant="outlined"
                  size="small"
                  required
                  disabled
                  label="Administrador"
                  name="administrador"
                  fullWidth
                  value={infoContaCliente.administrador?.nomeAdministradorFundo}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <InputLabel id="demo-multiple-chip-label" shrink>
                  Fundos
                </InputLabel>
                <Select
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  multiple
                  style={{ width: '100%' }}
                  label="Fundos"
                  disabled
                  value={infoContaCliente.fundos}
                  input={
                    <OutlinedInput id="select-multiple-chip" label="Fundos" />
                  }
                  renderValue={selected => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                      {selected.map(value => (
                        <Chip
                          size="small"
                          disabled
                          key={value}
                          label={value}
                          variant="default"
                        />
                      ))}
                    </Box>
                  )}
                >
                  {infoContaCliente.fundos.map(fundo => (
                    <MenuItem key={fundo} value={fundo}>
                      {fundo}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          </BoxContainer>
        ) : null}
        <BoxContainer>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Typography variant="subtitle1" gutterBottom>
                Dados de Contato
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                size="small"
                required
                id="nome"
                disabled
                name="nome"
                label="Nome"
                fullWidth
                value={infoContaCliente?.pessoa?.pessoaContatos?.nomeContato}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                size="small"
                required
                disabled
                type="email"
                label="Email"
                id="email"
                name="email"
                value={infoContaCliente?.pessoa?.pessoaContatos?.email}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <TextField
                variant="outlined"
                size="small"
                disabled
                required
                id="redeSocial"
                label="Rede Social"
                fullWidth
                name="redeSocial"
                value={infoContaCliente?.pessoa?.pessoaContatos?.paginaWeb}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={1}>
              <TextField
                variant="outlined"
                size="small"
                required
                disabled
                label="DDI"
                name="dd1"
                fullWidth
                value={infoContaCliente?.pessoa?.pessoaContatos?.ddi}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={1}>
              <TextField
                variant="outlined"
                size="small"
                required
                disabled
                label="DDD"
                name="ddd1"
                fullWidth
                value={infoContaCliente?.pessoa?.pessoaContatos?.ddd}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <TextField
                variant="outlined"
                size="small"
                required
                disabled
                label="Telefone"
                name="telefone1"
                fullWidth
                value={infoContaCliente?.pessoa?.pessoaContatos?.telefone}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </BoxContainer>
        <BoxContainer>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Typography variant="subtitle1" gutterBottom>
                Dados de Endereço
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                variant="outlined"
                size="small"
                required
                id="cep"
                disabled
                label="CEP"
                fullWidth
                name="cep"
                value={infoContaCliente?.pessoa?.pessoasEndereco?.cep}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={9}>
              <TextField
                variant="outlined"
                size="small"
                required
                disabled
                id="logradouro"
                label="Logradouro"
                fullWidth
                name="logradouro"
                value={infoContaCliente?.pessoa?.pessoasEndereco?.endereco}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                variant="outlined"
                size="small"
                id="complemento"
                disabled
                label="Complemento"
                fullWidth
                name="complemento"
                value={infoContaCliente?.pessoa?.pessoasEndereco?.complemento}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <TextField
                variant="outlined"
                size="small"
                required
                disabled
                id="numero"
                label="Número"
                fullWidth
                name="numero"
                value={infoContaCliente?.pessoa?.pessoasEndereco?.enderecoNum}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <TextField
                variant="outlined"
                size="small"
                required
                disabled
                id="bairro"
                label="Bairro"
                fullWidth
                name="bairro"
                value={infoContaCliente?.pessoa?.pessoasEndereco?.bairro}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                variant="outlined"
                size="small"
                required
                disabled
                id="cidade"
                label="Cidade"
                fullWidth
                name="cidade"
                value={infoContaCliente?.pessoa?.pessoasEndereco?.cidade}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                variant="outlined"
                size="small"
                required
                disabled
                id="uf"
                label="UF"
                fullWidth
                name="uf"
                value={infoContaCliente?.pessoa?.pessoasEndereco?.uf}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                variant="outlined"
                size="small"
                required
                disabled
                id="pais"
                label="País"
                fullWidth
                value={infoContaCliente?.pessoa?.pessoasEndereco?.pais}
                name="pais"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3} style={{ marginTop: '20px' }}>
            <Grid item xs={12} md={6}>
              <InputLabel shrink htmlFor="age-native-label-placeholder">
                Tipo de Endereço
              </InputLabel>
              <FormControl
                variant="outlined"
                size="small"
                fullwidth
                className={classes.formControl}
              >
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  disabled
                  value={
                    infoContaCliente?.pessoa?.pessoasEndereco?.tipoEndereco
                  }
                  fullwidth
                >
                  <MenuItem value="Residencial">Residencial</MenuItem>
                  <MenuItem value="Comercial">Comercial</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel shrink htmlFor="age-native-label-placeholder">
                Tipo de Residência
              </InputLabel>
              <FormControl
                variant="outlined"
                size="small"
                fullwidth
                className={classes.formControl}
              >
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  disabled
                  value={
                    infoContaCliente?.pessoa?.pessoasEndereco?.tipoResidencia
                  }
                  fullwidth
                >
                  <MenuItem value="Própria">Própria</MenuItem>
                  <MenuItem value="Alugada">Alugada</MenuItem>
                  <MenuItem value="Financiada">Financiada</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </BoxContainer>
        <BoxContainer>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Typography variant="subtitle1" gutterBottom>
                Dados de Acesso
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                disabled
                variant="outlined"
                size="small"
                required
                id="usuario"
                label="Usuário"
                fullWidth
                name="usuario"
                value={infoContaCliente?.cnpjCpf}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                variant="outlined"
                size="small"
                type="password"
                required
                disabled
                id="senhaFinanceira"
                label="Senha Financeira"
                fullWidth
                name="senhaFinanceira"
                value={infoContaCliente?.pessoa?.clienteLogins?.senhaFinanceira}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </BoxContainer>
      </React.Fragment>
    </Wrapper>
  );
}
