import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import LoadingBtn from '../../components/Buttons/ConfirmButton';
import MaskedInput from 'react-text-mask';
import moment from 'moment';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import axios, { api_multipague_consulta_mercado } from '../../services/api';
import 'react-toastify/dist/ReactToastify.css';
import {
  MdSearch,
  MdClear,
  MdDownload,
  MdArrowForward,
  MdCheck,
  MdCleaningServices,
  MdOpenInNew,
} from 'react-icons/md';

import Box from '../../components/Box';
import Wrapper from '../../components/Wrapper';
import DataGrid from '../../components/DataGrid';
import {
  toastComponentError,
  toastComponentSuccess,
} from '../../components/Toast';
import { Dialog, Paper, alpha, useTheme } from '@material-ui/core';
import { FaEye } from 'react-icons/fa';

const useStyles = makeStyles(theme => ({
  dataGridPaperInd: {
    padding: '.5rem 1.5rem',
    marginTop: '-40px',
    marginBottom: '2rem',
    borderTopLeftRadius: '0',
    borderTopRightRadius: '0',
    borderBottomLeftRadius: '20px',
    borderBottomRightRadius: '20px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: `0px 8px 45px -15px ${alpha(theme.palette.primary.dark, 0.2)}`,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '20px',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: '20px',
    padding: theme.spacing(3, 4, 3),
  },
}));

function TextMaskCustomCnpj(props) {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '/',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
      ]}
      placeholderChar={'\u2000'}
    />
  );
}

export default function DataGridDemo() {
  const { contaPadrao } = useSelector(state => state.contas);
  const contaIdCliente = contaPadrao.id;
  const [open, setOpen] = React.useState(false);
  const [openProsseguir, setOpenProsseguir] = React.useState(false);
  const [openProsseguir2, setOpenProsseguir2] = React.useState(false);
  const [ultimaConsulta, setUltimaConsulta] = React.useState('');
  const [openPdf, setOpenPdf] = React.useState(false);
  const theme = useTheme()

  const columns = [
    { field: 'id', headerName: 'ID' },
    { field: 'cnpj', headerName: 'CNPJ', width: 200 },
    { field: 'razaoSocial', headerName: 'Nome', width: 350, flex: 1 },
    { field: 'vencimento', headerName: 'Data Consulta', width: 180 },
    { field: 'status', headerName: 'Status', width: 150 },
    {
      field: 'situacao',
      headerName: 'Ações',
      width: 150,
      type: 'text',
      renderCell: event => {
        return (
          <>
            <LoadingBtn
              variant="contained"
              color="primary"
              title={'Abrir'}
              startIcon={<MdOpenInNew color="background" />}
              onClick={() => {
                handleEditar(event.row);
              }}
            >
              Abrir
            </LoadingBtn>
          </>
        );
      },
    },
  ];

  const [inputValuesCnpj, setInputValuesCnpj] = React.useState({
    textmaskcnpj: '',
  });
  const handleChangeInputCnpj = event => {
    setInputValuesCnpj({
      ...inputValuesCnpj,
      [event.target.name]: event.target.value,
    });
    setCnpj(event.target.value);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseProsseguir = () => {
    setOpenProsseguir(false);
  };
  const handleCloseProsseguir2 = () => {
    setOpenProsseguir2(false);
  };

  const classes = useStyles();

  const [rows, setRows] = useState([]);
  const [ip, setIp] = useState('');
  const [valorInput, setValorInput] = useState({});
  localStorage.setItem('valorIdDetalhes', valorInput);
  const [loadButton, setLoadButton] = useState(false);
  const [pdf, setPdf] = useState('');
  const [pdfExists, setPdfExists] = useState('');
  const [loadButtonAccount, setLoadButtonAccount] = useState(false);
  const [loadButtonEmail, setLoadButtonEmail] = useState(false);
  const [abrirTermos, setAbrirTermos] = useState(false);
  const [abrirTermos1, setAbrirTermos1] = useState(false);
  const [codigo, setCodigo] = useState('');
  const [descricao, setDescricao] = useState('');
  const [linkPdf, setLink] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [dataSolicitacaoDe, setDataSolicitacaoDe] = useState('');
  const [dataSolicitacaoAte, setDataSolicitacaoAte] = useState('');
  const handleSolicitacaoDe = event => {
    setDataSolicitacaoDe(event.target.value);
  };
  const handleSolicitacaoAte = event => {
    setDataSolicitacaoAte(event.target.value);
  };

  const USER_TOKEN = localStorage.getItem('tkn-access');
  const AuthStr = 'Bearer '.concat(USER_TOKEN);
  const URL = `/relatorio?idClienteConta=${contaIdCliente}`;
  const URLPesquisa = `/relatorio?idClienteConta=${contaIdCliente}&dataInicial=${dataSolicitacaoDe}&dataFinal=${dataSolicitacaoAte}&razaoSocial=${descricao}&cnpj=${codigo}`;

  useEffect(() => {
    setLoadButton(true);
    api_multipague_consulta_mercado
      .get(URL, { headers: { Authorization: AuthStr } })
      .then(response => {
        setLoadButton(false);
        setLoadButtonAccount(false);
        setLoadButtonEmail(false);
        const monitorFilter = response.data;

        if (monitorFilter.length) {
          const aquiTable = monitorFilter.map(data => ({
            ...data,
            id: data.id,
            cnpj: data.cnpj,
            razaoSocial: data.razaoSocial,
            vencimento: moment(new Date(data.data)).format('DD/MM/YYYY'),
            status: data.status,
          }));
          setRows(aquiTable);
        } else {
          toastComponentError('Nenhum dado disponível para essa data!');
        }
      })
      .catch(error => {
        setLoadButton(false);
      });
  }, [contaIdCliente]);

  useEffect(() => {
    if (ultimaConsulta) {
      api_multipague_consulta_mercado
        .get(
          `/relatorio/${ultimaConsulta}/pdf?idClienteConta=${contaIdCliente}`,
        )
        .then(response => {
          const pdf64Exists = response.data;
          setPdfExists(pdf64Exists);
        })
        .catch(error => {
          setLoadButton(false);
          if (
            error.response.status == 400 ||
            error.response.status == 404 ||
            error.response.status == 409 ||
            error.response.status == 500
          ) {
            toastComponentError(error.response.data.mensagem);
          }
        });
    }
  }, [ultimaConsulta]);

  function pesquisar() {
    setLoadButton(true);
    api_multipague_consulta_mercado
      .get(URLPesquisa)
      .then(response => {
        setLoadButton(false);
        const monitorFilter = response.data;

        if (monitorFilter.length) {
          const aquiTable = monitorFilter.map(data => ({
            ...data,
            id: data.id,
            cnpj: data.cnpj,
            razaoSocial: data.razaoSocial,
            vencimento: moment(new Date(data.data)).format('DD/MM/YYYY'),
            status: data.status,
          }));
          setRows(aquiTable);
        } else {
          toastComponentError('Nenhum dado disponível para essa data!');
        }
      })
      .catch(error => {
        setLoadButton(false);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 409 ||
          error.response.status == 500
        ) {
          toastComponentError(error.response.data.mensagem);
        }
        setRows([]);
      });
  }

  function limpar() {
    setDescricao('');
    setCodigo('');
    setDataSolicitacaoDe('');
    setDataSolicitacaoAte('');
    pesquisar();
  }

  function json(url) {
    return fetch(url).then(res => res.json());
  }

  let apiKey = '8dc4a62c86a09cd7519c9db6fb8436127d13e50f497ca11628cc130d';
  json(`https://api.ipdata.co?api-key=${apiKey}`).then(data => {
    setIp(data.ip);
  });

  const fazerNovaConsulta = value => {
    json();
    setLoadButton(true);
    api_multipague_consulta_mercado
      .post(
        `/relatorio?idClienteConta=${contaIdCliente}&cnpj=${cnpj.replace(
          /[^0-9]+/g,
          '',
        )}&ip=${ip}`,
      )
      .then(response => {
        setLoadButton(false);
        setOpen(false)
        setOpenProsseguir(false);
        setOpenProsseguir2(false);
        toastComponentSuccess('Enviada solicitação de consulta!');
        setTimeout(function () {
          window.location.href = '/consulta-mercado';
        }, 3000);
      })
      .catch(error => {
        setOpen(false)
        setOpenProsseguir(false);
        setOpenProsseguir2(false);
        setLoadButton(false);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 409 ||
          error.response.status == 500
        ) {
          toastComponentError(error.response.data.mensagem);
        }
      });
  };

  const novaConsulta = value => {
    json();
    setLoadButton(true);
    api_multipague_consulta_mercado
      .get(
        `/relatorio?idClienteConta=${contaIdCliente}&cnpj=${cnpj.replace(
          /[^0-9]+/g,
          '',
        )}&dataInicial=${moment(
          new Date(Date.now() - 30 * 24 * 60 * 60 * 1000),
        ).format('YYYY-MM-DD')}&dataFinal=${moment(new Date(Date.now())).format(
          'YYYY-MM-DD',
        )}&status=CONCLUIDO`,
      )
      .then(response => {
        setLoadButton(false);
        setOpen(false);
        setOpenProsseguir(true);
        setUltimaConsulta(response.data[0].id);
      })
      .catch(response => {
        setLoadButton(false);
        setOpen(false);
        setOpenProsseguir2(true);
        setUltimaConsulta(response.data[0].id);
      });
  };

  const abrindoTermos = value => {
    setAbrirTermos(true);
  };

  const handleEditar = value => {
    setLoadButton(true);
    const pdfId = value.id;
    api_multipague_consulta_mercado
      .get(
        `/relatorio/${pdfId}/pdf?idClienteConta=${contaIdCliente}`,
        { headers: { Authorization: AuthStr } },
      )
      .then(response => {
        setLoadButton(false);
        const pdf64 = response.data;
        setPdf(pdf64);
        setOpenPdf(true);
      })
      .catch(error => {
        setLoadButton(false);
        toastComponentError('Não foi possível abrir o arquivo');
      });
  };

  return (
    <Wrapper
      title="Consulta de Mercado"
      crumb={[{ name: 'Dashboard', link: '/dashboard' }]}
      newRegister={handleOpen}
      newRegisterLabel="Nova Consulta"
    >
      <Dialog
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openPdf}
        className={classes.modal}
        onClose={() => {
          setOpenPdf(false);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openPdf}>
          <div className={classes.paper} style={{ maxWidth: '70vw' }}>
            <h3 id="transition-modal-title">
              Deseja fazer o download do PDF ?
            </h3>
            {linkPdf}
            <div
              style={{
                display: 'flex',
                margin: '30px 0 10px',
                justifyContent: 'space-around',
              }}
            >
              <LoadingBtn
                href={`data:application/pdf;base64,${pdf}`}
                download="Relatório"
                title={'Download PDF'}
                variant="contained"
                color="disabled"
                startIcon={
                  <MdDownload size={20} width={20} color="background" />
                }
              />
            </div>
          </div>
        </Fade>
      </Dialog>
      <Dialog
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper} style={{ maxWidth: '70vw' }}>
            <span style={{ marginTop: '30px' }}>
              Digite abaixo o CNPJ que deseja consultar
            </span>
            <TextField
              fullWidth
              label="CNPJ"
              onChange={handleChangeInputCnpj}
              size="small"
              style={{ marginTop: '30px' }}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputComponent: TextMaskCustomCnpj,
              }}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                margin: '30px 0 5px',
              }}
            >
              <LoadingBtn
                variant="contained"
                color="secondary"
                isLoading={loadButton}
                title={'Cancelar'}
                startIcon={<MdClear color="background" />}
                onClick={handleClose}
              ></LoadingBtn>
              <LoadingBtn
                variant="contained"
                color="primary"
                title={'Prosseguir'}
                startIcon={<MdArrowForward color="background" />}
                isLoading={loadButton}
                onClick={novaConsulta}
              ></LoadingBtn>
              {abrirTermos1 && (
                <div>
                  <h1 style={{ margin: 0, marginBottom: 2 }}>
                    Termo de Aceite
                  </h1>
                  <p>
                    De acordo com o contrato do {theme?.config?.clienteName}, estou aderindo à
                    plataforma do Credit Box no {theme?.config?.clienteName}, estou ciente e
                    concordo que as consultas ficarão registradas através da
                    minha senha de acesso, com data e hora e autorizo a cobrança
                    de R$ 8,00 do serviço prestado.
                  </p>
                  <LoadingBtn
                    onClick={fazerNovaConsulta}
                    variant="contained"
                    color="primary"
                    disabled={loadButton}
                    isLoading={loadButton}
                    title={'Aceito e Consultar'}
                    startIcon={<MdCheck color="background" />}
                  ></LoadingBtn>
                </div>
              )}
            </div>
          </div>
        </Fade>
      </Dialog>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openProsseguir}
        onClose={handleCloseProsseguir}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openProsseguir}>
          <div className={classes.paper} style={{ maxWidth: '70vw' }}>
            {!abrirTermos && (
              <span style={{ margin: '30px 0 40px' }}>
                Já existem consultas referente a este CNPJ nos últimos 10 dias
              </span>
            )}
            {!abrirTermos && (
              <div
                style={{
                  display: 'flex',
                  marginTop: '10px',
                  justifyContent: 'space-between',
                  padding: '15px 0 0',
                }}
              >
                <LoadingBtn
                  variant="contained"
                  color="default"
                  isLoading={loadButton}
                  onClick={handleCloseProsseguir}
                  title={'Cancelar'}
                  startIcon={<MdClear color="background" />}
                >
                  Cancelar
                </LoadingBtn>
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    padding: 0,
                    margin: 0,
                  }}
                >
                  <LoadingBtn
                    href={`data:application/pdf;base64,${pdfExists}`}
                    download="pdfTitle"
                    variant="contained"
                    color="primary"
                    style={{ marginRight: 6 }}
                    isLoading={loadButton}
                    title={'Ver Consulta Existente'}
                    startIcon={<FaEye size={13} color="background" />}
                  ></LoadingBtn>
                  <LoadingBtn
                    variant="contained"
                    color="secondary"
                    isLoading={loadButton}
                    onClick={abrindoTermos}
                    title={' Fazer Nova Consulta'}
                    startIcon={<MdCheck color="background" />}
                  ></LoadingBtn>
                </Box>
              </div>
            )}
            {abrirTermos && (
              <div>
                <h3 style={{ margin: 0, marginBottom: 2 }}>Termo de Aceite</h3>
                <p style={{ marginBottom: 10 }}>
                  De acordo com o contrato do {theme?.config?.clienteName}, estou aderindo à
                  plataforma do Credit Box no {theme?.config?.clienteName}, estou ciente e
                  concordo que as consultas ficarão registradas através da minha
                  senha de acesso, com data e hora e autorizo a cobrança de R$
                  8,00 do serviço prestado.
                </p>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <LoadingBtn
                    onClick={handleCloseProsseguir}
                    variant="contained"
                    color="secondary"
                    isLoading={loadButton}
                    title={'Cancelar'}
                    startIcon={<MdClear color="background" />}
                  >
                    Cancelar
                  </LoadingBtn>
                  <LoadingBtn
                    onClick={fazerNovaConsulta}
                    variant="contained"
                    color="primary"
                    disabled={loadButton}
                    isLoading={loadButton}
                    title={'Aceito e Consultar'}
                    startIcon={<MdCheck color="background" />}
                  ></LoadingBtn>
                </div>
              </div>
            )}
          </div>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openProsseguir2}
        onClose={handleCloseProsseguir2}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openProsseguir2}>
          <div className={classes.paper} style={{ maxWidth: '70vw' }}>
            <div>
              <h1 style={{ margin: 0, marginBottom: 2 }}>Termo de Aceite</h1>
              <p style={{ marginBottom: 10 }}>
                De acordo com o contrato do {theme?.config?.clienteName}, estou aderindo à
                plataforma do Credit Box no {theme?.config?.clienteName}, estou ciente e
                concordo que as consultas ficarão registradas através da minha
                senha de acesso, com data e hora e autorizo a cobrança de R$
                8,00 do serviço prestado.
              </p>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <LoadingBtn
                  onClick={handleCloseProsseguir2}
                  variant="contained"
                  color="secondary"
                  isLoading={loadButton}
                  title={'Cancelar'}
                  startIcon={<MdClear color="background" />}
                >
                  Cancelar
                </LoadingBtn>
                <LoadingBtn
                  onClick={fazerNovaConsulta}
                  variant="contained"
                  color="primary"
                  disabled={loadButton}
                  isLoading={loadButton}
                  title={'Aceito e Consultar'}
                  startIcon={<MdCheck color="background" />}
                ></LoadingBtn>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
      <Box style={{ paddingTop: 5 }}>
        <Grid container spacing={3} style={{ marginTop: '15px' }}>
          <Grid item xs={12} md={2} lg={2}>
            <TextField
              fullWidth
              label="CNPJ"
              id="outlined-size-small"
              value={codigo}
              onChange={e => {
                setCodigo(e.target.value);
              }}
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <TextField
              fullWidth
              label="Nome Completo"
              id="outlined-size-small"
              onChange={e => {
                setDescricao(e.target.value);
              }}
              value={descricao}
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={2} lg={2}>
            <TextField
              fullWidth
              label="Data Inicial:"
              id="outlined-size-small"
              value={dataSolicitacaoDe}
              onChange={handleSolicitacaoDe}
              variant="outlined"
              type="date"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={2} lg={2}>
            <TextField
              fullWidth
              label="Data Final:"
              id="outlined-size-small"
              variant="outlined"
              value={dataSolicitacaoAte}
              onChange={handleSolicitacaoAte}
              type="date"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3} style={{ paddingBottom: 40 }}>
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            style={{ display: 'flex', gap: '1rem', justifyContent: 'flex-end' }}
          >
            <LoadingBtn
              variant="contained"
              color="default"
              isLoading={loadButton}
              title={'Limpar'}
              startIcon={<MdCleaningServices color="background" />}
              onClick={() => {
                limpar();
              }}
            >
              Limpar
            </LoadingBtn>
            <LoadingBtn
              variant="contained"
              color="primary"
              title={'Pesquisar'}
              isLoading={loadButton}
              startIcon={!loadButton && <MdSearch color="background" />}
              onClick={() => {
                pesquisar();
              }}
            ></LoadingBtn>
          </Grid>
        </Grid>
      </Box>
      <Paper
        className={classes.dataGridPaperInd}
        elevation={0}
        data-testid="container-datagrid"
      >
        <DataGrid
          rows={rows}
          columns={columns}
          loading={loadButton}
          pageSize={10}
          height="500px"
        />
      </Paper>
    </Wrapper>
  );
}
