export function listarBancosRequest() {
  return {
    type: 'LISTAR_BANCOS_REQUEST',
  };
}
export function listarBancosSuccess(bancos) {
  return {
    type: 'LISTAR_BANCOS_SUCCESS',
    payload: bancos,
  };
}
export function listarBancosError() {
  return {
    type: 'LISTAR_BANCOS_ERROR',
  };
}
