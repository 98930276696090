import React, { useEffect, useMemo, useRef, useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import { Form } from '@unform/web';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';
import Buttons from '../../components/Buttons/ConfirmButton';
import ContainerComponent from '../../components/Container';
import InputMoney from '../../components/Inputs/Money';
import InputText from '../../components/InputText';
import RadioGroup from '../../components/RadioButton';
import SelectComponent from '../../components/Select';
import TopBar from '../../components/TopBar';
import { FormattFloatNumber } from '../../util/FormatToFloatNumber';
import PopUp from '../../components/PopUp';
import DateComponent from '../../components/InputDate';
import getValidationErros from '../../util/FormattedError';
import { api_multipague_esteira } from '../../services/api';
import {
  AgendamentoPagamento,
  Container,
  ContainerAgendamentoPagamento,
  ContainerButtons,
  ContainerRadioGroup,
  Data,
  Subcontainer,
  SubContainerRadioGroup,
} from './styles';
import Password from '../../components/Password';
import { useDispatch, useSelector } from 'react-redux';
import { hideLoading, showLoading } from '../../store/modules/loading/actions';
import { toastComponentError, toastComponentSuccess } from '../../components/Toast';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100%',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));

// ============ Actions Redux ============

const Transferencia = () => {
  const { idTed } = useParams();
  const { contaPadrao } = useSelector(state => state.contas);
  const dispatch = useDispatch();
  useEffect(() => {
    console.log(JSON.parse(localStorage.getItem('vizualizar_ted_esteira')));
  }, []);

  const initialData = JSON.parse(
    localStorage.getItem('vizualizar_ted_esteira'),
  );

  const root = {
    path: [
      {
        name: 'Esteira',
        caminho: '/backoffice/Esteira',
      },
    ],
    pagAtual: 'Transferência',
  };

  const md5 = require('md5');
  const submitFormRef = useRef(null);
  const history = useHistory();
  const [favoritos, setFavoritos] = useState([]);
  const [finalidade, setFinalidade] = useState([]);
  const [centroCusto, setCentroCusto] = useState([]);
  const [dataAgendamento, setDataAgendamento] = useState(Date.now());
  const [paymentDate, setPaymentDate] = useState([
    {
      label: 'Hoje',
      value: uuidv4(),
      selected: true,
      agendado: false,
    },
    {
      label: 'Agendamento',
      value: uuidv4(),
      selected: false,
      agendado: true,
    },
  ]);
  const [agendamentoFormaPagamento, setAgendamentoFormaPagamento] = useState(
    {},
  );

  useEffect(() => {
    ListTed();
  }, []);

  async function ListTed() {
    try {
      dispatch(showLoading(true));
      const { data } = await api_multipague_esteira.get(
        `/administracao/transferencia?codigoTransferencia=${idTed}`,
      );
      console.log('=====>', data);
      if (data.length) {
        submitFormRef.current.setData({
          nomeCliente: data[0].nomeFavorecido,
          idCentroCusto: data[0].codigoCentroCusto,
          idFinalidade: data[0].descricaoFinalidade,
          valor: new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(data[0].valor),
          DataAgendamento: moment(data[0].dataCadastro).format('DD/MM/YYYY'),
          DataPagamento: moment(data[0].dataPagamento).format('DD/MM/YYYY'),
          descricaoHistorico: data[0].historico,
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(hideLoading(false));
    }
  }

  const mostrarDataAgendamento = useMemo(() => {
    if (
      agendamentoFormaPagamento.agendado &&
      agendamentoFormaPagamento.selected
    ) {
      return (
        <DateComponent
          id="data-pagamento-agendado"
          label="Agendar Pagamento"
          name="agendamento"
          callback={setDataAgendamento}
          value={dataAgendamento}
          defaultValue={dataAgendamento}
        />
      );
    }
  }, [agendamentoFormaPagamento, dataAgendamento]);

  function selecionarDiaPagamento(data) {
    const dataSelecionada = paymentDate.map(val =>
      val.value === data
        ? {
            ...val,
            selected: true,
          }
        : {
            ...val,
            selected: false,
          },
    );
    const filterDalaSelecionada = dataSelecionada.filter(date => date.selected);
    setAgendamentoFormaPagamento(filterDalaSelecionada[0]);
    setPaymentDate(dataSelecionada);
  }

  async function submitData(formInfo, password) {
    console.log(formInfo);
    try {
      const schema = Yup.object().shape({
        idFavorecido: Yup.string().required('Finalidade é obrigatoria'),
        idFinalidade: Yup.string().required('Finalidade é obrigatoria'),
        descricaoHistorico: Yup.string().required('Histórico é obrigatório'),
        idCentroCusto: Yup.string().required('Selecione um centro de custo'),
        valor: Yup.string().required('Digite um valor'),
      });

      await schema.validate(formInfo, {
        abortEarly: false,
      });
    } catch (err) {
      console.log(err, err.response);
      if (err instanceof Yup.ValidationError) {
        console.log(err.inner);
        const erros = getValidationErros(err);
        submitFormRef.current?.setErrors(erros);
      } else {
        toastComponentError(
          ` Codigo: ${err.response.data.codigo} - ${err.response.data.mensagem}`,
        );
      }
    }
  }

  async function submitTed(senha) {
    const formInfo = submitFormRef.current.getData();
    console.log('=====>', formInfo);
    try {
      const body = {
        ...formInfo,
        assinaturaFinanceira: null,
        dataAgendamento:
          agendamentoFormaPagamento.agendado &&
          agendamentoFormaPagamento.selected
            ? moment(dataAgendamento).format('DD/MM/YYYY')
            : moment(Date.now()).format('DD/MM/YYYY'),

        idClienteConta: Number(contaPadrao.id),
        valor: FormattFloatNumber(formInfo.valor),
        assinaturaFinanceira: md5(senha.password),
        idCentroCusto: Number(formInfo.idCentroCusto),
        idFavorecido: Number(formInfo.idFavorecido),
        idFinalidade: Number(formInfo.idFinalidade),
      };

      api_multipague_transacao.interceptors.request.use(function (config) {
        const newHeader = {
          ...config.headers,
          'x-assinatura-financeira': md5(senha.password),
          IdempotencyKey: uuidv4(),
        };
        config.headers = newHeader;
        return config;
      });

      const { data } = await api_multipague_transacao.post(
        '/transferencia/ted',
        body,
      );

      if (data.id) {
        toastComponentSuccess('Transferencia realizada com sucesso');
        history.push(`/Comprovante/Transferencia/${data.id}`);
      }
    } catch (err) {
      toastComponentError(
        ` Codigo: ${err.response.data.codigo} - ${err.response.data.mensagem}`,
      );
    }
  }

  const classes = useStyles();
  return (
    <>
      <PopUp>
        <Password callback={data => submitTed(data)} />
      </PopUp>
      <Form ref={submitFormRef} onSubmit={submitData} initialData={initialData}>
        <div className={classes.root}>
          <CssBaseline />
          <TopBar />
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Container maxWidth="lg" className={classes.container}>
              <ContainerComponent noMenu root={root}>
                <Container>
                  <Subcontainer>
                    <InputText
                      label="Favorecido"
                      id="idFavorecido"
                      name="nomeCliente"
                      placeHolder="Favorecido"
                      disabled
                    />
                    <InputText
                      label="Centro de Custo"
                      id="idCentroCusto"
                      name="idCentroCusto"
                      disabled
                    />

                    <ContainerAgendamentoPagamento>
                      <InputText
                        label="Finalidade"
                        id="idFinalidade"
                        name="idFinalidade"
                        disabled
                      />

                      <InputText
                        label="Valor"
                        name="valor"
                        id="valor"
                        disabled
                      />

                      <InputText
                        label="Data Agendamento"
                        name="DataAgendamento"
                        id="DataAgendamento"
                        disabled
                      />
                      <InputText
                        label="Data Pagamento"
                        name="DataPagamento"
                        id="DataPagamento"
                        disabled
                      />
                    </ContainerAgendamentoPagamento>
                    <InputText
                      id="descricaoHistorico"
                      label="Histórico"
                      name="descricaoHistorico"
                      multiline
                      rows={4}
                      variant="outlined"
                      disabled
                    />
                  </Subcontainer>
                  <ContainerButtons>
                    <Buttons
                      variant="contained"
                      disabled={false}
                      width={100}
                      onClick={() => history.goBack()}
                      type="button"
                    >
                      Voltar
                    </Buttons>
                  </ContainerButtons>
                </Container>
              </ContainerComponent>
            </Container>
          </main>
        </div>
      </Form>
    </>
  );
};

export default Transferencia;
