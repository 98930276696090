import React, { createContext, useState, useContext, useEffect } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import * as RetornoPagamentoService from '../services/RetornoPagamentoService';

const RetornoPagamentoContext = createContext();

export default function RetornoPagamentoProvider({ children }) {
  const [dataInicial, setDataInicial] = useState();
  const [dataFinal, setDataFinal] = useState();
  const [loading, setLoading] = useState(true);
  const [rowsTable, setRowsTable] = useState([]);
  const { contaPadrao } = useSelector(state => state.contas);
  const [rowCount, setRowCount] = useState();
  const [textNoRows, setTextNoRows] = useState();
  const [rowsState, setRowsState] = React.useState({
    page: 0,
    pageSize: 10,
  });

  const submitFilter = async () => {
    setRowsState(prev => ({ ...prev, page: 0 }));
    setRowsTable([]);
    getRetornos({ dataInicial, dataFinal }, rowsState.page, rowsState.pageSize);
  };

  const handlePageSize = newPageSize => {
    setRowsState({ page: 0, pageSize: newPageSize });
  };

  const clearFilter = () => {
    setRowsState(prev => ({ ...prev, page: 0 }));
    setDataInicial('');
    setDataFinal('');
    getRetornos({}, rowsState.page, rowsState.pageSize);
  };

  const onPage = page => {
    setRowsState(prev => ({ ...prev, page: page }));
    if (dataInicial && dataFinal) {
      getRetornos({ dataInicial, dataFinal }, page, rowsState.pageSize);
    } else {
      getRetornos({}, page, rowsState.pageSize);
    }
  };

  useEffect(() => {
    if (dataInicial && dataFinal) {
      setRowsTable([]);
      getRetornos({ dataInicial, dataFinal }, 0, rowsState.pageSize);
    } else {
      getRetornos();
    }
  }, [contaPadrao]);

  useEffect(() => {
    setRowsState(prev => ({ ...prev, page: 0 }));
    getRetornos({ dataInicial, dataFinal }, 0, rowsState.pageSize);
  }, [rowsState.pageSize]);

  const getRetornos = async (params = {}, page = 0, pageSize = 10) => {
    try {
      if (contaPadrao.id) {
        setLoading(true);
        const { data } = await RetornoPagamentoService.findAll({
          ...params,
          codigoClienteConta: contaPadrao.id,
          page: page,
          size: pageSize,
        });
        const formated = data.content.map(item => {
          return {
            ...item,
            dataMovimento: moment(item.dataMovimento).format('DD/MM/YYYY'),
            url: item.caminhoArquivo,
          };
        });

        setRowsTable(formated);
        setLoading(false);
        setRowCount(data?.totalElements);
      }
    } catch (err) {
      setRowCount(0);
      setRowsTable([]);
      setLoading(false);
      const textError =
        err.response.status === 404
          ? 'Nenhum arquivo de retorno encontrado'
          : '';
      setTextNoRows(textError);
    }
  };

  return (
    <RetornoPagamentoContext.Provider
      value={{
        rowsTable,
        dataInicial,
        setDataInicial,
        dataFinal,
        setDataFinal,
        setRowsState,
        loading,
        setLoading,
        handlePageSize,
        clearFilter,
        submitFilter,
        getRetornos,
        contaPadrao,
        rowCount,
        rowsState,
        onPage,
        textNoRows,
      }}
    >
      {children}
    </RetornoPagamentoContext.Provider>
  );
}

export function useRetornoPagamento() {
  const context = useContext(RetornoPagamentoContext);
  return context;
}
