import { useQuery } from 'react-query';
import moment from 'moment';
import { api_multipague_agenda_financeira_dashboard } from '../../services/api';

export const useGetAgendaFinanceira = (infoRedux, conta = false, options) => {
  return useQuery(
    'getAgendaFinanceira',
    async () => {
      try {
        const hoje = new Date();
        const final = new Date(hoje).setDate(hoje.getDate() + 365);

        const response = await api_multipague_agenda_financeira_dashboard.get(
          !!conta
            ? `/agenda-financeira?dataInicial=${moment(hoje).format(
                'YYYY-MM-DD',
              )}&dataFinal=${moment(final).format(
                'YYYY-MM-DD',
              )}&contextoConsolidado=true`
            : `/agenda-financeira?cliente=${
                infoRedux?.contaPadrao?.cliente?.id
              }&clienteConta=${infoRedux?.contaPadrao?.id}&dataInicial=${moment(
                hoje,
              ).format('YYYY-MM-DD')}&dataFinal=${moment(final).format(
                'YYYY-MM-DD',
              )}`,
        );

        return response.data;
      } catch (error) {
        console.error('Error fetching agenda financeira:', error);
        throw new Error('Failed to fetch agenda financeira');
      }
    },
    {
      ...options,
      enabled:
        !!infoRedux?.contaPadrao?.cliente?.id && !!infoRedux?.contaPadrao?.id,
    },
  );
};
