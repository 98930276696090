import React from 'react';
import moment from 'moment';

export const currencyFormatter = numero => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(numero);
};

export const columnsTable = [
  {
    minWidth: 70,
    field: 'idOperacao',
    headerName: 'Operação',
  },
  {
    minWidth: 110,
    field: 'documento',
    headerName: 'Documento',
  },
  {
    minWidth: 180,
    field: 'nomeSacado',
    headerName: 'Nome Sacado',
    renderCell: event => {
      return (
        <div
          style={{
            fontSize: event?.row?.nomeSacado?.length > 40 ? '10px' : '13px',
            lineHeight: '150%',
            height: '50px',
            alignItems: 'center',
            display: 'flex',
            flexWrap: 'wrap',
            whiteSpace: 'break-spaces',
          }}
        >
          {event.row?.nomeSacado}
        </div>
      );
    },
  },
  {
    headerName: 'Data Vencimento',
    minWidth: 140,
    field: 'dataVencimento',
    columnSx: { textAlign: 'center' },
    renderCell: event => {
      return (
        <div>{moment(event.row?.dataVencimento).format('DD/MM/YYYY')}</div>
      );
    },
  },
  {
    headerName: 'Valor de Face',
    minWidth: 120,
    field: 'valorDeFace',
    columnSx: { textAlign: 'center' },
    renderCell: event => {
      return <div>{currencyFormatter(event.row?.valorDeFace)}</div>;
    },
  },
  {
    headerName: 'Valor Juros Pre',
    minWidth: 130,
    field: 'valorJurosPre',
    columnSx: { textAlign: 'center' },
    renderCell: event => {
      return <div>{currencyFormatter(event.row?.valorJurosPre)}</div>;
    },
  },
  {
    headerName: 'Valor Correcao Índice',
    minWidth: 120,
    field: 'valorCorrecaoIndice',
    columnSx: { textAlign: 'center' },
    renderCell: event => {
      return <div>{currencyFormatter(event.row?.valorCorrecaoIndice)}</div>;
    },
  },
  {
    minWidth: 110,
    field: 'valorCorrecaoIndice',
    headerName: 'Juros Multa',
  },
  {
    minWidth: 100,
    field: 'valorMulta',
    headerName: 'Multa',
    columnSx: { textAlign: 'center' },
    renderCell: event => {
      return <div>{currencyFormatter(event.row?.valorMulta)}</div>;
    },
  },
  {
    minWidth: 110,
    field: 'valorJurosMora',
    headerName: 'Juros Mora',
    columnSx: { textAlign: 'center' },
    renderCell: event => {
      return <div>{currencyFormatter(event.row?.valorJurosMora)}</div>;
    },
  },
  {
    minWidth: 160,
    field: 'valorJurosRemuneratorio',
    headerName: 'Juros Remuneração',
    columnSx: { textAlign: 'center' },
    renderCell: event => {
      return <div>{currencyFormatter(event.row?.valorJurosRemuneratorio)}</div>;
    },
  },
  {
    minWidth: 140,
    field: 'valorCorrecaoIndiceAtraso',
    headerName: 'Correção Atraso',
    columnSx: { textAlign: 'center' },
    renderCell: event => {
      return (
        <div>{currencyFormatter(event.row?.valorCorrecaoIndiceAtraso)}</div>
      );
    },
  },
  {
    minWidth: 120,
    field: 'valorAbatimento',
    headerName: 'Abatimento',
    columnSx: { textAlign: 'center' },
    renderCell: event => {
      return <div>{currencyFormatter(event.row?.valorAbatimento)}</div>;
    },
  },
  {
    minWidth: 110,
    field: 'valorDesconto',
    headerName: 'Desconto',
    columnSx: { textAlign: 'center' },
    renderCell: event => {
      return <div>{currencyFormatter(event.row?.valorDesconto)}</div>;
    },
  },
  {
    minWidth: 110,
    field: 'valorDescontoAntecipacao',
    headerName: 'Desc. Antec.',
    columnSx: { textAlign: 'center' },
    renderCell: event => {
      return (
        <div>{currencyFormatter(event.row?.valorDescontoAntecipacao)}</div>
      );
    },
  },
  {
    minWidth: 120,
    field: 'valorDevedor',
    headerName: 'Valor Devedor',
    columnSx: { textAlign: 'center' },
    renderCell: event => {
      return (
        <div textAlign={'right'}>
          {currencyFormatter(event.row?.valorDevedor || 0)}
        </div>
      );
    },
  },
  {
    minWidth: 110,
    field: 'valorTotalPagar',
    headerName: 'Total Pago',
    columnSx: { textAlign: 'center' },
    renderCell: event => {
      return (
        <div textAlign={'right'}>
          {currencyFormatter(event.row?.valorDesconto || 0)}
        </div>
      );
    },
  },
  {
    minWidth: 130,
    field: 'valorDiferenca',
    headerName: 'Valor Diferença',
    columnSx: { textAlign: 'center' },
    renderCell: event => {
      return (
        <div textAlign={'right'}>
          {currencyFormatter(event.row?.valorDesconto || 0)}
        </div>
      );
    },
  },
];
