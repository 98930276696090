import React, { createContext, useState, useContext } from 'react'
import moment from 'moment';

const CountContext = createContext()

export default function CountProvider({ children }) {    
    const [horaexpiracao, setHoraExpiracao] = useState(moment(localStorage.getItem('horaAgora')).format('LTS'))    
    return (
        <CountContext.Provider value={{
            horaexpiracao,
            setHoraExpiracao
        }}>
            {children}
        </CountContext.Provider>
    )
}

export function useCount() {
    const context = useContext(CountContext)
    const { horaexpiracao, setHoraExpiracao } = context
    return { horaexpiracao, setHoraExpiracao }
}
