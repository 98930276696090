export function FormattFloatNumber(number, isRetunrNumberFormatToInput = true) {
  if (!number) {
    return '';
  }
  if (isRetunrNumberFormatToInput) {
    // return Number(number.replace('.', '').replace(',', '.'));
    // return Number(number.replace('.', '').replace(',', '.'));
    return String(Number(number).toFixed(2)).replace('.', '');
  } else {
    return Number(number.replaceAll('.', '').replaceAll(',', '.')).toFixed(2);
  }
}
