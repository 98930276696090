import React, { useEffect, useState } from 'react';
import { alpha, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import formattedMoney from '../../util/FormatteMoney/index';
import { useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from 'react-icons/ai';
import Divider from '@material-ui/core/Divider';
import SubtitlesIcon from '@material-ui/icons/Subtitles';
import { DashboardCard } from '../DashboardCards';
import { MessageErrorComponent } from '../DashboardCards/ErrorMessage';

export default function AgendaFinanceiraMobile({ data, error, carregando }) {
  const theme = useTheme();
  const infoRedux = useSelector(state => state.contas);

  const [informacoes, setInformacoes] = useState({
    codigoCliente: 0,
    valorTotalSaida: 0,
    divisaoCobrancas: [],
    agendasClienteContas: [],
  });

  const cobrancaSimples = informacoes?.divisaoCobrancas?.filter(
    item => item.tipoOperacao === 'COBRANCA_SIMPLES',
  )[0];
  const operacaoGarantia = informacoes?.divisaoCobrancas?.filter(
    item => item.tipoOperacao === 'OPERACAO_GARANTIA',
  )[0];

  useEffect(() => {
    if (infoRedux.contaPadrao?.cliente?.id && infoRedux.contaPadrao?.id) {
      setInformacoes(data);
    }
  }, [infoRedux, data]);

  return (
    <DashboardCard
      height={500}
      title={'Agenda Financeira'}
      carregando={carregando}
      children={
        <>
          {error ? (
            <MessageErrorComponent />
          ) : (
            <>
              <Box
                border={1}
                borderColor={alpha(
                  theme.palette.getContrastText(theme.palette.background.paper),
                  0.1,
                )}
                borderRadius={'20px'}
                p={2}
              >
                <Typography
                  style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    marginBottom: 8,
                  }}
                >
                  Cobranças Simples
                </Typography>
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography
                    style={{
                      fontWeight: 'bold',
                      color: theme.palette.text.primary,
                      alignItems: 'center',
                      display: 'flex',
                      alignContent: 'center',
                    }}
                  >
                    <AiOutlinePlusCircle
                      size={25}
                      style={{ paddingRight: '5px' }}
                      color={'green'}
                    />
                    Valor de entrada:
                  </Typography>
                  {!cobrancaSimples || cobrancaSimples?.valorTitulo == 0
                    ? 'R$ 0,00'
                    : formattedMoney(parseFloat(cobrancaSimples?.valorTitulo))}
                </Box>
              </Box>
              <Box
                border={1}
                borderColor={alpha(
                  theme.palette.getContrastText(theme.palette.background.paper),
                  0.1,
                )}
                borderRadius={'20px'}
                p={2}
              >
                <Typography
                  style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    marginBottom: 8,
                  }}
                >
                  Garantias
                </Typography>
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography
                    style={{
                      fontWeight: 'bold',
                      color: theme.palette.text.primary,
                      alignItems: 'center',
                      display: 'flex',
                      alignContent: 'center',
                    }}
                  >
                    <AiOutlinePlusCircle
                      size={25}
                      style={{ paddingRight: '5px' }}
                      color={'green'}
                    />
                    Valor de entrada:
                  </Typography>
                  {!operacaoGarantia || operacaoGarantia?.valorTitulo == 0
                    ? 'R$ 0,00'
                    : formattedMoney(parseFloat(operacaoGarantia?.valorTitulo))}
                </Box>
              </Box>
              <Box
                border={1}
                borderColor={alpha(
                  theme.palette.getContrastText(theme.palette.background.paper),
                  0.1,
                )}
                borderRadius={'20px'}
                p={2}
              >
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography
                    style={{
                      fontWeight: 'bold',
                      color: theme.palette.text.primary,
                      alignItems: 'center',
                      display: 'flex',
                      alignContent: 'center',
                      lineHeight: '180%',
                    }}
                  >
                    <AiOutlinePlusCircle
                      size={25}
                      style={{ paddingRight: '5px' }}
                      color={'green'}
                    />
                    Total:{' '}
                  </Typography>
                  <Typography variant="body2" style={{ paddingLeft: '15px' }}>
                    {!operacaoGarantia || operacaoGarantia?.valorTitulo == 0
                      ? 'R$ 0,00'
                      : formattedMoney(
                          parseFloat(
                            operacaoGarantia?.valorTitulo +
                              cobrancaSimples?.valorTitulo,
                          ),
                        )}
                  </Typography>
                </Box>
                <Divider />
                <Box
                  style={{
                    display: 'flex',
                    alignContent: 'center',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    alignContent: 'center',
                  }}
                >
                  <Typography
                    style={{
                      fontWeight: 'bold',
                      color: theme.palette.text.primary,
                      alignItems: 'center',
                      display: 'flex',
                      alignContent: 'center',
                      lineHeight: '180%',
                    }}
                  >
                    <AiOutlineMinusCircle
                      size={25}
                      style={{ paddingRight: '5px' }}
                      color={'red'}
                    />
                    Valor de Saída:
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                      alignContent: 'center',
                    }}
                  >
                    {!informacoes || informacoes?.valorTotalSaida == 0
                      ? 'R$ 0,00'
                      : formattedMoney(
                          parseFloat(informacoes?.valorTotalSaida),
                        )}
                  </Typography>
                </Box>
              </Box>
            </>
          )}
        </>
      }
      icon={
        <SubtitlesIcon
          size={50}
          style={{
            marginRight: 5,
            color: theme.palette.primary.main,
          }}
        />
      }
    />
  );
}
