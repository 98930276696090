import { makeStyles, alpha } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PubSub from 'pubsub-js';
import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import { useDispatch, useSelector } from 'react-redux';
import { api_multipague_conta } from '../services/api';
import formattedMoney from '../util/FormatteMoney/index';
import { Card } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import { MdAccountBalanceWallet } from 'react-icons/md';
import { useTheme } from '@material-ui/core/styles';
import { AiOutlineExclamationCircle } from 'react-icons/ai';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    '& svg': {
      [theme.breakpoints.down('xs')]: {
        width: '80px',
      },
    },
    fontSize: '1rem',
    letterSpacing: '0.01071em',
    color:
      theme.palette.type === 'light'
        ? theme.palette.primary.main
        : theme.palette.text.primary,
    padding: '1.5rem 1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontWeight: 'bold',
    borderRadius: '15px',
    borderBottom: `solid 2px ${theme.palette.background.paper}`,
    borderBottomColor:
      theme.palette.type === 'light'
        ? theme.palette.primary.main
        : theme.palette.primary.dark,
    boxShadow: `${alpha(theme.palette.text.primary, 0.2)} 1px 5px 5px`,
  },
  verSaldo: {
    display: 'flex',
    gap: '0.5rem',
    alignItems: 'center',
  },
  errorMessage: {
    fontSize: '13px',
    color: theme.palette.system.dark,
    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}));

export default function SimpleAlerts() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const clienteAccount = useSelector(state => state.contas);
  const classes = useStyles();
  const saldo = useSelector(state => state.saldo);
  const dateToFormat = new Date();
  const [error, setError] = useState({ isError: false, messageError: '' });

  useEffect(() => {
    if (clienteAccount.contaPadrao?.agencia) {
      saldoConta();
    }
  }, [clienteAccount]);

  useEffect(() => {
    var mySpecificSubscriber = function (msg, data) {
      const teste = data;
      setDataInformacoes(teste);
    };
    // subscribe only to 'car.drive' topics
    PubSub.subscribe('captureItem', mySpecificSubscriber);
    const infoStorage = localStorage.getItem('dataInformacoes');
    if (infoStorage) {
      setDataInformacoes(JSON.parse(infoStorage));
    }
  }, []);

  const [dataInformacoes, setDataInformacoes] = useState([]);

  const [informacoes, setInformacoes] = useState({
    saldo: '',
  });

  async function saldoConta() {
    try {
      const { data } = await api_multipague_conta.get(
        `/saldo/conta?nrBanco=${274}&agencia=${
          clienteAccount.contaPadrao.agencia
        }&conta=${clienteAccount.contaPadrao.conta}&digitoConta=${
          clienteAccount.contaPadrao.digitoConta
        }`,
      );
      setInformacoes(data);
    } catch (err) {
      setError({
        isError: true,
        messageError: err?.response?.data?.mensagem
          ? err?.response?.data?.mensagem
          : 'Não foi possível conectar-se ao servidor',
      });
    }
  }

  return (
    <Card className={classes.root}>
      <div className={classes.verSaldo}>
        <MdAccountBalanceWallet
          color={
            theme.palette.type === 'light'
              ? theme.palette.primary.main
              : theme.palette.text.primary
          }
          size={30}
        />
        {error.isError ? (
          <Typography
            style={{
              color: theme.palette.primary.main,
              fontSize: '20px',
              alignContent: 'center',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            Saldo total:
            <span
              style={{
                color: theme.palette.text.primary,
                fontSize: '14px',
                display: 'flex',
                alignItems: 'center',
                alignContent: 'center',
              }}
            >
              <AiOutlineExclamationCircle
                size={30}
                style={{ padding: '5px' }}
                color={'red'}
              />
              {error.messageError}
            </span>
          </Typography>
        ) : (
          <Typography
            style={{
              color: theme.palette.primary.main,
              fontSize: '20px',
              fontWeight: 'bold',
            }}
          >
            Saldo total:{' '}
            <span style={{ color: theme.palette.text.primary }}>
               {!!saldo?.showSaldo
                 ? informacoes.saldo == 0
                    ? 'R$ 0,00'
                    : formattedMoney(parseFloat(informacoes.saldo))
                 : 'R$ *****'}
            </span>
          </Typography>
        )}
      </div>
      <Hidden xsDown>
        <Typography
          style={{
            fontSize: '12px',
            marginTop: '0px',
            fontWeight: 300,
            color: theme.palette.text.primary,
          }}
        >
          Dados atualizados:{' '}
          <span style={{ fontWeight: 900 }}>
            <Moment date={dateToFormat} format="DD/MM/YYYY" />
          </span>
        </Typography>
      </Hidden>
    </Card>
  );
}
