import React, { useEffect, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import Button2 from '../../components/Buttons/ConfirmButton';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import TopBar from '../../components/TopBar';
import { useParams, useLocation } from 'react-router-dom';
import { api_multipague_configuracao } from '../../services/api';
import { ToastContainer, toast } from 'react-toastify';
import LoadingBtn from '../../components/Buttons/ConfirmButton';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import MaskedInput from 'react-text-mask';
import Box from '../../components/Box';
import Wrapper from '../../components/Wrapper';
import { useAuthContext } from '../../Context/AuthContext';
import { toastComponentError, toastComponentSuccess } from '../../components/Toast';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      Todos os Direitos Reservados.
      <Link color="inherit" href="https://grupomultiplica.com.br/">
      Beyond
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  rootcontent: {
    '& > *': {
      margin: theme.spacing(2),
      // width: '30%'
    },
  },
  rootcard: {
    minWidth: 275,
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100%',
    overflow: 'hidden',
    backgroundColor: '#fff',
    minHeight: '100vh',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 100,
  },
  bread: {
    paddingTop: theme.spacing(2),
    marginLeft: '25px',
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paperModal: {
    backgroundColor: theme.palette.background.paper,
    border: `2px solid ${theme.palette.getContrastText(theme.palette.background.paper)}`,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.light,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    textTransform: 'none',
  },
  cardMargin: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    minHeight: '500px',
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function TextMaskFin(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
      // placeholderChar={'\u2000'}
      // showMask
    />
  );
}
TextMaskFin.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

export default function ConfirmEmail(props) {
  const classes = useStyles();
  const theme = useTheme()
  const query = useQuery();
  const { permissionAdmin, permissionBackEmpresa } = useAuthContext();
  const [financial, setFinancial] = useState('');
  const [financialAcesso, setFinancialAcesso] = useState('');
  const [newFinancial, setNewFinancial] = useState('');
  const [newFinancialAcesso, setNewFinancialAcesso] = useState('');
  const [confirmNewFinancial, setConfirmNewFinancial] = useState('');
  const [confirmNewFinancialAcesso, setConfirmNewFinancialAcesso] =
    useState('');
  const [loadButton, setLoadButton] = useState(false);
  const [loadButtonAcesso, setLoadButtonAcesso] = useState(false);
  const [disableButtonFin, setDisableButtonFin] = useState(true);
  const [sendCpf, setSendCpf] = useState('');
  const { tk } = useParams();
  //   const [token, setTk] = useState('');
  var md5 = require('md5');

  const [openModal, setOpenModal] = React.useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const formData = {
    confirmacaoSenhaNova: md5(newFinancial),
    senhaAtual: md5(financial),
    senhaNova: md5(confirmNewFinancial),
  };
  const formDataAcesso = {
    confirmacaoSenhaNova: newFinancialAcesso,
    senhaAtual: financialAcesso,
    senhaNova: confirmNewFinancialAcesso,
  };

  const [values, setValues] = React.useState({
    showOldPassword: false,
    showPassword: false,
    showPasswordConfirm: false,
    showPasswordConfirmFin: false,
    showOldPasswordFin: false,
    showPasswordFin: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleMouseDownPassword = event => {
    event.preventDefault();
  };
  const handleClickShowPasswordConfirmFin = () => {
    setValues({
      ...values,
      showPasswordConfirmFin: !values.showPasswordConfirmFin,
    });
  };
  const handleMouseDownPasswordConfirmFin = event => {
    event.preventDefault();
  };
  const handleClickShowPasswordFin = () => {
    setValues({ ...values, showPasswordFin: !values.showPasswordFin });
  };
  const handleMouseDownPasswordFin = event => {
    event.preventDefault();
  };
  const handleClickShowPasswordConfirm = () => {
    setValues({ ...values, showPasswordConfirm: !values.showPasswordConfirm });
  };
  const handleMouseDownPasswordConfirm = event => {
    event.preventDefault();
  };
  const handleClickShowOldPassword = () => {
    setValues({ ...values, showOldPassword: !values.showOldPassword });
  };
  const handleMouseDownOldPassword = event => {
    event.preventDefault();
  };
  const handleClickShowOldPasswordFin = () => {
    setValues({ ...values, showOldPasswordFin: !values.showOldPasswordFin });
  };
  const handleMouseDownOldPasswordFin = event => {
    event.preventDefault();
  };

  const handleMudarSenha = value => {
    setLoadButton(true);
    api_multipague_configuracao
      .put(`/usuario/senha-financeira`, JSON.stringify(formData), {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(response => {
        setLoadButton(false);
        toastComponentSuccess('Ok! Sua senha financeira foi alterada com sucesso!');
        setTimeout(function () {
          window.location.href = '/dashboard';
        }, 3000);
      })
      .catch(error => {
        console.log(error);
        setLoadButton(false);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 409 ||
          error.response.status == 500
        ) {
          // alert(error.response.data.mensagem)
          toastComponentError(error.response.data.mensagem);
        }
      });
  };

  const handleMudarSenhaAcesso = value => {
    setLoadButtonAcesso(true);
    api_multipague_configuracao
      .put(`/usuario/senha`, JSON.stringify(formDataAcesso), {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(response => {
        setLoadButtonAcesso(false);
        toastComponentSuccess('Ok! Sua senha de acesso foi alterada com sucesso!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(function () {
          window.location.href = '/dashboard';
        }, 3000);
      })
      .catch(error => {
        console.log(error);
        setLoadButtonAcesso(false);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 409 ||
          error.response.status == 500
        ) {
          // alert(error.response.data.mensagem)
          toastComponentError(error.response.data.mensagem, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };

  function check() {
    if (
      document.getElementById('senha').value ==
      document.getElementById('confirmeSenha').value
    ) {
      document.getElementById('message').style.color = 'green';
      document.getElementById('message').innerHTML = 'correto';
    } else {
      document.getElementById('message').style.color = 'red';
      document.getElementById('message').innerHTML = 'as senhas não conferem';
    }
  }
  function checkFin() {
    if (
      document.getElementById('senhaAssinaturaParceiro').value ==
      document.getElementById('confirmeSenhaFinanceira').value
    ) {
      document.getElementById('messageFin').style.color = 'green';
      document.getElementById('messageFin').innerHTML = 'correto';
    } else {
      document.getElementById('messageFin').style.color = 'red';
      document.getElementById('messageFin').innerHTML =
        'as senhas não conferem';
    }
  }

  const [disableButton, setDisableButton] = useState(true);
  const [disableButtonCpf, setDisableButtonCpf] = useState(true);

  const handleChangeNovaSenha = e => {
    const newValue = e.target.value;

    if (
      newValue.match(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{6,}$/,
      )
    ) {
      setNewFinancialAcesso('');
      setError(false);
      setDisableButton(false);
    } else {
      setError('A senha informada não atende aos requisitos');
      setDisableButton(true);
    }
    setNewFinancialAcesso(newValue);
  };

  const sendForgotPassword = () => {
    localStorage.setItem('esqueci-senha-financeira', sendCpf);
    api_multipague_configuracao
      .post(`/usuario/senha/enviar-email?username=${sendCpf}`)
      .then(response => {
        window.location.href = '/insert-code-financial-password';
        toastComponentSuccess('Um código de confirmação foi enviado para o seu email', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch(error => {
        console.log(error);
        setLoadButton(false);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 409 ||
          error.response.status == 500
        ) {
          toastComponentError(error.response.data.mensagem, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };

  const numbers = /^[0-9]+$/;
  const [error, setError] = useState('');

  return (
    <Wrapper
      title="Alterar Senhas"
      crumb={[{ name: 'Dashboard', link: '/dashboard' }]}
    >
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openModal}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <div className={classes.paperModal} style={{ textAlign: 'center' }}>
            <InputLabel
              htmlFor="age-native-label-placeholder"
              style={{ marginBottom: '5px' }}
            >
              Digite o seu CPF
            </InputLabel>
            <OutlinedInput
              style={{ marginBottom: '5px' }}
              margin="dense"
              variant="outlined"
              size="small"
              // inputComponent={TextMaskCustom}
              // type={values.showPassword ? 'text' : 'password'}
              required
              fullWidth
              id="autorizacao"
              name="autorizacao"
              onChange={e => {
                setSendCpf(e.target.value);
                e.target.value.length === 11
                  ? setDisableButtonCpf(false)
                  : setDisableButtonCpf(true);
              }}
              autoFocus
            />
            <Button
              variant="contained"
              // color="primary"
              // type="submit"
              className={classes.submit}
              disabled={disableButtonCpf}
              isLoading={loadButton}
              onClick={sendForgotPassword}
            >
              Confirmar
            </Button>
          </div>
        </Fade>
      </Modal>
      {permissionAdmin && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '1rem',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            isLoading={loadButton}
            onClick={handleOpenModal}
            style={{ textTransform: 'none' }}
          >
            Esqueci a Senha Financeira
          </Button>
        </div>
      )}
      <Grid
        container
        spacing={3}
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <Grid
          item
          xs={permissionAdmin ? 12 : 12}
          md={permissionAdmin ? 6 : 6}
          lg={permissionAdmin ? 6 : 6}
        >
          <Box>
            <div className={classes.paper}>
              <Avatar
                className={classes.avatar}
              >
              <LockOutlinedIcon style={{
                color: theme.palette.getContrastText(
                  theme.palette.primary.light,
                ),
              }}/>
              </Avatar>
              <Typography
                component="h1"
                variant="h5"
                style={{ marginBottom: '20px' }}
              >
                Troca da senha de acesso
              </Typography>
              <form className={classes.form} noValidate>
                <InputLabel htmlFor="age-native-label-placeholder">
                  Digite a sua antiga senha de acesso
                </InputLabel>
                <OutlinedInput
                  style={{ marginBottom: '20px', marginTop: '10px' }}
                  margin="dense"
                  variant="outlined"
                  size="small"
                  type={values.showOldPassword ? 'text' : 'password'}
                  required
                  fullWidth
                  id="financialpassword"
                  name="financialpassword"
                  onChange={e => {
                    setFinancialAcesso(e.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowOldPassword}
                        onMouseDown={handleMouseDownOldPassword}
                      >
                        {values.showOldPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <InputLabel htmlFor="age-native-label-placeholder">
                  Digite a sua nova senha de acesso
                </InputLabel>
                <OutlinedInput
                  style={{ marginBottom: '5px', marginTop: '10px' }}
                  margin="dense"
                  variant="outlined"
                  size="small"
                  type={values.showPassword ? 'text' : 'password'}
                  required
                  fullWidth
                  id="senha"
                  name="senha"
                  helperText="Obrigatório no mínimo 6 caracteres, sendo 1 letra maiúscula e 1 caractere especial."
                  error={error}
                  onBlur={handleChangeNovaSenha}
                  // onChange={(e) => { setNewFinancialAcesso(e.target.value) }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {values.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <span>
                  Obrigatório no mínimo 6 caracteres, sendo 1 letra maiúscula e
                  1 caractere especial.
                </span>
                <InputLabel
                  htmlFor="age-native-label-placeholder"
                  style={{ marginTop: '10px' }}
                >
                  Confirme a sua nova senha de acesso
                </InputLabel>
                <OutlinedInput
                  style={{ marginBottom: '20px', marginTop: '10px' }}
                  margin="dense"
                  variant="outlined"
                  size="small"
                  type={values.showPasswordConfirm ? 'text' : 'password'}
                  required
                  onKeyUp={check}
                  fullWidth
                  id="confirmeSenha"
                  name="confirmeSenha"
                  onChange={e => {
                    setConfirmNewFinancialAcesso(e.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPasswordConfirm}
                        onMouseDown={handleMouseDownPasswordConfirm}
                      >
                        {values.showPasswordConfirm ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <span id="message"></span>
                {/* <input hidden value={this.props.location.search.tk} onChange={(e) => { setTk(e.target.value) }} /> */}
                <Grid align="center" style={{ paddingTop: '10px' }}>
                  <Button
                    variant="contained"
                    // color="primary"
                    // type="submit"
                    disabled={disableButton}
                    className={classes.submit}
                    isLoading={loadButtonAcesso}
                    onClick={handleMudarSenhaAcesso}
                  >
                    Confirmar
                  </Button>
                </Grid>
                {/* <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid> */}
              </form>
              <ToastContainer />
            </div>
          </Box>
        </Grid>

        {permissionAdmin && (
          <Grid item xs={12} md={6} lg={6}>
            <Box>
              <div className={classes.paper}>
                <Avatar
                  className={classes.avatar}
                >
                  <LockOutlinedIcon style={{
                color: theme.palette.getContrastText(
                  theme.palette.primary.light,
                ),
              }}/>
                </Avatar>
                <Typography
                  component="h1"
                  variant="h5"
                  style={{ marginBottom: '20px' }}
                >
                  Troca da senha financeira
                </Typography>
                <form className={classes.form} noValidate>
                  <InputLabel htmlFor="age-native-label-placeholder">
                    Digite a sua antiga senha financeira
                  </InputLabel>
                  <OutlinedInput
                    style={{ marginBottom: '20px', marginTop: '10px' }}
                    margin="dense"
                    variant="outlined"
                    size="small"
                    type={values.showOldPasswordFin ? 'text' : 'password'}
                    required
                    fullWidth
                    inputComponent={TextMaskFin}
                    id="financialpassword"
                    name="financialpassword"
                    onChange={e => {
                      setFinancial(e.target.value);
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowOldPasswordFin}
                          onMouseDown={handleMouseDownOldPasswordFin}
                        >
                          {values.showOldPasswordFin ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />

                  <InputLabel htmlFor="age-native-label-placeholder">
                    Digite a sua nova senha financeira
                  </InputLabel>
                  <OutlinedInput
                    style={{ marginBottom: '5px', marginTop: '10px' }}
                    margin="dense"
                    variant="outlined"
                    size="small"
                    type={values.showPasswordFin ? 'text' : 'password'}
                    required
                    fullWidth
                    inputComponent={TextMaskFin}
                    id="senhaAssinaturaParceiro"
                    name="senhaAssinaturaParceiro"
                    onChange={e => {
                      e.target.value.length == 6 &&
                      e.target.value.match(numbers)
                        ? setDisableButtonFin(false)
                        : setDisableButtonFin(true);
                      setNewFinancial(e.target.value);
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPasswordFin}
                          onMouseDown={handleMouseDownPasswordFin}
                        >
                          {values.showPasswordFin ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <span>A senha financeira deve ser de 6 números.</span>
                  <InputLabel
                    htmlFor="age-native-label-placeholder"
                    style={{ marginTop: '10px' }}
                  >
                    Confirme a sua nova senha financeira
                  </InputLabel>
                  <OutlinedInput
                    style={{ marginBottom: '20px', marginTop: '10px' }}
                    margin="dense"
                    variant="outlined"
                    size="small"
                    type={values.showPasswordConfirmFin ? 'text' : 'password'}
                    required
                    onKeyUp={checkFin}
                    fullWidth
                    inputComponent={TextMaskFin}
                    id="confirmeSenhaFinanceira"
                    name="confirmeSenhaFinanceira"
                    onChange={e => {
                      setConfirmNewFinancial(e.target.value);
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPasswordConfirmFin}
                          onMouseDown={handleMouseDownPasswordConfirmFin}
                        >
                          {values.showPasswordConfirmFin ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <span id="messageFin"></span>
                  {/* <input hidden value={this.props.location.search.tk} onChange={(e) => { setTk(e.target.value) }} /> */}
                  <Grid align="center" style={{ paddingTop: '10px' }}>
                    <Button
                      variant="contained"
                      // color="primary"
                      // type="submit"
                      disabled={disableButtonFin}
                      className={classes.submit}
                      // isLoading={loadButton}
                      onClick={handleMudarSenha}
                    >
                      Confirmar
                    </Button>
                  </Grid>
                  {/* <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid> */}
                </form>
                <ToastContainer />
              </div>
            </Box>
          </Grid>
        )}
      </Grid>
    </Wrapper>
  );
}
