import styled, { css } from 'styled-components';
import TextField from '@material-ui/core/TextField';
import { styled as MuyStyled } from '@material-ui/core';

export const Container = styled.div`
  width: 100%;
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;
export const Subcontainer = styled.div`
  width: 100%;
  display: grid;
  row-gap: 20px;
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;
export const ContainerInputFile = styled.div`
  width: 100%;
  display: grid;
  row-gap: 20px;
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;
export const ContainerNomeFiles = styled.div`
  width: 100%;
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;

export const Files = MuyStyled('div')(({ theme }) => {
  return {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '5px 10px',
    padding: '0.8rem',
    marginBottom: '0.5rem',
    boxShadow: '0 2px 4px rgb(0 0 0 / 10%)',
    border: `solid 1px ${theme.palette.background.default}`,
  };
});

export const NameFile = styled.div`
  width: 100%;
  display: grid;
  row-gap: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;
export const DeleteFile = styled.div`
  width: 100%;
  display: grid;
  row-gap: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  svg {
    cursor: pointer;
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;

// export const ContainerIcon = MuyStyled('div')(({ theme, cursor }) => {
//   return {
//   width: "100%",
//   display: "flex",
//   backgroundColor: theme.palette.type === 'light'
//   ? `${theme.palette.background.default}`:
//   `${theme.palette.background.paper}`,
//   borderRadius: "4px",
//   border: `1px dashed ${theme.palette.text.disabled}`,
//   justifyContent: "center",
//   alignItems: "center",
//   ...(cursor && {
//     cursor: "pointer",
//   }),
//   gap:"0.5rem",
//   padding:"1rem",

//   '& span' :{
//     color: `${theme.palette.getContrastText(theme.palette.background.paper)}`,
//     fontWeight:"bold",
//   },

//   '& input[type="file"]': {
//     display: "none"
//   }
// }})

export const ContainerIcon = MuyStyled('div')(({ theme }) => {
  return {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    '& div': {
      height: '40px',
      width: '40px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '5px',
      border: `solid 2px ${theme.palette.primary.main}`,
      borderRadius: '80px',
    },

    '& svg': {
      cursor: 'pointer',
      minHeight: '100px',
    },

    '& input[type="file"]': {
      display: 'none',
    },
  };
});

export const InputText = styled(TextField)`
  width: 100%;
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;
export const ContainerButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (min-width: 1024px) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  @media only screen and (min-width: 1440px) {
  }
`;
