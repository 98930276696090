import React from 'react';
import { Backdrop, Fade } from '@material-ui/core';

import { Header, Wrapper, Container, Content } from './style';
import { MdClose } from 'react-icons/md';

const Modal = ({ title, open, close, children, staticBackdrop, style }) => {
  return (
    <Container
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={close}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      disableBackdropClick={Boolean(staticBackdrop)}
    >
      <Fade in={open}>
        <Wrapper style={{ ...style }}>
          {title && (
            <Header>
              {title}
              <MdClose
                size={20}
                onClick={close}
                style={{ cursor: 'pointer' }}
              />
            </Header>
          )}
          <Content>{children}</Content>
        </Wrapper>
      </Fade>
    </Container>
  );
};

export default Modal;
