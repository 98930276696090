import React from 'react';
import {
  alpha,
  Grid,
  makeStyles,
  styled,
  Typography,
  useTheme,
} from '@material-ui/core';

import Box from '../../../components/Box';

const CardLastTransactions = ({ keys, onSelectCard }) => {
  const theme = useTheme();

  const StyledBox = styled(Box)(({ theme }) => ({
    borderRadius: '20px',
    border: `1px solid ${theme.palette.divider}`,
    padding: 15,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: alpha(theme.palette.text.disabled, 0.1),
      boxShadow: `20px 10px 40px ${theme.palette.text.primary}`,
    },
  }));

  const useStyles = makeStyles(theme => ({
    gridContainer: {
      width: '70px',

      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
  }));

  const TypographyCustomCard = ({ name, value }) => {
    return (
      <Typography
        key={name}
        style={{
          color: alpha(theme.palette.text.primary, 0.5),
          fontWeight: '600',
          display: 'flex',
          fontSize: '14px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {name}
        <Typography
          style={{
            color: alpha(theme.palette.text.primary, 0.5),
            marginLeft: 8,
            fontSize: '14px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {value}
        </Typography>
      </Typography>
    );
  };

  return (
    <Grid
      item
      sm={12}
      xs={12}
      onClick={onSelectCard}
      className={useStyles().gridContainer}
    >
      <StyledBox>
        <TypographyCustomCard name={'Nome: '} value={keys?.nome} />
        <TypographyCustomCard name={'Documento: '} value={keys?.documento} />
        <TypographyCustomCard
          name={'Banco: '}
          value={keys?.favorecido?.bancoDTO?.nome}
        />
        <TypographyCustomCard
          name={'Agência: '}
          value={keys?.favorecido?.agencia}
        />
        <TypographyCustomCard
          name={'Conta: '}
          value={`${keys?.favorecido?.conta}${
            !keys?.favorecido?.chavePix
              ? `-${keys?.favorecido?.digitoConta}`
              : ''
          }`}
        />
      </StyledBox>
    </Grid>
  );
};

export default CardLastTransactions;
