import React, { useEffect } from 'react';
import Wrapper from '../../components/Wrapper';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineOppositeContent,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
} from '@material-ui/lab';
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useStyles } from './styles';
import moment from 'moment';
import { Add, HistoryOutlined } from '@material-ui/icons';
import { api_multipague_transacao } from '../../services/api';
import { toastComponentError } from '../../components/Toast';
import { masterOuAnalistaCredito } from '../../util/handlePermissions';

const HistoricoTransferencia = ({ modalidade }) => {
  const history = useHistory();
  const classes = useStyles();
  const timelineRef = React.useRef(Array(historico?.length).fill(false));
  const [timelineVisivel, setTimelineVisivel] = React.useState(
    timelineRef.current,
  );
  const [dataCadastroeStatus, setDataCadastroeStatus] = React.useState({
    usuarioCadastro: '',
    dataCadastro: '',
    status: '',
    descricaoHistorico: '',
  });

  function handleClick(i) {
    timelineRef.current[i] = !timelineRef.current[i];
    setTimelineVisivel([...timelineRef.current]);
  }
  const historico = history.location?.state?.historic || [];
  const idTransacao = history.location?.state?.idTransacao || [];

  const formInfo = history.location?.state?.formInfo;

  const tipoHistorico = (() => {
    if (modalidade == 'TED') {
      return historico[0]?.evento?.descricao == 'Transferência TED Hoje' ||
        historico[0]?.evento?.descricao == 'Transferência TED Agendada'
        ? 'TED'
        : historico[0]?.evento?.descricao;
    } else {
      return modalidade;
    }
  })();

  useEffect(() => {
    if (tipoHistorico == 'TED' || tipoHistorico == 'PIX') {
      handlePopulateDataCadastro();
    } else if (
      tipoHistorico == 'Arquivo de Pagamento' ||
      tipoHistorico == 'Pagamento boleto'
    ) {
      setDataCadastroeStatus({
        usuarioCadastro: historico[0]?.loginUsuario,
        dataCadastro: historico[0]?.dataCadastro,
        status: historico[0]?.statusObjetoTransacao,
      });
    }

    if (!tipoHistorico) {
      handlePopulateDataCadastro();
    }
  }, []);

  const handlePopulateDataCadastro = async () => {
    api_multipague_transacao
      .get(`/transferencia/${idTransacao || historico[0]?.codigoTransferencia}`)
      .then(response => {
        setDataCadastroeStatus({
          usuarioCadastro: response.data.loginUsuario,
          dataCadastro: response.data.dataCadastro,
          status: response.data.status,
          descricaoHistorico: response.data.descricaoHistorico,
          valor: response.data.valor,
        });
      })
      .catch(err => {
        toastComponentError(err.response?.data?.mensagem);
      });
  };

  const handleParecer = item => {
    if (masterOuAnalistaCredito && item?.parecer != null) {
      return (
        <TimelineItem key={item.parecer}>
          <TimelineOppositeContent color="secondary">
            <Typography variant="body2">{'Parecer'}</Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot color="grey" />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Typography variant="body2">{item?.parecer}</Typography>
          </TimelineContent>
        </TimelineItem>
      );
    } else if (
      masterOuAnalistaCredito &&
      item?.exigeAlcadaCredito &&
      item?.parecer != null
    ) {
      return (
        <TimelineItem key={item.parecer}>
          <TimelineOppositeContent color="secondary">
            <Typography variant="body2">{'Parecer'}</Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot color="grey" />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Typography variant="body2">{item?.parecer}</Typography>
          </TimelineContent>
        </TimelineItem>
      );
    } else if (!masterOuAnalistaCredito) {
      return null;
    }
  };

  return (
    <Wrapper
      goBack={() =>
        history.location?.state?.isEsteira
          ? history.push('/Esteira', { formInfo: formInfo })
          : modalidade === 'PIX'
          ? history.push('/ListaPIX', { formInfo: formInfo })
          : history.push('/ListaTransferencia', { formInfo: formInfo })
      }
      arrowBack
      title={'Histórico' + (tipoHistorico ? ' de ' + tipoHistorico : '')}
      crumb={[
        { name: 'Dashboard', link: '/dashboard' },
        {
          name: history.location?.state?.isEsteira
            ? 'Esteira'
            : 'Lista de ' + modalidade,
          link: history.location?.state?.isEsteira
            ? '/Esteira'
            : modalidade === 'TED'
            ? '/ListaTransferencia'
            : '/ListaPIX',
        },
      ]}
    >
      <Paper className={classes.paperTimeline}>
        <Box className={classes.boxTimeline}>
          {historico.length > 0 && tipoHistorico != 'Arquivo de Pagamento' && (
            <>
              <Typography
                variant="body2"
                className={classes.stateHistoricoFavorecico}
              >
                <span> Favorecido: </span>{' '}
                {historico[0]?.beneficiarioFavorecido?.nome}
              </Typography>
              <Typography variant="body2" className={classes.stateHistorico}>
                <span>
                  {historico[0]?.beneficiarioFavorecido?.conta
                    ? 'Conta:'
                    : 'Banco'}{' '}
                </span>
                {historico[0]?.beneficiarioFavorecido.banco.nome}
                {historico[0]?.beneficiarioFavorecido?.agencia
                  ? ' - ' + historico[0]?.beneficiarioFavorecido?.agencia
                  : ''}
                {historico[0]?.beneficiarioFavorecido?.conta
                  ? ' - ' + historico[0]?.beneficiarioFavorecido?.conta
                  : ''}
                {historico[0]?.beneficiarioFavorecido?.digitoConta
                  ? ' - ' + historico[0]?.beneficiarioFavorecido?.digitoConta
                  : ''}
              </Typography>
            </>
          )}
          <Typography variant="body2" className={classes.stateHistorico}>
            <span>Valor: </span>
            {(historico[0]?.valor || dataCadastroeStatus.valor)?.toLocaleString(
              'pt-br',
              {
                style: 'currency',
                currency: 'BRL',
              },
            )}
          </Typography>
          <Typography variant="body2" className={classes.stateHistorico}>
            <span>Cadastrado por: </span>
            {dataCadastroeStatus?.usuarioCadastro}
          </Typography>
          <Typography variant="body2" className={classes.stateHistorico}>
            <span>Data do Cadastro: </span>
            {moment(dataCadastroeStatus?.dataCadastro).format(
              'DD/MM/YYYY HH:mm:ss',
            )}
          </Typography>
          <Typography variant="body2" className={classes.stateHistorico}>
            <span>Data do Pagamento: </span>
            {moment(historico[0]?.dataPagamento).format('DD/MM/YYYY')}
          </Typography>
          <Typography
            variant="body2"
            className={classes.stateHistoricoSituacao}
          >
            <span>Situação: </span>
            {dataCadastroeStatus?.status}
          </Typography>
          {tipoHistorico != 'Arquivo de Pagamento' && (
            <Typography variant="body2" className={classes.stateHistorico}>
              <span>Descrição Histórico: </span>
              {dataCadastroeStatus?.descricaoHistorico}
            </Typography>
          )}
        </Box>
        <Grid container className={classes.timeline}>
          {historico.map((item, i) => (
            <Grid
              item
              md={
                historico?.length === 1 ? 12 : historico?.length === 2 ? 6 : 4
              }
              xl={
                historico?.length === 1 ? 12 : historico?.length === 2 ? 6 : 4
              }
              lg={
                historico?.length === 1 ? 12 : historico?.length === 2 ? 6 : 4
              }
              sm={12}
              xs={12}
              className={classes.divHistoricoWithIconAndTimeline}
            >
              <div className={classes.divHistoricoIcon}>
                {!timelineVisivel[i] && (
                  <Typography variant="body2" className={classes.textHistory}>
                    <Typography variant="body2">
                      Nome Esteira: {item?.clienteCorrentista?.nome}
                    </Typography>
                    <Typography variant="body2">
                      {'Data: '}
                      {item.dataTramitacao === null
                        ? item.situacao
                        : moment(item.dataTramitacao).format(
                            'DD/MM/YYYY HH:mm:ss',
                          )}
                    </Typography>
                  </Typography>
                )}
                <IconButton
                  className={classes.iconHistory}
                  onClick={() => handleClick(i)}
                >
                  {timelineVisivel[i] ? (
                    <HistoryOutlined
                      style={{ color: useTheme().palette.primary.main }}
                      size={24}
                    />
                  ) : (
                    <Tooltip title="Ver mais" placement="bottom">
                      <Add color="primary" size={54} />
                    </Tooltip>
                  )}
                </IconButton>
              </div>
              {timelineVisivel[i] && (
                <Timeline style={{ paddingTop: '7%' }} key={item.id}>
                  <TimelineItem key={item.loginTramitador}>
                    <TimelineOppositeContent color="secondary">
                      <Typography variant="body2">{'Usuário'}</Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot color="primary" />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Typography variant="body2">
                        {item?.loginTramitador === null
                          ? '-'
                          : item?.loginTramitador}
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem key={item?.clienteCorrentista?.nome}>
                    <TimelineOppositeContent color="secondary">
                      <Typography variant="body2">{'Nome Esteira'}</Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot color="grey" />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Typography
                        variant="body2"
                        className={classes.tipographyClienteCorrentistaNome}
                      >
                        {item?.clienteCorrentista?.nome}
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                  {handleParecer(item)}
                  <TimelineItem key={item.situacao}>
                    <TimelineOppositeContent color="secondary">
                      <Typography variant="body2">{'Situação'}</Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot color="grey" />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Typography variant="body2">{item.situacao}</Typography>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem key={item.dataTramitacao}>
                    <TimelineOppositeContent color="secondary">
                      <Typography variant="body2">
                        {'Data e Hora da Interação'}
                      </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot color="grey" />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Typography variant="body2">
                        {item.dataTramitacao === null
                          ? item.situacao
                          : moment(item.dataTramitacao).format(
                              'DD/MM/YYYY HH:mm:ss',
                            )}
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                </Timeline>
              )}
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Wrapper>
  );
};

export default HistoricoTransferencia;
