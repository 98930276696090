export function extratoTedRequest(idTed) {
  return {
    type: 'EXTRATO_TED_REQUEST',
    payload: idTed,
  };
}
export function extratoTedSuccess(extrato) {
  return {
    type: 'EXTRATO_TED_SUCCESS',
    payload: extrato,
  };
}
export function extratoTedError() {
  return {
    type: 'EXTRATO_TED_ERROR',
  };
}
