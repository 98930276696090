import styled, { css } from 'styled-components';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

export const Container = styled.div`
  display: grid;
  grid-template-columns: auto 100% auto;
  grid-template-rows: auto;

  ${props =>
    props.cor &&
    css`
      background-color: ${props.cor};
    `}
  ${props =>
    props.altura &&
    css`
      height: ${props.altura};
    `}
  @media only screen and (min-width: 1024px) {
    display: grid;
    grid-template-columns: auto 100% auto;
    ${props =>
      props.cor &&
      css`
        background-color: ${props.cor};
      `}
    ${props =>
      props.altura &&
      css`
        height: ${props.altura};
      `}
  }
  @media only screen and (min-width: 1440px) {
    display: grid;
    grid-template-columns: auto 100% auto;
    ${props =>
      props.cor &&
      css`
        background-color: ${props.cor};
      `}
    ${props =>
      props.altura &&
      css`
        height: ${props.altura};
      `}
  }
`;
export const Subcontainer = styled.div`
  grid-area: 1/2/2/3;
  display: grid;
  grid-template-columns: 10% auto 10%;
  grid-template-rows: 20px auto 20px;
  @media only screen and (min-width: 1024px) {
    grid-area: 1/2/2/3;
    display: grid;
    grid-template-columns: 10% auto 10%;
    grid-template-rows: 20px auto 20px;
  }
  @media only screen and (min-width: 1440px) {
    grid-area: 1/2/2/3;
    display: grid;
    grid-template-columns: 10% auto 10%;
    grid-template-rows: 20px auto 20px;
  }
`;
export const Principal = styled.div`
  grid-area: 2/2/3/3;
  display: grid;
  row-gap: 20px;
  @media only screen and (min-width: 1024px) {
    grid-area: 2/2/3/3;
    row-gap: 40px;
  }
  @media only screen and (min-width: 1440px) {
    grid-area: 2/2/3/3;
  }
`;
export const CaminhoScreen = styled.div`

  @media only screen and (min-width: 1024px) {

  }
  @media only screen and (min-width: 1440px) {

  }
`;


export const ContainerPath = styled(Breadcrumbs)`

  @media only screen and (min-width: 1024px) {

  }
  @media only screen and (min-width: 1440px) {

  }
`;

export const PathToPag = styled.div`
  cursor: pointer;
  p{
    font-weight: 500;
    color: #000;
    font-family: 'CoreSans', sans-serif;
  }
  @media only screen and (min-width: 1024px) {

  }
  @media only screen and (min-width: 1440px) {

  }
`;
export const TitlePrincipalPath = styled.div`
  cursor: no-drop;
  p{
    font-weight: bold;
    color: #000;
    font-family: 'CoreSans', sans-serif;
  }

  @media only screen and (min-width: 1024px) {

  }
  @media only screen and (min-width: 1440px) {

  }
`;
