export function listarCentroCustoRequest(id) {
  return {
    type: 'LISTAR_CENTRO_CUSTO_REQUEST',
    payload: id,
  };
}
export function listarCentroCustoSuccess(listBeneficiarios) {
  return {
    type: 'LISTAR_CENTRO_CUSTO_SUCCESS',
    payload: listBeneficiarios,
  };
}
export function listarCentroCustoError() {
  return {
    type: 'LISTAR_CENTRO_CUSTO_ERROR',
  };
}
