import { all, takeLatest, select, call, put } from 'redux-saga/effects';
import { api_multipague_transacao } from '../../../services/api';
import { showLoading } from '../loading/actions';
import { extratoTedSuccess, extratoTedError } from './actions';

import { toastComponentError } from '../../../components/Toast';

function* comprovanteExtrato({ payload }) {
  try {
    yield put(showLoading(true));

    const { data } = yield call(
      api_multipague_transacao.get,
      `/transferencia/comprovante/${payload}`,
    );


    if (typeof data != 'undefined') {
      yield put(extratoTedSuccess(data));
      yield put(showLoading(false));
      localStorage.setItem('extratoTransferencia', JSON.stringify(data));
    } else {
      localStorage.removeItem('extratoTransferencia');
      yield put(showLoading(false));
      yield put(extratoTedError());
    }
  } catch (err) {
    console.log('----------->', err);
    yield put(showLoading(false));
    localStorage.removeItem('extratoTransferencia');
    toastComponentError(
      `COMPROVANTE: Codigo: ${err.response.data.codigo} - ${err.response.data.mensagem}`,
    );
    yield put(extratoTedError());
  }
}

export default all([takeLatest('EXTRATO_TED_REQUEST', comprovanteExtrato)]);
