import React, { useEffect, useState } from 'react';
import { Chip, Divider, Grid, Typography } from '@material-ui/core';
import { toastComponentSuccess } from '../../../components/Toast';
import {
  api_multipague_configuracao,
  api_multipague_conta,
} from '../../../services/api';

const useTariffsClient = () => {
  const [mostrarTarifas, setMostrarTarifas] = useState();
  const [isTarifado, setIsTarifado] = useState(false);
  const [listaTarifas, setListaTarifas] = useState(false);
  const idConta = localStorage.getItem('idConta');

  function criarConta() {
    const URL = `/aberturaConta/${localStorage.getItem('contaId')}`;
    api_multipague_conta
      .post(URL)
      .then(function (response) {
        toastComponentSuccess(response.data.mensagem);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    const URL = `/eventoClienteConta/lista?idClienteConta=${idConta}&tarifado=${true}`;
    api_multipague_configuracao
      .get(URL)
      .then(function (response) {
        setIsTarifado(true);
        const formatTarifado = response.data.map(usr => {
          return (
            <>
              <div
                style={{ display: 'flex', width: '100%', padding: '15px 0' }}
                key={usr.id}
              >
                <div style={{ width: '100%' }}>
                  <p>
                    <strong>Evento: </strong>
                    {usr.evento.descricao}{' '}
                  </p>
                </div>
                <div style={{ width: '100%', textAlign: 'right' }}>
                  <p>
                    <strong>Valor Tarifa: </strong>
                    {usr.valorTarifa.toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })}{' '}
                  </p>
                </div>
              </div>
              <Divider />
            </>
          );
        });
        setListaTarifas(formatTarifado);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [idConta]);

  const tariffs = (
    <>
      <Grid item xs={12} md={6} lg={6}>
        {isTarifado && (
          <Chip
            color="secondary"
            variant="outlined"
            label={!mostrarTarifas ? 'VER TARIFAS' : 'OCULTAR TARIFAS'}
            style={{
              width: '180px',
              fontSize: '13px',
              marginLeft: '1px',
            }}
            onClick={() =>
              mostrarTarifas
                ? setMostrarTarifas(false)
                : setMostrarTarifas(true)
            }
          />
        )}
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <Chip
          color="primary"
          variant="outlined"
          label={'ADICIONAR CONTA'}
          style={{ float: 'right', width: '180px', fontSize: '13px' }}
          onClick={() => criarConta()}
        />
      </Grid>
      {mostrarTarifas && (
        <Grid item xs={12} md={12}>
          {listaTarifas}
        </Grid>
      )}
    </>
  );

  return { tariffs };
};

export default useTariffsClient;
