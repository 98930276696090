import styled, { css } from 'styled-components';
import TextField from '@material-ui/core/TextField';
import Select from '../Select';
import InputData from '../InputDate';
import { Form } from '@unform/web';

export const Container = styled(Form)`
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;
export const Subcontainer = styled.div`
  display: grid;
  row-gap: 20px;
  @media only screen and (min-width: 1024px) {
    row-gap: 40px;
  }
  @media only screen and (min-width: 1440px) {
  }
`;
export const ContainerMotivoCancelamento = styled.div`
    display: grid;
    row-gap: 20px;
  @media only screen and (min-width: 1024px) {
    row-gap: 40px;
  }
  @media only screen and (min-width: 1440px) {
  }
`;
export const ContainerObservacao = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;

export const InputText = styled(TextField)`
  width: 100%;
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;
export const InputSelect = styled(Select)`
  width: 100%;
  min-width: 100%;
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;
export const ContainerButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (min-width: 1024px) {
    justify-content: space-between;
  }
  @media only screen and (min-width: 1440px) {
  }
`;
export const ContainerDataProrrogacao = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;

export const Data = styled(InputData)`
  width: 100%;
  min-width: 100%;
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;
