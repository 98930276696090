import React from 'react';

import List from '@material-ui/core/List';
import { makeStyles, alpha, useTheme } from '@material-ui/core/styles';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import PollIcon from '@material-ui/icons/Poll';
import SubtitlesIcon from '@material-ui/icons/Subtitles';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import SettingsIcon from '@material-ui/icons/Settings';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import Notes from '@material-ui/icons/Notes';
import PersonIcon from '@material-ui/icons/Person';
import DraftsIcon from '@material-ui/icons/Drafts';
import AssignmentIcon from '@material-ui/icons/Assignment';

import ItemMenu from './ItemMenu';
import ItemSubmenu from './ItemSubmenu';
import { Container } from './style';
import { useAuthContext } from '../../Context/AuthContext';
import KeycloakService from '../../auth';
import { PixIcon } from '../IconsCustom/Pix';

export default function MenuList({ tooltip = false }) {
  const { permissionAdmin, permissionMercado } = useAuthContext();
  const infoLocalStorage = localStorage.getItem('accounts_default');
  const infoEvento = JSON.parse(infoLocalStorage)?.indEventoPix;

  const useStyles = makeStyles(theme => ({
    root: {
      width: tooltip ? 110 : 260,
      flexGrow: 1,
      maxWidth: 260,
      overflow: 'hidden',
      backgroundColor: theme.palette.appBar[theme.palette.type].bgMenu,
      color: theme.palette.appBar[theme.palette.type].text,

      [theme.breakpoints.down('xs')]: {
        width: tooltip ? 0 : 260,
      },
    },
    submenu: {
      paddingLeft: '1rem',
    },
    menuActive: {},
    divList: {
      marginLeft: '6%',
      borderTop: 'none',
    },
  }));

  const classes = useStyles();

  return (
    <Container className="container-menu">
      <List
        component="nav"
        aria-labelledby="menu-list-subheader"
        className={classes.root}
      >
        <ItemMenu
          tooltip={tooltip}
          to="/dashboard"
          name="Dashboard"
          Icon={DashboardIcon}
        />

        <ItemMenu
          tooltip={tooltip}
          Icon={AccountBalanceIcon}
          name="Minhas Contas"
        >
          <div className={classes.divList}>
            <ItemSubmenu tooltip={tooltip} to="/MinhasContas" name="Geral" />
            <ItemSubmenu tooltip={tooltip} to="/extrato" name="Extrato" />
          </div>
        </ItemMenu>

        <ItemMenu
          tooltip={tooltip}
          Icon={ImportExportIcon}
          name="Transferência"
        >
          <div className={classes.divList}>
            <ItemSubmenu
              tooltip={tooltip}
              to="/Listagem/Favorecidos"
              path="/Favorecidos"
              name="Favorecido"
            />
            <ItemSubmenu
              tooltip={tooltip}
              to="/ListaTransferencia"
              name="TED"
            />
          </div>
        </ItemMenu>

        {infoEvento && (
          <ItemMenu
            tooltip={tooltip}
            Icon={() => (
              <PixIcon color={useTheme().palette.primary.main} size={'24px'} />
            )}
            name="PIX"
          >
            <div className={classes.divList}>
              <ItemSubmenu
                tooltip={tooltip}
                to="/ChavesPix"
                name="Chaves PIX"
              />
              <ItemSubmenu tooltip={tooltip} to="/ListaPIX" name="PIX" />
            </div>
          </ItemMenu>
        )}

        <ItemMenu tooltip={tooltip} Icon={MonetizationOnIcon} name="Pagamento">
          <div className={classes.divList}>
            <ItemSubmenu
              tooltip={tooltip}
              to="/ListaBoleto"
              name="Pag.Cód.Barras"
            />
            <ItemSubmenu
              tooltip={tooltip}
              to="/Pagamento-Lote"
              name="Pagamento em Lote"
            />
            <ItemSubmenu
              tooltip={tooltip}
              to="/Pagamento-Retorno"
              name="Retorno Pagamento"
            />
          </div>
        </ItemMenu>

        <ItemMenu tooltip={tooltip} Icon={SubtitlesIcon} name="Cob. Simples">
          <div className={classes.divList}>
            <ItemSubmenu
              tooltip={tooltip}
              to="/Cobranca/Simples"
              name="Inclusão Boletos"
            />
            <ItemSubmenu
              tooltip={tooltip}
              to="/boleto/impressao-em-lote"
              name="Impressão Boletos"
            />
            <ItemSubmenu
              tooltip={tooltip}
              to="/Buscar/Boleto"
              name="Consultar Boleto"
            />
            <ItemSubmenu
              tooltip={tooltip}
              to="/Processamento/Cnab"
              name="Acompanhar CNAB"
            />
            <ItemSubmenu
              tooltip={tooltip}
              to="/Carteira"
              name="Associação de Carteira"
            />
            <ItemSubmenu
              tooltip={tooltip}
              to="/retorno/remessa"
              name="Retorno Remessa"
            />
            <ItemSubmenu tooltip={tooltip} to="/pagador" name="Pagador" />
          </div>
        </ItemMenu>

        {permissionMercado ? (
          <ItemMenu
            tooltip={tooltip}
            to="/consulta-mercado"
            name="Consulta Mercado"
            Icon={PollIcon}
          />
        ) : (
          <></>
        )}

        <ItemMenu
          tooltip={tooltip}
          to="/Esteira"
          name="Esteira"
          Icon={AssignmentIcon}
        />

        {permissionAdmin ? (
          <ItemMenu
            tooltip={tooltip}
            to="/Usuarios"
            name="Gestão de Usuários"
            Icon={PersonIcon}
          />
        ) : (
          <></>
        )}

        <ItemMenu tooltip={tooltip} to="/faq" name="FAQ" Icon={LiveHelpIcon} />
        <ItemMenu tooltip={tooltip} to="/termos" name="Termos" Icon={Notes} />

        <ItemMenu
          tooltip={tooltip}
          to="/novidades-da-versao"
          name="Novidades"
          Icon={DraftsIcon}
        />

        <ItemMenu tooltip={tooltip} Icon={SettingsIcon} name="Configurações">
          <div className={classes.divList}>
            <ItemSubmenu
              tooltip={tooltip}
              to="/centro-de-custo"
              path="Centro de Custos"
              name="Centro de Custos"
            />
          </div>
        </ItemMenu>

        <ItemMenu
          tooltip={tooltip}
          onClick={() => KeycloakService.logoutMfe()}
          name="Sair"
          Icon={MeetingRoomIcon}
        />
      </List>
    </Container>
  );
}
