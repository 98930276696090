import TextField from '@material-ui/core/TextField';
import styled from 'styled-components';
import DateInput from '../../components/InputDate';

export const Container = styled.div`
  display: grid;
  /* justify-content: center;
  align-items: center; */
  background-color: #fff;
  padding: 20px 0;
  row-gap: 20px;

  @media only screen and (min-width: 1024px) {
    display: grid;
    /* justify-content: center;
    align-items: center; */
    row-gap: 20px;
  }
`;
export const Subcontainer = styled.div`
  /* grid-template-columns: 100%; */
  height: auto;
  display: grid;
  width: 100%;
  row-gap: 20px;

  @media only screen and (min-width: 1024px) {
    /* width: 900px; */
    height: auto;
    display: grid;
    row-gap: 20px;
  }
`;
export const InputText = styled(TextField)`
  width: 100%;
  @media only screen and (min-width: 1024px) {
  }
`;
export const ContainerValorPagamento = styled.div`
  width: 100%;
  display: grid;
  row-gap: 20px;

  @media only screen and (min-width: 1024px) {
    width: 100%;
    display: flex;
    row-gap: 20px;
    column-gap: 50px;
    justify-content: flex-start;
    align-items: center;
  }
`;
export const ContainerAgendamentoPagamento = styled.div`
  width: 100%;
  display: grid;
  row-gap: 20px;

  @media only screen and (min-width: 1024px) {
    display: grid;
    grid-template-columns: 50%;
    row-gap: 20px;
    align-items: center;
    width: 100%;
  }
`;
export const ContainerRadioGroup = styled.div`
  display: grid;
  justify-content: flex-start;
  align-items: center;
  background-color: #fff;

  @media only screen and (min-width: 1024px) {
    display: grid;
    justify-content: flex-start;
    align-items: center;
  }
`;
export const SubContainerRadioGroup = styled.div`
  /* width: 330px; */
  height: auto;
  display: grid;
  row-gap: 20px;

  @media only screen and (min-width: 1024px) {
    /* width: 900px; */
    height: auto;
    display: grid;
    row-gap: 20px;
    justify-content: flex-start;
    align-items: center;
  }
`;
export const AgendamentoPagamento = styled.div`
  /* width: 330px; */
  height: auto;
  display: grid;
  row-gap: 20px;

  @media only screen and (min-width: 1024px) {
    /* width: 900px; */
    height: auto;
    display: grid;
    justify-content: flex-start;
    align-items: center;
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  @media only screen and (min-width: 1024px) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`;

export const Data = styled(DateInput)`
  width: 100%;
  margin: 0 !important;

  @media only screen and (min-width: 1024px) {
    margin: 0 !important;
  }
  @media only screen and (min-width: 1440px) {
    margin: 0 !important;
  }
`;
