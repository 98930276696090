import styled, { keyframes, css } from 'styled-components';
import Tooltip from '../../components/tooltip';
import { Form } from '@unform/web';


// ================ PESQUISA ==================
export const FormPesquisa = styled(Form)`
  display:flex;
  flex-direction: column;
`;

export const FormRow = styled.div`
  display:flex;
  justify-content: space-between;
  gap:1rem;
  flex-wrap: wrap;
`
export const FormCol = styled.div `
  display:flex;
  gap:1rem;
  flex:1;
  margin-bottom: ${({marginBottom})=> marginBottom || "1rem" };
  padding-top: ${({paddingTop})=> paddingTop || "0px" };
`


export const CheckFilter = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;
export const Printed = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  div {
    :nth-child(2) {
      margin-left: 10px;
    }
  }
  @media only screen and (min-width: 1024px) {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    div {
      :nth-child(2) {
        margin-left: 10px;
      }
    }
  }
  @media only screen and (min-width: 1440px) {
  }
`;


// ====================== TABLE =============================


export const ContainerActionsTable = styled.div`
  margin-left:5px;
  margin-right:5px;
  height:30px;
  button{
    height:30px;
    transition: all 200ms ease-in-out;
    &:hover{
      transform: scale(1.15);
    }
  }

  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;


//

export const ButtonTable = styled.button.attrs(props => ({
  disabled: false,
})) ` `;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const ErroContainer = styled(Tooltip)`
  width: auto;
  height: auto;
  span {
    background-color: red;

    color: blue;
    border-radius: 4px;
    width: 175px;
    height: 76px;
    display: flex;
    justify-content: center;
    align-items: center;
    &::before {
      border-color: red transparent;
    }
  }
`;

export const LoadingIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    animation: ${rotate} 2s linear infinite;
  }
`;
