export const columnsTable = [
  {
    field: 'nomePagador',
    headerName: 'Nome Pagador',
    width: 250,
    flex: 1,
  },
  {
    field: 'numeroTitulo',
    headerName: 'Número do Título',
    width: 150,
  },
  {
    field: 'nossoNumero',
    headerName: 'Nosso Número',
    width: 150,
  },
  { field: 'dataVencimento', headerName: 'Data Vencimento', width: 150 },
  {
    field: 'descricaoErro',
    headerName: 'Descrição do Erro',
    width: 250,
    flex: 1,
  },
];
