import React, { useEffect, useMemo, useState } from 'react';
import { Form } from '@unform/web';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/Delete';

import { MdSwapVert } from 'react-icons/md';

import Buttons from '../../components/Buttons/ConfirmButton';
import { InputMoney } from '../../components/Inputs/Money';
import InputText from '../../components/InputText';
import RadioGroup from '../../components/RadioButton';
import {
  api_multipague_transacao,
  api_multipague_arquivo,
} from '../../services/api';
import { listarTodosFavoritosRequest } from '../../store/modules/beneficiarios/listar_todos/actions';
import { listarCentroCustoRequest } from '../../store/modules/centroCusto/listar/actions';
import { listarFinalidadeRequest } from '../../store/modules/finalidade/actions';
import {
  hideLoadingButton,
  showLoadingButton,
} from '../../store/modules/loading/actions';
import DateComponent from '../../components/InputDate';
import AutoCompleteUnform from '../../components/AutoCompleteUnform';
import Box from '../../components/Box';
import Wrapper from '../../components/Wrapper';
import { Button, ListItem, ListItemText, Tooltip } from '@material-ui/core';
import {
  toastComponentError,
  toastComponentSuccess,
} from '../../components/Toast';
import { VisuallyHiddenInput } from './DialogModalComponent/styles';
import { FiUpload } from 'react-icons/fi';
import AutoCompleteMui from '../../components/AutoCompleteMui';
import { paymentDateInitialObject } from './DialogModalComponent/objects';
import useDialogModalComponent from './DialogModalComponent';
import { Alert } from '@material-ui/lab';

const Transferencia = ({ tipo }) => {
  const {
    setOpenModalBMP,
    favorecido,
    setFavorecido,
    valorTed,
    setValorTed,
    agendamentoFormaPagamento,
    setAgendamentoFormaPagamento,
    dataAgendamento,
    setDataAgendamento,
    dataArquivoId,
    setDataArquivoId,
    ordemdePagamento,
    setOrdemdePagamento,
    dialog,
    dialogPassword,
    submitFormRef,
    submitData,
    errorFavorecido,
    setErrorFavorecido,
  } = useDialogModalComponent(tipo);

  const dispatch = useDispatch();
  const finalidadesList = useSelector(state => state.finalidades);
  const { contaPadrao } = useSelector(state => state.contas);
  const { loadingButton } = useSelector(state => state.loading);
  const listCentroCusto = useSelector(state => state.centroCusto);
  const { userAccount } = useSelector(state => state.user);
  const listagemFavoritos = useSelector(state => state.listTodosFavoritos);
  const [favoritos, setFavoritos] = useState([]);
  const [ordemdePagamentoArray, setOrdemdePagamentoArray] = useState([]);
  const [isLoadingOrdem, setIsLoadingOrdem] = useState(false);
  const [finalidade, setFinalidade] = useState([]);
  const [centroCusto, setCentroCusto] = useState([]);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [paymentDate, setPaymentDate] = useState(paymentDateInitialObject);

  useEffect(() => {
    dispatch(listarFinalidadeRequest());
  }, [dispatch]);

  useEffect(() => {
    setIsLoadingOrdem(true);
    setOrdemdePagamento(null);
    const endPoint = `beyond/ordem-pagamento?cnpjCpfCedente=${contaPadrao?.cliente?.cnpjCpf}&cnpjFundo=${favorecido.cnpjCpf}&indFinalizada=false&indRejeitada=false`;
    api_multipague_transacao
      .get(`${endPoint}`)
      .then(response => {
        const formattedData = response.data.map(item => ({
          ...item,
          idCedente: item.idCedente,
          idFundo: item.idFundo,
          label: String(item.id),
          qtdTitulos: item.qtdTitulos,
        }));
        setOrdemdePagamentoArray(formattedData);
        setIsLoadingOrdem(false);
      })
      .catch(err => {
        console.log(err);
        setOrdemdePagamento(null);
        setOrdemdePagamentoArray([]);
        setIsLoadingOrdem(false);
      });
  }, [favorecido.id]);

  useEffect(() => {
    if (listagemFavoritos.length) {
      const formatted = listagemFavoritos.map(list => ({
        label: `${list.nomeBanco} - ${list.nome}`,
        value: list.id,
        ...list,
      }));
      setFavoritos(formatted);
    }
  }, [listagemFavoritos]);

  useEffect(() => {
    if (userAccount?.id) {
      dispatch(
        listarTodosFavoritosRequest({
          id: userAccount.id,
          ativo: true,
          situacao: 'APROVADO',
          idClienteConta: contaPadrao.id,
        }),
      );
      dispatch(listarCentroCustoRequest(userAccount?.id));
    }
  }, [userAccount]);

  useEffect(() => {
    const formatted = finalidadesList.map(data => ({
      ...data,
      label: data.descricao,
      value: data.id,
    }));

    setFinalidade(formatted);
  }, [finalidadesList]);

  useEffect(() => {
    const formatted = listCentroCusto.map(data => ({
      ...data,
      label: data.descricao,
      value: data.id,
    }));
    setCentroCusto(formatted);
  }, [listCentroCusto]);

  const mostrarDataAgendamento = useMemo(() => {
    if (
      agendamentoFormaPagamento.agendado &&
      agendamentoFormaPagamento.selected
    ) {
      return (
        <DateComponent
          id="data-pagamento-agendado"
          label="Agendar Pagamento"
          name="agendamento"
          style={{ marginTop: 15 }}
          width={'80%'}
          callback={setDataAgendamento}
          value={dataAgendamento}
          defaultValue={dataAgendamento}
        />
      );
    }
  }, [agendamentoFormaPagamento, dataAgendamento]);

  function selecionarDiaPagamento(data) {
    const dataSelecionada = paymentDate.map(val =>
      val.value === data
        ? {
            ...val,
            selected: true,
          }
        : {
            ...val,
            selected: false,
          },
    );
    const filterDalaSelecionada = dataSelecionada.filter(date => date.selected);
    setAgendamentoFormaPagamento(filterDalaSelecionada[0]);
    setPaymentDate(dataSelecionada);
  }

  async function sendFiles(file) {
    if (!!file) {
      try {
        dispatch(showLoadingButton(true));
        setSelectedFile(file);
        const formData = new FormData();

        formData.append('file', file);
        formData.append('clienteConta', Number(contaPadrao.id));
        const { data } = await api_multipague_arquivo.post(
          '/arquivo/upload',
          formData,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
          },
        );

        if (!!data) {
          toastComponentSuccess('Upload realizado com sucesso');
          setDataArquivoId(data.id);
          dispatch(hideLoadingButton(false));
        }
      } catch (err) {
        toastComponentError(err.response.data.mensagem);
        dispatch(hideLoadingButton(false));
      }
    }
  }

  const handleCapture = ({ target }) => {
    sendFiles(target.files[0]);
  };

  useEffect(() => {
    dispatch(hideLoadingButton(false));
  }, [tipo, dispatch]);

  const handleOrdemPagamentoChange = (_, options) => {
    if (options) {
      setOrdemdePagamento(options);
      const totalValorPago = options.reduce(
        (sum, item) => sum + item.valorPago,
        0,
      );
      setValorTed({
        ...valorTed,
        value: totalValorPago,
        error: '',
      });
    } else {
      setOrdemdePagamento(null);
      setValorTed({
        ...valorTed,
        value: 0,
        error: '',
      });
    }
  };

  return (
    <Wrapper
      title={tipo === 'PIX' ? 'PIX' : 'Transferência'}
      crumb={[
        {
          name: 'Minhas Contas',
          link: '/MinhasContas',
        },
        {
          name: tipo === 'PIX' ? 'Lista de PIX' : 'Lista de TEDs',
          link: tipo === 'PIX' ? '/ListaPIX' : '/ListaTransferencia',
        },
      ]}
    >
      {dialogPassword}
      <Form ref={submitFormRef}>
        <Box>
          {dialog}
          <Grid container spacing={3}>
            <Grid item xs={12} md={ordemdePagamentoArray.length > 0 ? 3 : 4}>
              <AutoCompleteMui
                label={'Favorecido'}
                name={'idFavorecido'}
                onChange={(_, option) => {
                  if (option?.label) {
                    setErrorFavorecido(false);
                    setFavorecido(option);
                  } else {
                    setErrorFavorecido(false);
                    setFavorecido('');
                  }
                }}
                value={favorecido}
                error={errorFavorecido}
                options={favoritos}
                renderOption={option => {
                  return (
                    <ListItem style={{ padding: 0 }}>
                      <ListItemText
                        primary={option.label}
                        secondary={`Agência ${option.agencia} / Conta: ${option.conta}-${option.digitoConta}`}
                      />
                    </ListItem>
                  );
                }}
              />
            </Grid>
            {ordemdePagamentoArray.length > 0 && (
              <Grid item xs={12} md={3}>
                <AutoCompleteMui
                  name="idOrdemdePagamento"
                  label="Ordem De Pagamento"
                  style={{ height: '18px' }}
                  multiple
                  isLoading={isLoadingOrdem}
                  value={ordemdePagamentoArray?.filter(objeto =>
                    ordemdePagamento
                      ?.map(item => item?.id)
                      ?.includes(objeto?.id),
                  )}
                  onChange={handleOrdemPagamentoChange}
                  options={ordemdePagamentoArray}
                />
              </Grid>
            )}

            <Grid item xs={12} md={ordemdePagamentoArray.length > 0 ? 3 : 4}>
              <AutoCompleteUnform
                name="idCentroCusto"
                label="Centro de Custo"
                options={centroCusto}
                id="idCentroCusto"
              />
            </Grid>
            <Grid item xs={12} md={ordemdePagamentoArray.length > 0 ? 3 : 4}>
              <AutoCompleteUnform
                name="idFinalidade"
                label="Finalidade"
                options={finalidade}
                id="idFinalidade"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <InputMoney
                value={valorTed.value}
                onChange={val =>
                  setValorTed({
                    ...valorTed,
                    value: val,
                    error: '',
                  })
                }
                error={valorTed.error}
                disabled={valorTed.disabled}
                label="Valor"
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                justifyContent: 'flex-end',
                overflow: 'auto',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  overflow: 'auto',
                  textAlign: 'right',
                  marginRight: '-10px',
                  scrollbarWidth: 'thin',
                }}
              >
                {selectedFile
                  ? selectedFile.name
                  : 'Nenhum arquivo selecionado!'}
                <Tooltip title="Deletar arquivo">
                  <DeleteIcon
                    color="error"
                    style={{ marginLeft: 10, cursor: 'pointer' }}
                    size={15}
                    onClick={() => {
                      setSelectedFile(null);
                      setDataArquivoId(null);
                    }}
                  />
                </Tooltip>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <Tooltip title="Documento Ordem de Pagamento">
                <Button
                  variant="outlined"
                  color="secondary"
                  component="label"
                  style={{ width: '100%', height: '100%' }}
                  role={undefined}
                  onChange={handleCapture}
                  tabIndex={-1}
                  disabled={dataArquivoId > 0}
                  startIcon={<FiUpload />}
                >
                  {dataArquivoId > 0
                    ? 'Arquivo anexado'
                    : 'Selecione um arquivo'}
                  <VisuallyHiddenInput type="file" accept="application/pdf" />
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: valorTed.error ? '20px' : null,
            }}
          >
            <Grid item xs={12} md={6}>
              <RadioGroup
                optionDefault={paymentDate[0].value}
                titulo="Data Pagamento"
                options={paymentDate}
                name="data_pagamento"
                callback={selecionarDiaPagamento}
              />
              {mostrarDataAgendamento}
            </Grid>
            <Grid item xs={12} md={12}>
              <InputText
                id="descricaoHistorico"
                label="Histórico"
                name="descricaoHistorico"
                multiline
                rows={4}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <Buttons
                color="primary"
                variant="contained"
                width={100}
                title={'Transferir'}
                disabled={loadingButton}
                isLoading={loadingButton}
                onClick={() => {
                  if (
                    favoritos
                      .filter(item => item.id === favorecido?.id)
                      .map(item => item.nrBanco)[0] === 274
                  ) {
                    const favoritosSelected = favoritos
                      .filter(item => item.id === favorecido?.id)
                      .map(item => item)[0];
                    setFavorecido({
                      nome: favoritosSelected.nome,
                      banco: `${favoritosSelected.nrBanco} ${favoritosSelected.nomeBanco} / Agência ${favoritosSelected.agencia} / Conta ${favoritosSelected.conta} -
                        ${favoritosSelected.digitoConta}`,
                      id: favoritosSelected.id,
                      cnpj: favoritosSelected.cnpjCpf,
                      label: `${favoritosSelected.nomeBanco} - ${favoritosSelected.nome}`,
                    });
                    setOpenModalBMP(true);
                  } else {
                    submitData();
                  }
                }}
                type="submit"
                startIcon={<MdSwapVert color={'background'} />}
              ></Buttons>
            </Grid>
          </Grid>
        </Box>
      </Form>
    </Wrapper>
  );
};

export default Transferencia;
