import styled, { css } from 'styled-components';
import Button from '@material-ui/core/Button';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonComponent = styled(Button).attrs(props => ({
  disabled: props.isLoading || props.disabled,
}))`
  text-transform: capitalize !important;

  ${props =>
    props.width &&
    css`
      width: ${props.width}px;
    `}
  ${props =>
    props.isLoading &&
    css`
      cursor: no-drop !important;
    `}
  ${props =>
    props.disabled &&
    css`
      cursor: no-drop !important;
    `}
`;
