import styled from 'styled-components';
import { Form } from '@unform/web';

export const Formulario = styled(Form)`
  display: flex;
  gap: 20px;
  
`;


export const ButtonTable = styled.button.attrs(props => ({
  disabled: false,
})) `
  border: none;
  background-color: transparent;
`;

export const Checks = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;