import styled from 'styled-components';
import { Form } from '@unform/web';
import { styled as MuiStyled } from '@material-ui/core';

export const Container = styled(Form)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 30px;
`;
export const Subcontainer = styled.div`
  display: grid;
  row-gap: 20px;
`;
export const ContainerInput = styled.div`
  display: grid;
  /* justify-content: center; */
  align-items: center;
  /* margin: 0 auto; */
  row-gap: 20px;
`;
export const ContainerInputPassword = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30%;
  input {
    width: 40%;
  }
`;
export const ContainerKeyboard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  @media only screen and (min-width: 1024px) {
    /* display: flex;
    justify-content: flex-end;
    align-items: center; */
    width: 800px;
  }
`;
export const ContainerButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media only screen and (min-width: 1024px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
export const ContainerTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  h1 {
    font-size: 22px;
    font-weight: normal;
  }
  @media only screen and (min-width: 1024px) {
  }
`;

export const ContainerError = MuiStyled('div')(({ theme }) => {
  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    '& p': {
      color: theme.palette.text.secondary,
    },
  };
});
