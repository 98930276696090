import React from 'react';

import ContainerComponent from '../../components/Container';
import Favoritos from '../../components/NewBeneficiary';
import { useParams } from 'react-router-dom';

const CadastroFavorecido = () => {

  return <Favoritos noMenu={false} />;
};

export default CadastroFavorecido;
