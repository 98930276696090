export const defaultNovoCorrentista = {
  email: '',
  nome: '',
  cpf: '',
  rg: '',
  ddd: '',
  redeSocial: '',
  telefone: '',
};

export const defaultInfoClientConta = {
  clienteContas: [],
  clienteCorrentistas: [],
  cnpjCpf: '',
  razaoSocial: '',
  fundos: [],
  id: '',
  administrador: '',
  checked: false,
  nome: '',
  dataCadastro: '',
  usuarioAberturaConta: '',
  situacao: '',
  pessoa: {
    id: '',
    nomeFantasia: '',
    documentoMunicipal: '',
    documentoEstadual: '',
    pessoasEndereco: [],
    pessoaContatos: [
      {
        contatoPrincipal: '',
        dataCadastro: '',
        ddd: '',
        ddi: '',
        email: '',
        id: '',
        nomeContato: '',
        paginaWeb: '',
        redeSocial: '',
        telefone: '',
        tipoTelefone: '',
        usuarioCadastro: '',
      },
    ],
    clienteLogins: [],
    razaoSocial: '',
  },
};
