import { combineReducers } from 'redux';
import bancos from '../modules/bancos/reducers';
import addFavorito from '../modules/beneficiarios/adicionar/reducers';
import deleteFavorito from '../modules/beneficiarios/deletar/reducers';
import editFavorito from '../modules/beneficiarios/editar/reducers';
import listFavorito from '../modules/beneficiarios/listar/reducers';
import listTodosFavoritos from '../modules/beneficiarios/listar_todos/reducers';
import pesquisarFavorito from '../modules/beneficiarios/pesquisar/reducers';
import carrinho from '../modules/carrinho/reducers';
import centroCusto from '../modules/centroCusto/listar/reducers';
import contas from '../modules/contas/reducers';
import extratoTed from '../modules/extratoTed/reducers';
import finalidades from '../modules/finalidade/reducers';
import loading from '../modules/loading/reducers';
import user from '../modules/user/reducers';
import boletoDigitacao from '../modules/digitacaoBoleto/reducers';
import cancelamentoBoleto from '../modules/editarBoleto/reducers';
import motivos from '../modules/motivo/reducers';
import instrucoes from '../modules/instrucao/reducers';
import especies from '../modules/especie/reducers';
import PopUp from '../modules/popUp/reducers';
import app from "./app";
import saldo from "../modules/saldo/reducers";

export default combineReducers({
  app,
  carrinho,
  loading,
  addFavorito,
  listTodosFavoritos,
  listFavorito,
  editFavorito,
  deleteFavorito,
  pesquisarFavorito,
  bancos,
  finalidades,
  extratoTed,
  contas,
  user,
  centroCusto,
  boletoDigitacao,
  cancelamentoBoleto,
  motivos,
  instrucoes,
  especies,
  PopUp,
  saldo
});
