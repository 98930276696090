export function setDigitacaoDadosBoletoSuccess(boleto) {
  return {
    type: 'SET_DADOS_BOLETO',
    payload: boleto,
  };
}
export function setDigitacaoDadosPagadorBoletoSuccess(pagador) {
  return {
    type: 'SET_DADOS_PAGADOR',
    payload: pagador,
  };
}

export function setDigitacaoInstrucoesBoletoSuccess(instrucoes) {
  return {
    type: 'SET_DADOS_INSTRUCOES',
    payload: instrucoes,
  };
}
