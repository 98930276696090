import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import PubSub from 'pubsub-js';
import Box from '@material-ui/core/Box';
import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import { api_multipague_conta_dashboard } from '../services/api';
import formattedMoney from '../util/FormatteMoney/index';
import { Card } from '@material-ui/core';
import { IoIosPaper } from 'react-icons/io';
import { MdAccountBalanceWallet } from 'react-icons/md';
import Grid from '@material-ui/core/Grid';
import DataGrid from '../components/DataGrid';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { useTheme } from '@material-ui/core';
import { useHistory } from 'react-router';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import ConfirmButton from './Buttons/ConfirmButton';
import { toastComponentError } from './Toast';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    fontSize: '1rem',
    borderTopRightRadius: '20px',
    borderTopLeftRadius: '20px',
    letterSpacing: '0.01071em',
    color:
      theme.palette.type === 'light'
        ? theme.palette.primary.main
        : theme.palette.text.primary,
    padding: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxShadow: 'none',
    fontWeight: 'bold',
  },
  verSaldo: {
    display: 'flex',
    gap: '0.5rem',
    alignItems: 'center',
  },
  errorMessage: {
    fontSize: '12px',
    color: theme.palette.system.dark,
    [theme.breakpoints.down('md')]: {
      fontSize: '10px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '50%',
    },
  },
  saldoError: {
    color: theme.palette.system.dark,
  },
  dividerGrid: {
    width: '100%',
    padding: '10px 0',
    borderTop: `2px solid ${theme.palette.primary.light}`,
  },
  buttonExtrato: {
    color: theme.palette.type === 'dark' && theme.palette.primary.main,
  },
}));

export default function SaldoAtual() {
  const theme = useTheme();
  const history = useHistory();
  const classes = useStyles();
  const dateToFormat = new Date();
  const [loading, setLoading] = useState();
  const saldo = useSelector(state => state.saldo);
  const [saldoTotal, setSaldoTotal] = useState();
  const [error, setError] = useState({ isError: false, messageError: '' });

  useEffect(() => {
    var mySpecificSubscriber = function (msg, data) {
      const teste = data;
      setDataInformacoes(teste);
    };
    // subscribe only to 'car.drive' topics
    PubSub.subscribe('captureItem', mySpecificSubscriber);
    const infoStorage = localStorage.getItem('dataInformacoes');
    if (infoStorage) {
      setDataInformacoes(JSON.parse(infoStorage));
    }
  }, []);

  const [dataInformacoes, setDataInformacoes] = useState([]);

  useEffect(() => {
    setLoading(true);
    api_multipague_conta_dashboard
      .get(`/saldo/cliente?contextoConsolidado=true`)
      .then(response => {
        setSaldoTotal(
          response.data.total === 0
            ? 'R$ 0,00'
            : formattedMoney(parseFloat(response.data.total)),
        );
        setLoading(false);
        const monitorFilter = response.data.contas;

        const aquiTable = monitorFilter.map(data => ({
          agencia: data.agencia,
          banco: data.banco,
          conta: data.conta,
          contaLogada: data.contaLogada,
          digito: data.digito,
          id: data.idConta,
          nomeBanco: data.nomeBanco,
          saldo: formattedMoney(parseFloat(data.saldo)),
          tipoConta: data.tipoConta,
          tipoControle: data.tipoControle,
        }));
        setRowsTable(aquiTable);
      })
      .catch(error => {
        setLoading(false);
        setError({
          isError: true,
          messageError: error?.response?.data?.mensagem
            ? error?.response?.data?.mensagem
            : 'Não foi possível conectar-se ao servidor',
        });
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 409 ||
          error.response.status == 500
        ) {
          toastComponentError(error.response.data.mensagem, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  }, []);

  const [rowsTable, setRowsTable] = useState([]);
  const columnsTable = [
    { field: 'id', headerName: 'idConta', flex: 1 },
    { field: 'nomeBanco', headerName: 'Banco', flex: 2 },
    { field: 'agencia', headerName: 'Agencia', flex: 1 },
    { field: 'conta', headerName: 'Conta', flex: 1 },
    { field: 'digito', headerName: 'Dígito', flex: 1 },
    { field: 'saldo', headerName: 'Saldo', flex: 2 },
    {
      field: 'Logada',
      headerName: 'Logada',
      flex: 1,
      renderCell: event => {
        return (
          <FiberManualRecordIcon
            data-testId="iconlogada"
            color={event.row.contaLogada === true ? 'primary' : 'action'}
          />
        );
      },
    },
  ];
  return (
    <Box
      style={{
        backgroundColor: theme.palette.background.paper,
        borderRadius: '20px',
      }}
    >
      <Card className={classes.root}>
        <div className={classes.verSaldo}>
          <MdAccountBalanceWallet
            color={
              theme.palette.type === 'light'
                ? theme.palette.primary.main
                : theme.palette.text.primary
            }
            size={30}
          />
          {error.isError ? (
            <Typography
              style={{
                maxWidth: '50%',
                color: theme.palette.primary.main,
                alignContent: 'center',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              Saldo total:
              <span style={{ fontSize: '20px', padding: '5px' }}>
                <AiOutlineExclamationCircle size={20} color="red" />
              </span>
              <span
                style={{
                  color: theme.palette.text.primary,
                  fontSize: '13px',
                  maxWidth: 'auto',
                  display: 'flex',
                  alignItems: 'center',
                  alignContent: 'center',
                }}
              >
                {error.messageError}
              </span>
            </Typography>
          ) : (
            <Typography
              style={{
                color: theme.palette.primary.main,
                fontSize: '18px',
                fontWeight: 'bold',
              }}
            >
              Saldo total:{' '}
              <span
                style={{
                  fontSize: '16px',
                  fontWeight: 'light',
                  color: theme.palette.text.primary,
                }}
              >
                {!!saldo?.showSaldo
                  ? saldoTotal
                  : 'R$ *****'}
              </span>
            </Typography>
          )}
          <ConfirmButton
            variant="outlined"
            color="secondary"
            title={'Extrato'}
            startIcon={<IoIosPaper color="background" />}
            onClick={() => history.push('/extrato')}
          >
            <Hidden xsDown>Extrato</Hidden>
          </ConfirmButton>
        </div>
        <Hidden xsDown>
          <Typography
            style={{
              fontSize: '12px',
              marginTop: '0px',
              fontWeight: 300,
              color: theme.palette.text.primary,
            }}
          >
            Dados atualizados:{' '}
            <span style={{ fontWeight: 900 }}>
              <Moment date={dateToFormat} format="DD/MM/YYYY" />
            </span>
          </Typography>
        </Hidden>
      </Card>
      <Grid item xs={12} md={12} lg={12}>
        <Card
          elevation={0}
          style={{
            padding: 6,
            borderBottomRightRadius: '20px',
            borderBottomLeftRadius: '20px',
          }}
        >
          <div className={classes.dividerGrid}>
            <DataGrid
              height="calc(100vh - 360px)"
              rows={rowsTable}
              columns={columnsTable}
              pageSize={10}
              width="100%"
              loading={loading}
            />
          </div>
        </Card>
      </Grid>
    </Box>
  );
}
