const INITIAL_STATE = {
  showLoading: false,
  loadingButton: false
};

const loading = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SHOW_LOADING': {
      return {
        ...state,
        showLoading: action.payload,
      }
    }
    case 'HIDE_LOADING': {
      return {
        ...state,
        showLoading: action.payload,
      }
    }
    case 'SHOW_LOADING_BUTTON': {
      return {
        ...state,
        loadingButton: action.payload,
      }
    }
    case 'HIDE_LOADING_BUTTON': {
      return {
        ...state,
        loadingButton: action.payload,
      }
    }
    default: {
      return state
    }
  }
}

export default loading;
