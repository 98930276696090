import produce from 'immer';

const INITIAL_STATE = {
};

const user = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'LISTAR_BOLETO_SUCCESS': {
      return action.payload
    }

    case 'LISTAR_BOLETO_ERROR': {
      return state;
    }

    default: {
      return state;
    }
  }
};

export default user;
