import { v4 as uuidv4 } from 'uuid';

export const paymentDateInitialObject = [
  {
    label: 'Hoje',
    value: uuidv4(),
    selected: true,
    agendado: false,
  },
  {
    label: 'Agendamento',
    value: uuidv4(),
    selected: false,
    agendado: true,
  },
];

export const valorTedInitialObject = [
  {
    value: 0,
    error: '',
    disabled: false,
  },
];
