import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import {
  api_multipague_cobranca,
  api_multipague_conta,
} from '../../../src/services/api';
import { api_multipague_cobranca_cnab } from '../../../src/services/api';
import { Cancel } from '@material-ui/icons';
import ErrorIcon from '@material-ui/icons/Error';
import { CircularProgress, Tooltip } from '@material-ui/core';
import { DataGridPage } from '../../components/DataGrid/DataGridPaginationServer';
import {
  toastComponentError,
  toastComponentSuccess,
} from '../../components/Toast';
import Checkbox from '@material-ui/core/Checkbox';
import { showPopUp } from '../../store/modules/popUp/actions';
import PopUp from '../../components/PopUp';
import Password from '../../components/Password';
import DialogModal from '../../components/DialogModal';
import { MdMonetizationOn } from 'react-icons/md';
import SmsToken from '../../components/Inputs/SmsToken';
import Buttons from '../../components/Buttons/ConfirmButton';
import jwt_decode from 'jwt-decode';
import { useTheme } from '@material-ui/core';
import { useAuthContext } from '../../Context/AuthContext';
import { v4 as uuidv4 } from 'uuid';

const ListaTransferencia = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { permissionAdmin } = useAuthContext();
  const [rowCount, setRowCount] = useState();
  const [textNoRows, setTextNoRows] = useState('');
  const [rowsState, setRowsState] = React.useState({
    page: 0,
    pageSize: 10,
  });
  const { contaPadrao } = useSelector(state => state.contas);
  const [rowsTable, setRowsTable] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const [loadingCancel, setLoadingCancel] = useState(false);
  const [rowId, setRowId] = useState(0);
  const date = new Date();
  const [loadingButtonReprocessa, setLoadingButtonReprocessa] = useState(false);
  const [carregandoDadosNoPaged, setCarregandoDadosNoPaged] = useState(false);

  const [dataDe, setDataDe] = useState(
    moment(new Date(date.getFullYear(), date.getMonth(), 1)).format(
      'YYYY-MM-DD',
    ),
  );
  const [dataAte, setDataAte] = useState(
    moment(new Date(date.getFullYear(), date.getMonth() + 1, 0)).format(
      'YYYY-MM-DD',
    ),
  );
  const [dataPagDe, setDataPagDe] = useState(
    moment(new Date(date.getFullYear(), date.getMonth(), 1)).format(
      'YYYY-MM-DD',
    ),
  );
  const [dataPagAte, setDataPagAte] = useState(
    moment(new Date(date.getFullYear(), date.getMonth() + 1, 0)).format(
      'YYYY-MM-DD',
    ),
  );
  const [nomeFavorecido, setNomeFavorecido] = useState('');
  const [codigoBarras, setCodigoBarras] = useState('');
  const [checkAll, setCheckAll] = useState(false);
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [valorSms, setValorSms] = React.useState('');
  const [open, setOpen] = React.useState(false);

  const columnsTable = [
    {
      field: '__check__',
      type: 'checkboxSelection',
      resizable: false,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      disableReorder: true,
      disableExport: true,
      renderHeader: () => {
        return carregandoDadosNoPaged ? (
          <CircularProgress size={24} />
        ) : (
          <Checkbox
            checked={
              selectionModel.length !== 0 && rowCount === selectionModel.length
                ? true
                : checkAll
            }
            onChange={(_e, value) => changeCheckboxAll(value)}
            color="primary"
          />
        );
      },
      renderCell: event => {
        return (
          <Checkbox
            checked={
              selectionModel.includes(event.id) &&
              event.row.status === 'REJEITADO'
            }
            onChange={(_e, checked) => {
              if (!checked && event.row.status === 'REJEITADO') {
                setCheckAll(false);
                setSelectionModel(old => old.filter(id => id !== event.id));
              }
            }}
            disabled={event.row.status !== 'REJEITADO'}
            color="primary"
          />
        );
      },
    },
    { field: 'nome', headerName: 'Nome', flex: 1 },
    {
      field: 'bancos',
      headerName: 'Banco',
      type: 'string',
      flex: 1,
    },
    {
      field: 'dataCadastros',
      headerName: 'Data de Cadastro',
      type: 'string',
      flex: 1,
    },
    {
      field: 'dataPagamentos',
      headerName: 'Data de Pagamento',
      type: 'string',
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      type: 'string',
      flex: 1,
      renderCell: event => {
        return (
          <>
            {event.row.status == 'REJEITADO' ? (
              <>
                <p>{event.row.status}</p>
                <Tooltip title={event.row.erro.mensagemErro}>
                  <ErrorIcon color="error" style={{ marginLeft: '5px' }} />
                </Tooltip>
              </>
            ) : (
              <>
                <p>{event.row.status}</p>
              </>
            )}
          </>
        );
      },
    },
    {
      field: 'valor',
      headerName: 'Valor',
      flex: 1,
    },
    {
      field: 'cancelar',
      headerName: 'Cancelar',
      align: 'center',
      flex: 1,
      renderCell: event => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              alignSelf: 'center',
            }}
          >
            {event.row.situacao === 'PENDENTE' ? (
              <Tooltip title="Cancelar">
                <button
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {loadingCancel && event.row.id === rowId ? (
                    <CircularProgress size={24} />
                  ) : (
                    <Cancel
                      onClick={() => handleCancel(event.row)}
                      color="primary"
                      size={24}
                    />
                  )}
                </button>
              </Tooltip>
            ) : (
              <Tooltip title="Cancelamento não permitido">
                <button
                  disabled
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Cancel color="disabled" size={24} />
                </button>
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    if (contaPadrao) {
      pesquisar();
    }
  }, [contaPadrao]);

  function limpar() {
    setNomeFavorecido('');
    setDataDe('');
    setDataAte('');
    setDataPagDe('');
    setDataPagAte('');
    setCheckAll('');
    setSelectionModel([]);
  }

  function pesquisar(page = 0, pageSize = 10, paged = true, search = false) {
    const idCliente = localStorage.getItem('contaId');
    let config = {
      headers: {
        Accept: paged ? 'application/paged+json' : 'application/json',
        ContentType: paged ? 'application/paged+json' : 'application/json',
      },
    };
    setRowsState(prev => ({ ...prev, page }));
    setLoadingTable(true);
    api_multipague_cobranca_cnab
      .get(
        `/boleto?cliente=${idCliente}&clienteConta=${contaPadrao.id}&page=${page}&size=${pageSize}&nomeBeneficiario=${nomeFavorecido}&codigoBarras=${codigoBarras}&dataCadastroInicial=${dataDe}&dataCadastroFinal=${dataAte}&dataPagamentoInicial=${dataPagDe}&dataPagamentoFinal=${dataPagAte}&sort=DESC`,
        config,
      )
      .then(response => {
        setLoadingTable(false);
        const qtdItems = paged
          ? response.data.totalElements
          : response.data.length;
        const formattedData = paged
          ? response.data.content.map(dados => ({
              id: dados.id,
              key: dados.id,
              nome:
                dados.nomeBeneficiario != null
                  ? dados.nomeBeneficiario
                  : 'Sem informações',
              codigoBarras: dados.codigoBarras,
              dataCadastros: moment(dados.dataCadastro).format('DD/MM/YYYY'),
              dataPagamentos: moment(dados.dataPagamento).format('DD/MM/YYYY'),
              bancos: dados.banco != null ? dados.banco.descricao : ' ',
              valor: dados.valorTitulo.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              }),
              ...dados,
            }))
          : response.data.map(dados => ({
              id: dados.id,
              key: dados.id,
              nome:
                dados.nomeBeneficiario != null
                  ? dados.nomeBeneficiario
                  : 'Sem informações',
              codigoBarras: dados.codigoBarras,
              dataCadastros: moment(dados.dataCadastro).format('DD/MM/YYYY'),
              dataPagamentos: moment(dados.dataPagamento).format('DD/MM/YYYY'),
              bancos: dados.banco != null ? dados.banco.descricao : ' ',
              valor: dados.valorTitulo.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              }),
              ...dados,
            }));
        setRowCount(qtdItems);
        setSelectionModel(
          paged
            ? search
              ? []
              : selectionModel
            : formattedData
                .filter(item => item.status == 'REJEITADO')
                .map(item2 => item2.id),
        );
        setRowsTable(formattedData);
        setCarregandoDadosNoPaged(false);
      })
      .catch(err => {
        console.log(' err:', err);
        setSelectionModel([]);
        setCarregandoDadosNoPaged(false);
        setLoadingTable(false);
        const textError =
          err.response.status === 404 ? 'Nenhum boleto encontrado' : null;
        setTextNoRows(textError);
        setRowCount(0);
        setRowsTable([]);
        console.log(err);
      });
  }

  const onPage = page => {
    setRowsState(prev => ({ ...prev, page }));
    pesquisar(page, rowsState.pageSize);
  };

  const changeCheckboxAll = async checked => {
    if (checked) {
      setCarregandoDadosNoPaged(true);
      setRowCount(0);
      setCheckAll(checked);
      pesquisar(rowsState.page, rowsState.pageSize, false);
    } else {
      setCheckAll(checked);
      setSelectionModel([]);
    }
  };

  const handlePageSize = pageSize => {
    setRowsState({ page: 0, pageSize: pageSize });
    pesquisar(0, pageSize);
  };

  const handleCancel = value => {
    setRowId(value.id);
    const idTransferencia = value.id;
    const cancelCliente = value?.clienteConta?.cliente?.id;
    setLoadingCancel(true);
    api_multipague_cobranca
      .put(`/boleto/${idTransferencia}/cancelar?cliente=${cancelCliente}`)
      .then(response => {
        toastComponentSuccess('Evento cancelado com sucesso!');
        setLoadingCancel(false);
        pesquisar();
      })
      .catch(error => {
        setLoadingCancel(false);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 409 ||
          error.response.status == 500
        ) {
          toastComponentError(error.response.data.mensagem);
        }
      });
  };

  const filterInputs = {
    inputsData: [
      {
        html: () => (
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            label="Nome"
            value={nomeFavorecido}
            onChange={e => {
              setNomeFavorecido(e.target.value);
            }}
            InputProps={{
              style: { borderRadius: '20px' },
            }}
          />
        ),
        grid: { xs: 12, sm: 12, md: 6, lg: 6 },
      },
      {
        html: () => (
          <TextField
            variant="outlined"
            size="small"
            required
            id="dataDe"
            placeholder="DD/MM/AAAA"
            label="Data Cadastro - De:"
            value={dataDe}
            type="date"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            onChange={e => {
              setDataDe(e.target.value);
            }}
            InputProps={{
              style: { borderRadius: '20px' },
            }}
          />
        ),
        grid: { xs: 12, sm: 3 },
      },
      {
        html: () => (
          <TextField
            variant="outlined"
            size="small"
            required
            id="dataAte"
            placeholder="DD/MM/AAAA"
            label="Data Cadastro - Até:"
            value={dataAte}
            type="date"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            onChange={e => {
              setDataAte(e.target.value);
            }}
            InputProps={{
              style: { borderRadius: '20px' },
            }}
          />
        ),
        grid: { xs: 12, sm: 3 },
      },
      {
        html: () => (
          <TextField
            variant="outlined"
            size="small"
            required
            id="dataDe"
            placeholder="DD/MM/AAAA"
            label="Data Pagamento - De:"
            value={dataPagDe}
            type="date"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              style: { borderRadius: '20px' },
            }}
            onChange={e => {
              setDataPagDe(e.target.value);
            }}
          />
        ),
        grid: { xs: 12, sm: 3 },
      },
      {
        html: () => (
          <TextField
            variant="outlined"
            size="small"
            required
            id="dataAte"
            placeholder="DD/MM/AAAA"
            label="Data Pagamento - Até:"
            value={dataPagAte}
            type="date"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              style: { borderRadius: '20px' },
            }}
            onChange={e => {
              setDataPagAte(e.target.value);
            }}
          />
        ),
        grid: { xs: 12, sm: 3 },
      },
      {
        html: () => (
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            label="Cód. de Barras"
            onChange={e => {
              setCodigoBarras(e.target.value);
            }}
            InputProps={{
              style: { borderRadius: '20px' },
            }}
          />
        ),
        grid: { xs: 12, sm: 3 },
      },
    ],
    searchButton: {
      searchButton: () => {
        setCheckAll(false);
        setSelectionModel([]);
        pesquisar(0, rowsState.pageSize, true, true);
      },
    },
    cleanButton: {
      cleanButton: () => limpar(),
    },
  };

  async function submitTed(senha) {
    setLoadingButtonReprocessa(true);
    dispatch(showPopUp(false));

    const { status } = await api_multipague_cobranca
      .post('/boleto/reprocessar-rejeitados', selectionModel, {
        headers: {
          ContentType: 'application/json',
          IdempotencyKey: uuidv4(),
          'x-assinatura-financeira': senha,
          token: valorSms,
        },
      })
      .then(response => {
        if (response.status === 202) {
          setLoadingButtonReprocessa(false);
          toastComponentSuccess('Boletos encaminhados para análise');
        }
      })
      .catch(err => {
        setLoadingButtonReprocessa(false);
        toastComponentError(err.response.data.mensagem);
      });
  }

  function handleOpen() {
    setOpen(true);
    api_multipague_conta
      .post(`/token/send-token/${contaPadrao.id}`)
      .then(response => {
        setOpen(true);
      })
      .catch(err => {
        console.log(err);
      });
  }

  const submitData = () => {
    if (selectionModel.length > 0) {
      if (!permissionAdmin) {
        submitTed('');
      } else if (permissionAdmin && contaPadrao?.tokenPorTransacao == true) {
        handleOpen();
      } else {
        const jwtToken = jwt_decode(localStorage.getItem('tkn-access'));
        const contaId = localStorage.getItem('idConta');

        if (jwtToken?.permissaoContas) {
          const conta = jwtToken?.permissaoContas?.find(item =>
            item.contas.find(conta => conta.id == contaId),
          );

          if (conta?.role === 'correntista_operador') {
            submitTed('');
          } else {
            dispatch(showPopUp(true));
          }
        }
      }
    } else {
      toastComponentError('Nenhum boleto selecionado!');
    }
  };

  function enviarSms() {
    setOpen(false);
    dispatch(showPopUp(true));
  }

  return (
    <>
      <PopUp>
        <Password callback={data => submitTed(data)} />
      </PopUp>
      <DialogModal
        open={open}
        onClose={() => setOpen(false)}
        dialogContent={
          <SmsToken
            id="outlined-basic"
            variant="outlined"
            placeholder="******"
            size="small"
            type="password"
            name="smsToken"
            inputProps={{ maxLength: 6 }}
            onChange={e => setValorSms(e.target.value)}
          />
        }
        dialogTitle={'Insira o token que você recebeu via SMS'}
        dialogActions={
          <div style={{ margin: 'auto' }}>
            <Buttons
              color="primary"
              width={150}
              title={'Prosseguir'}
              style={{ marginTop: '20px', marginBottom: '10px' }}
              onClick={() => enviarSms()}
              startIcon={
                <MdMonetizationOn color={useTheme().palette.background.paper} />
              }
            />
          </div>
        }
      />
      <DataGridPage
        headerComponent={() => {
          return (
            <Grid container>
              <Grid
                item
                md={12}
                style={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <Chip
                  color="primary"
                  disabled={loadingButtonReprocessa}
                  variant="outlined"
                  style={{ cursor: 'pointer' }}
                  label={
                    loadingButtonReprocessa ? (
                      <div style={{ margin: '0 30px' }}>
                        <CircularProgress size={18} style={{ marginTop: 5 }} />
                      </div>
                    ) : (
                      'Reenviar boletos'
                    )
                  }
                  onClick={() => submitData()}
                />
              </Grid>
            </Grid>
          );
        }}
        title="Lista de Boletos"
        crumb={[{ name: 'Dashboard', link: '/dashboard' }, { name: 'Boletos' }]}
        newRegister={() => history.push('/Pagamento/Boleto')}
        formData={filterInputs}
        rows={rowsTable}
        key={rowsTable.key}
        rowCount={rowCount}
        setDatagrid={setRowsState}
        onSelectionModelChange={newSelection => {
          const ids = Object.values(newSelection);
          const newIds = ids.filter(id => !selectionModel.includes(id));
          setSelectionModel(old => [...old, ...newIds]);
        }}
        onRowClick={params => {
          if (params.row.status !== 'REJEITADO') {
            setTimeout(() => {
              setSelectionModel(old => old.filter(item => item !== params.id));
            }, 1);
          }
        }}
        paginationMode="server"
        columns={columnsTable}
        loading={loadingTable}
        minHeight={'600px'}
        {...rowsState}
        textNoRows={textNoRows}
        pagination
        selectionModel={selectionModel}
        checkboxSelection
        {...rowsState}
        onPageChangeFunction={onPage}
        componentsProps={{
          pagination: { classes: null },
        }}
        onPageSizeChangeFunction={newPageSize => handlePageSize(newPageSize)}
      />
    </>
  );
};

export default ListaTransferencia;
