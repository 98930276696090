import React, { useEffect, useState, useRef } from 'react';
import Link from '@material-ui/core/Link';
import { Link as NewLink } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '../../components/Box';
import Wrapper from '../../components/Wrapper';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://grupomultiplica.com.br/">
      Beyond
            </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  rootcontent: {
    '& > *': {
      margin: theme.spacing(2),
    },
  },
  rootcard: {
    minWidth: 275,
  },
  toolbar: {
    paddingRight: 24,
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100%',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    minHeight: '900px',
    borderRadius: '20px',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 100,
  },
  bread: {
    paddingTop: theme.spacing(2),
    marginLeft: '25px',
  },
}));

export default function MinhasContas() {
  const classes = useStyles();

  return (
    <Wrapper title="FAQ" crumb={[{ name: 'Dashboard', link: '/dashboard' }]} spacing>
      <Box style={{ borderRadius: '20px', boxShadow: 'none' }}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>
              ABERTURA DE CONTAS
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p>
                Após o preenchimento dos campos solicitados na plataforma, será
                necessário cadastrar duas senhas.
              </p>
              <p>
                Uma para acesso a plataforma e a outra para aprovação de
                transações bancárias (transferência e pagamento de boletos).
                <strong></strong>
              </p>
              <p>
                Por segurança exigimos que suas senhas contenham no mínimo 6
                caracteres, contendo letra maiúscula, letra minúscula, caractere
                especial e números. Não existe restrições de sequencias
                repetidas.
              </p>
              <p>
                <strong>
                  E-mail para encaminhar a documentação digitalizada
                </strong>
              </p>
              <p>
                <a href="mailto:beyondbank@grupomultiplica.com.br">
                  beyondbank@grupomultiplica.com.br
                </a>
                <strong></strong>
              </p>
              <p>
                <strong> Documentos digitalizados a serem encaminhados</strong>
              </p>
              <p>
                <strong> </strong>
                .Contrato Social
              </p>
              <p>.Procurações (se houver)</p>
              <p>
                .RG e CPF ou CNH, comprovante de endereço (conta de consumo dos
                últimos 90 dias) dos sócios e representantes legais.
              </p>
              <p>
                <strong>Ativar a conta </strong>
              </p>
              <p>
                <strong> </strong>
                Após o envio dos documentos solicitados e validação pelo Grupo
                Multiplica, você receberá um e-mail com o link de ativação da
                sua conta com o seguinte assunto: “Ative agora sua conta{" "}{useTheme()?.config?.clienteName}!”
              </p>
              <p>
                <strong>Acesso a plataforma</strong>
              </p>
              <p>O usuário é o CPF do representante legal da empresa.</p>
              <p>
                <strong>Alteração de E-mail:</strong>
              </p>
              <p>
                Solicitar através do e-mail:
                <a href="mailto:beyondbank@grupomultiplica.com.br">
                  beyondbank@grupomultiplica.com.br
                </a>
              </p>
              <p>
                <strong>Perfil de acesso</strong>
              </p>
              <p>
                A plataforma possui 2 perfis de acesso de usuário <br />
                <strong>Administrador:</strong> Acesso total, inclusão/liberação
                de pagamentos, transações e cadastro de usuários
                <br />
                <strong>Operador:</strong> Acesso a consultas, inclusão de
                pagamentos e transações.
                <br />
                O usuário da abertura da conta terá automaticamente o perfil de
                administrador
                <br />
                A inclusão de perfil será através do menu “Gestão de Usuários”
                <br />
                Após a inclusão do usuário na gestão de acesso, selecionar o
                usuário e “clicar” em enviar e-mail para criação de senha
                <br />A aprovação/liberação das transaões através do menu
                “Esteira”
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}>
              ABERTURA DE CONTA SOLIDÁRIA (ASSINATURA EM CONJUNTO)
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p>
                Na abertura da conta
                <br />
                Selecionar/acionar o ícone: Alterar para assinatura conjunta
                <br />
                Será liberado para cadastramento do segundo representante legal
                ou,
                <br />
                Se já estiver aberta a conta individual
                <br />
                Acessar a conta
                <br />
                Acionar o ícone de perfil de usuário do lado direito da tela e
                selecionar minha conta
                <br />
                Acionar + dados do segundo representante legal
                <br />
                Cadastrar os dados do segundo representante legal
                <br />
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}>
              ABERTURA DE CONTA NÃO SOLIDÁRIA (ASSINATURA EM CONJUNTO)
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p>
                Na abertura da conta
                <br />
                Selecionar: “+ Adicionar novo correntista” <br />
                Será liberado para cadastramento do segundo representante legal
                <br />
                Selecionar/acionar o ícone: “Assinatura solidária
                (representantes assinam em conjunto)”. <br />
                Se já estiver aberta a conta individual Acessar a conta
                <br />
                Acionar o ícone de perfil de usuário do lado direito da tela e
                selecionar minha conta
                <br />
                Acionar o ícone “+ Dados do Representante” <br />
                Cadastrar os dados do segundo representante legal e <br />
                Selecionar: “Assinatura solidária (representantes assinam em
                conjunto)” <br />
                Acionar o ícone “ADICIONAR”
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}>RESET DE SENHA</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p>
                Na tela inicial do {useTheme()?.config?.clienteName} <br />
                Selecionar esqueci minha senha <br />
                Será direcionada para tela para troca de senha <br />
                Será enviado um código de autorização para o e-mail <br />
                Informe o código de autorização, digite e confirme sua nova
                senha <br />
                Após logar no sistema <br />
                Acionar o ícone perfil no canto superior da tela <br />
                Selecionar “alterar senhas”. Será habilitado para: <br />
                Troca de senha de acesso, <br />
                Troca de senha financeira e <br />
                Esqueci senha financeira <br />
                <strong>Aprovação em Lote</strong> <br />
                Acessar o “menu” o item Esteira <br />
                Selecionar os itens a serem aprovados/reprovados em lote <br />
                Adicionar o parecer e aprovar com a senha financeira <br />
                <strong>Assinatura Solidária (em Conjunto)</strong> <br />
                Os representantes deverão acessar a conta com seus respectivos
                login <br />
                Acessar o menu “esteira”, selecionar a(s) transação (ões) a
                serem aprovadas <br />
                Selecionar “aprovar em lote” ou “reprovar em lote” <br />
                Informar o parecer e aprovar com a senha financeira <br />
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}>TRANSFERÊNCIA</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p>
                <strong>TED ou transferência entre contas pagamento.</strong>
              </p>
              <p>Primeiro cadastre o favorecido</p>
              <p>
                Para cadastrar um novo favorecido clique em
                Transferência/Favorecido/Novo registro e preencher os dados
                solicitados.{' '}
                <strong>Não se esqueça de clicar em ATIVAR.</strong>
              </p>
              <p>
                Se o favorecido for demonstrado como aprovado e ativo, poderá
                efetuar a transferência. Se não aguardar a aprovação pelo Grupo
                Multiplica.
              </p>
              <p>
                Nota: Para cadastrar um novo favorecido não utilizar caracteres
                especiais.{' '}
              </p>
              <p>
                Se quiser ativar ou desativar um favorecido, selecione o
                cliente, clique em editar e arraste o botão para o lado que
                deseja para ATIVAR ou DESATIVAR.
              </p>
              <p>
                Para alterar informações do favorecido, selecione o cliente,
                clique no botão "Editar" e salvar.
              </p>
              <p>
                Transferência entre contas pagamento, cadastrar no campo tipo de
                conta: conta pagamento.
              </p>
              <p>
                <strong>Horário para transferência</strong>
              </p>
              <p>
                <strong> TED: Das 7:00 horas até 17:00 horas</strong>
              </p>
              <p>
                <strong> Débito agendado para TED e Pagamento de boleto</strong>
              </p>
              <p>
                <strong> </strong>A transação será efetivada mediante saldo
                disponível às 8:00 horas do dia agendado. Certifique-se no
                extrato.
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}>DASHBOARD</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p>
                <strong> Saldo</strong>
              </p>
              <p>
                O saldo será alimentado por todas as transações bancárias
                realizadas e recebidas no dia. Os agendamentos de TED, pagamento
                de boletos e liquidação de títulos só serão consideradas no
                saldo quando forem executados pelo banco, caso contrário, os
                valores ficam registrados na Agenda Financeira.
              </p>
              <p>
                <strong> Agenda Financeira</strong>
              </p>
              <p>
                Resumo das movimentações bancárias agendadas e recebimento de
                títulos no prazo de até 12 meses.
              </p>
              <p>
                Valor de entrada: serão considerados os títulos a vencer, com
                vencimento no dia e vencidos.
              </p>
              <p>
                Valor de saída: agendamento de TED e pagamento de boleto.
                <strong></strong>
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography>PAGAMENTO DE BOLETO</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p>
                <strong>
                  Situações em que não será possível realizar o pagamento do
                  boleto:
                </strong>
              </p>
              <p>Boletos vencidos;</p>
              <p>Boletos baixados;</p>
              <p>Boletos não encontrados ou não registrados na CIP;</p>
              <p>Valor do boleto maior que o saldo disponível na conta.</p>
              <p>
                Nota: Pagamento de boletos na data do vencimento, poderão ser
                realizados até às 17:00 horas.
              </p>
              <p>
                <b>Horário Limite para pagamento de Boleto: </b>Horário limite
                para pagamento de boleto de valor superior a R$ 250.000,00 até
                às 16:30 horas. Valores abaixo de R$ 250.000,00 até às 22:00
                horas.
              </p>
              <p>
                <strong>PAGAMENTO DE CONTA DE CONSUMO E TRIBUTOS</strong>
              </p>
              <p>
                Consultar lista de convênio e o horário de corte,{' '}
                <NewLink to="/convenios-bmp.pdf" target="_blank" download>
                  conforme anexo.
                </NewLink>
              </p>
              <p>
                <strong>
                  TRANSFERÊNCIA E PAGAMENTO DE BOLETO ATRAVÉS DE ARQUIVO CNAB
                </strong>
              </p>
              <p>
                Utilizar o layout de pagamento CNAB 240 do Bradesco,{' '}
                <NewLink to="/manual-cnab.pdf" target="_blank" download>
                  conforme anexo.
                </NewLink>
              </p>
              <p>
                Acessar o menu Pagamento\Pagamento em lote\ selecionar o arquivo
                e enviar.
              </p>
              <p>
                O arquivo retorno será disponibilizado após o processamento.
              </p>
              <p>
                Na coluna “Situação”, poderá acompanhar o status do
                processamento do arquivo.
              </p>
              <p>Na coluna “Detalhes” o resultado do processamento.</p>
              <p>Os agendamentos serão demonstrados no menu Agendamento.</p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography>COBRANÇA SIMPLES</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p>
                <strong> Layout para cobrança simples</strong>
              </p>
              <p>
                Utilizar o layout CNAB 400,{' '}
                <NewLink to="/layout-cnab.pdf" target="_blank" download>
                  conforme anexo
                </NewLink>
                , para ajustar o seu sistema de Gestão e envio de arquivo para
                registro de boleto.
              </p>
              <p>
                <strong> Nota: </strong>A instrução de juros, multa e desconto,
                poderá ser "ISENTO" ou em valor.
              </p>
              <p>
                <strong>Horário para Inclusão do boleto: </strong>24 horas
              </p>
              <p>
                <strong>Inclusão de Boleto</strong>
              </p>
              <p>
                O primeiro passo para registro de ingressos no sistema, é a
                configuração dos dados e instruções de cobrança. Em “Cob.
                Simples – Associação de Carteira” você fará a configuração
                destas informações, que serão consideradas na importação do
                arquivo CNAB e digitação dos títulos.
              </p>
              <p>
                Muita atenção ao preencher essa informação, é uma informação
                default/padrão da sua carteira registrada no sistema
                {useTheme()?.config?.clienteName}. Será apenas uma por cliente e não poderá ser
                alterada.
              </p>
              <p>
                Preencher as informações de espécie, nosso número, limite de
                dias para pagamento, tipo de juros e cálculo de multa que mais
                reflitam a sua carteira. O sistema irá utilizar essa informação
                “padrão” quando não for informada.
              </p>
              <p>
                A cobrança de encargos e juros poderá ser valor fixo, percentual
                ou isento.
              </p>
              <p>
                Atenção no campo nosso número: Quem será responsável por esta
                informação? O banco ou o cliente.
              </p>
              <p>
                <strong>Incluir boleto individual por digitação</strong>
              </p>
              <p>
                Clicar em Cobrança Simples/CNAB/Novo Registro e selecionar a
                opção “Digitação”.
              </p>
              <p>
                Nas instruções de cobrança, o tipo de cálculo, juros e multa
                veem pré-definidos de acordo com a "associação de carteira" e
                por padrão juros e multas são cobrados na data posterior ao
                vencimento e o desconto até um dia antes do vencimento. Os
                campos destinados a mensagem são de preenchimento obrigatório,
                pois são exigidas pelo banco, já que são informadas como
                instrução de cobrança no boleto.
              </p>
              <p>
                Atenção:{' '}
                <strong>
                  A instrução de juros e multa só poderá ser valor
                </strong>
              </p>
              <p>
                O Nosso Número estará desabilitado quando o banco for
                responsável por gerar o NN e registrar o boleto. Configuração
                realizada na Associação de Carteira; Caso "nosso número" seja
                gerado pelo ERP, informar no campo NN - Cliente. Caso "nosso
                número" seja gerado pelo banco, informe Banco.
              </p>
              <p>
                No campo Rua/Avenida, informar o número e complemento. A
                mensagem “Boleto salvo com sucesso” não confirma o registro do
                título no banco, apenas informa que ele foi cadastrado no
                sistema. Para visualizar a situação do boleto junto ao banco,
                você deve verificar a coluna “Situação”. Quando ocorrer "erro ao
                registrar boleto" será possível editar o boleto para verificar o
                motivo da rejeição, para isso clique em "Editar". Caso haja
                necessidade de alterar o padrão da associação de carteira,
                entrar em contato através do e-mail:
                beyondbank@grupomultiplica.com.br.
              </p>
              <p>
                Se houver necessidade de alterar o padrão da associação de
                carteira, entrar em contato através do e-mail:
                beyondbank@grupomultiplica.com.br.{' '}
              </p>
              <strong>Incluir boleto através de arquivo CNAB</strong>
              <p>
                Clicar em Cobrança Simples/CNAB/Novo Registro/CNAB e clicar na
                seta nenhum arquivo anexado, selecionar o arquivo e clicar e em
                enviar. Caso tenha selecionado o arquivo errado basta clicar no
                botão de lixeira. Nota: Ao optar por arquivo CNAB, serão
                acatados os dados do CNAB, não o da "associação da carteira". Na
                parte inferior da tela, através da barra de rolagem poderá
                verificar a situação do boleto. Também poderá cancelar,
                prorrogar, editar e imprimir.
              </p>
              <strong>Arquivo Retorno Remessa</strong>
              <p>
                Todo dia após as 12:00 horas será disponibilizado um arquivo
                retorno de pagamento/ liquidação. Acessar Cobrança
                Simples/Retorno Remessa. Na coluna ações clicar no botão, para
                download do arquivo.
              </p>
              <strong>Acompanhar a situação do processamento</strong>
              <p>
                Ao clicar em “Enviar” o sistema irá atualizar a página
                informando se o arquivo foi importado com sucesso ou não. Para
                atualizar a situação do arquivo, é preciso clicar em “Pesquisar”
                para atualizar o status (enviado para o banco/em
                processamento/registrado), também é possível visualizar os
                títulos importados e o status de cada um em{' '}
                <strong>Consultar Boleto</strong>.
              </p>
              <p>
                <strong>Detalhes</strong>
              </p>
              <p>
                Clicar em Cobrança Simples/CNAB/Novo Registro/CNAB e clicar na
                seta nenhum arquivo anexado, selecionar o arquivo e clicar e em
                enviar. Caso tenha selecionado o arquivo errado basta clicar no
                botão de lixeira.
              </p>
              <p>
                Ao clicar em “Detalhe” do arquivo, você irá visualizar todos os
                títulos do arquivo junto com as informações do sacado, NN,
                vencimento e valor, na mesma tela também será possível
                alterá-los em situação em que contenham algum erro, para isto
                clique no triângulo localizado ao lado do título (funcionalidade
                liberada apenas para títulos com erro de registro).
              </p>
              <strong>
                Tempo para liberação para pagamento após o registro do boleto .
              </strong>
              <p>O tempo máximo para liberação é de no máximo 15 minutos.</p>
              <strong>Impressão de boleto em lote</strong>
              <p>Acessar o menu CNAB/Boletos em Lote</p>
              <strong>Reprocessamento de Lote CNAB</strong>
              <p>
                Acessar Dashboard/ Cob. Simples/ Acompanhar Cnab/ Aba
                Reprocessamento, clicar na seta do arquivo que deseja
                reprocessar, e automaticamente o arquivo que apresentou erro,
                irá realizar um novo processamento, e apresentar a mensagem
                “arquivo processado com sucesso”.
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography>CONSULTA A FRANCESINHA</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p>
                Acesso: <br />
                Cob. Simples\Situação do boleto
                <br />
              </p>
              <p>
                A consulta poderá ser feita através CPF, CNPJ, nome, período
                (informar: data solicitação de e data solicitação até) e Status.
              </p>
              <p>Após digitar a forma de consulta, teclar em “pesquisar”.</p>
              <p>
                Também é possível a extração dos dados teclando na opção
                “excel”.{' '}
              </p>
              <p>
                Para formatar os dados da planilha excel, selecione a coluna A,
                acione a opção “dados”, “texto para coluna”, selecione
                delimitado e avançar, selecione tabulação, virgula e avançar,
                selecione texto e selecione concluir.
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography>IMPRESSÃO DE COMPROVANTE EM LOTE </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p>
                Acessar: Dashboard/Minhas Contas/ Extrato/ Saldo por
                Período/Imprimir em Lote, selecionar comprovantes desejados, e
                clicar no ícone Imprimir em Lote.
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography>APROVAR OPERAÇÃO ATRAVÉS DE TOKEN </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p>Cadastrar para habilitar a função</p>

              <p>
                Acessar: Dashboard/ Canto Superior Direito/ Minha Conta/ Dados
                da Conta/ Clicar em Permitir Envio de SMS Token{' '}
              </p>

              <p>
                Função disponibilizada para aprovação de transação com a
                digitação do token e após da senha financeira do token.{' '}
              </p>

              <p>
                Será enviado um número para o celular cadastrado na conta. Esse
                número deverá ser informado na aprovação da operação.{' '}
              </p>

              <p>
                SMS Token será enviado para o telefone do cadastro do
                Administrador.{' '}
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography>CENTRO DE CUSTOS</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p>
                O Centro de Custos é uma funcionalidade oferecida pelo
                {useTheme()?.config?.clienteName} para que você possa registrar suas transações
                bancárias por meio de grupos.
              </p>
              <p>
                O cadastro do Centro de Custos é feito no menu
                Configurações/Centros de Custo/ Novo Registro.
              </p>
              <p>
                Por padrão ao abrir sua conta {useTheme()?.config?.clienteName} o sistema incluirá
                automaticamente o centro de custos Geral.
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Wrapper>
  );
}
