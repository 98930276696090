function formattedMoney(money) {
  if (!!money) {
    const formattedVal = money.toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    });
    return formattedVal;
  }
}
export default formattedMoney;
