import { InputLabel } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import 'react-quill/dist/quill.core.css';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';

export const TextEditor = ({
  modules = {},
  formats = [],
  value: propValue,
  onChange,
  readOnly = false,
}) => {
  const [value, setValue] = useState(propValue || '');

  useEffect(() => {
    if (propValue !== value) {
      setValue(propValue);
    }
  }, [propValue]);

  const handleChange = content => {
    setValue(content);
    if (onChange) {
      onChange(content);
    }
  };

  return (
    <ReactQuill
      formats={formats}
      value={value}
      onChange={handleChange}
      modules={modules}
      readOnly={readOnly}
    />
  );
};
