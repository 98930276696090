import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  /* .MuiDataGrid-root .MuiDataGrid-colCellMoving{
    background-color: #fff !important;
  } */

  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;