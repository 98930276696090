import React from 'react';
import { DataGridPage } from '../../components/DataGrid/DataGridPaginationServer';
import { api_multipague_transacao } from '../../services/api';
import {
  toastComponentError,
  toastComponentSuccess,
} from '../../components/Toast';
import { useHistory } from 'react-router-dom';
import RenderActionsCell from '../../components/DataGrid/DataGridPaginationServer/Actions';
import { Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ModalDelete from './ModalDelete';

const ChavesPIX = () => {
  const history = useHistory();
  const [dataGridState, setDataGridState] = React.useState({
    rows: [],
    page: 0,
    pageSize: 10,
    loading: false,
  });
  const [openModalDelete, setOpenModalDelete] = React.useState(false);
  const [loadingDelete, setLoadingDelete] = React.useState(false);
  const [openMenuActions, setOpenMenuActions] = React.useState(false);
  const [row, setRow] = React.useState({
    id: 0,
    chavePix: '',
  });

  const columnsTable = [
    { field: 'id', headerName: 'Id Conta', flex: 1 },
    { field: 'chavePix', headerName: 'Chave Pix', flex: 1 },
    { field: 'tipo', headerName: 'Tipo', flex: 1 },
    {
      field: 'acoes',
      headerName: 'Ações',
      width: 100,
      align: 'center',
      renderCell: event => {
        return (
          <RenderActionsCell
            event={event}
            actionsComponent={
              <Tooltip title="Deletar Chave">
                <button>
                  <DeleteIcon
                    onClick={() => {
                      setOpenModalDelete(true);
                      setRow({
                        id: event.row.id,
                        chavePix: event.row.chavePix,
                      });
                    }}
                    color="primary"
                    size={24}
                  />
                </button>
              </Tooltip>
            }
            openMenuActions={openMenuActions}
            setOpenMenuActions={setOpenMenuActions}
            rowId={row.id}
          />
        );
      },
    },
  ];

  const deleteKey = chave => {
    setLoadingDelete(true);
    api_multipague_transacao
      .delete(`/chaves-pix/exclui-chave?chave=${chave}`)
      .then(response => {
        setLoadingDelete(false);
        toastComponentSuccess('Chave deletada com sucesso');
        setOpenModalDelete(false);
        listKey(setDataGridState);
      })
      .catch(error => {
        setLoadingDelete(false);
        toastComponentError(
          error.response.data.mensagem ||
            'Não foi possível conectar ao servidor',
        );
        setOpenModalDelete(false);
      });
  };

  const listKey = setDataGridState => {
    api_multipague_transacao
      .get(`/chaves-pix/findAllByClienteConta`)
      .then(response => {
        setDataGridState(prev => ({
          ...prev,
          loading: false,
          rows: response.data.map(data => ({
            ...data,
            chavePix: data.chavePix,
            id: data.id,
            tipo: data.tipo,
          })),
        }));
      })
      .catch(error => {
        setDataGridState(prev => ({ ...prev, loading: false }));
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 409 ||
          error.response.status == 500
        ) {
          toastComponentError(
            error.response.data.mensagem
              ? error.response.data.mensagem
              : 'Não foi possível conectar ao servidor',
          );
        }
      });
  };

  React.useEffect(() => {
    setDataGridState(prev => ({ ...prev, loading: true }));
    listKey(setDataGridState);
  }, []);

  return (
    <>
      <ModalDelete
        open={openModalDelete}
        setOpen={setOpenModalDelete}
        loadingDelete={loadingDelete}
        deleteKey={() => {
          deleteKey(row.chavePix);
        }}
        row={row.id}
      />
      <DataGridPage
        title="Chaves PIX"
        crumb={[
          { name: 'Dashboard', link: '/dashboard' },
          { name: 'Minhas Contas' },
        ]}
        paginationMode={'client'}
        crumbActive="Chaves PIX"
        rows={dataGridState.rows || []}
        columns={columnsTable}
        minHeight={'600px'}
        textNoRows={'Nenhuma chave cadastrada'}
        pageSize={dataGridState.pageSize}
        loading={dataGridState.loading}
        onRowClick={params => setRow({ id: params.id })}
        newRegister={() => history.push('/NovaChavePix')}
      />
    </>
  );
};

export default ChavesPIX;
