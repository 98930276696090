import React, { createContext, useState, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as ImpressaoBoletosService from '../services/ImpressaoBoletosService';
import { toastComponentWarn } from '../components/Toast';

const ImpressaoBoletosContext = createContext();

export default function ImpressaoBoletosProvider({ children }) {
  const [dataInicial, setDataInicial] = useState();
  const [dataFinal, setDataFinal] = useState();
  const [loading, setLoading] = useState(true);
  const [rowsTable, setRowsTable] = useState([]);
  const [carregaBoletos, setCarregaBoletos] = useState(false);
  const { contaPadrao } = useSelector(state => state.contas);
  const [rowCount, setRowCount] = useState();
  const [textNoRows, setTextNoRows] = useState();
  const [rowsState, setRowsState] = React.useState({
    page: 0,
    pageSize: 10,
  });

  const clearFilter = () => {
    setRowsState(prev => ({ ...prev, page: 0 }));
    setDataInicial('');
    setDataFinal('');
    getBoletos({}, rowsState.page, rowsState.pageSize);
  };

  const submitFilter = async () => {
    setRowsState(prev => ({ ...prev, page: 0 }));
    if (dataInicial && dataFinal) {
      setRowsTable([]);
      getBoletos(
        { dataInicial, dataFinal },
        rowsState.page,
        rowsState.pageSize,
      );
    } else {
      toastComponentWarn('Data inicial e data final devem ser preenchidas!');
    }
  };

  const onPage = page => {
    setRowsState(prev => ({ ...prev, page: page }));
    if (dataInicial && dataFinal) {
      getBoletos({ dataInicial, dataFinal }, page, rowsState.pageSize);
    } else {
      getBoletos({}, page, rowsState.pageSize);
    }
  };

  const handlePageSize = newPageSize => {
    setCarregaBoletos(true);
    setRowsState({ page: 0, pageSize: newPageSize });
  };

  useEffect(() => {
    if (dataInicial && dataFinal) {
      setRowsTable([]);
      getBoletos({ dataInicial, dataFinal }, 0, rowsState.pageSize);
    } else {
      getBoletos();
    }
  }, [contaPadrao]);

  useEffect(() => {
    if (carregaBoletos) {
      getBoletos({ dataInicial, dataFinal }, 0, rowsState.pageSize);
    }
  }, [rowsState.pageSize, carregaBoletos]);

  const getBoletos = async (params = {}, page = 0, pageSize = 10) => {
    if (contaPadrao.id) {
      try {
        setRowsTable([]);
        setLoading(true);
        const formated = await ImpressaoBoletosService.findAll({
          ...params,
          clienteConta: contaPadrao.id,
          page: page,
          size: pageSize,
        });
        setRowsTable(formated.formated);
        setLoading(false);
        setRowCount(formated.dataLength);
        const textError =
          formated.error && formated.error.response.status === 404
            ? 'Nenhum lote de boleto encontrado'
            : '';
        setTextNoRows(textError);
      } catch {
        setRowCount(0);
        setRowsTable([]);
        setLoading(false);
      }
    }
  };

  return (
    <ImpressaoBoletosContext.Provider
      value={{
        rowsTable,
        dataInicial,
        setDataInicial,
        dataFinal,
        setDataFinal,
        loading,
        setLoading,
        handlePageSize,
        clearFilter,
        submitFilter,
        getBoletos,
        contaPadrao,
        rowCount,
        rowsState,
        onPage,
        textNoRows,
        setRowsState
      }}
    >
      {children}
    </ImpressaoBoletosContext.Provider>
  );
}

export function useImpressaoBoletos() {
  const context = useContext(ImpressaoBoletosContext);
  return context;
}
