import React from 'react';
import { Hidden } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Expenses from '../../components/DespesasConta';
import Running from '../../components/EsteiraConta';
import GoalsExpenses from '../../components/MetasDespesas';
import Payment from '../../components/Pagamento';
import SaldoAtual from '../../components/SaldoAtual';
import TotalPayroll from '../../components/TotalFolhaPag';
import Transfer from '../../components/Transferencia';

import Wrapper from '../../components/Wrapper';
import CobrancaSimplesEGarantias from '../../components/CobrancaSimples/index';
import AgendaFinanceira from '../../components/AgendaFinanceira';
import CobrancaSimplesMobile from '../../components/CobrancaSimples/CobrancaSimplesMobile';

export default function MinhasContas() {
  return (
    <Wrapper
      title="Minhas Contas"
      crumb={[
        { name: 'Dashboard', link: '/dashboard' },
        { name: 'Minhas Contas' },
      ]}
      crumbActive="Geral"
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <SaldoAtual />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4} lg={4}>
          <Transfer />
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <Payment />
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <TotalPayroll />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Hidden lgUp>
          <Grid item xs={12} md={6} lg={6}>
            <AgendaFinanceira conta mobile />
          </Grid>
        </Hidden>
        <Hidden lgUp>
          <Grid item xs={12} md={6} lg={6}>
            <CobrancaSimplesMobile conta />
          </Grid>
        </Hidden>

        <Hidden mdDown>
          <Grid item xs={12} md={6} lg={6}>
            <AgendaFinanceira conta />
          </Grid>
        </Hidden>
        <Hidden mdDown>
          <Grid item xs={12} md={6} lg={6}>
            <CobrancaSimplesEGarantias conta />
          </Grid>
        </Hidden>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={6}>
          <Expenses />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Running />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <GoalsExpenses />
        </Grid>
      </Grid>
    </Wrapper>
  );
}
