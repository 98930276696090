import { useQuery } from 'react-query';
import { api_multipague_conta } from '../../services/api';

export const useGetSaldo = (contaId, options) => {
  return useQuery(
    ['getSaldo', contaId],
    async () => {
      try {
        let endPoint = `/saldo/cliente-conta/${contaId}`;
        const { data } = await api_multipague_conta.get(`${endPoint}`);
        return data;
      } catch (error) {
        console.error('Error fetching saldo:', error);
        throw new Error('Failed to fetch saldo');
      }
    },
    {
      ...options,
      refetchInterval: 5000,
      enabled: !!contaId
    },
  );
};
