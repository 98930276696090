import produce from 'immer';

const INITIAL_STATE = {
  data: {
    boleto: {},
    pagador: {},
    instrucoes: {},
  },
};

const user = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_DADOS_BOLETO': {
      return produce(state, draft => {
        draft.data.boleto = action.payload;
      });
    }

    case 'SET_DADOS_PAGADOR': {
      return produce(state, draft => {
        draft.data.pagador = action.payload;
      });
    }

    case 'SET_DADOS_INSTRUCOES': {
      return produce(state, draft => {
        draft.data.instrucoes = action.payload;
      });
    }

    default: {
      return state;
    }
  }
};

export default user;
