import { all } from 'redux-saga/effects';
import bancos from './bancos/sagas';
import addFavorito from './beneficiarios/adicionar/sagas';
import deleteFavorito from './beneficiarios/deletar/sagas';
import editFavorito from './beneficiarios/editar/sagas';
import listavorito from './beneficiarios/listar/sagas';
import listTodosFavoritos from './beneficiarios/listar_todos/sagas';
import PesquisarFavorecidos from './beneficiarios/pesquisar/sagas';
import carrinho from './carrinho/sagas';
import centroCusto from './centroCusto/listar/sagas';
import contas from './contas/sagas';
import extratoTed from './extratoTed/sagas';
import saldo from './saldo/sagas';
import finalidades from './finalidade/sagas';
import user from './user/sagas';
import cancelamentoBoleto from './editarBoleto/sagas';
import motivos from './motivo/sagas';
import instrucoes from './instrucao/sagas';
import especies from './especie/sagas';

export default function* rootSaga() {
  return yield all([
    carrinho,
    addFavorito,
    editFavorito,
    listavorito,
    listTodosFavoritos,
    deleteFavorito,
    bancos,
    finalidades,
    PesquisarFavorecidos,
    extratoTed,
    saldo,
    contas,
    user,
    centroCusto,
    cancelamentoBoleto,
    motivos,
    instrucoes,
    especies
  ]);
}
