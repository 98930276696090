import { all, takeLatest, select, call, put } from 'redux-saga/effects';
import { api_multipague_configuracao } from '../../../services/api';
import { hideLoading, showLoading } from '../loading/actions';
import { listarBancosError, listarBancosSuccess } from './actions';

import { toast } from 'react-toastify';

function* listarBancos({ payload }) {
  try {
    yield put(showLoading(true));

    const { data } = yield call(api_multipague_configuracao.get, `/banco`);
    yield put(hideLoading(false));

    if (data.length) {
      yield put(listarBancosSuccess(data));
    } else {
      yield put(listarBancosError());
    }
  } catch (error) {
    yield put(hideLoading(false));
    // toast.error('Erro ao listar favorecidos. Tente novamente mais tarde.');
  }
}

export default all([takeLatest('LISTAR_BANCOS_REQUEST', listarBancos)]);
