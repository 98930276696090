export const maskCpfCnpj = val => {
  return String(val).length > 11
    ? String(val).replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        '$1.$2.$3/$4-$5',
      )
    : String(val).replace(/(\d{3})?(\d{3})?(\d{3})?(\d{2})/, '$1.$2.$3-$4');
};

export const maskCep = value => {
  value = value.replace(/\D/g, '');
  if (value.length > 8) {
    value = value.substr(0, 8);
  }
  value = value.replace(/^(\d{5})(\d)/, '$1-$2');
  return value;
};

export const maskTelefone = value => {
  let formattedValue = String(value).replace(/\D/g, '');

  if (formattedValue.length > 11) {
    return formattedValue.replace(
      /^(\d{2})(\d{1})(\d{4})(\d{4})/,
      '($1) $2 $3-$4',
    );
  } else {
    return formattedValue.replace(/^(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
  }
};
