import React from 'react';

import Wrapper from '../../../components/Wrapper';
import Box from '../../../components/Box';
import { useAccordion } from './useAccordion';
import ModalCadastro from './ModalCadastro';

const NovaChavePix = () => {
  const {
    open,
    setOpen,
    openTipoMfa,
    setOpenTipoMfa,
    keySelected,
    accordion,
    tipoMfa,
    setTipoMfa,
    solicitarCodigo,
    codigoMfa,
    criarChave,
    loadingCode,
    loadingCreateKey,
    setCodigoMfa,
  } = useAccordion();

  const [error, setError] = React.useState(false);

  const handleChange = event => {
    setTipoMfa(event.target.value);
  };

  return (
    <Wrapper
      title="Cadastro de Chave PIX"
      crumb={[{ name: 'Chaves Pix', link: '/ChavesPix' }]}
      crumbActive="Cadastro de Chave PIX"
    >
      <ModalCadastro
        onCLoseRadio={() => {
          setCodigoMfa(null);
          setOpenTipoMfa(false);
        }}
        keySelected={keySelected}
        openTipoMfa={openTipoMfa}
        handleChange={handleChange}
        tipoMfa={tipoMfa}
        onConfirmRadio={() => {
          solicitarCodigo();
        }}
        onCloseCadastro={() => {
          setCodigoMfa(null);
          setOpen(false);
        }}
        loadingCode={loadingCode}
        open={open}
        onCancel={() => {
          setCodigoMfa(null);
          setOpen(false);
        }}
        codigoMfa={codigoMfa}
        error={error}
        onConfirmCreateEmailCelular={() => {
          setError(!codigoMfa ? true : false);
          if (!!codigoMfa && !error) {
            criarChave();
          }
        }}
        onChangeCodigo={e => {
          setError(!e.target.value);
          setCodigoMfa(e.target.value);
        }}
        onConfirmCreate={() => {
          criarChave();
        }}
        loadingCreateKey={loadingCreateKey}
      />
      <Box style={{ padding: 25 }}>
        {accordion('panel1', 'CELULAR', 'Celular')}
        {accordion('panel2', 'CPF', 'CPF')}
        {accordion('panel3', 'CNPJ', 'CNPJ')}
        {accordion('panel4', 'EMAIL', 'E-mail')}
        {accordion('panel5', 'ALEATORIA', 'Chave Aleatória')}
      </Box>
    </Wrapper>
  );
};

export default NovaChavePix;
