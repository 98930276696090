export const columnsTable = [
  { field: 'id', headerName: 'ID', width: 110 },
  { field: 'documento', headerName: 'CNPJ/CPF', width: 160 },
  { field: 'nome', headerName: 'Nome', flex: 1, minWidth: 180 },
  {
    field: 'banco',
    headerName: 'Banco',
    type: 'string',
    width: 120,
    align: 'center',
    headerAlign: 'center',
  },
  {
    field: 'agencia',
    headerName: 'Agencia',
    type: 'string',
    width: 130,
  },
  {
    field: 'conta',
    headerName: 'Conta',
    type: 'string',
    width: 130,
  },
  {
    field: 'situacao',
    headerName: 'Situação',
    width: 130,
  },
  {
    field: 'ativoStatus',
    headerName: 'Ativo',
    width: 130,
  },
];
