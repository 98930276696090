import React, { useEffect, useMemo, useState } from 'react';
import { useCount } from '../../Context/CountTime';
import AppBar from '@material-ui/core/AppBar';
import axios from 'axios';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import { Link, useHistory } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Drawer from '@material-ui/core/Drawer';
import { TiThMenu } from 'react-icons/ti';
import IconButton from '@material-ui/core/IconButton';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import {
  Brightness5,
  Brightness2,
  Visibility,
  VisibilityOff,
  ErrorRounded,
} from '@material-ui/icons';
import { MdKeyboardArrowDown } from 'react-icons/md';
import Box from '@material-ui/core/Box';
import LockIcon from '@material-ui/icons/Lock';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AccountCircle from '@material-ui/icons/AccountCircle';
import PeopleIcon from '@material-ui/icons/People';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import clsx from 'clsx';
import PubSub from 'pubsub-js';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme, alpha } from '@material-ui/core/styles';
import DefaultMenu from '../Menu';
import {
  listarContasRequest,
  setContaPadrao,
} from '../../store/modules/contas/actions';
import formattedMoney from '../../util/FormatteMoney/index';
import '../styles.css';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import moment from 'moment';
import qs from 'qs';
import TopBarMobile from './TopBarMobile';
import { changeModeDark } from '../../store/modules/app';
import { Tooltip } from '@material-ui/core';
import { saldoRequest } from '../../store/modules/saldo/actions';
import { IoPersonCircle } from 'react-icons/io5';
import { MSAMBIENTE } from '../../util/constants';
import { useGetSaldo } from '../../hooks/saldo';
import { useStyles } from './styles';
import KeycloakService from '../../auth';

export default function OutlinedCard() {
  const dispatch = useDispatch();
  const history = useHistory();
  let kcOidc;
  switch (MSAMBIENTE) {
    case 'prod':
      kcOidc = require('../../keycloak-prd.json');
      break;
    case 'hml':
      kcOidc = require('../../keycloak.json');
      break;
    case 'prodx':
      kcOidc = require('../../keycloak-prd-xmind.json');
      break;
    default:
      kcOidc = require('../../keycloak.json');
      break;
  }
  const refreshExp = parseInt(localStorage.getItem('expiracao-refresh'));
  var idCliente = localStorage.getItem('contaId');
  const infoRedux = useSelector(state => state.contas);
  const { modeTheme } = useSelector(state => state.app);
  const saldo = useSelector(state => state.saldo);
  const horaRefresh = moment().add(refreshExp, 'seconds').format('LTS');
  const { horaexpiracao, setHoraExpiracao } = useCount('');

  setInterval(function () {
    const horaCompare = moment().format('LTS');
    horaexpiracao === horaCompare || newHoraRefresh === horaCompare ? (
      setOpenModal(true)
    ) : (
      <></>
    );
  }, 1000);

  var data = qs.stringify({
    grant_type: 'refresh_token',
    client_id: kcOidc.resource,
    client_secret: kcOidc.credentials.secret,
    refresh_token: localStorage.getItem('tkn-refresh'),
    scope: 'openid',
  });

  var config = {
    method: 'post',
    url:
      kcOidc['auth-server-url'] +
      'realms/' +
      kcOidc.realm +
      '/protocol/openid-connect/token',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: data,
  };

  const newHoraRefresh = localStorage.getItem('hora-refresh');

  const handleSubmit = event => {
    localStorage.setItem('hora-refresh', horaRefresh);

    event.preventDefault();
    axios(config)
      .then(function (response) {
        setHoraExpiracao(newHoraRefresh);
        setOpenModal(false);
        localStorage.setItem('tkn-access', response.data.access_token);
        localStorage.setItem('tkn-refresh', response.data.refresh_token);
        window.location.reload();
        setIsRenovado(true);
      })
      .catch(function (error) {
        localStorage.setItem('tkn-access', '');
        localStorage.setItem('tkn-refresh', '');
        localStorage.setItem('permission', '');
        localStorage.setItem('auth-ok', false);
        localStorage.setItem('gender', '');
        localStorage.setItem('name', '');
        localStorage.setItem('given_name', '');
        if (
          error.response.status == 401 ||
          error.response.status == 404 ||
          error.response.status == 409 ||
          error.response.status == 500
        ) {
          mostrarErro();
        }
      });
  };

  const [openModal, setOpenModal] = React.useState(false);
  const [isRenovado, setIsRenovado] = useState(false);

  setInterval(function () {
    openModal === true ? (
      isRenovado ? (
        setTimeout(function () {
          location.reload();
        }, 1000)
      ) : (
        setTimeout(function () {
          KeycloakService.logoutMfe();
        }, 15000)
      )
    ) : (
      <></>
    );
  }, 10000);

  const id_cliente = localStorage.getItem('contaId');
  useEffect(() => {
    if (id_cliente) {
      dispatch(listarContasRequest(id_cliente));
    }
  }, [id_cliente, dispatch]);

  const accountComponent = useMemo(() => {
    const { contasCliente, contaPadrao } = infoRedux;

    const activeContas = contasCliente.filter(
      item => item.situacao === 'ATIVADA',
    );

    const listItems = activeContas.map(item => {
      const fundoSelecionado = item.id === contaPadrao?.id;

      return (
        <ListItem
          button
          onClick={() => setDefaultAccount(item)}
          key={item.id}
          selected={fundoSelecionado}
        >
          <ListItemAvatar>
            <Avatar>
              <AccountBalanceIcon color="inherit" />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={`Banco: ${item.banco.nome} / Agência ${item.agencia} / Conta: ${item.conta}-${item.digitoConta} (${item.tipoControle})`}
            secondary={`Razão Social: ${item.cliente.nome} / ${
              item.usuarioAdministrador != null
                ? `Associado à: ${item.usuarioAdministrador.nomeUsuarioAdministrador} /`
                : ''
            } Saldo: ${
              formattedMoney(parseFloat(item.saldo.saldo)) ?? 'R$ 0,00'
            }`}
          />
        </ListItem>
      );
    });

    return <>{listItems}</>;
  }, [infoRedux, dispatch]);

  function setDefaultAccount(value) {
    const filterAccount = infoRedux.contasCliente.map(data => ({
      ...data,
      contaPadrao: data.id === value.id ? true : false,
    }));
    dispatch(setContaPadrao(filterAccount));
    setAnchorEl(null);
    if (infoRedux.contaPadrao.id !== value.id) {
      location.href = '/dashboard';
    }
  }

  const [dataInformacoes, setDataInformacoes] = useState({
    banco: '',
    agencia: '',
    conta: '',
    cliente: '',
  });

  useEffect(() => {
    localStorage.setItem('dataInformacoes', JSON.stringify(dataInformacoes));
    PubSub.publish('captureItem', dataInformacoes);
  }, [dataInformacoes]);

  useEffect(() => {
    const infoStorage = localStorage.getItem('dataInformacoes');
    if (infoStorage) {
      setDataInformacoes(JSON.parse(infoStorage));
    }
  }, []);

  const { data: saldoData } = useGetSaldo(infoRedux.contaPadrao?.id, {
    enabled: !!infoRedux,
  });

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const [localEmpresa, setLocalEmpresa] = useState('');
  const [cnpjEmpresa, setCNPJEmpresa] = useState('');

  const handleOpenAccount = () => {
    history.push('/select-account');
  };

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElProfile, setAnchorElProfile] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const handleProfileMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };
  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Selecione uma conta</MenuItem>
      <List>{accountComponent}</List>
    </Menu>
  );
  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleClickProfile = event => {
    setAnchorElProfile(event.currentTarget);
  };

  const handleCloseProfile = () => {
    setAnchorElProfile(null);
  };

  useEffect(() => {
    if (infoRedux.contaPadrao.cliente) {
      setLocalEmpresa(infoRedux.contaPadrao.cliente.nome);
      setCNPJEmpresa(infoRedux.contaPadrao.cliente.cnpjCpf);
    }
  }, [infoRedux]);

  useEffect(() => {
    if (!idCliente) {
      history.push('/select-account');
    }
  }, [idCliente]);

  const theme = useTheme();
  const matchesPoint = useMediaQuery(theme.breakpoints.down('sm'));
  useEffect(() => {
    if (matchesPoint) {
      setOpen(false);
    }
  }, [matchesPoint]);

  localStorage.setItem('printBanco', infoRedux.contaPadrao.banco?.nome);
  localStorage.setItem('printAgencia', infoRedux.contaPadrao?.agencia);
  localStorage.setItem('printDigito', infoRedux.contaPadrao?.digitoConta);
  localStorage.setItem('printConta', infoRedux.contaPadrao?.conta);
  localStorage.setItem('idConta', infoRedux.contaPadrao?.id);
  localStorage.setItem('printNome', localEmpresa);
  localStorage.setItem('printCnpj', cnpjEmpresa);

  return (
    <>
      <AppBar
        position="absolute"
        elevation={0}
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden,
            )}
          >
            <TiThMenu
              size={!matchesPoint ? 26 : 20}
              color={
                !matchesPoint
                  ? theme.palette.type === 'dark'
                    ? theme.palette.background.default
                    : theme.palette.appBar[theme.palette.type].bgMenu
                  : theme.palette.getContrastText(
                      theme.palette.appBar[theme.palette.type].bgMenu,
                    )
              }
            />
          </IconButton>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={openModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={openModal}>
              <div className={classes.paperModal}>
                <h2 id="transition-modal-title">Tempo expirado</h2>
                <p id="transition-modal-description">
                  Deseja renovar a sessão?
                </p>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={handleSubmit}
                >
                  Renovar
                </Button>
                <Link onClick={() => KeycloakService.logoutMfe()}>
                  <Button
                    variant="contained"
                    color="default"
                    className={classes.button}
                  >
                    Sair
                  </Button>
                </Link>
              </div>
            </Fade>
          </Modal>
          {openModal === true ? (
            setTimeout(function () {
              location.reload();
            }, 16000)
          ) : (
            <></>
          )}
          <Box
            flexGrow={1}
            justifyContent={'flex-start'}
            className={classes.boxGridBgMenu}
          >
            <Grid container spacing={2}>
              <Hidden smDown>
                <Grid md={8} lg={8} className={classes.gridBgMenu}>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    sm={4}
                    className={classes.gridBgMenuNome}
                  >
                    <IconButton color="inherit" onClick={handleOpenAccount}>
                      <AccountBalanceWalletIcon color="primary" />
                    </IconButton>
                    <Typography variant="body2">
                      <Typography
                        style={{
                          flexGrow: '2',
                          fontSize: '12px',
                          textTransform: 'uppercase',
                        }}
                        color="inherit"
                        variant="h5"
                        noWrap
                        class="font-titulo"
                      >
                        {localEmpresa}
                        <Typography
                          style={{
                            fontSize: '11px',
                            textTransform: 'lowercase',
                          }}
                        >
                          {infoRedux?.contaPadrao?.apelidoConta}
                        </Typography>
                      </Typography>
                    </Typography>
                  </Grid>

                  <Menu
                    id="simple-menu"
                    anchorEl={anchorElProfile}
                    keepMounted
                    open={Boolean(anchorElProfile)}
                    onClose={handleCloseProfile}
                  >
                    <Link to="/meu-perfil">
                      <MenuItem onClick={handleCloseProfile}>
                        <AccountCircle color="primary" />
                        <Typography component="body2" color="primary">
                          Minha conta
                        </Typography>
                      </MenuItem>
                    </Link>
                    <Link to="/new-financial-password">
                      <MenuItem>
                        <LockIcon color="primary" />
                        <Typography component="body2" color="primary">
                          Alterar senhas
                        </Typography>
                      </MenuItem>
                    </Link>
                    <Link to="/usuarios">
                      <MenuItem>
                        <PeopleIcon color="primary" />
                        <Typography component="body2" color="primary">
                          Usuários
                        </Typography>
                      </MenuItem>
                    </Link>
                    <Link onClick={() => KeycloakService.logoutMfe()}>
                      <MenuItem>
                        <ExitToAppIcon color="primary" />
                        <Typography component="body2" color="primary">
                          Sair
                        </Typography>
                      </MenuItem>
                    </Link>
                  </Menu>

                  <Grid
                    item
                    xs={12}
                    md={4}
                    sm={4}
                    className={classes.acessoGrid}
                  >
                    <IconButton
                      onClick={handleProfileMenuOpen}
                      aria-label="account of current user"
                      aria-controls="primary-search-account-menu"
                      aria-haspopup="true"
                      color="inherit"
                    >
                      <AccountBalanceIcon color="primary" />
                    </IconButton>
                    <Typography variant="body2" noWrap>
                      <Typography
                        style={{
                          flexGrow: '2',
                          flexDirection: 'column',
                          fontSize: '12px',
                        }}
                        color="inherit"
                        variant="h5"
                        noWrap
                        class="font-titulo"
                      >
                        <Typography
                          color="inherit"
                          variant="h5"
                          noWrap
                          class="font-titulo"
                          style={{
                            fontSize: '12px',
                          }}
                        >
                          Banco:
                          <span className={classes.gridBgMenuBanco}>
                            {' '}
                            {infoRedux.contaPadrao.banco?.id}
                            {' - '}
                            {infoRedux.contaPadrao.banco?.nome}
                          </span>
                        </Typography>
                        <Typography
                          color="inherit"
                          variant="h5"
                          noWrap
                          class="font-titulo"
                          style={{ fontSize: '12px' }}
                        >
                          Agência:
                          <span className={classes.gridBgMenuBanco}>
                            {' '}
                            {infoRedux.contaPadrao?.agencia}
                          </span>
                        </Typography>
                        <Typography
                          color="inherit"
                          variant="h5"
                          noWrap
                          style={{ fontSize: '12px' }}
                          class="font-titulo"
                        >
                          Conta Corrente:
                          <span className={classes.gridBgMenuBanco}>
                            {' '}
                            {infoRedux.contaPadrao?.conta} -{' '}
                            {infoRedux.contaPadrao?.digitoConta}
                          </span>
                        </Typography>
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    sm={4}
                    className={classes.bancoEmpresaGrid}
                  >
                    <Typography className={classes.gridBgMenuSaldo}>
                      Saldo:{' '}
                      {!!saldo?.showSaldo
                        ? saldoData?.saldo != 0
                          ? ` ${formattedMoney(
                              parseFloat(saldoData?.saldo),
                            )}${' '}`
                          : 'R$ 0,00 '
                        : null}
                    </Typography>
                    <Tooltip
                      placement="right"
                      title={
                        saldo === 'erro'
                          ? 'Não foi possível conectar-se ao servidor'
                          : !saldo?.showSaldo
                          ? 'Visualizar saldo'
                          : 'Ocultar saldo'
                      }
                    >
                      <IconButton
                        className={classes.iconButtonSaldo}
                        color="inherit"
                        onClick={() => {
                          dispatch(saldoRequest(!saldo?.showSaldo));
                        }}
                      >
                        {saldo === 'erro' ? (
                          <ErrorRounded color="primary" />
                        ) : saldo?.showSaldo ? (
                          <VisibilityOff color="primary" />
                        ) : (
                          <Visibility color="primary" />
                        )}
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  lg={4}
                  sm={4}
                  className={classes.IconButtonsGrid}
                >
                  <Typography
                    variant="body2"
                    style={{
                      textAlign: 'right',
                      color:
                        theme.palette.type === 'dark'
                          ? theme.palette.background.default
                          : theme.palette.appBar[theme.palette.type].bgMenu,
                      fontSize: '12px',
                      marginRight: '15px',
                    }}
                  >
                    <Typography
                      style={{
                        flexGrow: '2',
                        color:
                          theme.palette.type === 'dark'
                            ? theme.palette.background.default
                            : theme.palette.appBar[theme.palette.type].bgMenu,
                        textTransform: 'capitalize',
                        fontSize: '18px',
                        fontStyle: 'normal',
                      }}
                      color="inherit"
                      variant="h5"
                      noWrap
                      class="font-titulo"
                    >
                      {localStorage.getItem('given_name')}
                    </Typography>
                    Seu acesso expira às{' '}
                    {newHoraRefresh === null ? horaexpiracao : newHoraRefresh}
                  </Typography>
                  <IconButton className={classes.iconButtonRight}>
                    <IoPersonCircle
                      size={40}
                      color={
                        theme.palette.type === 'dark'
                          ? theme.palette.background.default
                          : theme.palette.appBar[theme.palette.type].bgMenu
                      }
                      onClick={handleClickProfile}
                    />
                    <MdKeyboardArrowDown
                      size={20}
                      className={classes.iconButtonArrowDown}
                      color={
                        theme.palette.type === 'dark'
                          ? theme.palette.background.default
                          : theme.palette.appBar[theme.palette.type].bgMenu
                      }
                      onClick={handleClickProfile}
                    />
                  </IconButton>
                  <Tooltip
                    title={
                      modeTheme === 'dark'
                        ? 'Desativar tema escuro'
                        : 'Ativar tema escuro'
                    }
                  >
                    <IconButton
                      color="primary"
                      onClick={() =>
                        dispatch(
                          changeModeDark(modeTheme === 'dark' ? false : true),
                        )
                      }
                    >
                      {modeTheme === 'dark' ? (
                        <Brightness5
                          className={classes.iconButtonBrightness5}
                        />
                      ) : (
                        <Brightness2
                          className={classes.iconButtonBrightness2}
                        />
                      )}
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Hidden>
              <Hidden mdUp>
                <TopBarMobile
                  open={open}
                  saldo={saldoData}
                  localEmpresa={localEmpresa}
                  handleProfileMenuOpen={handleProfileMenuOpen}
                  infoRedux={infoRedux}
                  anchorElProfile={anchorElProfile}
                  handleCloseProfile={handleCloseProfile}
                  newHoraRefresh={newHoraRefresh}
                  horaexpiracao={horaexpiracao}
                  handleClickProfile={handleClickProfile}
                  handleOpenAccount={handleOpenAccount}
                />
              </Hidden>
            </Grid>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div
          className={clsx(
            classes.toolbarIcon,
            !open && classes.toolbarIconShift,
          )}
        >
          <img src={theme.config.menuLogo} className={classes.logo} />
          <IconButton
            onClick={handleDrawerClose}
            className={classes.chevroletBackground}
          >
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <DefaultMenu open={open} tooltip={!open} />
      </Drawer>
      {renderMenu}
    </>
  );
}
