import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import moment from 'moment';
import { api_multipague_cobranca } from '../../services/api';
import getValidationErros from '../../util/FormattedError';
import ContainerComponent from '../Container';
import Button from '../Buttons/ConfirmButton';
import { useHistory, useParams } from 'react-router-dom';
import InputText from '../InputText';
import {
  Container,
  Subcontainer,
  ContainerMotivoCancelamento,
  ContainerObservacao,
  InputSelect,
  ContainerButtons,
  ContainerDataProrrogacao,
  Data,
} from './styles';
import { pesquisarBoletoRequest } from '../../store/modules/editarBoleto/actions';
import { hideLoading, showLoading } from '../../store/modules/loading/actions';
import { listarMotivoRequest } from '../../store/modules/motivo/actions';
import { listarInstrucoesRequest } from '../../store/modules/instrucao/actions';
import {MdArrowBack,MdDone,MdCached} from "react-icons/md";
import Box from "../Box";
import Wrapper from "../Wrapper";
import { toastComponentError, toastComponentSuccess } from '../Toast';

const CancelarBoleto = () => {
  const { isCancelamento, idBoleto } = useParams();
  const root = {
    path: [
      {
        name: 'Minhas Contas',
        caminho: '/MinhasContas',
      },
      {
        name: 'Cobrança Simples',
        caminho: '/Cobranca/Simples',
      },
    ],
    pagAtual: isCancelamento === 'true' ? 'Cancelar' : 'Prorrogar',
  };
  const history = useHistory();
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const valorBoleto = useSelector(state => state.cancelamentoBoleto);
  const { contaPadrao } = useSelector(state => state.contas);
  const { userAccount } = useSelector(state => state.user);
  const instrucoesRedux = useSelector(state => state.instrucoes);
  const motivos = useSelector(state => state.motivos);
  const [motivoCancelamento, setMotivoCancelamento] = useState([]);

  const [dataProrrogracao, setDataProrrogracao] = useState(Date.now());
  const [dataMinimaProrrogracao, setDataMinimaProrrogracao] = useState(
    '03/23/2021',
  );
  const [instrucoesFormatted, setInstrucoesFormatted] = useState([]);

  useEffect(() => {
    dispatch(listarInstrucoesRequest());
  }, [dispatch]);

  useEffect(() => {
    console.log('mudou o state instrucao', instrucoesFormatted);
  }, [instrucoesFormatted]);

  useEffect(() => {
    if (instrucoesRedux.length) {
      const formatted = instrucoesRedux.map(i => ({
        label: i.descricao,
        value: i.id,
        ...i,
      }));
      setInstrucoesFormatted(formatted);
    }
  }, [instrucoesRedux]);

  useEffect(() => {
    if (isCancelamento === 'true' && contaPadrao?.id) {
      dispatch(listarMotivoRequest('CANCELAR_BOLETO'));
    } else if (contaPadrao?.id) {
      dispatch(listarMotivoRequest('PRORROGAR_BOLETO'));
    }
  }, [contaPadrao?.id]);

  useEffect(() => {
    const formatted = motivos.map(m => ({
      label: m.descricao,
      value: m.id,
      ...m,
    }));

    setMotivoCancelamento(formatted);
  }, [motivos]);

  useEffect(() => {
    if (contaPadrao?.id && idBoleto) {
      dispatch(
        pesquisarBoletoRequest({
          idBoleto: idBoleto,
          idConta: contaPadrao?.id,
        }),
      );
    }
  }, [contaPadrao?.id]);

  useEffect(() => {
    console.log('======> valor boleto', valorBoleto);
    if (valorBoleto?.instrucoes) {
      const valDescontoDate = valorBoleto.instrucoes.filter(
        val => val.tipo === 'VALOR_DESCONTO',
      );
      if (valDescontoDate.length) {
        console.log(
          '===============> VAL DESCONTO',
          moment(valDescontoDate[0].data).format('DD-MM-YYYY'),
        );
        setDataMinimaProrrogracao(
          new Date(moment(valDescontoDate[0].data).format('MM-DD-YYYY')),
        );
        setDataProrrogracao(
          new Date(moment(valDescontoDate[0].data).format('MM-DD-YYYY')),
        );
      }
    }
  }, [valorBoleto]);

  async function submitForm(infoForm) {
    dispatch(showLoading(true));

    try {
      const schmeCadastro = Yup.object().shape({
        motivo: Yup.string().required('Campo obrigatorio'),
        instrucao: Yup.string().required('Campo obrigatorio'),
        observacao: Yup.string().required('Campo obrigatorio'),
      });

      await schmeCadastro.validate(infoForm, {
        abortEarly: false,
      });

      if (isCancelamento === 'false') {
        const body = {
          ...valorBoleto,
          dataVencimento: moment(dataProrrogracao).format('YYYY-MM-DD'),
          observacaoAlteracao: infoForm.observacao,
          codigoMotivo: Number(infoForm.motivo),
          codigoTipoInstrucao: Number(infoForm.instrucao),
        };

        const { data } = await api_multipague_cobranca.put(
          `/cobranca-simples/${idBoleto}`,
          body,
        );

        console.log('==========> Aqui cancelamento', data);

        toastComponentSuccess('Boleto prorrogado com sucesso');
      } else {
        console.log(idBoleto);
        const { data } = await api_multipague_cobranca.post(
          `/cobranca-simples/${idBoleto}/cancelar/?clienteConta=${
            contaPadrao.id
          }&codigoMotivo=${Number(
            infoForm.motivo,
          )}&codigoTipoInstrucao=${Number(infoForm.instrucao)}`,
        );
        console.log('==========> Aqui cancelamento', data);
        toastComponentSuccess('Boleto cancelado com sucesso');
        history.push('/Buscar/Boleto');
      }

      dispatch(hideLoading(false));
    } catch (err) {
      dispatch(hideLoading(false));

      if (err instanceof Yup.ValidationError) {
        console.log(err.inner);
        const erros = getValidationErros(err);
        formRef.current?.setErrors(erros);
      } else {
        toastComponentError(
          ` Codigo: ${err.response.data.codigo} - ${err.response.data.mensagem}`,
        );
      }
    }
  }

  return (
    <Wrapper
      title="Cancelar Boleto"
      crumb={[
        { name: "Dashboard", link: "/dashboard" },
        { name: "Cob Simples", link: "/Cobranca/Simples" },
      ]}
    >
      <Container ref={formRef} onSubmit={submitForm}>
        <Box>
          <Subcontainer>
            {isCancelamento === 'false' && (
              <ContainerDataProrrogacao>
                <Data
                  callback={setDataProrrogracao}
                  id="data-prorrogacao"
                  label="Data Prorrogação"
                  name="data_prorrogacao"
                  dataMinima={dataMinimaProrrogracao}
                  value={dataProrrogracao}
                />
              </ContainerDataProrrogacao>
            )}

            <ContainerMotivoCancelamento>
              <InputSelect
                id="instrucao"
                name="instrucao"
                itens={instrucoesFormatted}
                label="Instrução"
              />
              <InputSelect
                id="motivo"
                name="motivo"
                itens={motivoCancelamento}
                label="Motivo"
              />
            </ContainerMotivoCancelamento>
            <ContainerObservacao>
              <InputText
                id="observacao"
                name="observacao"
                label="Observação"
                multiline
                rows={4}
                variant="outlined"
              />
            </ContainerObservacao>
            <ContainerButtons>
              <Button
                color="default"
                variant="contained"
                disabled={false}
                width={150}
                title={"Voltar"}
                type="button"
                onClick={() => history.go(-1)}
                startIcon={<MdArrowBack color='background'/>}
              >
              </Button>

              {isCancelamento === 'true' ? (
                <>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={false}
                    width={150}
                    type="submit"
                    title={"Confirmar"}
                    startIcon={<MdDone color='background' />}
                  >
                    Confirmar
                  </Button>

                </>
              ) : (
                <Button
                  color="primary"
                  variant="contained"
                  disabled={false}
                  title={"Prorrogar"}
                  width={150}
                  type="submit"
                  startIcon={<MdCached color='background' />}
                >
                  Prorrogar
                </Button>
              )}
            </ContainerButtons>
          </Subcontainer>
        </Box>
      </Container>
    </Wrapper>

  );
};

export default CancelarBoleto;
