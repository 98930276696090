import { all, call, put, takeLatest } from 'redux-saga/effects';
import { api_multipague_conta_paged_favoritos } from '../../../../services/api';
import { hideLoading, showLoading } from '../../loading/actions';
import { pesquisarFavoritosError, pesquisarFavoritosSuccess } from './actions';
import { toastComponentError } from '../../../../components/Toast';

function* pesquisarFavorecidos({ payload }) {
  try {
    if (!payload.hideLoading) {
      yield put(showLoading(true));
    }
    const { data } = yield call(
      api_multipague_conta_paged_favoritos.get,
      `/favorecido?idCliente=${payload.idCliente}&idClienteConta=${
        payload.idClienteConta
      }&cnpjCpf=${payload.cpf_cnpj}&nome=${
        payload.nome_completo
      }&idFavorecido=${
        payload.idFavorecido ? Number(payload.idFavorecido) : ''
      }&page=${payload.page}&size=${payload.size}`,
    );

    if (data.totalElements > 0) {
      yield put(
        pesquisarFavoritosSuccess({
          content: data.content,
          totalElements: data.totalElements,
          currentPage: data.currentPage,
          numberOfElements: data.numberOfElements,
          pageSize: data.numberOfElements,
          totalPages: data.totalPages,
        }),
      );
    }
    yield put(hideLoading(false));
  } catch (err) {
    yield put(hideLoading(false));
    yield put(pesquisarFavoritosError({ error: err.response.status }));
    console.log(err.response.data.codigo, err.response.data.mensagem);
    if (err.response.status != 404) {
      toastComponentError(
        ` Codigo: ${err.response.data.codigo} - ${err.response.data.mensagem}`,
      );
    }
  }
}

export default all([
  takeLatest('PESQUISAR_FAVORITOS_REQUEST', pesquisarFavorecidos),
]);
