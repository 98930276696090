import React, { useEffect, useState } from 'react';
import { alpha, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { api_multipague_cobranca_dashboard } from '../../services/api';
import formattedMoney from '../../util/FormatteMoney/index';
import { useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import {
  AiOutlineCheckCircle,
  AiOutlineExclamationCircle,
} from 'react-icons/ai';
import Divider from '@material-ui/core/Divider';
import SubtitlesIcon from '@material-ui/icons/Subtitles';
import { DashboardCard } from '../DashboardCards';
import { MessageErrorComponent } from '../DashboardCards/ErrorMessage';
import { useGetOperacoes } from '../../hooks/operacoes';

export default function CobrancaSimplesMobile({ conta = false }) {
  const infoRedux = useSelector(state => state.contas);
  const theme = useTheme();
  const [dataOperacao, setDataOperacao] = useState({
    cobrancaSimples: [],
    operacaoGarantia: [],
  });

  const { data, isLoading, isError } = useGetOperacoes(infoRedux, conta);

  useEffect(() => {
    if (data?.cobrancas[0]?.cobrancaSimplesTotalizador?.length > 0) {
      const cobrancaSimples =
        data?.cobrancas[0]?.cobrancaSimplesTotalizador.filter(
          item => item.tipoOperacaoBoleto === 'COBRANCA_SIMPLES',
        )[0];

      setDataOperacao(prev => ({ ...prev, cobrancaSimples: cobrancaSimples }));

      const operacaoGarantia =
        data?.cobrancas[0]?.cobrancaSimplesTotalizador.filter(
          item => item.tipoOperacaoBoleto === 'OPERACAO_GARANTIA',
        )[0];

      setDataOperacao(prev => ({
        ...prev,
        operacaoGarantia: operacaoGarantia,
      }));
    }
  }, [data]);

  return (
    <DashboardCard
      height={500}
      title={'Operações'}
      carregando={isLoading}
      children={
        <>
          {isError ? (
            <MessageErrorComponent />
          ) : (
            <>
              <Box
                p={2}
                border={1}
                borderColor={alpha(
                  theme.palette.getContrastText(theme.palette.background.paper),
                  0.1,
                )}
                borderRadius={'20px'}
              >
                <Typography
                  style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    marginBottom: 8,
                  }}
                >
                  Cobranças Simples
                </Typography>
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    style={{
                      fontWeight: 'bold',
                      color: theme.palette.text.primary,
                      display: 'flex',
                      lineHeight: '180%',
                      alignItems: 'center',
                    }}
                  >
                    <AiOutlineCheckCircle
                      size={25}
                      style={{ paddingRight: '5px' }}
                      color={'green'}
                    />
                    Vencendo hoje:
                  </Typography>
                  {dataOperacao?.cobrancaSimples?.vencendoHoje == 0
                    ? 'R$ 0,00'
                    : formattedMoney(
                        parseFloat(dataOperacao?.cobrancaSimples?.vencendoHoje),
                      )}
                </Box>
                <Divider />
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    style={{
                      fontWeight: 'bold',
                      color: theme.palette.text.primary,
                      display: 'flex',
                      lineHeight: '180%',
                      alignItems: 'center',
                    }}
                  >
                    <AiOutlineExclamationCircle
                      size={25}
                      style={{ paddingRight: '5px' }}
                      color={'orange'}
                    />
                    À vencer:
                  </Typography>
                  {dataOperacao?.cobrancaSimples?.aVencer == 0
                    ? 'R$ 0,00'
                    : formattedMoney(
                        parseFloat(dataOperacao?.cobrancaSimples?.aVencer),
                      )}
                </Box>
                <Divider />
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    style={{
                      fontWeight: 'bold',
                      color: theme.palette.text.primary,
                      display: 'flex',
                      alignContent: 'center',
                      lineHeight: '180%',
                      alignItems: 'center',
                    }}
                  >
                    <AiOutlineExclamationCircle
                      size={25}
                      style={{ paddingRight: '5px' }}
                      color={'red'}
                    />
                    Vencidas:
                  </Typography>
                  {dataOperacao?.cobrancaSimples?.vencido == 0
                    ? 'R$ 0,00'
                    : formattedMoney(
                        parseFloat(dataOperacao?.cobrancaSimples?.vencido),
                      )}
                </Box>
              </Box>
              <Box
                p={2}
                border={1}
                borderColor={alpha(
                  theme.palette.getContrastText(theme.palette.background.paper),
                  0.1,
                )}
                borderRadius={'20px'}
              >
                <Typography
                  style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    marginBottom: 8,
                  }}
                >
                  Garantias
                </Typography>
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography
                    style={{
                      fontWeight: 'bold',
                      color: theme.palette.text.primary,
                      alignItems: 'center',
                      display: 'flex',
                      alignContent: 'center',
                      lineHeight: '180%',
                    }}
                  >
                    <AiOutlineCheckCircle
                      size={25}
                      style={{ paddingRight: '5px' }}
                      color={'green'}
                    />
                    Vencendo hoje:
                  </Typography>
                  {!dataOperacao?.operacaoGarantia ||
                  dataOperacao?.operacaoGarantia?.vencendoHoje == 0
                    ? 'R$ 0,00'
                    : formattedMoney(
                        parseFloat(
                          dataOperacao?.operacaoGarantia?.vencendoHoje,
                        ),
                      )}
                </Box>
                <Divider />
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    style={{
                      fontWeight: 'bold',
                      color: theme.palette.text.primary,
                      alignItems: 'center',
                      display: 'flex',
                      alignContent: 'center',
                      lineHeight: '180%',
                    }}
                  >
                    <AiOutlineExclamationCircle
                      size={25}
                      style={{ paddingRight: '5px' }}
                      color={'orange'}
                    />
                    À vencer:
                  </Typography>
                  {!dataOperacao?.operacaoGarantia ||
                  dataOperacao?.operacaoGarantia?.aVencer == 0
                    ? 'R$ 0,00'
                    : formattedMoney(
                        parseFloat(dataOperacao?.operacaoGarantia?.aVencer),
                      )}
                </Box>
                <Divider />
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography
                    style={{
                      fontWeight: 'bold',
                      color: theme.palette.text.primary,
                      alignItems: 'center',
                      display: 'flex',
                      alignContent: 'center',
                      lineHeight: '180%',
                    }}
                  >
                    <AiOutlineExclamationCircle
                      size={25}
                      style={{ paddingRight: '5px' }}
                      color={'red'}
                    />
                    Vencidas:
                  </Typography>
                  {!dataOperacao?.operacaoGarantia ||
                  dataOperacao?.operacaoGarantia?.vencido == 0
                    ? 'R$ 0,00'
                    : formattedMoney(
                        parseFloat(dataOperacao?.operacaoGarantia?.vencido),
                      )}
                </Box>
              </Box>
              <Box
                p={2}
                border={1}
                borderColor={alpha(
                  theme.palette.getContrastText(theme.palette.background.paper),
                  0.1,
                )}
                borderRadius={'20px'}
              >
                <Typography
                  style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    marginBottom: 8,
                  }}
                >
                  Total
                </Typography>

                <Box display={'flex'} justifyContent={'center'}>
                  <Box alignItems="center" display={'flex'}>
                    <AiOutlineCheckCircle
                      size={20}
                      style={{ margin: '0 5px 0 15px' }}
                      color={'green'}
                    />
                    {data?.total?.vencendoHoje == 0
                      ? 'R$ 0,00'
                      : formattedMoney(parseFloat(data?.total?.vencendoHoje))}
                  </Box>
                  <Box alignItems="center" display={'flex'}>
                    <AiOutlineExclamationCircle
                      size={20}
                      style={{ margin: '0 5px 0 15px' }}
                      color={'orange'}
                    />
                    {data?.total?.aVencer == 0
                      ? 'R$ 0,00'
                      : formattedMoney(parseFloat(data?.total?.aVencer))}
                  </Box>
                  <Box alignItems="center" display={'flex'}>
                    <AiOutlineExclamationCircle
                      size={20}
                      style={{ margin: '0 5px 0 15px' }}
                      color={'red'}
                    />
                    {data?.total?.vencido == 0
                      ? 'R$ 0,00'
                      : formattedMoney(parseFloat(data?.total?.vencido))}
                  </Box>
                </Box>
              </Box>
            </>
          )}
        </>
      }
      icon={
        <SubtitlesIcon
          size={50}
          style={{
            marginRight: 5,
            color: theme.palette.primary.main,
          }}
        />
      }
    />
  );
}
