import React, { useState, useCallback, useEffect, useRef } from 'react';
import Chip from '@material-ui/core/Chip';
import { useTheme } from '@material-ui/core/styles';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import jwt_decode from 'jwt-decode';
import SmsToken from '../../components/Inputs/SmsToken';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DateComponent from '../../components/InputDate';
import InputText from '../../components/InputText';
import CpfCnpj from '../../components/Inputs/CNPJ';
import CPF from '../../components/Inputs/CPF';
import Button from '../../components/Buttons/ConfirmButton';
import {
  DialogActions,
  Checkbox,
  Typography,
  DialogContent,
  Dialog,
  DialogTitle,
  Divider,
} from '@material-ui/core';
import { MdDownload, MdMonetizationOn, MdReplay, MdSend } from 'react-icons/md';
import RadioButton from '../../components/RadioButton';
import Password from '../../components/Password';
import {
  api_multipague_configuracao,
  api_multipague_esteira_paged,
  api_multipague_esteira,
  api_multipague_conta,
} from '../../services/api';
import {
  Container,
  ButtonTable,
  Checks,
  BotoesPrint,
  BotoesPrint2,
  useStyles,
} from './styles';
import PopUp from '../../components/PopUp';
import { useHistory } from 'react-router';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import PanToolIcon from '@material-ui/icons/PanTool';
import CancelIcon from '@material-ui/icons/Cancel';
import Tooltip from '@material-ui/core/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { showPopUp } from '../../store/modules/popUp/actions';
import { hideLoading, showLoading } from '../../store/modules/loading/actions';
import { maskCpfCnpj } from '../../util/mask';
import formattedMoney from '../../util/FormatteMoney';
import Grid from '@material-ui/core/Grid';
import Box from '../../components/Box';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useAuthContext } from '../../Context/AuthContext';
import {
  Block,
  HistoryOutlined,
  ThumbDownOutlined,
  ThumbUpOutlined,
  Timelapse,
  TimerOff,
} from '@material-ui/icons';
import { DataGridPage } from '../../components/DataGrid/DataGridPaginationServer';
import {
  toastComponentError,
  toastComponentSuccess,
} from '../../components/Toast';
import { Form } from '@unform/web';
import { FaEye, FaEyeSlash, FaSearch } from 'react-icons/fa';
import { ModalOrdemPagamento } from './ModalOrdemPagemento';
import { masterOuAnalistaCredito } from '../../util/handlePermissions';

const Estera = () => {
  const { permissionAdmin } = useAuthContext();

  const optionsTypePerson = [
    {
      label: 'Pessoa Física',
      value: 'PF',
      selected: true,
    },
    {
      label: 'Pessoa Jurídica',
      value: 'PJ',
      selected: false,
    },
  ];

  const [typePersonSelected, setTypePersonSelected] = useState(
    optionsTypePerson[0].selected
      ? optionsTypePerson[0].value
      : optionsTypePerson[1].value,
  );
  const history = useHistory();
  const formInfo = history?.location?.state?.formInfo;
  const dispatch = useDispatch();
  const { contaPadrao } = useSelector(state => state.contas);
  const token = localStorage.getItem('tkn-access');
  const { realm_access } = jwt_decode(token);
  const formPopUpRef = useRef(null);
  const formRef = useRef(null);
  const [rowsTable, setRowsTable] = useState([]);
  const [idAddParecer, setIdAddParecer] = useState('');
  const [evento, setEvento] = useState([]);
  const [parecerLote, setParecerLote] = useState('');
  const [loadingButtonSms, setLoadingButtonSms] = useState(false);
  const [showPopUpState, setShowPopUpState] = useState(false);
  const [approvalUser, setApprovalUser] = useState([]);
  const [aprovacaoEmLote, setAprovacaoEmLote] = useState('');
  const [reprovacaoEmLote, setReprovacaoEmLote] = useState('');
  const [reprovar, setReprovar] = useState('');
  const [parecerOpen, setParecerOpen] = useState({ open: false, parecer: '' });
  const [aprovar, setAprovar] = useState(null);
  const [cancelar, setCancelar] = useState('');
  const [loadingLote, setLoadingLote] = useState(false);
  const [rowCount, setRowCount] = useState();
  const [InfoEsteira, setInfoEsteira] = useState();
  const [historicoParecer, setHistoricoParecer] = useState('');
  const [textNoRows, setTextNoRows] = useState('');
  const [checkedRow, setCheckedRow] = useState({
    id: '',
    approved: null,
    row: '',
  });
  const [rowsState, setRowsState] = React.useState({
    page: 0,
    pageSize: 10,
    carregaDatagrid: false,
    loading: false,
  });
  const [openSMS, setOpenSMS] = React.useState(false);
  const [valorSms, setValorSms] = useState('');
  const infoRedux = useSelector(state => state.contas);
  const [dataEntrada, setDataEntrada] = useState({
    inicial: formInfo?.dataEntrada,
    final: formInfo?.dataFinal,
  });
  const [dataParecer, setDataParecer] = useState({
    inicial: null,
    final: null,
  });
  const [idRegistro, setIdRegistro] = useState('false');
  const [openModalOrdemPagamento, setOpenModalOrdemPagamento] = useState(false);
  const [ordensDePagamento, setOrdensDePagamento] = useState([]);
  const [handleHistoricLoading, setHandleHistoricLoading] = useState({
    rowIdLoading: 0,
    historicLoading: false,
  });
  const [checkStatusEsteira, setCheckStatusEsteira] = useState(
    formInfo?.checkStatusEsteira
      ? formInfo.checkStatusEsteira
      : [
          {
            label: 'pendente',
            enum: 'PENDENTE',
            value: 1,
            checked: true,
          },
          {
            label: 'aprovados',
            enum: 'APROVADO',
            value: 2,
            checked: false,
          },
          {
            label: 'negados',
            enum: 'NEGADO',
            value: 3,
            checked: false,
          },
          {
            label: 'expirados',
            enum: 'EXPIRADO',
            value: 4,
            checked: false,
          },
          {
            label: 'pendente',
            enum: 'REJEITADO',
            value: 5,
            checked: false,
          },
        ],
  );

  const handleRowContent = row => {
    return row?.length > 40 ? (
      <Tooltip title={row}>
        <div
          className={classes.rowContent}
          style={{
            fontSize: row?.length > 40 ? '10px' : '13px',
          }}
        >
          {row}
        </div>
      </Tooltip>
    ) : (
      <div
        className={classes.rowContent}
        style={{
          fontSize: row?.length > 40 ? '10px' : '13px',
        }}
      >
        {row}
      </div>
    );
  };

  const handleHistoricoTransferencia = idTransacao => {
    setHandleHistoricLoading(prev => ({ ...prev, historicLoading: true }));
    api_multipague_esteira
      .get(`/administracao/transferencia?codigoTransferencia=${idTransacao}`)
      .then(response => {
        setHandleHistoricLoading(prev => ({ ...prev, historicLoading: false }));
        history.push('/historico-teds', {
          historic: response.data,
          isEsteira: true,
          idTransacao: idTransacao,
          formInfo: {
            dataEntrada: dataEntrada?.inicial,
            dataFinal: dataEntrada?.final,
            checkStatusEsteira: checkStatusEsteira,
            ...formRef.current.getData(),
          },
        });
      })
      .catch(err => {
        setHandleHistoricLoading(prev => ({ ...prev, historicLoading: false }));
        toastComponentError(err.response?.data?.mensagem);
      });
  };

  const handleHistoricoArquivoPagamento = idTransacao => {
    setHandleHistoricLoading(prev => ({ ...prev, historicLoading: true }));
    api_multipague_esteira
      .get(
        `/administracao/arquivo-pagamento?codigoArquivoPagamento=${idTransacao}`,
      )
      .then(response => {
        setHandleHistoricLoading(prev => ({ ...prev, historicLoading: false }));
        history.push('/historico-teds', {
          historic: response.data,
          isEsteira: true,
          formInfo: {
            dataEntrada: dataEntrada?.inicial,
            dataFinal: dataEntrada?.final,
            ...formRef.current.getData(),
          },
        });
      })
      .catch(err => {
        setHandleHistoricLoading(prev => ({ ...prev, historicLoading: false }));
        toastComponentError(err.response?.data?.mensagem);
      });
  };

  const handleHistoricoBoleto = idTransacao => {
    setHandleHistoricLoading(prev => ({ ...prev, historicLoading: true }));
    api_multipague_esteira
      .get(
        `/administracao/pagamento-boleto?codigoPagamentoBoleto=${idTransacao}`,
      )
      .then(response => {
        setHandleHistoricLoading(prev => ({ ...prev, historicLoading: false }));
        history.push('/historico-teds', {
          historic: response.data,
          isEsteira: true,
          formInfo: {
            dataEntrada: dataEntrada?.inicial,
            dataFinal: dataEntrada?.final,
            ...formRef.current.getData(),
          },
        });
      })
      .catch(err => {
        setHandleHistoricLoading(prev => ({ ...prev, historicLoading: false }));
        toastComponentError(err.response?.data?.mensagem);
      });
  };

  const columnsTable = [
    {
      field: '__check__',
      type: 'checkboxSelection',
      resizable: false,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      disableReorder: true,
      disableExport: true,
      renderHeader: () => {
        return (
          <Checkbox
            checked={checkboxAll}
            onChange={(_e, value) => changeCheckboxAll(value)}
            color="primary"
          />
        );
      },
      renderCell: params => {
        return (
          <Checkbox
            checked={selectionModel.includes(params.id)}
            onChange={(_e, checked) => {
              if (!checked) {
                setCheckboxAll(false);
                setSelectionModel(old => old.filter(id => id !== params.id));
              }
            }}
            color="primary"
          />
        );
      },
    },

    {
      field: 'data_entrada',
      headerName: 'Data da Entrada',
      type: 'string',
      width: 150,
    },
    {
      field: 'cnpj',
      headerName: 'CPF/CNPJ',
      type: 'string',
      width: 150,
      renderCell: event => {
        return (
          <Tooltip title={event?.row?.cnpj}>
            <div>{event?.row?.cnpj}</div>
          </Tooltip>
        );
      },
    },
    {
      field: 'correntista',
      headerName: 'Correntista',
      type: 'string',
      width: 250,
      renderCell: params => {
        const { row } = params;
        const theme = useTheme();
        const [hovered, setHovered] = useState(false);

        const handleHover = () => {
          setHovered(true);
        };

        const handleMouseLeave = () => {
          setHovered(false);
        };

        const handleOrdens = () => {
          setOpenModalOrdemPagamento(true);
          setOrdensDePagamento(row?.ordensPagamento);
        };

        return row?.exigeAlcadaCredito && row?.ordensPagamento?.length > 0 ? (
          <>
            {hovered ? (
              <div className={classes.rowCorrentistaAbsolute}>
                <p
                  style={{
                    fontSize: '10px',
                    transform: 'translate(6%, -30%)',
                    color: theme.palette.common.white,
                  }}
                >
                  Ordem de Pagamento
                </p>
              </div>
            ) : null}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
              }}
              onClick={() => {
                row?.ordensPagamento?.length > 1
                  ? handleOrdens()
                  : handleOrdemPagamento(row.ordensPagamento[0]);
              }}
              onMouseEnter={handleHover}
              onMouseLeave={handleMouseLeave}
            >
              <FaSearch
                size={20}
                style={{ marginRight: 8, cursor: 'pointer' }}
                color={
                  hovered
                    ? theme.palette.primary.dark
                    : theme.palette.primary.main
                }
              />
            </div>
            {handleRowContent(row.correntista)}
          </>
        ) : (
          handleRowContent(row.correntista)
        );
      },
    },
    {
      field: 'nome_empresa',
      headerName: 'Nome da Empresa',
      type: 'string',
      width: 180,
      renderCell: params => {
        return handleRowContent(params.row.nome_empresa);
      },
    },
    {
      field: 'valor',
      headerName: 'Valor',
      type: 'string',
      width: 120,
      renderCell: params => {
        return handleRowContent(params.row.valor);
      },
    },
    {
      field: 'nome_eventos',
      headerName: 'Evento',
      width: 100,
      renderCell: event => {
        return (
          <Tooltip title={event?.row?.nome_eventos}>
            <div
              style={{
                lineHeight: '150%',
                height: '50px',
                alignItems: 'center',
                display: 'flex',
                flexWrap: 'wrap',
                fontSize:
                  event?.row?.nome_eventos?.length > 20 ? '10px' : '13px',
                whiteSpace: 'break-spaces',
              }}
            >
              {event?.row?.nome_eventos}
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: 'aprovar_negar',
      headerName: 'Aprovar / Negar',
      sortable: false,
      width: 130,
      disableClickEventBubbling: true,
      renderCell: event => {
        const theme = useTheme();
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
              width: '100%',
            }}
          >
            {event.row.situacao !== 'PARCIALMENTE_APROVADO' &&
            permissionAdmin ? (
              <ButtonTable
                onClick={() => {
                  setAprovar(true);
                  event.row.situacao === 'PENDENTE' &&
                    preCheckedParecer(event.row.id, true, event.row);
                }}
                type="button"
              >
                {event.row.situacao === 'APROVADO' ? (
                  <ThumbUpIcon
                    variant="contained"
                    cursor="pointer"
                    style={{ color: '#28a745' }}
                  />
                ) : event.row.situacao === 'PENDENTE' ? (
                  <ThumbUpOutlined
                    variant="contained"
                    cursor="pointer"
                    style={{
                      color: theme.palette.primary.main,
                    }}
                  />
                ) : (
                  <ThumbUpIcon
                    variant="contained"
                    cursor="pointer"
                    style={{
                      color: '#c5c5c5',
                    }}
                  />
                )}
              </ButtonTable>
            ) : (
              ''
            )}

            {event.row.situacao === 'PARCIALMENTE_APROVADO' &&
            permissionAdmin ? (
              <Tooltip
                title="Parcialmente Aprovado"
                aria-label="parcialmente-aprovado"
              >
                <PanToolIcon variant="contained" style={{ color: '#ffe800' }} />
              </Tooltip>
            ) : (
              ''
            )}

            {event.row.situacao !== 'PARCIALMENTE_APROVADO' &&
            permissionAdmin ? (
              <ButtonTable
                onClick={() => {
                  setAprovar(false);
                  event.row.situacao === 'PENDENTE' &&
                    preCheckedParecer(event.row.id, false, event.row);
                }}
                type="button"
              >
                {event.row.situacao === 'NEGADO' ? (
                  <ThumbDownIcon
                    variant="contained"
                    cursor="pointer"
                    style={{ color: '#dc3545' }}
                  />
                ) : event.row.situacao === 'PENDENTE' ? (
                  <ThumbDownOutlined
                    variant="contained"
                    cursor="pointer"
                    style={{
                      color: theme.palette.primary.main,
                    }}
                  />
                ) : (
                  <ThumbDownIcon
                    variant="contained"
                    cursor="pointer"
                    style={{
                      color: '#c5c5c5',
                    }}
                  />
                )}
              </ButtonTable>
            ) : (
              ''
            )}
          </div>
        );
      },
    },
    {
      field: 'parecer',
      headerName: 'Parecer',
      sortable: false,
      width: 110,
      disableClickEventBubbling: true,
      renderCell: event => {
        const theme = useTheme();
        const handleParecer = item => {
          const buttonParecer = (
            <ButtonTable
              onClick={() => {
                item.situacao === 'PARCIALMENTE_APROVADO' ||
                item.situacao === 'PENDENTE'
                  ? setParecerOpen({
                      open: false,
                      parecer: item.parecer,
                    })
                  : setParecerOpen({
                      open: true,
                      parecer: item.parecer,
                    });
              }}
              type="button"
            >
              {item.situacao === 'PARCIALMENTE_APROVADO' ||
              item.situacao === 'PENDENTE' ? (
                <FaEyeSlash
                  size={20}
                  color={
                    item.situacao === 'PARCIALMENTE_APROVADO' ||
                    item.situacao === 'PENDENTE'
                      ? theme.palette.text.disabled
                      : theme.palette.primary.main
                  }
                />
              ) : (
                <FaEye
                  size={20}
                  color={
                    item.situacao === 'PARCIALMENTE_APROVADO' ||
                    item.situacao === 'PENDENTE'
                      ? theme.palette.text.disabled
                      : theme.palette.primary.main
                  }
                />
              )}
            </ButtonTable>
          );
          if (masterOuAnalistaCredito) {
            return buttonParecer;
          } else if (masterOuAnalistaCredito || !item?.exigeAlcadaCredito) {
            return buttonParecer;
          } else if (!masterOuAnalistaCredito && item?.exigeAlcadaCredito) {
            return <FaEyeSlash size={20} color={theme.palette.text.disabled} />;
          }
        };

        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            {permissionAdmin && handleParecer(event.row)}
          </div>
        );
      },
    },
    {
      field: 'historico',
      headerName: 'Histórico',
      width: 100,
      renderCell: event => {
        return (
          <Tooltip title="Histórico de aprovações">
            <button
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                cursor: 'pointer',
              }}
            >
              {handleHistoricLoading.historicLoading &&
              event?.row?.id === handleHistoricLoading.rowIdLoading ? (
                <CircularProgress size={24} />
              ) : (
                <HistoryOutlined
                  onClick={() => {
                    const setHistoric = (codigo, tipo) => {
                      {
                        setHandleHistoricLoading(prev => ({
                          ...prev,
                          rowIdLoading: event?.row?.id,
                        }));
                        if (tipo == 'transferencia') {
                          handleHistoricoTransferencia(codigo);
                        } else if (tipo == 'arquivoPagamento') {
                          handleHistoricoArquivoPagamento(codigo);
                        } else if (tipo == 'boleto') {
                          handleHistoricoBoleto(codigo);
                        }
                      }
                    };
                    event?.row?.codigoTransferencia
                      ? setHistoric(
                          event?.row?.codigoTransferencia,
                          'transferencia',
                        )
                      : event?.row?.codigoArquivoPagamento
                      ? setHistoric(
                          event?.row?.codigoArquivoPagamento,
                          'arquivoPagamento',
                        )
                      : event?.row?.codigoPagamentoBoleto
                      ? setHistoric(event?.row?.codigoPagamentoBoleto, 'boleto')
                      : null;
                  }}
                  color={'primary'}
                  size={24}
                />
              )}
            </button>
          </Tooltip>
        );
      },
    },
    !permissionAdmin && {
      disableClickEventBubbling: true,
      field: 'situacao',
      headerName: 'Situação',
      type: 'string',
      width: 110,
      align: 'center',
      renderCell: event => {
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {event.row.situacao === 'PENDENTE' && (
              <Tooltip title="Pendente" aria-label="pendente">
                <Timelapse
                  variant="contained"
                  style={{ color: useTheme().palette.text.disabled }}
                />
              </Tooltip>
            )}
            {event.row.situacao === 'APROVADO' && (
              <Tooltip title="Aprovado" aria-label="aprovado">
                <ThumbUpIcon
                  variant="contained"
                  style={{ color: useTheme().palette.text.disabled }}
                />
              </Tooltip>
            )}
            {event.row.situacao === 'NEGADO' && (
              <Tooltip title="Negado" aria-label="negado">
                <ThumbDownIcon
                  variant="contained"
                  style={{ color: useTheme().palette.text.disabled }}
                />
              </Tooltip>
            )}
            {event.row.situacao === 'CANCELADO' && (
              <Tooltip title="Cancelado" aria-label="cancelado">
                <CancelIcon variant="contained" />
              </Tooltip>
            )}
            {event.row.situacao === 'EXPIRADO' && (
              <Tooltip title="Expirado" aria-label="expirado">
                <TimerOff
                  variant="contained"
                  style={{ color: useTheme().palette.text.disabled }}
                />
              </Tooltip>
            )}
            {event.row.situacao === 'PARCIALMENTE_APROVADO' && (
              <Tooltip
                title="Parcialmente Aprovado"
                aria-label="parcialmente-aprovado"
              >
                <PanToolIcon
                  variant="contained"
                  style={{ color: useTheme().palette.text.disabled }}
                />
              </Tooltip>
            )}
            {event.row.situacao === 'AGUARDANDO_PROCESSAMENTO_ARQUIVO' && (
              <Tooltip
                title="Aguardando Processamento Arquivo"
                aria-label="aguardando_processamento_arquivo"
              >
                <Timelapse
                  variant="contained"
                  style={{ color: useTheme().palette.text.disabled }}
                />
              </Tooltip>
            )}
            {event.row.situacao === 'ARQUIVO_SEM_REGISTRO_SUCESSO' && (
              <Tooltip
                title="Arquivo Sem Registro"
                aria-label="arquivo_sem_registro_sucesso"
              >
                <ThumbDownIcon
                  variant="contained"
                  style={{ color: useTheme().palette.text.disabled }}
                />
              </Tooltip>
            )}
            {event.row.situacao === 'REJEITADO' && (
              <Tooltip title="Rejeitado" aria-label="rejeitado">
                <Block
                  variant="contained"
                  style={{ color: useTheme().palette.text.disabled }}
                />
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      field: 'download',
      headerName: 'Anexo',
      sortable: false,
      width: 110,
      disableClickEventBubbling: true,
      renderCell: event => {
        const theme = useTheme();
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              cursor: 'pointer',
            }}
          >
            {!!event?.row?.arquivoOrdemPagamento?.caminhoStorage ? (
              <Tooltip title="Download Ordem de Pagamento">
                <a
                  href={event?.row?.arquivoOrdemPagamento?.caminhoStorage}
                  download={event?.row?.arquivoOrdemPagamento?.nomeArquivo}
                  style={{ marginTop: '15px' }}
                >
                  <MdDownload size={24} color={theme.palette.primary.main} />
                </a>
              </Tooltip>
            ) : (
              <Tooltip title="Download Ordem de Pagamento">
                <a
                  href={event?.row?.arquivoOrdemPagamento?.caminhoStorage}
                  download={event?.row?.arquivoOrdemPagamento?.nomeArquivo}
                  style={{ marginTop: '15px' }}
                >
                  <MdDownload size={24} color={theme.palette.text.disabled} />
                </a>
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ];

  const handleOrdemPagamento = idOrdemPagamento => {
    history.push('/ordem-pagamento', {
      ordemPagamento: idOrdemPagamento,
      ordensPagamento: ordensDePagamento,
    });
  };

  function enviarSms() {
    handleCloseSMS();
    dispatch(showPopUp(true));
  }

  function handleOpenSMS() {
    // Manda o sms pro cliente
    api_multipague_conta
      .post(`/token/send-token/${contaPadrao.id}`)
      .then(response => {
        setOpenSMS(true);
      })
      .catch(err => {
        toastComponentError(
          err.response.data.mensagem ||
            'Não foi possível se comunicar com o servidor',
        );
      });
  }
  function handleCloseSMS() {
    setOpenSMS(false);
  }

  const contaId = localStorage.getItem('idConta');

  useEffect(() => {
    const jwtToken = jwt_decode(localStorage.getItem('tkn-access'));

    const verify = realm_access.roles.filter(
      val =>
        val.toUpperCase() === 'correntista_administrador'.toUpperCase() ||
        val.toUpperCase() === 'analista_credito'.toUpperCase() ||
        val.toUpperCase() === 'correntista_operador'.toUpperCase(),
    );

    if (jwtToken?.permissaoContas) {
      const conta = jwtToken?.permissaoContas?.find(item =>
        item?.contas?.find(conta2 => conta2?.id == contaId),
      );

      if (
        verify.length === 0 &&
        conta?.role !== 'correntista_operador' &&
        conta?.role !== 'correntista_administrador' &&
        conta?.role !== 'analista_credito'
      ) {
        history.push('/dashboard');
      }
    }
  }, [contaId]);

  function cleanFilter() {
    setDataEntrada({
      final: '',
      inicial: '',
    });
    setDataParecer({
      final: '',
      inicial: '',
    });
    formRef.current?.setData({
      cnpj_empresa: '',
      nome_empresa: '',
    });
    setInfoEsteira();
    submitForm(formRef.current.getData(), 0, cleanFilter);
    setRowsState(prev => ({ ...prev, page: 0 }));
  }

  useEffect(() => {
    loadEventos();
  }, []);

  useEffect(() => {
    if (!!formInfo?.cnpj_empresa || !!formInfo?.nome_empresa) {
      formRef.current?.setData({
        cnpj_empresa: formInfo?.cnpj_empresa,
        nome_empresa: formInfo?.nome_empresa,
      });
      return () => {
        formRef.current?.setData({
          cnpj_empresa: '',
          nome_empresa: '',
        });
      };
    }
  }, [formInfo]);

  useEffect(() => {
    if (contaPadrao.id) {
      submitForm(formRef.current.getData(), 0);
    }
  }, [contaPadrao.id]);

  function openAddParecer(id) {
    setShowPopUpState(true);
    setIdAddParecer(id);
  }

  const checkedParecer = (id, approved, row) => {
    setShowPopUpState(false);
    const { clienteConta } = row;
    const findRow = rowsTable.filter(row => row.id === id);

    if (findRow.length) {
      if (
        !!historicoParecer &&
        approved != null &&
        infoRedux.contaPadrao?.tokenPorTransacao == true
      ) {
        handleOpenSMS();
        setApprovalUser([
          {
            id: id,
            approved: approved,
            row: row,
          },
        ]);
      } else if (
        !!historicoParecer &&
        approved != null &&
        infoRedux.contaPadrao?.tokenPorTransacao == false
      ) {
        dispatch(showPopUp(true));
        setApprovalUser([
          {
            id: id,
            approved: approved,
            row: row,
          },
        ]);
      } else {
        toastComponentError(
          !historicoParecer
            ? `Erro: Por favor adicione o parecer`
            : 'Erro: Por favor clique no botão de aprovar/negar',
        );
        setHistoricoParecer('');
        openAddParecer(id);
        setApprovalUser([]);
        return;
      }
    }
  };

  const preCheckedParecer = (id, approved, row) => {
    openAddParecer(id);
    setCheckedRow({
      id: id,
      approved: approved,
      row: row,
    });
  };

  async function loadEventos() {
    try {
      const { data } = await api_multipague_configuracao.get('/evento');

      if (data.length) {
        const formatted = data.map(data => ({
          label: data.descricao,
          value: data.id,
          ...data,
        }));
        setEvento(formatted);
      }
    } catch (err) {
      toastComponentError(
        ` Codigo: ${err.response.data.codigo} - ${err.response.data.mensagem}`,
      );
    }
  }

  const onPage = page => {
    setRowsState(prev => ({ ...prev, page: page }));
    submitForm(formRef?.current?.getData(), page);
  };

  const submitForm = useCallback(
    async (formSubmit, page = 0, cleanFilter = false) => {
      try {
        setRowsState(prev => ({ ...prev, loading: true }));
        const situacao = checkStatusEsteira.filter(val => val.checked);
        const { data } = await api_multipague_esteira_paged.get(
          !cleanFilter
            ? `/administracao?clienteConta=${
                contaPadrao.id
              }&dataInicioEntrada=${
                !!dataEntrada.inicial
                  ? moment(dataEntrada.inicial).format('YYYY-MM-DD')
                  : ''
              }&dataFimEntrada=${
                !!dataEntrada.final
                  ? moment(dataEntrada.final).format('YYYY-MM-DD')
                  : ''
              }&situacao=${
                situacao.length ? situacao[0].enum : ''
              }&cnpjCpf=${formSubmit.cnpj_empresa.replace(/[^\d]/g, '')}&nome=${
                formSubmit.nome_empresa
              }&page=${page}&size=${rowsState.pageSize}`
            : `/administracao?clienteConta=${
                contaPadrao.id
              }&dataInicioEntrada=&dataFimEntrada=&situacao=${
                situacao.length ? situacao[0].enum : ''
              }&cnpjCpf=${formSubmit.cnpj_empresa.replace(/[^\d]/g, '')}&nome=${
                formSubmit.nome_empresa
              }&page=${page}&size=${rowsState.pageSize}`,
        );

        if (data.content.length > 0) {
          if (situacao[0].value === 4) {
            const formattedExpireDate = data.content.map(val => ({
              ...val,
              id: val.id,
              nome_eventos: val.evento.descricao,
              data_entrada:
                moment(val.dataCadastro).format('DD/MM/YYYY') +
                ' às ' +
                moment(val.dataCadastro).format('LT'),
              cnpj:
                val.beneficiarioFavorecido === null
                  ? '---'
                  : maskCpfCnpj(val.beneficiarioFavorecido.cnpjCpf),
              correntista:
                val.clienteCorrentista === null
                  ? ''
                  : val.clienteCorrentista.nome,
              nome_empresa:
                val.beneficiarioFavorecido == null
                  ? `Aprovação do arquivo ${val.codigoArquivoPagamento}`
                  : val.beneficiarioFavorecido?.nome,
              nome_banco: val.banco === null ? '---' : val.banco?.nome,
              conta_cliente: `${val.clienteConta.agencia} / ${val.clienteConta.conta}-${val.clienteConta.digitoConta}`,
              data_parecer: moment(val.dataSituacao).format('DD/MM/YYYY'),
              permiteReprocessar: val.permiteReprocessar,
              situacao: val.situacao,
              parecer: val.parecer,
              cliente_correntista:
                val.clienteCorrentista == null
                  ? ''
                  : val.clienteCorrentista.nome,
              valor: !!val.valor ? formattedMoney(val.valor) : '--',
              valorSoma: val.valor,
              parecerAprovado: null,
              parecerList: [],
            }));
            const qtdItems = data.totalElements;
            setRowsTable(formattedExpireDate);
            setRowsState(prev => ({ ...prev, loading: false }));
            setInfoEsteira(formSubmit);
            setRowCount(qtdItems);
            return;
          } else if (situacao[0].value === 1) {
            const formatted = data.content.map(val => ({
              ...val,
              id: val.id,
              nome_eventos: val.evento.descricao,
              data_entrada:
                moment(val.dataCadastro).format('DD/MM/YYYY') +
                ' às ' +
                moment(val.dataCadastro).format('LT'),
              cnpj:
                val.beneficiarioFavorecido === null
                  ? '---'
                  : maskCpfCnpj(val.beneficiarioFavorecido.cnpjCpf),
              correntista:
                val.clienteCorrentista === null
                  ? ''
                  : val.clienteCorrentista.nome,
              nome_empresa:
                val.beneficiarioFavorecido == null
                  ? `Aprovação do arquivo ${val.codigoArquivoPagamento}`
                  : val.beneficiarioFavorecido?.nome,
              situacao: val.situacao,
              nome_banco: val.banco === null ? '---' : val.banco?.nome,
              conta_cliente: `${val.clienteConta.agencia} / ${val.clienteConta.conta}-${val.clienteConta.digitoConta}`,
              data_parecer: moment(val.dataSituacao).format('DD/MM/YYYY'),
              cliente_correntista:
                val.clienteCorrentista == null
                  ? ''
                  : val.clienteCorrentista.nome,
              parecer: val.parecer,
              valor: !!val.valor ? formattedMoney(val.valor) : '--',
              valorSoma: val.valor,
              parecerAprovado: null,
              parecerList: [],
            }));

            const qtdItems = data.totalElements;
            const newExpired = formatted.filter(isValido => {
              return isValido.observacaoProcessamento != null;
            });
            setRowCount(qtdItems);
            setInfoEsteira(formSubmit);
            setRowsTable(
              checkStatusEsteira[3].checked === false ? formatted : newExpired,
            );
            return;
          }
          const formatted = data.content.map(val => ({
            ...val,
            id: val.id,
            nome_eventos: val.evento.descricao,
            data_entrada:
              moment(val.dataCadastro).format('DD/MM/YYYY') +
              ' às ' +
              moment(val.dataCadastro).format('LT'),
            cnpj:
              val.beneficiarioFavorecido === null
                ? '---'
                : maskCpfCnpj(val.beneficiarioFavorecido.cnpjCpf),
            correntista:
              val.clienteCorrentista === null
                ? ''
                : val.clienteCorrentista.nome,
            nome_empresa:
              val.beneficiarioFavorecido == null
                ? `Aprovação do arquivo ${val.codigoArquivoPagamento}`
                : val.beneficiarioFavorecido?.nome,
            situacao: val.situacao,
            nome_banco: val.banco === null ? '---' : val.banco?.nome,
            conta_cliente: `${val.clienteConta.agencia} / ${val.clienteConta.conta}-${val.clienteConta.digitoConta}`,
            data_parecer: moment(val.dataSituacao).format('DD/MM/YYYY'),
            cliente_correntista:
              val.clienteCorrentista == null ? '' : val.clienteCorrentista.nome,
            parecer: val.parecer,
            valor: !!val.valor ? formattedMoney(val.valor) : '--',
            valorSoma: val.valor,
            parecerAprovado: null,
            parecerList: [],
          }));
          const qtdItems = data.totalElements;
          const newExpired = formatted.filter(isValido => {
            return isValido.observacaoProcessamento != null;
          });
          setRowCount(qtdItems);
          setInfoEsteira(formSubmit);
          setRowsTable(
            checkStatusEsteira[3].checked === false ? formatted : newExpired,
          );
        }
      } catch (err) {
        setTextNoRows(err.response.data.mensagem);
        if (err.response.status != 404) {
          if (err.response.data.codigo || err.response?.data.mensagem) {
            toastComponentError(
              ` Codigo: ${err.response?.data.codigo} - ${err.response?.data.mensagem}`,
            );
          }
        }
        setRowCount(0);
        setRowsTable([]);
      } finally {
        setRowsState(prev => ({ ...prev, loading: false }));
      }
    },
    [
      dataEntrada,
      contaPadrao,
      dataParecer,
      checkStatusEsteira,
      rowsState,
      InfoEsteira,
      dataEntrada,
    ],
  );

  const changeCheckboxAll = async checked => {
    setCheckboxAll(checked);
    if (checked) {
      const formSubmit = formRef?.current?.getData();
      const situacao = checkStatusEsteira.filter(val => val.checked);
      const { data } = await api_multipague_esteira.get(
        `/administracao?clienteConta=${contaPadrao.id}&dataInicioEntrada=${
          !!dataEntrada.inicial
            ? moment(dataEntrada.inicial).format('YYYY-MM-DD')
            : ''
        }&dataFimEntrada=${
          !!dataEntrada.final
            ? moment(dataEntrada.final).format('YYYY-MM-DD')
            : ''
        }&situacao=${
          situacao.length ? situacao[0].enum : ''
        }&cnpjCpf=${formSubmit.cnpj_empresa.replace(/[^\d]/g, '')}&nome=${
          formSubmit.nome_empresa
        }`,
      );
      const formatted = data.map(val => ({
        ...val,
        id: val.id,
        nome_eventos: val.evento.descricao,
        data_entrada:
          moment(val.dataCadastro).format('DD/MM/YYYY') +
          ' às ' +
          moment(val.dataCadastro).format('LT'),
        cnpj:
          val.beneficiarioFavorecido === null
            ? '---'
            : maskCpfCnpj(val.beneficiarioFavorecido.cnpjCpf),
        nome_empresa:
          val.beneficiarioFavorecido == null
            ? `Aprovação do arquivo ${val.codigoArquivoPagamento}`
            : val.beneficiarioFavorecido?.nome,
        situacao: val.situacao,
        nome_banco: val.banco === null ? '---' : val.banco?.nome,
        conta_cliente: `${val.clienteConta.agencia} / ${val.clienteConta.conta}-${val.clienteConta.digitoConta}`,
        data_parecer: moment(val.dataSituacao).format('DD/MM/YYYY'),
        cliente_correntista:
          val.clienteCorrentista == null ? '' : val.clienteCorrentista.nome,
        parecer: val.parecer,
        valor: !!val.valor ? formattedMoney(val.valor) : '--',
        valorSoma: val.valor,
        parecerAprovado: null,
        parecerList: [],
      }));
      setRowsTableAll(formatted);
      setGridSelection(formatted);
      setSelectionModel(() => data.map(e => e.id));
    } else {
      setRowsTableAll(null);
      setSelectionModel([]);
      setGridSelection([]);
    }
  };

  const PopUpParecerList = () => {
    return (
      <Form ref={formPopUpRef}>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={showPopUpState}
          onClose={() => {
            setShowPopUpState(false);
          }}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade onEnter={() => setHistoricoParecer('')} in={showPopUpState}>
            <Box className={classes.paper}>
              <h2 id="transition-modal-title">
                {aprovar ? 'Aprovar' : 'Negar'}
              </h2>
              <p
                id="transition-modal-description"
                style={{ marginBottom: '10px' }}
              >
                Adicione um parecer antes de enviar.
              </p>
              <TextField
                fullWidth
                style={{ marginBottom: '10px' }}
                id="outlined-multiline-static"
                label="Parecer"
                variant="outlined"
                onChange={e => {
                  setHistoricoParecer(e.target.value);
                }}
              />
              <Button
                color="primary"
                variant="contained"
                disabled={loadingLote}
                title={'Enviar'}
                width={150}
                startIcon={<MdSend color="background" />}
                onClick={() =>
                  checkedParecer(
                    checkedRow.id,
                    aprovar ? true : !aprovar ? false : '',
                    checkedRow.row,
                  )
                }
              ></Button>
              {loadingLote && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Box>
          </Fade>
        </Modal>
      </Form>
    );
  };

  function checkFilters(id) {
    const formatted = checkStatusEsteira.map(check => ({
      ...check,
      checked: check.value === id ? !check.checked : false,
    }));
    setRowsState(prev => ({ ...prev, page: 0 }));
    setCheckStatusEsteira(formatted);
  }

  async function submitApprovalResend(senha) {
    dispatch(showPopUp(false));
    handleClose;
    const { data } = await api_multipague_esteira
      .put(
        `/administracao/${idRegistro}/reprocessar`,
        {},
        infoRedux.contaPadrao?.tokenPorTransacao == true
          ? {
              headers: {
                Authorization: `Bearer ${token}`,
                ContentType: 'application/json',
                'x-assinatura-financeira': senha,
                token: valorSms,
              },
            }
          : {
              headers: {
                Authorization: `Bearer ${token}`,
                ContentType: 'application/json',
                'x-assinatura-financeira': senha,
              },
            },
      )
      .then(function (response) {
        toastComponentSuccess(`Reprocessamento concluído com sucesso!`);
        setIdRegistro('false');
        setTimeout(function () {
          window.location.href = '/Esteira';
        }, 3500);
      })
      .catch(err => {
        if (
          err.response.status == 500 ||
          err.response.status == 400 ||
          err.response.status == 404
        ) {
          toastComponentError(err.response.data.mensagem);
        }
      });
  }

  async function submitApproval(senha) {
    if (aprovacaoEmLote) {
      dispatch(showPopUp(false));
      setLoadingLote(true);
      handleClose;

      const arr = new Array();
      for (var i = 0; i <= gridSelection.length; i++) {
        var convertInt = gridSelection;
        var result = convertInt.map(function (x) {
          return parseInt(x.id);
        });
        var obj = {
          idsTransacoes: result,
          codigoClienteConta: contaPadrao.id,
          parecer: parecerLote,
          situacao: 'APROVADO',
        };
        arr.push(obj);
      }
      const params = obj;
      await api_multipague_esteira
        .post(
          `/administracao/processar-lote`,
          params,
          infoRedux.contaPadrao?.tokenPorTransacao == true
            ? {
                headers: {
                  'x-assinatura-financeira': senha,
                  token: valorSms,
                },
              }
            : {
                headers: {
                  'x-assinatura-financeira': senha,
                },
              },
        )
        .then(function (response) {
          setLoadingLote(false);
          const tamanho = params.idsTransacoes;
          toastComponentSuccess(
            `Aprovação em lote concluída com sucesso! (${tamanho.length} registros)`,
          );
          setTimeout(function () {
            window.location.href = '/Esteira';
          }, 5000);
        })
        .catch(function (error) {
          setLoadingLote(false);
          toastComponentError(
            parecerLote === ''
              ? 'Adicione o parecer'
              : error.response.data.mensagem ||
                  'Houve um erro! Confira sua senha financeira.',
          );
        });
    } else if (reprovacaoEmLote) {
      dispatch(showPopUp(false));
      setLoadingLote(true);
      handleClose;

      const arr = new Array();
      for (var i = 0; i <= gridSelection.length; i++) {
        var convertInt = gridSelection;
        var result = convertInt.map(function (x) {
          return parseInt(x.id);
        });
        var obj = {
          idsTransacoes: result,
          codigoClienteConta: contaPadrao.id,
          parecer: parecerLote,
          situacao: 'NEGADO',
        };
        arr.push(obj);
      }
      const params = obj;
      await api_multipague_esteira
        .post(
          `/administracao/processar-lote`,
          params,
          infoRedux.contaPadrao?.tokenPorTransacao == true
            ? {
                headers: {
                  'x-assinatura-financeira': senha,
                  token: valorSms,
                },
              }
            : {
                headers: {
                  'x-assinatura-financeira': senha,
                },
              },
        )
        .then(function (response) {
          setLoadingLote(false);
          const tamanho = params.idsTransacoes;
          toastComponentSuccess(
            `Reprovação em lote concluída com sucesso! (${tamanho.length} registros)`,
          );
          setTimeout(function () {
            window.location.href = '/Esteira';
          }, 5000);
        })
        .catch(function (error) {
          setLoadingLote(false);
          toastComponentError(
            parecerLote === ''
              ? 'Adicione o parecer'
              : error.response.data.mensagem ||
                  'Houve um erro! Confira sua senha financeira.',
          );
        });
    } else if (reprovar === true) {
      dispatch(showPopUp(false));
      setLoadingLote(true);
      handleClose;

      const arr = new Array();
      for (var i = 0; i <= gridSelection.length; i++) {
        var convertInt = gridSelection;
        var result = convertInt.map(function (x) {
          return parseInt(x.id);
        });
        var obj = {
          idsTransacoes: result,
          codigoClienteConta: contaPadrao.id,
          parecer: parecerLote,
          situacao: 'NEGADO',
        };
        arr.push(obj);
      }
      const params = obj;
      await api_multipague_esteira
        .post(
          `/administracao/processar-lote`,
          params,
          infoRedux.contaPadrao?.tokenPorTransacao == true
            ? {
                headers: {
                  'x-assinatura-financeira': senha,
                  token: valorSms,
                },
              }
            : {
                headers: {
                  'x-assinatura-financeira': senha,
                },
              },
        )
        .then(function (response) {
          toastComponentSuccess('Reprovação em lote concluída com sucesso!');
          setTimeout(function () {
            window.location.href = '/Esteira';
          }, 5000);
        })
        .catch(function (error) {
          setLoadingLote(false);
          toastComponentError(
            parecerLote === ''
              ? 'Adicione o parecer'
              : error.response.data.mensagem,
          );
        });
    } else if (cancelar === true) {
      dispatch(showPopUp(false));
      setLoadingLote(true);
      handleClose;

      const arr = new Array();
      for (var i = 0; i <= gridSelection.length; i++) {
        var convertInt = gridSelection;
        var result = convertInt.map(function (x) {
          return parseInt(x.id);
        });
        var obj = {
          idsTransacoes: result,
          codigoClienteConta: contaPadrao.id,
          parecer: parecerLote,
          situacao: 'CANCELADO',
        };
        arr.push(obj);
      }
      const params = obj;
      await api_multipague_esteira
        .post(
          `/administracao/processar-lote`,
          params,
          infoRedux.contaPadrao?.tokenPorTransacao == true
            ? {
                headers: {
                  'x-assinatura-financeira': senha,
                  token: valorSms,
                },
              }
            : {
                headers: {
                  'x-assinatura-financeira': senha,
                },
              },
        )
        .then(function (response) {
          toastComponentSuccess('Cancelamento em lote concluída com sucesso!');
          setTimeout(function () {
            window.location.href = '/Esteira';
          }, 5000);
        })
        .catch(function (error) {
          setLoadingLote(false);
          toastComponentError(
            parecerLote === ''
              ? 'Adicione o parecer'
              : error.response.data.mensagem,
          );
        });
    }
    try {
      const { approved, row } = approvalUser[0];
      dispatch(showPopUp(false));
      dispatch(showLoading(true));
      const { clienteConta, id } = row;

      const findRow = rowsTable.filter(row => row.id === id);

      if (findRow.length) {
        if (!!historicoParecer && approved != null) {
          const { data } = await api_multipague_esteira.put(
            `/administracao/${id}`,
            {
              codigoClienteConta: clienteConta.id,
              parecer: historicoParecer,
              situacao: approved ? 'APROVADO' : !approved ? 'NEGADO' : null,
            },
            infoRedux.contaPadrao?.tokenPorTransacao == true
              ? {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    ContentType: 'application/json',
                    IdempotencyKey: uuidv4(),
                    'x-assinatura-financeira': senha,
                    token: valorSms,
                  },
                }
              : {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    ContentType: 'application/json',
                    IdempotencyKey: uuidv4(),
                    'x-assinatura-financeira': senha,
                  },
                },
          );

          const formattedRow = rowsTable.map(row => ({
            ...row,
            situacao:
              row.id === id
                ? approved
                  ? 'APROVADO'
                  : !approved
                  ? 'NEGADO'
                  : null
                : row.situacao,
          }));

          setRowsTable(formattedRow);
          toastComponentSuccess(`Situação atualizada com sucesso`);
          submitForm(formRef.current.getData(), 0);

          return;
        } else {
          toastComponentError(
            !historicoParecer && `Erro: Por favor adicione o parecer`,
          );
          return;
        }
      }
    } catch (err) {
      toastComponentError(
        ` Codigo: ${err.response.data.codigo} - ${err.response.data.mensagem}`,
      );
    } finally {
      dispatch(hideLoading(false));
    }
  }

  const [gridSelection, setGridSelection] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [checkboxAll, setCheckboxAll] = useState(false);
  const [rowsTableAll, setRowsTableAll] = useState(null);

  const [totalizando, setTotalizando] = useState('');

  useEffect(() => {
    const selectedRows = !!rowsTableAll
      ? rowsTableAll.filter(row => selectionModel.includes(row.id))
      : rowsTable.filter(row => selectionModel.includes(row.id));

    setGridSelection(selectedRows);
    const str = selectedRows.map(totalizador => totalizador.valorSoma);
    function getSum(total, num) {
      return total + num;
    }
    setTotalizando(str.reduce(getSum, 0));
  }, [selectionModel]);

  useEffect(() => {
    if (rowsState.carregaDatagrid) {
      submitForm(formRef.current.getData(), 0);
    }
  }, [rowsState.pageSize]);

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const abrirTeclado = () => {
    if (infoRedux.contaPadrao?.tokenPorTransacao == true) {
      handleOpenSMS();
    } else if (infoRedux.contaPadrao?.tokenPorTransacao == false) {
      dispatch(showPopUp(true));
    }
  };

  function abrirTecladoReprocessar(event) {
    handleOpenSMS();
    setIdRegistro(event.row.id);
  }

  const handleOpen = () => {
    setOpen(true);
    setReprovacaoEmLote(false);
    setAprovacaoEmLote(true);
  };
  const handleOpenReprovar = () => {
    setOpen(true);
    setAprovacaoEmLote(false);
    setReprovacaoEmLote(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePageSize = pageSize => {
    setRowsState(prev => ({
      ...prev,
      page: 0,
      pageSize: pageSize,
      carregaDatagrid: true,
    }));
  };

  const filterInputs = {
    inputsData: [
      {
        html: () => (
          <RadioButton
            titulo=""
            options={optionsTypePerson}
            optionDefault={typePersonSelected}
            callback={setTypePersonSelected}
          />
        ),
        grid: { xs: 12, sm: 12, md: 12 },
      },
      {
        html: () => (
          <DateComponent
            callback={event =>
              setDataEntrada({
                ...dataEntrada,
                inicial: event,
              })
            }
            label="Data inicial"
            id="data-inicial-filtro"
            value={dataEntrada.inicial}
            dataMinima="1999-01-01"
          />
        ),
        grid: { xs: 12, sm: 3, md: 3 },
      },
      {
        html: () => (
          <DateComponent
            callback={event =>
              setDataEntrada({
                ...dataEntrada,
                final: event,
              })
            }
            label="Data final"
            id="data-inicial-filtro"
            value={dataEntrada.final}
            dataMinima="1999-01-01"
          />
        ),
        grid: { xs: 12, sm: 3, md: 3 },
      },
      {
        html: () =>
          typePersonSelected === 'PF' ? (
            <CPF
              type="cpf_cnpj"
              id="cnpj_empresa"
              name="cnpj_empresa"
              label="CPF"
              variant="outlined"
            />
          ) : (
            <CpfCnpj
              type="cpf_cnpj"
              id="cnpj_empresa"
              name="cnpj_empresa"
              label="CNPJ"
              variant="outlined"
            />
          ),
        grid: { xs: 12, sm: 3, md: 3 },
      },
      {
        html: () => (
          <InputText
            id="nome_empresa"
            name="nome_empresa"
            label="Nome"
            variant="outlined"
          />
        ),
        grid: { xs: 12, sm: 3, md: 3 },
      },
      {
        html: () => (
          <Checks>
            <FormControlLabel
              control={
                <Switch
                  color={'primary'}
                  onChange={event => checkFilters(1)}
                  checked={checkStatusEsteira[0].checked}
                />
              }
              label="Pendentes"
            />
            <FormControlLabel
              control={
                <Switch
                  color={'primary'}
                  onChange={event => checkFilters(2)}
                  checked={checkStatusEsteira[1].checked}
                />
              }
              label="Aprovados"
            />
            <FormControlLabel
              control={
                <Switch
                  color={'primary'}
                  onChange={event => checkFilters(3)}
                  checked={checkStatusEsteira[2].checked}
                />
              }
              label="Negados"
            />
            <FormControlLabel
              control={
                <Switch
                  color={'primary'}
                  onChange={event => checkFilters(4)}
                  checked={checkStatusEsteira[3].checked}
                />
              }
              label="Expirados"
            />
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  onChange={event => checkFilters(5)}
                  checked={checkStatusEsteira[4].checked}
                />
              }
              label="Rejeitados"
            />
          </Checks>
        ),
        grid: { xs: 12, sm: 10, md: 10 },
      },
    ],
    searchButton: {
      searchButton: () => {
        setRowsTableAll(null);
        setSelectionModel([]);
        setGridSelection([]);
        onPage(0);
      },
    },
    cleanButton: {
      cleanButton: () => cleanFilter(),
    },
  };

  return (
    <>
      <ModalOrdemPagamento
        setShowModal={setOpenModalOrdemPagamento}
        showModal={openModalOrdemPagamento}
        ordens={ordensDePagamento}
      />
      <PopUp>
        {idRegistro == 'false' ? (
          <Password callback={data => submitApproval(data)} />
        ) : (
          <Password callback={data => submitApprovalResend(data)} />
        )}
      </PopUp>
      {PopUpParecerList()}
      <Container ref={formRef}>
        <Dialog
          open={openSMS}
          onClose={handleCloseSMS}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Insira o token que você recebeu via SMS
          </DialogTitle>
          <DialogContent style={{ margin: 'auto', textAlign: 'center' }}>
            <SmsToken
              id="outlined-basic"
              variant="outlined"
              placeholder="******"
              size="small"
              type="password"
              name="smsToken"
              inputProps={{ maxLength: 6 }}
              onChange={e => setValorSms(e.target.value)}
            />
          </DialogContent>
          <DialogActions style={{ margin: 'auto' }}>
            <Button
              color="primary"
              variant="contained"
              width={150}
              title={'Prosseguir'}
              style={{ marginTop: '20px', marginBottom: '10px' }}
              onClick={() => enviarSms()}
              isLoading={loadingButtonSms}
              startIcon={
                <MdMonetizationOn color={useTheme().palette.background.paper} />
              }
            >
              Prosseguir
            </Button>
          </DialogActions>
        </Dialog>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade onEnter={() => setParecerLote('')} in={open}>
            <Box className={classes.paper}>
              <h2 id="transition-modal-title">
                {aprovacaoEmLote
                  ? 'Aprovação'
                  : reprovacaoEmLote
                  ? 'Reprovação'
                  : 'Cancelamento'}{' '}
                em Lote
              </h2>
              <p
                id="transition-modal-description"
                style={{ marginBottom: '10px' }}
              >
                Adicione um parecer antes de enviar.
              </p>
              <TextField
                fullWidth
                style={{ marginBottom: '10px' }}
                id="outlined-basic"
                label="Parecer"
                variant="outlined"
                onChange={e => {
                  setParecerLote(e.target.value);
                }}
              />
              <Button
                color="primary"
                variant="contained"
                disabled={loadingLote}
                title={'Enviar'}
                width={150}
                startIcon={<MdSend color="background" />}
                onClick={() => abrirTeclado()}
              ></Button>
              {loadingLote && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Box>
          </Fade>
        </Modal>

        <Modal
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className={classes.modal}
          open={parecerOpen.open}
          onClose={() => setParecerOpen({ open: false, parecer: '' })}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Box
            style={{
              minWidth: '20vw',
              minHeight: '8vw',
              padding: 10,
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <h2
              style={{ paddingBottom: 5, textAlign: 'center' }}
              id="transition-modal-title"
            >
              Parecer
            </h2>
            <Divider />
            <Typography
              variant="p"
              style={{ marginTop: 10, textAlign: 'center' }}
            >
              {parecerOpen.parecer}
            </Typography>
          </Box>
        </Modal>

        <DataGridPage
          title="Esteira"
          crumb={[
            {
              name: 'Dashboard',
              link: '/dashboard',
            },
          ]}
          headerComponent={() =>
            permissionAdmin && (
              <div
                style={{
                  padding: '1rem',
                  paddingBottom: '0px',
                  width: '100%',
                }}
              >
                <BotoesPrint>
                  <Grid container>
                    <Grid
                      className={classes.buttonsHeaderGrid}
                      item
                      xs={12}
                      md={6}
                      xl={6}
                    >
                      <Chip
                        label={
                          formattedMoney(totalizando)
                            ? 'Total selecionado: ' +
                              formattedMoney(totalizando)
                            : 'Total selecionado: 0'
                        }
                        color="primary"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <BotoesPrint2>
                        <Chip
                          variant="outlined"
                          label="Reprovação em Lote"
                          color="secondary"
                          disabled={!checkStatusEsteira[0].checked}
                          onClick={handleOpenReprovar}
                          className={classes.buttonsHeaderGrid}
                        />
                        <Chip
                          label="Aprovação em Lote"
                          color="primary"
                          variant="outlined"
                          onClick={handleOpen}
                          className={classes.buttonsHeaderGrid}
                          disabled={!checkStatusEsteira[0].checked}
                        />
                      </BotoesPrint2>
                    </Grid>
                  </Grid>
                </BotoesPrint>
              </div>
            )
          }
          minHeight="600px"
          checkboxSelection
          rows={rowsTable}
          formData={filterInputs}
          columns={columnsTable}
          pagination
          loading={rowsState.loading}
          rowCount={rowCount}
          page={rowsState.page}
          pageSize={rowsState.pageSize}
          onSelectionModelChange={newSelection => {
            const ids = Object.values(newSelection);
            const newIds = ids.filter(id => !selectionModel.includes(id));
            setSelectionModel(old => [...old, ...newIds]);
          }}
          selectionModel={selectionModel}
          onPageChangeFunction={onPage}
          textNoRows={textNoRows}
          onPageSizeChangeFunction={newPageSize => handlePageSize(newPageSize)}
        />
      </Container>
    </>
  );
};

export default Estera;
