export const columnsTable = [
  {
    field: 'idTermoClienteConta',
    headerName: 'Termo Cliente Conta',
    width: 170,
  },
  {
    field: 'idTermoCorrentista',
    headerName: 'Termo Correntista',
    type: 'string',
    width: 150,
  },
  { field: 'assunto', headerName: 'Assunto', flex: 1, width: 180 },
  {
    field: 'observacao',
    headerName: 'Observação',
    width: 180,
    flex: 1,
  },
  {
    field: 'situacao',
    headerName: 'Situacao',
    width: 100,
  },
];
