import React, { useState } from 'react';

import { DataGridPage } from '../../components/DataGrid/DataGridPaginationServer';
import Totalizador from './Totalizador';
import { useGetOrdemDePagamentoItens } from '../../hooks/ordem-pagamento/ordem-pagamento-itens';
import { useHistory } from 'react-router-dom';
import { columnsTable } from './columsTable';

const OrdemPagamento = () => {
  const history = useHistory();
  const [page, setPage] = useState(0);

  const idOrdemPagamento = history.location?.state?.ordemPagamento;
  const ordensPagamento = history.location?.state?.ordensPagamento;

  const { data, isLoading } = useGetOrdemDePagamentoItens(
    idOrdemPagamento,
    page,
  );

  return (
    <DataGridPage
      title="Ordem de Pagamento"
      crumb={[
        { name: 'Dashboard', link: '/dashboard' },
        { name: 'Esteira', link: '/Esteira' },
      ]}
      headerComponent={() => (
        <Totalizador
          data={data?.content}
          qntTitulos={data?.totalElements}
          ordensDePagamento={ordensPagamento}
        />
      )}
      minHeight="600px"
      rows={data?.content || []}
      columns={columnsTable}
      loading={isLoading}
      pagination
      rowsPerPageOptions={[10]}
      rowCount={data?.totalElements}
      page={data?.pageable?.pageNumber}
      pageSize={20}
      onPageChangeFunction={page => setPage(page)}
    />
  );
};

export default OrdemPagamento;
